import * as React from "react";
import type { SVGProps } from "react";
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M6.667 2.5A4.171 4.171 0 0 0 2.5 6.667v6.666A4.171 4.171 0 0 0 6.667 17.5h6.666a4.171 4.171 0 0 0 4.167-4.167V6.667A4.171 4.171 0 0 0 13.333 2.5H6.667Zm0 1.667h6.666c1.379 0 2.5 1.121 2.5 2.5v6.666c0 1.379-1.121 2.5-2.5 2.5H6.667a2.503 2.503 0 0 1-2.5-2.5V6.667c0-1.379 1.121-2.5 2.5-2.5Zm7.5.833a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667ZM10 5.833A4.171 4.171 0 0 0 5.833 10 4.171 4.171 0 0 0 10 14.167 4.171 4.171 0 0 0 14.167 10 4.171 4.171 0 0 0 10 5.833ZM10 7.5c1.378 0 2.5 1.122 2.5 2.5s-1.122 2.5-2.5 2.5A2.503 2.503 0 0 1 7.5 10c0-1.378 1.122-2.5 2.5-2.5Z" />
  </svg>
);
export default SvgInstagram;
