import { createApi } from '@reduxjs/toolkit/query/react'
import { Post } from '../../pages/LandingPage/Models/Post'
import { baseQuery, baseQueryWithReauth } from '../Core/BaseApi'
import { ContentModel } from './Model/ContentModel'
import { CreateContentReq } from './Model/CreateContentReqRes'
import { DefaultRequest } from '../Core/DefaultResponse'
import { EditNameReq } from './Model/EditNameReq'
import {CopyRevisionModel} from "./Model/CopyRevisionModel";
import {ForgotPasswordReq, ResetPasswordReq} from "./Model/ForgotPasswordReq";
import {accessToken} from "../../store/persistedSlice";
import { LoopsRequest, LoopsResponse } from './Model/LoopsReqRes'
import { CopyLoopModel } from './Model/CopyLoopModel'


export const ContentApi = createApi({
    reducerPath: "Content",
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        createContent: builder.mutation<ContentModel, CreateContentReq>({
            query: (body) => ({
                url: `content`,
                method: 'POST',
                body,
              }),
        }),
        editName: builder.mutation<ContentModel, EditNameReq>({
            query: ({updated_name, contentId}) => ({
                url: `content/${contentId}`,
                method: 'PUT',
                body: {updated_name: updated_name}
              }),
        }),
        delete: builder.mutation<void, DefaultRequest>({
            query: ({id}) => ({
                url: `content/${id}`,
                method: 'DELETE',
              }),
        }),
        getContent: builder.query<ContentModel, DefaultRequest>({
            query: ({id}) => ({
                url:  `content/${id}`,
            })
        }),
        getLoops: builder.query<LoopsResponse[], LoopsRequest>({
            query: (req) => ({
                url:  `content/loops?page=${req.page}&size=${req.size}&filter_text=${req.filterText}&favorite=${req.favorite}&platform=${req.platform}&content_type=${req.contentType}`,
            })
        }),
        getList: builder.query<ContentModel[], DefaultRequest>({
            query: ({page, size}) => ({
                url:  `content/list?page=${page ?? 0}&size=${size ?? 999}`,
            })
        }),
        copyRevision: builder.mutation< ContentModel, CopyRevisionModel>({
            query: (body) => ({
                url: `content/clone-revision`,
                method: 'POST',
                body
            }),
        }),
        copyLoop: builder.mutation< ContentModel, CopyLoopModel>({
            query: (body) => ({
                url: `content/clone`,
                method: 'POST',
                body
            }),
        }),
        forgotPassword: builder.mutation<any, ForgotPasswordReq>({
            query: ({email}) => ({
                url: `auth/forgot-password`,
                method: 'POST',
                body: {email: email}
            }),
            transformErrorResponse: (response) => response.data
        }),
        resetPassword: builder.mutation<any, ResetPasswordReq>({
            query: ({password,token}) => ({
                url: `auth/reset-password`,
                method: 'POST',
                body: {password: password,token: token}
            }),
            transformErrorResponse: (response) => response.data
        })
    }),
})

export const {
    useCreateContentMutation,
    useEditNameMutation,
    useDeleteMutation: useDeleteContentMutation,
    useLazyGetContentQuery,
    useLazyGetListQuery,
    useCopyRevisionMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useLazyGetLoopsQuery,
    useCopyLoopMutation
} = ContentApi
