import * as React from "react";
import type { SVGProps } from "react";
const SvgPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M18.333 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0ZM4.68 9.8c0-2.629 1.91-5.043 5.506-5.043 2.891 0 5.137 2.06 5.137 4.813 0 2.872-1.81 5.183-4.324 5.183-.844 0-1.638-.439-1.91-.957l-.519 1.981c-.188.724-.696 1.631-1.036 2.185a8.265 8.265 0 0 1-.57-.208c-.073-.66-.138-1.667.03-2.385.15-.65.977-4.142.977-4.142s-.25-.5-.25-1.237c0-1.159.672-2.024 1.508-2.024.711 0 1.054.534 1.054 1.174 0 .457-.186 1.06-.381 1.692-.11.358-.224.725-.31 1.082-.196.83.417 1.507 1.235 1.507 1.48 0 2.62-1.562 2.62-3.816 0-1.995-1.434-3.39-3.481-3.39-2.371 0-3.763 1.778-3.763 3.616 0 .716.276 1.484.62 1.902a.25.25 0 0 1 .058.239c-.038.16-.105.43-.158.645l-.073.3c-.036.153-.121.185-.279.111C5.33 12.544 4.68 11.022 4.68 9.8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPinterest;
