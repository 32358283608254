import * as React from "react";
const SvgNotificationsWarningLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={5} />
    <path
      fill="#F55"
      d="M26.714 28.333H13.286a2.518 2.518 0 0 1-2.182-3.778l6.714-11.63a2.519 2.519 0 0 1 4.364 0l6.714 11.63a2.518 2.518 0 0 1-2.182 3.778Z"
      opacity={0.3}
    />
    <path
      fill="#F55"
      d="M20 25a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.667ZM20 21.666a.833.833 0 0 1-.833-.833v-3.334a.833.833 0 0 1 1.666 0v3.334a.833.833 0 0 1-.833.833Z"
    />
  </svg>
);
export default SvgNotificationsWarningLogo;
