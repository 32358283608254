import * as React from "react";
const SvgSidebarNotificationsCollapsedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width="40" height="40" fill="#121315" />
    <path
      fill={props.fill ?? "#8E8E8E"}
      d="M14.167 26.614a.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.594-.24H15v-5.833a4.88 4.88 0 0 1 1.042-3.073 4.807 4.807 0 0 1 2.708-1.76v-.584c0-.347.121-.642.364-.885.244-.243.539-.365.886-.365s.642.122.885.365.365.538.365.885v.584a4.807 4.807 0 0 1 2.708 1.76A4.88 4.88 0 0 1 25 19.114v5.833h.833c.236 0 .434.08.594.24.16.16.24.357.24.594 0 .236-.08.434-.24.593a.806.806 0 0 1-.594.24H14.167Zm5.833 2.5c-.458 0-.85-.163-1.177-.49a1.604 1.604 0 0 1-.49-1.177h3.334c0 .459-.164.851-.49 1.177-.326.327-.719.49-1.177.49Zm-3.333-4.167h6.666v-5.833a3.21 3.21 0 0 0-.979-2.354A3.21 3.21 0 0 0 20 15.78a3.21 3.21 0 0 0-2.354.98 3.21 3.21 0 0 0-.98 2.354v5.833Z"
    />
  </svg>
);
export default SvgSidebarNotificationsCollapsedIcon;
