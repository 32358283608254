import * as React from "react";
const SvgNoNotificationsYet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={83}
    fill="none"
    {...props}
  >
    <rect width={167} height={67} x={16.5} y={0.5} fill="#fff" rx={6.5} />
    <rect width={167} height={67} x={16.5} y={0.5} stroke="#EAEBE6" rx={6.5} />
    <rect width={183} height={67} x={8.5} y={8.5} fill="#fff" rx={6.5} />
    <rect width={183} height={67} x={8.5} y={8.5} stroke="#EAEBE6" rx={6.5} />
    <rect width={199} height={66} x={0.5} y={16.5} fill="#fff" rx={6.5} />
    <rect width={199} height={66} x={0.5} y={16.5} stroke="#EAEBE6" rx={6.5} />
    <rect width={40} height={40} x={8} y={24} fill="#ECECEC" rx={3} />
    <rect width={113} height={6} x={58} y={24} fill="#ECECEC" rx={3} />
    <rect width={80} height={6} x={58} y={38} fill="#ECECEC" rx={3} />
    <rect width={80} height={6} x={58} y={69} fill="#ECECEC" rx={3} />
  </svg>
);
export default SvgNoNotificationsYet;
