import { useEffect, useState } from "react";
import ColorPalettePickerView from "../../../components/ColorPalette/ColorPalettePickerView";
import { ColorPalettePickerModel } from "../../../models/ColorPalettePickerModel";
import RevisionTextEditor from "../../../components/RevisionTextEditor";
import { Revision, useRegenerateMutation } from "../../../services/RevisionApi";
import { BrandRequest, FileProps } from "../../../services/BrandApi";
import PrimaryButton from "../../../components/PrimaryButton";
import { t } from "i18next";
import RevisionImageEditor from "../../../components/RevisionImageEditor";
import { toggleLoading } from "../../../store/appSlice";
import { useDispatch } from "react-redux";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import { PhotoshootEditRequest, useEditImageMutation } from "../../../services/CreativeApi";
import { Content } from "../../../services/ContentApi";


export interface IRevisionEditorUpdateImageProps {
  className?: string
  revision?: Revision
  content?: Content
  brand?: BrandRequest
  onRevisionUpdate: (revision: Revision) => void
  onRevisionSaved: (revision: Revision) => void
}

function RevisionEditorUpdateImage(props: IRevisionEditorUpdateImageProps) {
  const dispatch = useDispatch()
  const [logoFileId, setLogoFileId] = useState<string>()
  const [productFileId, setProductFileId] = useState<string>()
  const [aiBackgroundImageFileId, setAiBackgroundImageFileId] = useState<string>()
  const [colors, setColors] = useState<string[]>()
  const [fonts, setFonts] = useState<string[]>()
  const [revision, setRevision] = useState<Revision>()
  const [regenerateRevision, { isLoading: regenerating, isSuccess: regenrationSuccess, isError: regenerationError, data: updatedRevision }] = useRegenerateMutation()
  const [
    regenerateProductRevision,
    {
      isLoading: regeneratingProduct,
      isSuccess: regenerationProductSuccess,
      isError: regenerationProductError,
      data: updatedCreativeResponse,
    },
  ] = useEditImageMutation();
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false)
  const [saveRevisionCalled, setSaveRevisionCalled] = useState<boolean>(false)
  const [imageOpsInProggress, setImageOpsInProggress] = useState<boolean[]>([false, false, false])

  useEffect(() => {
    setRevision(props.revision)
  }, [props.revision])

  useEffect(() => {
    if (regenrationSuccess && updatedRevision && saveRevisionCalled) {
      props.onRevisionSaved(updatedRevision)
    }
  }, [regenrationSuccess, updatedRevision])

  useEffect(() => {
    if (updatedRevision) {
      setRevision(updatedRevision)
      props?.onRevisionUpdate(updatedRevision)
    }
    else {
      return
    }
  }, [updatedRevision])


  useEffect(() => {
    if (
      regenerationProductSuccess &&
      updatedCreativeResponse &&
      saveRevisionCalled
    ) {
      if (revision && updatedCreativeResponse.revisions) {
        props.onRevisionSaved({ ...revision, generated_content: updatedCreativeResponse.revisions[0]?.generated_content });
      }
    }
  }, [regenerationProductSuccess, updatedCreativeResponse]);

  useEffect(() => {
    if (updatedCreativeResponse) {
      if (revision && updatedCreativeResponse.revisions) {
        props.onRevisionUpdate({
          ...revision,
          generated_content:
            updatedCreativeResponse.revisions[0]?.generated_content,
        });
      }
    } else {
      return;
    }
  }, [updatedCreativeResponse]);

  useEffect(() => {
    if (props.revision) {
      let colorSet = props.revision?.colorSet ?? ""
      if (colorSet.length > 0) {
        setColors(JSON.parse(colorSet))
      }
      else {
        // console.log(`empty color set ${colorSet}`)
        return;
      }
    } else {
      // console.log("revision not found")
      return;
    }

  }, [props.revision])

  useEffect(() => {
    if (props.revision) {
      let fontSet = props.revision?.fontSet ?? ""
      if (fontSet.length > 0) {
        setFonts(JSON.parse(fontSet))
      }
      else {
        // console.log(`empty font set ${fontSet}`)
        return
      }
    } else {
      // console.log("revision not found")
      return
    }

  }, [props.revision])

  useEffect(() => {
    setRevision({
      ...revision,
      colorSet: JSON.stringify(colors)
    })
  }, [colors])

  useEffect(() => {
    setRevision({
      ...revision,
      fontSet: JSON.stringify(fonts)
    })
  }, [fonts])

  useEffect(() => {
    if ((props.content?.platform ?? "") === "Product") {
      const photoshootContent = JSON.parse(
        props.content?.photoshootContent ?? ""
      );
      const productPhotoId = (photoshootContent.product_photo_id ?? "");
      setProductFileId(productPhotoId);
    }
    else {
      setLogoFileId(props.content?.logo_file_id);
      setProductFileId(props.content?.product_file_id);
    }

  }, [props.content])

  useEffect(() => {
    setAiBackgroundImageFileId(revision?.aiImage)
  }, [revision])

  useEffect(() => {
    dispatch(
      toggleLoading(
        regenerating ||
        imageOpsInProggress.some((e) => e) ||
        regeneratingProduct
      )
    );
  }, [regenerating, imageOpsInProggress, regeneratingProduct])


  function apply(regenerate_background_ai_image: boolean, regenerate_header_text: boolean, regenerate_footer_text: boolean, regenerate_cta: boolean, apply_changes_to_final_image: boolean, save_revision: boolean) {
    if ((props.content?.platform ?? "") === "Product") {
      const params = {
        photoshootEditRequest: {
          content_id: props.revision?.id?.content_id ?? "",
          revision_id: props.revision?.id?.revision_id ?? "",
          product_file_id: productFileId ?? "",
          color_set: JSON.parse(revision?.colorSet ?? "") ?? "",
          font_set: JSON.parse(revision?.fontSet ?? "") ?? "",
          texts: [(revision?.ctatext ?? ""), (revision?.headerText ?? "")],
          save_revision: save_revision
        } as PhotoshootEditRequest,
      };
      setSaveRevisionCalled(save_revision);
      regenerateProductRevision(params);
    }
    else {
      const params = {
        regenerateRevisionRequest: {
          content_id: props.revision?.id?.content_id ?? "",
          revision_id: props.revision?.id?.revision_id ?? "",
          ai_background_image_file_id: aiBackgroundImageFileId ?? "",
          ai_header_text: revision?.headerText ?? "",
          ai_footer_text: revision?.footerText ?? "",
          ai_cta_text: revision?.ctatext ?? "",
          product_image_file_id: productFileId ?? "",
          logo_image_file_id: logoFileId ?? "",
          color_set: JSON.parse(revision?.colorSet ?? "") ?? "",
          font_set: JSON.parse(revision?.fontSet ?? "") ?? "",
          regenerate_background_ai_image: regenerate_background_ai_image,
          regenerate_header_text: regenerate_header_text,
          regenerate_footer_text: regenerate_footer_text,
          regenerate_cta: regenerate_cta,
          apply_changes_to_final_image: apply_changes_to_final_image,
          save_revision: save_revision,
        },
      };
      setSaveRevisionCalled(save_revision);
      regenerateRevision(params);
    }

  }

  function getBrandColorSet() {
    let result: ColorPalettePickerModel[] = []
    if (props.brand?.brand_colors) {
      const brandColors = props.brand?.brand_colors.split(",")
      result.push({
        id: "-1",
        title: "Brand Set",
        color1: brandColors[0],
        color2: brandColors[1],
        color3: brandColors[2],
        color4: brandColors[3],
        color5: brandColors[4],
      } as ColorPalettePickerModel)
    }
    if (props.revision) {
      let revisionColorSet = props.revision?.colorSet ?? ""
      if (revisionColorSet.length > 0) {
        const revisionColors = JSON.parse(revisionColorSet)
        result.push({
          id: "-1",
          title: "Revision Set",
          color1: revisionColors[0],
          color2: revisionColors[1],
          color3: revisionColors[2],
          color4: revisionColors[3],
          color5: revisionColors[4],
        } as ColorPalettePickerModel)
      }
    }

    return result
  }

  console.log("Content is: ", props.content);
  console.log("Condition is: ",)
  return (
    <div className="flex flex-col w-full lg:w-[320px]">
      <div className="w-full transition-all  bg-white md:h-[calc(100vh-178px)] lg:flex flex-col -ml-2 justify-between align-between  items-start overflow-y-scroll scrollbar-hide">
        <div className="flex flex-col  item-start w-full grow">
          <div className="flex-col py-[20px] px-[20px] justify-center item-center g-[10px]">
            <div className="flex-col item-start g-[20px]">
              {/* <ColorPalettePickerView
                            className="pb-[20px] border-b-1 border-border"
                            isDisabled={regenerating}
                            items={getBrandColorSet() ?? []}
                            onChange={function (selectedColorSets: ColorPalettePickerModel[]): void {
                                if (colors && selectedColorSets.length > 0) {
                                    // only single color set is selected
                                    const newColorSet = selectedColorSets[0]
                                    let colorsCopy = [...colors]
                                    colorsCopy[2] = newColorSet.color1
                                    colorsCopy[3] = newColorSet.color2
                                    colorsCopy[4] = newColorSet.color3
                                    colorsCopy[5] = newColorSet.color4
                                    colorsCopy[6] = newColorSet.color5
                                    setColors(colorsCopy)
                                }
                            }}
                            title="Color Palette"
                            allowMultiSelect={false}
                            selectedValue={colors}
                        /> */}
              {((props.content?.platform ?? "") !== "Product" ||
                ((props.content?.platform ?? "") === "Product" &&
                  props.revision?.headerText)) && (
                  <RevisionTextEditor
                    className="pb-[20px] border-b-1 border-border"
                    isDisabled={
                      regenerating || imageOpsInProggress.some((e) => e)
                    }
                    title={
                      (props.content?.platform ?? "") === "Product"
                        ? "Punchy Message"
                        : "Headline"
                    }
                    text={revision?.headerText ?? ""}
                    color={
                      (props.content?.platform ?? "") === "Product"
                        ? colors &&
                        colors[revision?.colorSetIndex?.aiText2 ?? 12]
                        : colors &&
                        colors[revision?.colorSetIndex?.aiText1 ?? 1]
                    }
                    fontName={
                      (props.content?.platform ?? "") === "Product"
                        ? fonts &&
                        fonts[revision?.fontSetIndex?.aiText2 ?? 0]
                        : fonts &&
                        fonts[revision?.fontSetIndex?.aiText1 ?? 0]
                    }
                    fontSelected={function (
                      font: FileProps | undefined
                    ): void {
                      if (fonts) {
                        let fontsCopy = [...fonts];
                        fontsCopy[
                          (props.content?.platform ?? "") === "Product"
                            ? revision?.fontSetIndex?.aiText2 ?? 0
                            : revision?.fontSetIndex?.aiText1 ?? 0
                        ] = font?.name ?? "";
                        setFonts(fontsCopy);
                      }
                      setSaveEnabled(false);
                    }}
                    colorSelected={function (
                      color: string | undefined
                    ): void {
                      if (colors && color) {
                        let colorsCopy = [...colors];
                        colorsCopy[
                          (props.content?.platform ?? "") === "Product"
                            ? revision?.colorSetIndex?.aiText2 ?? 12
                            : revision?.colorSetIndex?.aiText1 ?? 0
                        ] = color ?? "";
                        setColors(colorsCopy);
                      }
                      setSaveEnabled(false);
                    }}
                    onTextChange={function (newText: string) {
                      setRevision({
                        ...revision,
                        headerText: newText,
                      });
                      setSaveEnabled(false);
                    }}
                    onGenerateWithAiClicked={() => {
                      apply(false, true, false, false, true, false);
                    }}
                  />
                )}
              {(props.content?.image_height ?? 1080) > 50 &&
                (props.content?.platform ?? "") !== "Product" && (
                  <RevisionTextEditor
                    className="pt-[20px] pb-[20px] border-b-1 border-border"
                    isDisabled={
                      regenerating || imageOpsInProggress.some((e) => e)
                    }
                    title={"Subheading"}
                    text={revision?.footerText ?? ""}
                    color={
                      colors && colors[revision?.colorSetIndex?.aiText2 ?? 2]
                    }
                    fontName={
                      fonts && fonts[revision?.fontSetIndex?.aiText2 ?? 1]
                    }
                    fontSelected={function (
                      font: FileProps | undefined
                    ): void {
                      if (fonts) {
                        let fontsCopy = [...fonts];
                        fontsCopy[revision?.fontSetIndex?.aiText2 ?? 1] =
                          font?.name ?? "";
                        setFonts(fontsCopy);
                      }
                    }}
                    colorSelected={function (
                      color: string | undefined
                    ): void {
                      if (colors) {
                        let colorsCopy = [...colors];
                        colorsCopy[revision?.colorSetIndex?.aiText2 ?? 2] =
                          color ?? "";
                        setColors(colorsCopy);
                      }
                    }}
                    onTextChange={function (newText: string) {
                      setRevision({
                        ...revision,
                        footerText: newText,
                      });
                      setSaveEnabled(false);
                    }}
                    onGenerateWithAiClicked={() => {
                      apply(false, false, true, false, true, false);
                    }}
                  />
                )}
              {((props.content?.image_height ?? 1080) < 500 ||
                props.content?.cta ||
                ((props.content?.platform ?? "") === "Product" &&
                  props.revision?.ctatext)) && (
                  <RevisionTextEditor
                    className="pt-[20px] pb-[20px] border-b-1 border-border"
                    isDisabled={
                      regenerating || imageOpsInProggress.some((e) => e)
                    }
                    title={
                      (props.content?.platform ?? "") === "Product"
                        ? "Burst Message"
                        : "Call to action"
                    }
                    text={revision?.ctatext ?? ""}
                    color={
                      (props.content?.platform ?? "") === "Product"
                        ? colors &&
                        colors[revision?.colorSetIndex?.aiText1 ?? 11]
                        : colors &&
                        colors[revision?.colorSetIndex?.aiText3 ?? 3]
                    }
                    fontName={
                      (props.content?.platform ?? "") === "Product"
                        ? fonts && fonts[revision?.fontSetIndex?.aiText1 ?? 0]
                        : fonts && fonts[revision?.fontSetIndex?.aiText3 ?? 2]
                    }
                    fontSelected={function (font: FileProps | undefined): void {
                      if (fonts) {
                        let fontsCopy = [...fonts];
                        fontsCopy[
                          (props.content?.platform ?? "") === "Product"
                            ? revision?.fontSetIndex?.aiText1 ?? 0
                            : revision?.fontSetIndex?.aiText3 ?? 2
                        ] = font?.name ?? "";
                        setFonts(fontsCopy);
                      }
                    }}
                    colorSelected={function (color: string | undefined): void {
                      if (colors && color) {
                        let colorsCopy = [...colors];
                        colorsCopy[
                          (props.content?.platform ?? "") === "Product"
                            ? revision?.colorSetIndex?.aiText1 ?? 11
                            : revision?.colorSetIndex?.aiText3 ?? 3
                        ] = color ?? "";
                        setColors(colorsCopy);
                      }
                    }}
                    onTextChange={function (newText: string) {
                      setRevision({
                        ...revision,
                        ctatext: newText,
                      });
                      setSaveEnabled(false);
                    }}
                    onGenerateWithAiClicked={() => {
                      apply(false, false, false, true, true, false);
                    }}
                  />
                )}

              <div className="flex flex-row justify-between pt-[20px] pb-[20px] border-b-1 gap-[10px] w-full mt-2">
                <div className="flex items-center text-H6 text-black">
                  Main Color
                </div>
                <ColorPicker
                  className="shrink-0 rounded-full h-[40px] w-[40px]"
                  hideDeleteButton={true}
                  isDisabled={
                    regenerating || imageOpsInProggress.some((e) => e)
                  }
                  selectedColor={colors && colors[0]}
                  colorSelected={function (color: string | undefined): void {
                    if (colors) {

                      let colorsCopy = [...colors];
                      colorsCopy[0] = color ?? "";
                      console.log("Colors main: ", colorsCopy);
                      setColors(colorsCopy);
                    }
                  }}
                  popoverClassName="relative -left-[116px] -bottom-[143px]"
                />
              </div>
              {logoFileId &&
                logoFileId.length > 0 &&
                (props.content?.image_height ?? 1080) > 50 && (
                  <RevisionImageEditor
                    title={t("Logo")}
                    isDisabled={
                      regenerating || imageOpsInProggress.some((e) => e)
                    }
                    className="pt-[20px] pb-[20px] border-border"
                    editorType="logo"
                    imageFileId={logoFileId}
                    onImageChanged={(file) => {
                      setLogoFileId(file.uploadedId);
                      setSaveEnabled(false);
                    }}
                    onImageDeleted={() => {
                      setLogoFileId("");
                    }}
                    onImageOpsProgress={(progress: boolean) => {
                      let imageOpsInProgressCopy = [...imageOpsInProggress];
                      if (progress != imageOpsInProggress[0]) {
                        imageOpsInProgressCopy[0] = progress;
                        setImageOpsInProggress(imageOpsInProgressCopy);
                      }
                    }}
                  />
                )}

              {productFileId &&
                productFileId.length > 0 &&
                (props.content?.image_height ?? 1080) > 50 && (
                  <RevisionImageEditor
                    title={t("Product Image")}
                    isDisabled={
                      regenerating || imageOpsInProggress.some((e) => e)
                    }
                    className="pt-[20px] pb-[20px]  border-border"
                    editorType="product"
                    imageFileId={productFileId}
                    onImageChanged={(file) => {
                      // console.log(`product file ${file.uploadedId}`)
                      setProductFileId(file.uploadedId);
                      setSaveEnabled(false);
                    }}
                    onImageDeleted={() => {
                      setProductFileId("");
                    }}
                    onImageOpsProgress={(progress: boolean) => {
                      let imageOpsInProgressCopy = [...imageOpsInProggress];
                      if (
                        Boolean(progress) !== Boolean(imageOpsInProggress[1])
                      ) {
                        imageOpsInProgressCopy[1] = progress;
                        setImageOpsInProggress(imageOpsInProgressCopy);
                      }
                    }}
                  />
                )}
              {(props.content?.platform ?? "") !== "Product" && (
                <RevisionImageEditor
                  title={t("AI Image")}
                  isDisabled={
                    regenerating || imageOpsInProggress.some((e) => e)
                  }
                  className="pt-[20px] pb-[20px] border-border"
                  imageFileId={aiBackgroundImageFileId}
                  editorType="ai"
                  onGenerateWithAiClicked={() => {
                    apply(true, false, false, false, true, false);
                  }}
                  onImageDeleted={() => {
                    setAiBackgroundImageFileId("");
                  }}
                  onImageChanged={(file) => {
                    // console.log(`product file ${file.uploadedId}`)
                    setAiBackgroundImageFileId(file.uploadedId);
                    setSaveEnabled(false);
                  }}
                  onImageOpsProgress={(progress: boolean) => {
                    let imageOpsInProgressCopy = [...imageOpsInProggress];
                    if (progress != imageOpsInProggress[2]) {
                      imageOpsInProgressCopy[2] = progress;
                      setImageOpsInProggress(imageOpsInProgressCopy);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[122px] p-[15px] pt-[20px] -ml-2 bg-white border-t-1 border-stone-200 justify-end items-center gap-2.5 flex flex-col">
        <PrimaryButton
          className="w-full  item-center "
          isDisabled={regenerating || imageOpsInProggress.some((e) => e)}
          title={t("update-image")}
          onClick={function (): void {
            apply(false, false, false, false, true, false);
            setSaveEnabled(true);
          }}
        />
        <PrimaryButton
          className="w-full  item-center "
          isDisabled={
            regenerating || imageOpsInProggress.some((e) => e) || !saveEnabled
          }
          title={t("save-image")}
          onClick={function (): void {
            apply(false, false, false, false, true, true);
          }}
        />
      </div>
    </div>
  );
};

export default RevisionEditorUpdateImage;


