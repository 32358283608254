import * as React from "react";
const RefreshOne1 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.5"
              d="M11.5784 9.70673L9.45711 11.828C9.06658 12.2186 9.06658 12.8517 9.45711 13.2423L11.5784 15.3636C11.969 15.7541 12.6021 15.7541 12.9926 15.3636L15.114 13.2423C15.5045 12.8517 15.5045 12.2186 15.114 11.828L12.9926 9.70673C12.6021 9.3162 11.969 9.3162 11.5784 9.70673Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.24998 5.40784C7.03461 3.6137 9.49 2.5 12.2455 2.5C16.1741 2.5 19.5706 4.76463 21.2055 8.05502C21.4513 8.54962 21.2495 9.14979 20.755 9.39554C20.2604 9.64129 19.6602 9.43957 19.4144 8.94498C18.1044 6.30837 15.3859 4.5 12.2455 4.5C9.72579 4.5 7.51419 5.66612 6.06181 7.5H7.63998C8.19226 7.5 8.63998 7.94771 8.63998 8.5C8.63998 9.05228 8.19226 9.5 7.63998 9.5H3.24998V4.5C3.24998 3.94772 3.69769 3.5 4.24998 3.5C4.80226 3.5 5.24998 3.94772 5.24998 4.5V5.40784ZM3.805 15.6045C4.2996 15.3587 4.89977 15.5604 5.14552 16.055C6.45558 18.6916 9.17455 20.5 12.3145 20.5C14.8342 20.5 17.0458 19.3339 18.4981 17.5H16.92C16.3677 17.5 15.92 17.0523 15.92 16.5C15.92 15.9477 16.3677 15.5 16.92 15.5H21.31V20.5C21.31 21.0523 20.8623 21.5 20.31 21.5C19.7577 21.5 19.31 21.0523 19.31 20.5V19.5922C17.5253 21.3863 15.07 22.5 12.3145 22.5C8.38641 22.5 4.98938 20.2354 3.35443 16.945C3.10868 16.4504 3.31041 15.8502 3.805 15.6045Z"
              fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default RefreshOne1;
