import { useEffect } from "react";
import { setDialog, toggleDialog } from "../../store/appSlice";
import { useAppDispatch } from "../../helpers/hooks";
import PrimaryButton from "../PrimaryButton";
import PartyBaloon from "../../assets/IconComponents/PartyBaloon";

export interface IConfirmationPopupProps {
    heading: string
    subHeading: string
    buttonText: string
    onClose: () => void
    icon?: JSX.Element
}

function ConfirmationPopupOpener(props: IConfirmationPopupProps) {
    const dispatch = useAppDispatch();
    useEffect(() => { showSubscriptionDialog(); }, [])
    const showSubscriptionDialog = () => {
        dispatch(setDialog({
            actions: <></>,
            title: "",
            message: "",
            customTemplate: (<ConfirmationPopup {...props}></ConfirmationPopup>),
        }))
        dispatch(toggleDialog(true))
    }

    return (<></>)
}

function ConfirmationPopup(props: IConfirmationPopupProps) {

    return (<>
        <div className="w-full h-[266px] px-[15px] py-10 bg-white rounded-lg flex-col justify-center items-center gap-5 inline-flex">
            <div className="w-14 h-14 relative">
                <div className="w-14 h-14 left-0 top-0 absolute opacity-10 bg-red rounded" />
                <div className="w-7 h-7 px-[1.17px] pt-[1.17px] pb-[1.14px] left-[14px] top-[14px] absolute justify-center items-center inline-flex">
                    {props.icon ? props.icon : <PartyBaloon />}
                </div>
            </div>
            <div className="self-stretch h-[50px] flex-col justify-center items-center gap-2.5 flex">
                <div className="text-center text-neutral-900 text-base font-semibold font-['Inter Tight'] leading-snug">{props.heading}</div>
                <div className="self-stretch text-center text-neutral-400 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">{props.subHeading}</div>
            </div>
            <PrimaryButton className="h-10 px-2.5 py-2 bg-red rounded-[5px] justify-center items-center gap-2.5 inline-flex" onClick={function (): void {
                props.onClose()
            }}
                title={props.buttonText}
            />
        </div >
    </>)
}
export default ConfirmationPopupOpener;

