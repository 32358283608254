import * as React from "react";
import type { SVGProps } from "react";
const SvgChannel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      d="M8.75 16.5a.723.723 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746h2.5c.213 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746h-2.5ZM1.75 6a.724.724 0 0 1-.534-.217A.732.732 0 0 1 1 5.246a.731.731 0 0 1 .75-.746h16.5c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746H1.75Zm3 5.25a.723.723 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746h10.5c.213 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746H4.75Z"
    />
  </svg>
);
export default SvgChannel;
