import { t } from "i18next";
import { SVGProps, useEffect, useRef, useState } from "react";
import { Popover } from "react-tiny-popover";
import ActionSheet from "../ActionSheet/ActionSheet";
import { AudienceResponse, useLazyGetAudienceQuery } from "../../services/AudiencetApi";
import AudienceSelectorPopup from "./AudienceSelectorPopup";
import AudienceSelectorItem from "./AudienceSelectorItem";
import { SelectArrow } from "../../assets/IconComponents";

export interface IBrandData {
  id?: string,
  title: string,
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

export interface IAudienceSelectorProps {
  onCreateAudience: () => void;
  audienceSelected: (data: AudienceResponse | undefined) => void
  onAudienceEdit: (data: AudienceResponse) => void
  tooltip?: string
  selectDefaultFirst?: boolean
  selectedAudienceId?: string
  className?: string
  brandId: string
  audiences: AudienceResponse[]
  popupClassname?: string
  editingDisabled?: boolean
}

function AudienceSelector(props: IAudienceSelectorProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [selectedAudience, setSelectedAudience] = useState<AudienceResponse | undefined>()
  const [getAudience, { data: brandAudience }] = useLazyGetAudienceQuery()
  const [actionSheetOpened, setActionSheetOpened] = useState(false);
  const [audience, setAudience] = useState<AudienceResponse[]>([])

  useEffect(() => {
    getAudience({ brandId: props.brandId })
  }, [props.brandId])

  useEffect(() => {
    if (brandAudience) {
      setAudience([...props.audiences, ...brandAudience?.filter(a => !props.audiences.some(k => k.audience_id === a.audience_id))])
    } else {
      setAudience([...props.audiences])
    }
  }, [brandAudience, props.audiences])

  useEffect(() => {
    props.audienceSelected(selectedAudience)
    setIsPopoverOpen(false)
  }, [selectedAudience])

  useEffect(() => {
    if (audience && props.selectDefaultFirst && audience.length > 0) {
      let firstAudience = audience![0]
      setSelectedAudience(firstAudience)
    } else if (audience && props.selectedAudienceId && audience.length > 0) {
      let selectedAudience = audience!.find(k => k.audience_id === props.selectedAudienceId)!
      setSelectedAudience(selectedAudience)
    }

  }, [audience])

  useEffect(() => {
    if (audience && props.selectedAudienceId && audience.length > 0) {
      let selectedAudience = audience!.find(k => k.audience_id === props.selectedAudienceId)!
      setSelectedAudience(selectedAudience)
    }

  }, [props.selectedAudienceId, audience])

  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="flex-1 flex-col self-stretch" ref={ref}>
        <Popover
          parentElement={ref.current === null ? undefined : ref.current}
          isOpen={isPopoverOpen}
          positions={["bottom"]}
          align="start"
          padding={10}
          containerClassName="z-[3000]"
          onClickOutside={() => {
            setIsPopoverOpen(false);
          }}
          content={
            <AudienceSelectorPopup
              className={props.popupClassname}
              data={audience ?? []}
              closePopup={() => setIsPopoverOpen(false)}
              selectedAudience={selectedAudience}
              selectedAudienceChanged={(e) => {
                setSelectedAudience(e);
              }}
              onCreateAudience={props.onCreateAudience}
              onAudienceEdit={props.onAudienceEdit}
              editingDisabled={props.editingDisabled}
            />
          }
        >
          <div
            className={props.className ?? "h-[41px]"}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
            data-tooltip-html={props.tooltip}
          >
            <div className="grow shrink basis-0 text-[#808080] size-[15px] leading-[18.20px]">{selectedAudience?.name ?? "Select Audience"}</div>
            <div className="w-5 h-5 relative transition duration-150 ">
              <SelectArrow fill="hsl(0,0%,80%)" className="hover:fill-[hsl(0,0%,60%)]" />
            </div>
          </div>
        </Popover>
      </div>
      <div className="lg:hidden max-lg:flex">
        <ActionSheet
          title="Select Audience"
          isExpanded={actionSheetOpened}
          setIsExpanded={setActionSheetOpened}
          triggerView={
            <button
              className={`
                                    h-full                                    
                                    bg-white p-[10px] pl-[6px]
                                    rounded-[7px] flex flex-row items-center
                                    border-1 border-border
                                `}
              onClick={() => { }}
            >
              <div className="flex flex-row">
                <text className={`text-H8 text-gray ml-2`}>{t("audience")}</text>
                {selectedAudience !== undefined && (
                  <>
                    <text className={`text-H8 text-gray ml-0`}>:</text>
                    <text className={`text-H8 text-blue ml-1`}>
                      {selectedAudience.name}
                    </text>
                  </>
                )}
              </div>
            </button>
          }
          body={
            <div className="w-full p-[15px] pt-[5px] flex flex-col">
              <div className="h-full">
                <div className="max-h-[170px] overflow-scroll scrollbar-hide">
                  {(audience ?? []).map((k) => (
                    <AudienceSelectorItem
                      data={k}
                      selected={selectedAudience?.audience_id === k.audience_id}
                      itemSelected={(e) => {
                        var selectAudience: AudienceResponse | undefined = undefined;

                        if (e.audience_id === selectedAudience?.audience_id) {
                          selectAudience = undefined;
                        } else {
                          selectAudience = e;
                        }

                        setSelectedAudience(selectAudience);
                      }}
                      onItemEdit={props.onAudienceEdit}
                    />
                  ))}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

export default AudienceSelector;
