import { Button, Drawer } from "antd";
import { useAppSelector } from "../../helpers/hooks";
import SingleNotification from "./SingleNotification";
import { useDispatch } from "react-redux";
import { toggleLoading, toggleNotificationsSidebar } from "../../store/appSlice";
import { Close } from "../../assets/IconComponents";
import IconButton from "../../components/IconButton";
import { NotificationResponse, useLazyGetNotificationsQuery } from "../../services/NotificationsManager";
import { useEffect, useState } from "react";
import SvgArrowRightWithTip from "../../assets/IconComponents/ArrowRightWithTip";
import SvgNoNotificationsYet from "../../assets/IconComponents/NoNotificationsYetIcon";
import { useNavigate } from "react-router-dom";

const NotificationsSidebar = (props) => {
  const notificationsSidebarActive = useAppSelector(state => state.app.notificationsSidebarActive)
  const [
    getNotifications,
    { data: notificationModels, isLoading: notificationModelsLoading },
  ] = useLazyGetNotificationsQuery();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [unfilteredNotificationModels, setUnfilteredNotificationModels] =
    useState<NotificationResponse[]>([]);
  useEffect(() => {
    if (lastSelectedBrandId) {
      getNotifications({
        size: 200,
        page: 0,
        brandId: [lastSelectedBrandId],
      });
    }
  }, []);
  useEffect(() => {
    dispatch(toggleLoading(notificationModelsLoading));
  }, [notificationModelsLoading]);
  useEffect(() => {
    if (notificationModels) {
      setUnfilteredNotificationModels(notificationModels);
    }
  }, [notificationModels]);
  return (
    <div>
      <Drawer
        headerStyle={{ display: "none" }}
        extra={false}
        open={notificationsSidebarActive}
        width="400"
        bodyStyle={{ padding: 0, overflowY: "hidden" }}
        onClose={() => {
          /*
          dispatch(setNewContent({} as INewContent));
          dispatch(toggleContentWizard());
          */
          dispatch(toggleNotificationsSidebar());
        }}
      >
        <div className="flex flex-col w-full h-full  bg-white">
          <div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1 shrink-0">
            <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
              <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">
                Notifications
              </div>
            </div>
            <div className="w-5 h-5 relative">
              <IconButton
                title={""}
                icon={Close}
                onClick={function (e: any): void {
                  dispatch(toggleNotificationsSidebar());
                }}
              />
            </div>
          </div>
          <div
            className={`flex flex-col h-full w-full mt-2 px-2 justify-between gap-2`}
          >
            <div className="flex flex-col w-full gap-1 overflow-scroll">
              {unfilteredNotificationModels.length > 0 &&
                unfilteredNotificationModels.map((k) => (
                  <SingleNotification notificationResponse={k} />
                ))}

              {unfilteredNotificationModels.length == 0 && (
                <div className="flex flex-col w-full items-center mt-4">
                  <SvgNoNotificationsYet />
                  <text className="text-H5 text-black mt-5">
                    No notifications yet.
                  </text>
                  <text className="text-BodyMedium text-black mt-2">
                    You will see notifications here when they are available.
                  </text>
                </div>
              )}
            </div>
            {/*
             {unfilteredNotificationModels.length > 0 &&
                              unfilteredNotificationModels.map((k) => (
                                <SingleNotification notificationResponse={k} />
                              ))}
                            {unfilteredNotificationModels.length == 0 && (
                              <div className="flex flex-col w-full items-center mt-4">
                                <SvgNoNotificationsYet />
                                <text className="text-H5 text-black mt-5">
                                  No notifications yet.
                                </text>
                                <text className="text-BodyMedium text-black mt-2">
                                  You will see notifications here when they are available.
                                </text>
                              </div>
                            )}
            */}
            <div
              className="sticky bottom-0 border-t-1 border-bg mb-2"
            >

              <Button
                type="text"
                size="small"
                className="text-red hover:text-red cursor-pointer mb-1 pb-2 pt-2 mt-2 underline"
                onClick={() => {
                  dispatch(toggleNotificationsSidebar());
                  navigate(`/app/notifications`);
                }}
              >
                See All Notifications
                <SvgArrowRightWithTip />
              </Button>
            </div>

          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationsSidebar;
