import { FunctionComponent, useEffect, useState } from "react";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import Warning from "../../assets/IconComponents/Warning";
import { Close, Help } from "../../assets/IconComponents";
import SvgErrorIcon from "../../assets/IconComponents/ErrorIcon";
import { Accept, useDropzone } from "react-dropzone";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import { t } from "i18next";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading } from "../../store/appSlice";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";
import {
  useDeleteFileDeleteByIdMutation,
  useDownloadQuery,
  useLazyDownloadQuery,
  useUploadMutation,
} from "../../services/FileApi";
import { blobToBase64String, blobToDataURL, dataURLToBlob } from "blob-util";
import { FileProps } from "../../services/BrandApi";
import { TransformStreamDefaultController } from "node:stream/web";
import { useRef } from "react";

interface UploadCompanyMaterialsProps {
  title: string;
  description: string;
  className?: string;
  maxFileCount?: number;
  index?: number;
  maxFileSize?: number;
  disabled?: boolean;
  fileChanged: (file: FileProps, index: number) => void;
  fileDeleted: (index: number) => void;
  uploadedFile?: FileProps;
}

const UploadCompanyMaterials: FunctionComponent<UploadCompanyMaterialsProps> = (
  props: UploadCompanyMaterialsProps
) => {
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const [uploadedFileId, setUploadedFileId] = useState<string>("");
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();



  const downloadFileFrom = async (fileId: string) => {
    console.log("Download for:", fileId);
    return new Promise<any>((resolve) => {
      downloadFile({
        id: fileId,
      })
        .unwrap()
        .then(async (k) => {
          const item = {
            uploadedId: fileId,
            name: k.name,
            type: k.file.type.includes("ima") ? "image" : "other",
            preview: await blobToDataURL(k.file),
          };

          return resolve(item);
        })
        .catch((e) => {
          console.log("error", e);
        });
    });
  };


  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

   const handleFilesChanged = (e) => {
    console.log("input files changed");
     selectFile(e.target.files[0]);
   };

  const selectFile = async (file: any) => {
    console.log("Select file for file: ,", file);
    if (file) {
      dispatch(toggleLoading(true));

      let selectedFile = file;
      let a = (await fileToBlob(selectedFile)) as string;
      let blob = dataURLToBlob(a);
      const formData: FormData = new FormData();
      formData.append("files", blob, selectedFile.name);
      uploadFiles({
        body: formData,
      })
        .unwrap()
        .then((k) => {
          selectedFile["uploadedId"] = k.ids ?? "";
          console.log("success", k);
          props.fileChanged(selectedFile,props.index ?? 0);
          dispatch(toggleLoading(false));
        });
    } else {
      setError(
        `Selected files should be maximum ${props.maxFileCount ?? 2} items`
      );
    }
  };

  return (
    <>
      <aside className="flex flex-row w-full mr-2">
        <div
          className={`flex flex-col w-full ${props.className} items-center `}
        >
          <div
            className={`flex flex-row w-full rounded-[4px] min-h-[60px] px-2 items-center justify-between
            `}
          >
            <div className="border-border border-1 px-2 min-h-[40px] w-full rounded-[4px] items-center  flex">
              <text className="text-BodyMedium text-black text-center ">
                {props.uploadedFile?.name ?? "Upload File"}
              </text>
            </div>

            <div className="flex items-center">
              {props.uploadedFile && (
                <PrimaryButton
                  icon={<Delete fill={"#121315"} />}
                  noFill
                  className=" h-[40px] w-[40px] bg-bg border-1 border-border rounded-[4px] ml-1"
                  isDisabled={false}
                  tooltip="Delete Visual"
                  onClick={() => {
                    const id = uploadedFileId;
                    deleteFile({
                      id: id,
                    });
                    props.fileDeleted(props.index ?? 0);
                  }}
                />
              )}

              <div className="ml-1 relative flex cursor-pointer  rounded-[3px]  border-border ">
                <input
                  type="file"
                  accept={".txt, .pdf, .docx, .doc, .ppt, .pptx"}
                  id={"upload-file-index" + props.index}
                  style={{ display: "none" }}
                  onChange={(event) => handleFilesChanged(event)}
                  value={""}
                />

                <label htmlFor={"upload-file-index" + props.index}>
                  <div className="flex flex-row border-1 border-border h-[40px] items-center min-w-[120px] rounded-[3px] ">
                    <SvgUploadIcon className="ml-2" fill="#121315" />
                    <text
                      className="text-H8 ml-2 text-black mr-2"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {t("upload-file")}  
                    </text>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default UploadCompanyMaterials;
