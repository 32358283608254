import { createApi } from "@reduxjs/toolkit/query/react";
import { Post } from "../../pages/LandingPage/Models/Post";
import { baseQuery, baseQueryWithReauth } from "../Core/BaseApi";
import { ContentModel } from "../ContentApi.old/Model/ContentModel";
import { CreateContentReq } from "../ContentApi.old/Model/CreateContentReqRes";
import { DefaultRequest } from "../Core/DefaultResponse";
import { EditNameReq } from "../ContentApi.old/Model/EditNameReq";
import { ApplyTextContent, ApplyTextReq } from "./Models/ApplyTextReqRes";
import { SelectTextReq } from "./Models/SelectTextReqRes";
import { EditTextReq } from "./Models/EditTextReqRes";
import { GenerateSimiliarReq } from "./Models/GenerateSimiliarTextReqRes";
import { SelectSimilarOneTextReq } from "./Models/SelectSimilarOneReqRes";
import { SelectRes } from "../TextApi.old/Models/SelectTextReqRes";

export const ImageApi = createApi({
  reducerPath: "Image",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    applyImage: builder.mutation<SelectRes[], ApplyTextReq>({
      query: (body) => ({
        url: `image/apply`,
        method: "POST",
        body,
      }),
    }),
    download: builder.query<string, DefaultRequest>({
      query: ({ id }) => ({
        url: `file/download/${id}`,
        responseHandler: "text",
      }),
    }),
    generateSimiliarImage: builder.mutation<SelectRes[], GenerateSimiliarReq>({
      query: (body) => ({
        url: `image/generate-similar`,
        method: "POST",
        body: body,
      }),
    }),
    findByRevisionId: builder.query<SelectRes[], DefaultRequest>({
      query: ({ revisionId }) => ({
        url: `image/${revisionId}`,
      }),
    }),
    findByContentId: builder.query<SelectRes[], DefaultRequest>({
      query: ({ contentId }) => ({
        url: `image/content/${contentId}`,
      }),
    }),
    favorite: builder.mutation<any, DefaultRequest>({
      query: ({ revisionId, data }) => ({
        url: `image/favorite/${revisionId}?isFavorite=${data?.isFavorite}`,
        method: "PUT",
      }),
    }),
    delete: builder.mutation<any, DefaultRequest>({
      query: ({ revisionId }) => ({
        url: `image/${revisionId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyDownloadQuery,
  useApplyImageMutation,
  useGenerateSimiliarImageMutation,
  useLazyFindByContentIdQuery: useLazyFindImageByContentIdQuery,
  useLazyFindByRevisionIdQuery: useLazyFindImageByRevisionIdQuery,
  useFavoriteMutation: useFavoriteImageMutation,
  useDeleteMutation: useDeleteImageMutation,
} = ImageApi;
