import {LeftOutlined} from "@ant-design/icons";
import ChannelList from "./SubStepComponents/ChannelList";
import Icon from "../../Icon";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import {useState} from "react";
import {Facebook, FacebookColor, Google, Instagram, InstagramColor} from "../../../assets/IconComponents";

const channels = [
    {key: 'facebook', label: 'Facebook', services: ['News Feed', 'Stories'], icon: Facebook, colorIcon: FacebookColor},
    {key: 'instagram', label: 'Instagram', services: ['News Feed', 'Explore', 'Stories'], icon: Instagram, colorIcon: InstagramColor},
    {key: 'google', label: 'Google', services: ['Search', 'Display'], icon: Google, colorIcon: FacebookColor},
]

const EditPlacementStep = (props) => {

    return (
        <div className={'w-[370px] h-full'}>
            {props.title && (
                <div>
                    <div className="p-3 flex justify-between items-center">
                        <div className={'flex items-center'}>
                            <span className="text-black mr-1 cursor-pointer" onClick={props.closeSideModal}><LeftOutlined/></span>
                            <span className="text-H8"> {props.title}</span>
                        </div>
                        <div>
                           <span className="text-gray text-xs"> {props.description ?? ''}</span>
                        </div>
                    </div>
                    <hr className="bg-border mx-1"/>
                </div>
            )}
            <div className={'w-full h-[calc(100%-55px)] px-5'}>
                <div className={'border-1 rounded-[4px] p-3 mt-5'}>
                    <div className="text-H8 text-black mb-2"
                         title={''}
                    >
                        AI Smart Placements
                        <Icon
                            width="15"
                            height="15"
                            component={SvgQuestionMark}
                            viewBox="0 0 20 20"
                            className="ml-1 inline-block"
                        />
                    </div>
                    <div className={'text-sm text-gray'}>
                        Our smart AI choose the best placements across ad networks to show your ads
                        to more people as possible.
                    </div>
                    {channels.map((channel, index) => (
                        <div className={`h-[42px] mt-2 flex items-center p-2 justify-between border-1 rounded-[4px]`}>
                            <div className="flex flex-row items-center">
                                <Icon width="15" height="15" viewBox="0 0 20 20"
                                      component={channel.colorIcon}
                                      color="#999999" className="mr-1 w-[20px] "/>
                                <text className="text-BodyMedium text-black ml-2">{channel.label}</text>
                            </div>
                            <div className={'text-sm text-gray'}>
                                {channel.services.map((service, i) => (<span>{i !== 0 ? ', ' : ''}{service}</span>))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EditPlacementStep;
