import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    favorite: build.mutation<FavoriteApiResponse, FavoriteApiArg>({
      query: (queryArg) => ({
        url: `/revision/favorite/${queryArg.revisionId}`,
        method: "PUT",
        params: { isFavorite: queryArg.isFavorite },
      }),
    }),
    editText: build.mutation<EditTextApiResponse, EditTextApiArg>({
      query: (queryArg) => ({
        url: `/revision/edit-text`,
        method: "PUT",
        body: queryArg.revisionUpdateRequest,
      }),
    }),
    regenerate: build.mutation<RegenerateApiResponse, RegenerateApiArg>({
      query: (queryArg) => ({
        url: `/revision/regenerate`,
        method: "POST",
        body: queryArg.regenerateRevisionRequest,
      }),
    }),
    apply: build.mutation<ApplyApiResponse, ApplyApiArg>({
      query: (queryArg) => ({
        url: `/revision/apply`,
        method: "POST",
        body: queryArg.revisionRequest,
        params: {
          updateBrief: queryArg.updateBrief,
          refresh: queryArg.refresh,
        },
      }),
    }),
    getRevisions: build.query<GetRevisionsApiResponse, GetRevisionsApiArg>({
      query: (queryArg) => ({
        url: `/community_content/revision`,
        params: {
          platform: queryArg.platform,
          imageWidth: queryArg.imageWidth,
          imageHeight: queryArg.imageHeight,
        },
      }),
    }),
    saveRevision: build.mutation<SaveRevisionApiResponse, SaveRevisionApiArg>({
      query: (queryArg) => ({
        url: `/community_content/revision`,
        method: "POST",
        body: queryArg.saveCommunityContentRequest,
      }),
    }),
    findRevisionById: build.query<
      FindRevisionByIdApiResponse,
      FindRevisionByIdApiArg
    >({
      query: (queryArg) => ({ url: `/revision/${queryArg.revisionId}` }),
    }),
    deleteRevisionById: build.mutation<
      DeleteRevisionByIdApiResponse,
      DeleteRevisionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/revision/${queryArg.revisionId}`,
        method: "DELETE",
      }),
    }),
    findRevisionsByContentId: build.query<
      FindRevisionsByContentIdApiResponse,
      FindRevisionsByContentIdApiArg
    >({
      query: (queryArg) => ({ url: `/revision/content/${queryArg.contentId}` }),
    }),
    deleteRevisionsByGroupId: build.mutation<
      DeleteRevisionsByGroupIdApiResponse,
      DeleteRevisionsByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/revision/${queryArg.contentId}/${queryArg.groupId}`,
        method: "DELETE",
      }),
    }),
    deleteRevision: build.mutation<
      DeleteRevisionApiResponse,
      DeleteRevisionApiArg
    >({
      query: (queryArg) => ({
        url: `/community_content/revision/${queryArg.revisionId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type FavoriteApiResponse = unknown;
export type FavoriteApiArg = {
  revisionId: string;
  isFavorite?: boolean;
};
export type EditTextApiResponse = /** status 200 OK */ Revision;
export type EditTextApiArg = {
  revisionUpdateRequest: RevisionUpdateRequest;
};
export type RegenerateApiResponse = /** status 200 OK */ Revision;
export type RegenerateApiArg = {
  regenerateRevisionRequest: RegenerateRevisionRequest;
};
export type ApplyApiResponse = /** status 200 OK */ Revision[];
export type ApplyApiArg = {
  updateBrief?: boolean;
  refresh?: boolean;
  revisionRequest: RevisionRequest;
};
export type GetRevisionsApiResponse =
  /** status 200 OK */ SaveCommunityContentResponse[];
export type GetRevisionsApiArg = {
  platform?: string;
  imageWidth?: number;
  imageHeight?: number;
};
export type SaveRevisionApiResponse =
  /** status 200 OK */ SaveCommunityContentResponse;
export type SaveRevisionApiArg = {
  saveCommunityContentRequest: SaveCommunityContentRequest;
};
export type FindRevisionByIdApiResponse = /** status 200 OK */ Revision;
export type FindRevisionByIdApiArg = {
  revisionId: string;
};
export type DeleteRevisionByIdApiResponse = unknown;
export type DeleteRevisionByIdApiArg = {
  revisionId: string;
};
export type FindRevisionsByContentIdApiResponse =
  /** status 200 OK */ Revision[];
export type FindRevisionsByContentIdApiArg = {
  contentId: string;
};
export type DeleteRevisionsByGroupIdApiResponse = unknown;
export type DeleteRevisionsByGroupIdApiArg = {
  contentId: string;
  groupId: number;
};
export type DeleteRevisionApiResponse = unknown;
export type DeleteRevisionApiArg = {
  revisionId: string;
};
export type Id = {
  content_id?: string;
  revision_id?: string;
};
export type Revision = {
  id?: Id;
  type?: string;
  fileId?: string;
  aiImage?: string;
  headerText?: string;
  footerText?: string;
  templateID?: string;
  colorSet?: string;
  fontSet?: string;
  generatedFrom?: string;
  original?: boolean;
  photoshootRevision?: string;
  fontSetIndex?: {
    [key: string]: number;
  };
  colorSetIndex?: {
    [key: string]: number;
  };
  ctatext?: string;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  revision_parent_id?: string;
  revision_group?: number;
  generated_content?: string;
  is_favorite?: boolean;
  ai_model?: string;
};
export type RevisionUpdateRequest = {
  revision_id: string;
  updated_text_content: string;
};
export type RegenerateRevisionRequest = {
  content_id: string;
  revision_id: string;
  ai_background_image_file_id?: string;
  ai_header_text?: string;
  ai_footer_text?: string;
  ai_cta_text?: string;
  product_image_file_id?: string;
  logo_image_file_id?: string;
  color_set?: string[];
  font_set?: string[];
  regenerate_background_ai_image?: boolean;
  regenerate_header_text?: boolean;
  regenerate_footer_text?: boolean;
  regenerate_cta?: boolean;
  apply_changes_to_final_image?: boolean;
  save_revision?: boolean;
};
export type RevisionRequest = {
  request_id: string;
  content_id: string;
  number_of_revisions?: number;
  ai_model_names?: string;
  revision_parent_id?: string;
  revision_group?: number;
  preserve?: string[];
};
export type SaveCommunityContentResponse = {
  type?: "image" | "text";
  platform?: string;
  size?: string;
  content_id?: string;
  revision_id?: string;
  generated_content?: string;
  filters?: string;
};
export type SaveCommunityContentRequest = {
  revisionId?: string;
};
export const {
  useFavoriteMutation,
  useEditTextMutation,
  useRegenerateMutation,
  useApplyMutation,
  useGetRevisionsQuery,
  useLazyGetRevisionsQuery,
  useSaveRevisionMutation,
  useFindRevisionByIdQuery,
  useLazyFindRevisionByIdQuery,
  useDeleteRevisionByIdMutation,
  useFindRevisionsByContentIdQuery,
  useLazyFindRevisionsByContentIdQuery,
  useDeleteRevisionsByGroupIdMutation,
  useDeleteRevisionMutation,
} = injectedRtkApi;
