import * as React from "react";
import type { SVGProps } from "react";
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <rect opacity="0.1" y="0.5" width="28" height="28" rx="4" fill="#FF5555"/>
    <path
        d="M20.0001 21.6665H15.3334C15.1566 21.6665 14.987 21.5963 14.862 21.4712C14.737 21.3462 14.6667 21.1766 14.6667 20.9998V16.3332C14.6667 16.1564 14.737 15.9868 14.862 15.8618C14.987 15.7367 15.1566 15.6665 15.3334 15.6665H20.0001C20.1769 15.6665 20.3465 15.7367 20.4715 15.8618C20.5965 15.9868 20.6667 16.1564 20.6667 16.3332V20.9998C20.6667 21.1766 20.5965 21.3462 20.4715 21.4712C20.3465 21.5963 20.1769 21.6665 20.0001 21.6665Z"
        fill="#FFA1A1"/>
    <path
        d="M11.2761 18.6665L13.1381 16.8045C13.2595 16.6788 13.3267 16.5104 13.3252 16.3356C13.3237 16.1608 13.2535 15.9936 13.1299 15.87C13.0063 15.7464 12.8391 15.6763 12.6643 15.6748C12.4895 15.6732 12.3211 15.7404 12.1954 15.8619L10.3334 17.7239L8.47139 15.8619C8.34565 15.7404 8.17725 15.6732 8.00245 15.6748C7.82766 15.6763 7.66045 15.7464 7.53684 15.87C7.41324 15.9936 7.34312 16.1608 7.3416 16.3356C7.34009 16.5104 7.40728 16.6788 7.52872 16.8045L9.39072 18.6665L7.52872 20.5285C7.46505 20.59 7.41426 20.6636 7.37932 20.7449C7.34438 20.8263 7.32599 20.9137 7.32522 21.0023C7.32445 21.0908 7.34132 21.1786 7.37484 21.2605C7.40836 21.3424 7.45786 21.4169 7.52046 21.4795C7.58305 21.5421 7.65749 21.5916 7.73942 21.6251C7.82135 21.6586 7.90914 21.6755 7.99766 21.6747C8.08617 21.6739 8.17365 21.6555 8.25499 21.6206C8.33633 21.5857 8.40989 21.5349 8.47139 21.4712L10.3334 19.6092L12.1954 21.4712C12.3211 21.5926 12.4895 21.6598 12.6643 21.6583C12.8391 21.6568 13.0063 21.5867 13.1299 21.4631C13.2535 21.3395 13.3237 21.1723 13.3252 20.9975C13.3267 20.8227 13.2595 20.6543 13.1381 20.5285L11.2761 18.6665ZM20.0001 14.3332H15.3334C15.2197 14.3333 15.108 14.3043 15.0087 14.249C14.9094 14.1936 14.8259 14.1139 14.7661 14.0172C14.7063 13.9206 14.6722 13.8102 14.6671 13.6967C14.662 13.5832 14.6859 13.4702 14.7367 13.3685L17.0701 8.70187C17.1315 8.59839 17.2189 8.51267 17.3235 8.45314C17.4281 8.39361 17.5464 8.3623 17.6667 8.3623C17.7871 8.3623 17.9054 8.39361 18.01 8.45314C18.1146 8.51267 18.2019 8.59839 18.2634 8.70187L20.5967 13.3685C20.6475 13.4702 20.6715 13.5832 20.6663 13.6967C20.6612 13.8102 20.6271 13.9206 20.5673 14.0172C20.5076 14.1139 20.4241 14.1936 20.3248 14.249C20.2255 14.3043 20.1137 14.3333 20.0001 14.3332Z"
        fill="#FF5555"/>
    <path
        d="M10.3333 14.3335C9.73991 14.3335 9.15989 14.1576 8.66654 13.8279C8.1732 13.4983 7.78868 13.0297 7.56161 12.4815C7.33455 11.9334 7.27514 11.3302 7.3909 10.7482C7.50665 10.1663 7.79238 9.63173 8.21193 9.21218C8.63149 8.79262 9.16604 8.5069 9.74798 8.39114C10.3299 8.27539 10.9331 8.3348 11.4813 8.56186C12.0295 8.78892 12.498 9.17344 12.8277 9.66679C13.1573 10.1601 13.3333 10.7402 13.3333 11.3335C13.3324 12.1289 13.016 12.8914 12.4536 13.4538C11.8912 14.0163 11.1286 14.3326 10.3333 14.3335Z"
        fill="#FFA1A1"/>
  </svg>
);
export default SvgAdd;
