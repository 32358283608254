import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../store/appSlice";
import { NotificationResponse, useLazyGetNotificationsQuery } from "../../services/NotificationsManager";
import SingleNotification from "./SingleNotification";
import ContentContainer from "../../components/ContentContainer";
import GeneralHorizontalTab from "../Tabs/GeneralHorizontalTab";
import BrandSelector from "../../components/BrandSelector/BrandSelector";
import ToggleButton from "../../components/ToggleButton";
import ToggleButtonSmall from "../../components/ToggleButtonSmall";
import { useLazyList6Query } from "../../services/BrandApi";
import SvgNoNotificationsYet from "../../assets/IconComponents/NoNotificationsYetIcon";

const AllNotifications = (props) => {

    const [getNotifications, { data: notificationModels, isLoading: notificationModelsLoading, isFetching: notificationModelsFetching }] =
      useLazyGetNotificationsQuery();
    const [toggleButtonChecked, setToggleButtonChecked] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [selectedBrandId, setSelectedBrandId] = useState<
      string | undefined
    >();
    const [getBrands, { data: brands, isLoading: brandsLoading }] = useLazyList6Query();
    const [unfilteredNotificationModels, setUnfilteredNotificationModels] = useState<
      NotificationResponse[]
    >([]);
    const dispatch = useDispatch();
     useEffect(() => {
       dispatch(
         toggleLoading(
           notificationModelsLoading ||
             brandsLoading ||
             notificationModelsFetching
         )
       );
     }, [notificationModelsLoading, brandsLoading, notificationModelsFetching]);

     useEffect(() => {
       if(notificationModels){
        setUnfilteredNotificationModels(notificationModels)
       }
     }, [notificationModels]);
     
     useEffect(() => {
       getBrands();
     }, []);


     useEffect(() => {
       if (brands) {
         const commonParams = {
           size: 200,
           page: 0,
           isRead: !toggleButtonChecked,
           group:
             currentTab === 0
               ? undefined
               : currentTab === 1
               ? "MarketingPlanner"
               : "Subscription",
         };

         if (selectedBrandId === undefined) {
           if (brands.length > 0) {
             const brandsReturned = brands.map((bra) => bra.id ?? "");
             getNotifications({
               ...commonParams,
               brandId: brandsReturned,
             });
           } else {
             getNotifications({
               ...commonParams,
               brandId: [],
             });
           }
         } else {
           getNotifications({
             ...commonParams,
             brandId: [selectedBrandId],
           });
         }
       }
     }, [selectedBrandId, toggleButtonChecked, currentTab, brands]);

     
     
     

  return (
    <>
      <div className="h-[calc(100vh-100px)]">
        <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white border-1 border-border items-center justify-between mb-4">
          <span className="text-H6 text-black px-5">Notifications</span>
        </div>

        <div className="w-full h-full pl-3 pr-3">
          <div className="flex w-full scrollbar-hide flex-col ">
            <ContentContainer
              className={`w-full h-[calc(100vh-100px)]  overflow-hidden`}
              hoverable={false}
              children={
                <div className="flex flex-col h-full w-full overflow-scroll">
                  <GeneralHorizontalTab
                    tabChangedTo={(e) => {
                      console.log("Tab index is: ", e);
                      setCurrentTab(e);
                    }}
                    noBorder
                    additionalTopBarContent={
                      <>
                        <BrandSelector
                          className="h-11"
                          BrandSelected={(e) => {
                            console.log("Brand is: ", e);
                            setSelectedBrandId(
                              e == undefined ? undefined : e.id ?? undefined
                            );
                          }}
                        />
                        <div className="flex flex-row">
                          <text className="text-H8 text-black mr-1">
                            Unreads
                          </text>
                          <ToggleButtonSmall
                            isChecked={toggleButtonChecked}
                            handleCheckBoxChange={() => {
                              setToggleButtonChecked(!toggleButtonChecked);
                            }}
                          />
                        </div>
                      </>
                    }
                    tabs={[
                      {
                        title: "All",
                        element: (
                          <div
                            className={`flex flex-col w-full  mt-2 px-2 items-center  gap-2`}
                          >
                            {unfilteredNotificationModels.length > 0 &&
                              unfilteredNotificationModels.map((k) => (
                                <SingleNotification
                                  isAllNotifications={true}
                                  notificationResponse={k}
                                />
                              ))}
                            {unfilteredNotificationModels.length == 0 && (
                              <div className="flex flex-col w-full items-center mt-4">
                                <SvgNoNotificationsYet />
                                <text className="text-H5 text-black mt-5">
                                  No notifications yet.
                                </text>
                                <text className="text-BodyMedium text-black mt-2">
                                  You will see notifications here when they are
                                  available.
                                </text>
                              </div>
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Marketing Planner",
                        element: (
                          <div
                            className={`flex flex-col w-full  mt-2 px-2 items-center  gap-2`}
                          >
                            {unfilteredNotificationModels.length > 0 &&
                              unfilteredNotificationModels.map((k) => (
                                <SingleNotification
                                  isAllNotifications={true}
                                  notificationResponse={k}
                                />
                              ))}
                            {unfilteredNotificationModels.length == 0 && (
                              <div className="flex flex-col w-full items-center mt-4">
                                <SvgNoNotificationsYet />
                                <text className="text-H5 text-black mt-5">
                                  No notifications yet.
                                </text>
                                <text className="text-BodyMedium text-black mt-2">
                                  You will see notifications here when they are
                                  available.
                                </text>
                              </div>
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Subscription",
                        element: (
                          <div
                            className={`flex flex-col w-full  mt-2 px-2 items-center  gap-2`}
                          >
                            {unfilteredNotificationModels.length > 0 &&
                              unfilteredNotificationModels.map((k) => (
                                <SingleNotification
                                  isAllNotifications={true}
                                  notificationResponse={k}
                                />
                              ))}
                            {unfilteredNotificationModels.length == 0 && (
                              <div className="flex flex-col w-full items-center mt-4">
                                <SvgNoNotificationsYet />
                                <text className="text-H5 text-black mt-5">
                                  No notifications yet.
                                </text>
                                <text className="text-BodyMedium text-black mt-2">
                                  You will see notifications here when they are
                                  available.
                                </text>
                              </div>
                            )}
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllNotifications;
