import * as React from "react";
import type { SVGProps } from "react";
const SvgSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#121315"
      d="M17.939 12.046a1.282 1.282 0 0 1-.522-1.03c0-.406.195-.791.521-1.03l1.51-1.107a.689.689 0 0 0 .246-.772 9.109 9.109 0 0 0-1.827-3.167.689.689 0 0 0-.792-.174l-1.7.748c-.37.163-.802.14-1.153-.064a1.282 1.282 0 0 1-.631-.966l-.205-1.866a.688.688 0 0 0-.546-.6 9.235 9.235 0 0 0-3.652-.004.687.687 0 0 0-.548.6l-.203 1.854a1.28 1.28 0 0 1-1.785 1.03l-1.709-.752a.688.688 0 0 0-.79.173A9.104 9.104 0 0 0 2.316 8.08a.688.688 0 0 0 .245.774l1.5 1.1c.327.24.522.625.522 1.03 0 .406-.195.791-.521 1.03l-1.51 1.107a.689.689 0 0 0-.246.772 9.108 9.108 0 0 0 1.827 3.167c.199.224.52.295.792.174l1.7-.748c.37-.163.802-.14 1.154.063.35.203.586.564.63.967l.205 1.867a.688.688 0 0 0 .546.599 9.227 9.227 0 0 0 3.652.004.687.687 0 0 0 .548-.6l.203-1.854a1.28 1.28 0 0 1 1.785-1.03l1.709.751c.272.12.592.05.79-.173a9.101 9.101 0 0 0 1.838-3.16.688.688 0 0 0-.246-.774l-1.5-1.1ZM11 14.208a3.208 3.208 0 1 1 0-6.416 3.208 3.208 0 0 1 0 6.416Z"
    />
  </svg>
);
export default SvgSettingsIcon;
