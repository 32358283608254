import * as React from "react";
const SvgTextToSpeechMenuCollapseButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#8E8E8E"}
      d="M11.496 6.396a.58.58 0 0 0 0-.825.583.583 0 0 0-.825 0L7.583 8.658 7 9.25l-.583-.592-3.088-3.087a.583.583 0 1 0-.825.825l4.084 4.083a.583.583 0 0 0 .825 0l4.083-4.083Z"
    />
  </svg>
);
export default SvgTextToSpeechMenuCollapseButton;
