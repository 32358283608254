import { FunctionComponent, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import PrimaryButton from "../PrimaryButton";
import { Copy, Delete, Share } from "../../assets/IconComponents";
import { useAppSelector } from "../../helpers/hooks";
import { t } from "i18next";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import { IBrandData } from "../BrandSelector/BrandSelector";
import BrandSelectorItem from "../BrandSelector/BrandSelectorItem";
import ContentContainer from "../ContentContainer";
import InputText, { InputTextState } from "../InputText";
import SvgLinkIcon from "../../assets/IconComponents/LinkIcon";
import SvgCheckIcon from "../../assets/IconComponents/CheckIcon";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { Content } from "../../services/ContentApi";
import ActionSheet from "../ActionSheet/ActionSheet";
import Icon from "../Icon";
import SvgCheckRedIcon from "../../assets/IconComponents/CheckIconRed";

interface ShareLoopProps {
  content?: Content
  className?: string
  newActionSheetOpened?: () => void
  isPopoverOpen: boolean
  setIsSharePopoverOpen?: (boolean) => void

}

const ShareLoop: FunctionComponent<ShareLoopProps> = (props: ShareLoopProps) => {
  const isContentLoading = useAppSelector(k => k.content.isContentLoading)
  const [copied, setCopied] = useState(false);
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [link, setLink] = useState(process.env.REACT_APP_URL + "/app/loopdetails/" + props.content?.id?.content_id + "/shared")
  const [actionSheetOpened, setActionSheetOpened] = useState(false)

  useEffect(() => {
    setLink(process.env.REACT_APP_URL + "/app/loopdetails/" + props.content?.id?.content_id + "/shared")

  }, [props.content])


  const copyLink = () => {
    copyToClipboard(link)
    setCopied(true)
    setTimeout(() => setCopied(false), 2 * 1000)
  }

  return (
    <>
      <div className="max-lg:hidden lg:flex flex-row items-center">
        <Popover
          isOpen={props.isPopoverOpen}
          positions={["bottom"]}
          align="end"
          padding={10}
          onClickOutside={() => {
            props.setIsSharePopoverOpen && props.setIsSharePopoverOpen(false);
          }}
          content={
            <ContentContainer
              hoverable={false}
              className="w-[400px] h-fit mr-3  overflow-hidden"
              children={
                <>
                  <div className="flex flex-col p-[15px] pt-[10px] h-[150px] overflow-scroll scrollbar-hide">
                    {!copied && (
                      <InputText
                        icon={SvgLinkIcon}
                        color="#00000"
                        title={t("share-the-editable-link") ?? ""}
                        innerClassName="rounded-[4px]"
                        defaultValue={link}
                        state={InputTextState.disabled}
                      />
                    )}
                    {copied && (
                      <InputText
                        icon={SvgCheckRedIcon}
                        title={t("share-the-editable-link") ?? ""}
                        innerClassName="rounded-[4px]"
                        defaultValue={t("copied-1") ?? "Copied!"}
                        state={InputTextState.disabled}
                        color="#FF5555"
                      />
                    )}
                    <PrimaryButton
                      icon={
                        <Copy
                          fill={isContentLoading ? "#ffffff" : "#ffffff"}
                          className="mr-2"
                        />
                      }
                      isDisabled={isContentLoading}
                      className="h-10 w-full mt-2"
                      title={t("copy-link")}
                      onClick={() => {
                        copyLink();
                      }}
                    />
                  </div>
                </>
              }
            />
          }
        >
          <div
            className={`h-[36px] ${props.className}`}
            onClick={() =>
              props.setIsSharePopoverOpen &&
              props.setIsSharePopoverOpen(!props.isPopoverOpen)
            }
          >
            <PrimaryButton
              icon={
                <Share
                  height={18}
                  width={18}
                  fill={isContentLoading ? "#ffffff" : "#ffffff"}
                  className="mr-2"
                />
              }
              isDisabled={isContentLoading}
              className="h-[36px] min-w-[85px] ml-1 md:ml-2 mr-[25px]"
              title={t("share")}
              onClick={() => {
                props.setIsSharePopoverOpen &&
                  props.setIsSharePopoverOpen(!props.isPopoverOpen);
              }}
            />
          </div>
        </Popover>
      </div>
      <div className="lg:hidden max-lg:flex w-full">
        <ActionSheet
          className="w-full"
          isExpanded={actionSheetOpened}
          setIsExpanded={setActionSheetOpened}
          triggerClassName="w-full mt-2"
          triggerView={
            <PrimaryButton
              icon={
                <Share
                  fill={isContentLoading ? "#ffffff" : "#ffffff"}
                  className="mr-2"
                />
              }
              isDisabled={isContentLoading}
              className="h-10 w-full "
              title={t("share")}
              onClick={() => {
                setActionSheetOpened(true);
                // if(props.newActionSheetOpened) props.newActionSheetOpened()
              }}
            />
          }
          title="Share"
          body={
            <div className="w-full p-[15px] pt-[5px] flex flex-col">
              <div className="flex flex-col h-full overflow-scroll scrollbar-hide">
                {!copied && (
                  <InputText
                    icon={SvgLinkIcon}
                    color="#00000"
                    title={t("share-the-editable-link") ?? ""}
                    innerClassName="rounded-[4px]"
                    defaultValue={link}
                    state={InputTextState.disabled}
                  />
                )}
                {copied && (
                  <InputText
                    icon={SvgCheckRedIcon}
                    title={t("share-the-editable-link") ?? ""}
                    innerClassName="rounded-[4px]"
                    defaultValue={t("copied-1") ?? "Copied!"}
                    state={InputTextState.disabled}
                    color="#FF5555"
                  />
                )}
                <PrimaryButton
                  icon={
                    <Copy
                      fill={isContentLoading ? "#ffffff" : "#ffffff"}
                      className="mr-2"
                    />
                  }
                  isDisabled={isContentLoading}
                  className="h-10 w-full mt-2"
                  title={t("copy-link")}
                  onClick={() => {
                    copyLink();
                  }}
                />

                {/*
                  {copied && (
                  <PrimaryButton
                    noFill
                    icon={<SvgCheckIcon className="mr-2" />}
                    isDisabled={isContentLoading}
                    className="h-10 w-full mt-2"
                    title={t("copied")}
                    onClick={() => { }}
                  />
                )}
                  */}
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

export default ShareLoop;