import * as React from "react";
import type { SVGProps } from "react";
const SvgRetweetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#999"
      d="M2.168 8.502a4.677 4.677 0 0 0 4.667 4.666h3.057l-.528.529a.667.667 0 1 0 .942.943l1.608-1.608a.667.667 0 0 0-.003-1.064l-1.605-1.604a.667.667 0 1 0-.942.942l.528.529H6.835a3.324 3.324 0 0 1-3.333-3.333 3.3 3.3 0 0 1 .63-1.944.667.667 0 1 0-1.082-.78 4.652 4.652 0 0 0-.882 2.724Zm2.658-3.99a.667.667 0 0 0 .264.52L6.697 6.64a.667.667 0 1 0 .943-.943l-.529-.529h3.057a3.324 3.324 0 0 1 3.334 3.334 3.3 3.3 0 0 1-.63 1.943.666.666 0 1 0 1.081.78 4.651 4.651 0 0 0 .882-2.723 4.677 4.677 0 0 0-4.667-4.667H7.111l.529-.529a.667.667 0 1 0-.943-.942l-1.61 1.609a.667.667 0 0 0-.261.538Z"
    />
  </svg>
);
export default SvgRetweetIcon;
