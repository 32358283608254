import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addMetaAccount: build.mutation<
      AddMetaAccountApiResponse,
      AddMetaAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/meta/account`,
        method: "POST",
        body: queryArg.metaAddAccountRequest,
      }),
    }),
    getSuggestedBudget: build.mutation<
      GetSuggestedBudgetApiResponse,
      GetSuggestedBudgetApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/google_ads/budget`,
        method: "POST",
        body: queryArg.suggestedBudgetRequest,
      }),
    }),
    addGoogleAdsAccount: build.mutation<
      AddGoogleAdsAccountApiResponse,
      AddGoogleAdsAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/google/account`,
        method: "POST",
        body: queryArg.googleAdsAddAccountRequest,
      }),
    }),
    getReachEstimate: build.mutation<
      GetReachEstimateApiResponse,
      GetReachEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/facebook/reach`,
        method: "POST",
        body: queryArg.reachEstimateRequest,
      }),
    }),
    getApiKeysByBrandId: build.query<
      GetApiKeysByBrandIdApiResponse,
      GetApiKeysByBrandIdApiArg
    >({
      query: (queryArg) => ({ url: `/ad_manager/brand/${queryArg.brandId}` }),
    }),
    connectApiKey: build.mutation<
      ConnectApiKeyApiResponse,
      ConnectApiKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/brand/${queryArg.brandId}`,
        method: "POST",
        body: queryArg.connectApiKeyRequest,
      }),
    }),
    addApiKeys: build.mutation<AddApiKeysApiResponse, AddApiKeysApiArg>({
      query: (queryArg) => ({
        url: `/ad_manager/`,
        method: "POST",
        body: queryArg.addApiKeysRequest,
      }),
    }),
    getApiKeysByOrganizationId: build.query<
      GetApiKeysByOrganizationIdApiResponse,
      GetApiKeysByOrganizationIdApiArg
    >({
      query: () => ({ url: `/ad_manager/organization` }),
    }),
    deleteApiKeysByOrganizationId: build.mutation<
      DeleteApiKeysByOrganizationIdApiResponse,
      DeleteApiKeysByOrganizationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/organization`,
        method: "DELETE",
      }),
    }),
    getUserInterests: build.query<
      GetUserInterestsApiResponse,
      GetUserInterestsApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/google_ads/interest`,
        params: { interestName: queryArg.interestName },
      }),
    }),
    getGeoLocations: build.query<
      GetGeoLocationsApiResponse,
      GetGeoLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/google_ads/geolocation`,
        params: { geoLocation: queryArg.geoLocation },
      }),
    }),
    deleteApiKeysByOrganizationIdAndKey: build.mutation<
      DeleteApiKeysByOrganizationIdAndKeyApiResponse,
      DeleteApiKeysByOrganizationIdAndKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/organization/${queryArg.apiKey}`,
        method: "DELETE",
      }),
    }),
    disconnectApiKeysByBrandId: build.mutation<
      DisconnectApiKeysByBrandIdApiResponse,
      DisconnectApiKeysByBrandIdApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/brand/${queryArg.brandId}/${queryArg.apiKey}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AddMetaAccountApiResponse = unknown;
export type AddMetaAccountApiArg = {
  metaAddAccountRequest: MetaAddAccountRequest;
};
export type GetSuggestedBudgetApiResponse =
  /** status 200 OK */ SuggestedBudgetResponse;
export type GetSuggestedBudgetApiArg = {
  suggestedBudgetRequest: SuggestedBudgetRequest;
};
export type AddGoogleAdsAccountApiResponse = unknown;
export type AddGoogleAdsAccountApiArg = {
  googleAdsAddAccountRequest: GoogleAdsAddAccountRequest;
};
export type GetReachEstimateApiResponse = /** status 200 OK */ ReachEstimate;
export type GetReachEstimateApiArg = {
  reachEstimateRequest: ReachEstimateRequest;
};
export type GetApiKeysByBrandIdApiResponse =
  /** status 200 OK */ BrandSocialsModel[];
export type GetApiKeysByBrandIdApiArg = {
  brandId: string;
};
export type ConnectApiKeyApiResponse = unknown;
export type ConnectApiKeyApiArg = {
  brandId: string;
  connectApiKeyRequest: ConnectApiKeyRequest;
};
export type AddApiKeysApiResponse = unknown;
export type AddApiKeysApiArg = {
  addApiKeysRequest: AddApiKeysRequest;
};
export type GetApiKeysByOrganizationIdApiResponse =
  /** status 200 OK */ SocialApiKeyModel[];
export type GetApiKeysByOrganizationIdApiArg = void;
export type DeleteApiKeysByOrganizationIdApiResponse = unknown;
export type DeleteApiKeysByOrganizationIdApiArg = {
  brandId: string;
};
export type GetUserInterestsApiResponse =
  /** status 200 OK */ InterestsResponse;
export type GetUserInterestsApiArg = {
  interestName: string;
};
export type GetGeoLocationsApiResponse = /** status 200 OK */ LocationResponse;
export type GetGeoLocationsApiArg = {
  geoLocation: string;
};
export type DeleteApiKeysByOrganizationIdAndKeyApiResponse = unknown;
export type DeleteApiKeysByOrganizationIdAndKeyApiArg = {
  apiKey: string;
};
export type DisconnectApiKeysByBrandIdApiResponse = unknown;
export type DisconnectApiKeysByBrandIdApiArg = {
  brandId: string;
  apiKey: string;
};
export type MetaAddAccountRequest = {
  access_token: string;
  user_id?: string;
  social_key_id?: string;
};
export type SuggestedBudgetResponse = {
  dailySuggestedBudget?: number;
  totalSuggestedBudget?: number;
};
export type Location = {
  name?: string;
  type?: "COUNTRY" | "CITY";
};
export type SuggestedBudgetRequest = {
  location?: Location[];
  location_exclude?: Location[];
  age_group?: string[];
  gender?: string[];
  language?: string[];
  interests?: string[];
  interests_exclude?: string[];
  education_level?: string[];
  relationship_status?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  final_url?: string;
  event_type?:
  | "PRODUCT_ROLLOUT"
  | "FEATURE_UNVEILING"
  | "RAISED_FUNDING"
  | "MARKET_EXPANSION"
  | "HOSTING_LEARNING_SESSIONS"
  | "SEASONAL_DISCOUNT_SALES"
  | "EVENT_PARTICIPATION"
  | "PARTNERSHIP_INITIATION"
  | "STRATEGIC_SHIFT"
  | "TEAM_ONBOARDING_CELEBRATION"
  | "BRAND_MILESTONE_CELEBRATION";
  product_type?:
  | "MOBILE_APP"
  | "WEB_SITE"
  | "PHYSICAL_PRODUCT"
  | "OTHER"
  | "DEFAULT"
  | "APP";
  paid_channel_count?: number;
};
export type GoogleAdsAddAccountRequest = {
  access_code: string;
  social_key_id?: string;
};
export type ReachEstimate = {
  userLowerBound?: number;
  userUpperBound?: number;
};
export type ReachEstimateRequest = {
  location?: Location[];
  location_exclude?: Location[];
  age_group?: string[];
  gender?: string[];
  language?: string[];
  interests?: string[];
  interests_exclude?: string[];
  education_level?: string[];
  relationship_status?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
};
export type AddApiKeysRequest = {
  brandId: string;
  apiKeyName: string;
  apiType?:
  | "facebook"
  | "instagram"
  | "x"
  | "linkedin"
  | "blog"
  | "newsletter"
  | "google_ads"
  | "meta_ads";
  apiKey: string;
  apiSecret: string;
  apiAccessToken: string;
  apiTokenSecret: string;
  user_profile?: string;
  account_id?: string;
};
export type AdPublishConnectionParameters = {
  apiKey?: string;
  apiSecret?: string;
  accessToken?: string;
  tokenSecret?: string;
  userProfile?: string;
  accountId?: string;
  pageId?: string;
  managerAccountId?: string;
  profilePictureUrl?: string;
};
export type SocialApiKeyModel = {
  id?: string;
  name?: string;
  organisationId?: string;
  apiType?:
  "FACEBOOK"
  | "INSTAGRAM"
  | "X"
  | "LINKEDIN"
  | "BLOG"
  | "NEWSLETTER"
  | "GOOGLE_ADS"
  | "META_ADS"
  | "FACEBOOK_PAGE"
  | "FACEBOOK_AD_ACCOUNT"
  connectionParameters?: AdPublishConnectionParameters;
};
export type BrandSocialsModel = {
  brandId?: string;
  apiKeyModel?: SocialApiKeyModel;
};
export type ConnectApiKeyRequest = {
  key_id?: string;
  apiType?:
  "FACEBOOK"
  | "INSTAGRAM"
  | "X"
  | "LINKEDIN"
  | "BLOG"
  | "NEWSLETTER"
  | "GOOGLE_ADS"
  | "META_ADS"
  | "FACEBOOK_PAGE"
  | "FACEBOOK_AD_ACCOUNT"
};

export type InterestsResponse = {
  insterests?: string[];
};
export type LocationResponse = {
  locations?: Location[];
};
export const {
  useAddMetaAccountMutation,
  useGetSuggestedBudgetMutation,
  useAddGoogleAdsAccountMutation,
  useGetReachEstimateMutation,
  useGetApiKeysByBrandIdQuery,
  useLazyGetApiKeysByBrandIdQuery,
  useConnectApiKeyMutation,
  useAddApiKeysMutation,
  useGetApiKeysByOrganizationIdQuery,
  useLazyGetApiKeysByOrganizationIdQuery,
  useDeleteApiKeysByOrganizationIdMutation,
  useGetUserInterestsQuery,
  useLazyGetUserInterestsQuery,
  useGetGeoLocationsQuery,
  useLazyGetGeoLocationsQuery,
  useDeleteApiKeysByOrganizationIdAndKeyMutation,
  useDisconnectApiKeysByBrandIdMutation,
} = injectedRtkApi;
