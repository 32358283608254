import * as React from "react";
import type { SVGProps } from "react";
const SvgDefaultBrandLogoNew = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      opacity="0.4" d="M3.79486 17.6247C3.41154 17.6247 3.07915 17.484 2.79768 17.2025C2.51622 16.921 2.37549 16.5886 2.37549 16.2053V3.79498C2.37549 3.4103 2.51622 3.07674 2.79768 2.79429C3.07915 2.51184 3.41154 2.37061 3.79486 2.37061H16.2052C16.5899 2.37061 16.9234 2.51184 17.2059 2.79429C17.4883 3.07674 17.6296 3.4103 17.6296 3.79498V16.2053C17.6296 16.5886 17.4883 16.921 17.2059 17.2025C16.9234 17.484 16.5899 17.6247 16.2052 17.6247H3.79486ZM3.79486 16.2053H16.2052V3.79498H3.79486V16.2053ZM5.55663 14.2741H14.4643C14.6039 14.2741 14.7061 14.2109 14.771 14.0844C14.8358 13.958 14.8285 13.8313 14.7491 13.7044L12.3266 10.4544C12.2495 10.3611 12.1542 10.3136 12.0409 10.312C11.9276 10.3103 11.8329 10.3578 11.7568 10.4544L9.2917 13.6338L7.64405 11.3711C7.56362 11.2745 7.46675 11.227 7.35343 11.2286C7.24011 11.2303 7.1454 11.2778 7.06932 11.3711L5.29259 13.7044C5.1993 13.8313 5.18508 13.958 5.24993 14.0844C5.31476 14.2109 5.417 14.2741 5.55663 14.2741Z"
    />
  </svg>
);
export default SvgDefaultBrandLogoNew;
