import * as React from "react";
import type { SVGProps } from "react";
const SvgContentType = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      d="m3.042 17.524-.479-.146a1.604 1.604 0 0 1-.91-1c-.16-.472-.114-.937.14-1.396l1.25-2.833v5.375Zm3.875 1.625c-.472 0-.875-.167-1.208-.5a1.646 1.646 0 0 1-.5-1.208v-4.313l1.916 5.458a.85.85 0 0 0 .12.292c.056.083.127.174.214.27h-.542Zm4.081-.425c-.328.135-.668.106-1.019-.086a1.638 1.638 0 0 1-.75-.822L5.25 7.003a1.142 1.142 0 0 1 .042-1.037c.18-.336.46-.58.84-.731l6.626-2.396a1.256 1.256 0 0 1 1.037.088c.339.18.584.453.736.82l3.98 10.692c.103.38.078.752-.075 1.116a1.322 1.322 0 0 1-.802.752l-6.636 2.417ZM9.335 8.4a.66.66 0 0 0 .478-.205.654.654 0 0 0 0-.958.66.66 0 0 0-.958.001.663.663 0 0 0 0 .957.657.657 0 0 0 .48.205Zm1.247 8.94 6.54-2.392-3.948-10.728-6.54 2.371 3.948 10.749Z"
    />
  </svg>
);
export default SvgContentType;
