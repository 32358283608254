import debounce from "debounce"
import { useEffect, useState } from "react"
import "./Train.scss"
import React from "react"

interface ITrainProps {
    direction?: 'left' | 'right'
    speed?: number
    baseX?: number
    children: React.ReactNode
    className?: string
    base?: number
}
function Train(props: ITrainProps) {
    const { children, base, className, direction = "right", speed = 1, baseX = -100 } = props
    const [translateX, setTranslateX] = useState(0)
    let nbOfChildren: number,
        lastElIndex: number;

        let rootEl =  React.createRef<HTMLUListElement>();
    let width: number;
    let scrollY = 0;


    var onMount = () => {
        scrollY = window.pageYOffset
        const children = rootEl.current?.getElementsByTagName('li');
        nbOfChildren = children?.length ?? 0;
        lastElIndex = nbOfChildren - 1;
        setTranslateX(baseX + (direction === 'right' ? scrollY / 2 : -1 * (scrollY / 2)))
    }

    useEffect(() => {
        window.addEventListener('scroll', onMount);
        window.addEventListener('resize', onMount);
        onMount()

        return () => {
            window.removeEventListener('scroll', onMount);
            window.removeEventListener('resize', onMount);
        }
    }, [])


    return (<>

        <div className={`ec-train ${className} ${base}`}>
            <ul ref={rootEl}
                style={{ "--transx": `${translateX}px` } as React.CSSProperties} >
                {children}
            </ul>
        </div>

    </>);
}

export default Train;