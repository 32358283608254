import SvgFacebookColor from "../../../assets/IconComponents/FacebookColor";
import ContentContainer from "../../../components/ContentContainer";
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import SvgMoreIcon from "../../../assets/IconComponents/MoreIcon";
import SvgRefresh from "../../../assets/IconComponents/Refresh";
import SvgDisconnectSocials from "../../../assets/IconComponents/DisconnectSocialIcon";
import { SocialApiKeyModel, useAddGoogleAdsAccountMutation, useAddMetaAccountMutation, useDeleteApiKeysByOrganizationIdAndKeyMutation } from "../../../services/AdManagerApi";
import { useEffect, useState } from "react";
import SvgGoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";
import SvgInstagramColor from "../../../assets/IconComponents/InstagramColor";
import SvgLinkedinColor from "../../../assets/IconComponents/LinkedinColor";
import SvgTwitterColor from "../../../assets/IconComponents/TwitterColor";
import { fbLogin, getFacebookLoginStatus, initFacebookSdk } from "../../../helpers/FacebookSdk";
import { useGoogleLogin } from "@react-oauth/google";
import SvgMetaSocialIcon from "../../../assets/IconComponents/MetaSocialIcon";


export interface ISocialAccountConnectionCardProps {
    social_account: SocialApiKeyModel
    onApiKeyDeleted: (deletedKey: SocialApiKeyModel) => void
}

function SocialAccountConnectionCard(props: ISocialAccountConnectionCardProps) {

    const [apiIcon, setApiIcon] = useState(<></>);
    const [mainIcon, setMainIcon] = useState(<></>)
    const [subText, setSubText] = useState("")
    const [deleteApiKey, { isSuccess: deleteSuccess }] = useDeleteApiKeysByOrganizationIdAndKeyMutation();
    const [addMetaAccount, { isSuccess: metaSuccess }] = useAddMetaAccountMutation();
    const [addGoogleAdsAccount, { isSuccess: googleSuccess }] = useAddGoogleAdsAccountMutation();

    useEffect(() => {
        props.onApiKeyDeleted(props.social_account)
    }, [deleteSuccess])

    function login() {
        fbLogin().then((response) => {
            console.log(response);
            if (response.status === "connected") {
                addMetaAccount({ metaAddAccountRequest: { access_token: response.authResponse.accessToken, user_id: response.authResponse.userID, social_key_id: props.social_account.id } })
            } else {
            }
        });
    }

    useEffect(() => {
        initFacebookSdk().then(() => {
            getFacebookLoginStatus().then((response) => {
                if (response == null) {
                    console.log("No login status for the person");
                } else {
                    console.log(response);
                }
            });
        });
    }, [])

    const googleLogin = useGoogleLogin({
        scope: "https://www.googleapis.com/auth/adwords",
        ux_mode: "popup",
        onSuccess: (tokenResponse) => {
            console.log('Google login successful', tokenResponse);
            addGoogleAdsAccount({ googleAdsAddAccountRequest: { access_code: tokenResponse.code, social_key_id: props.social_account.id } });
        },
        onError: () => {
            console.error('Google login failed');
        },
        flow: 'auth-code',
    });


    useEffect(() => {
        if (props.social_account.apiType === "FACEBOOK" || props.social_account.apiType === "META_ADS" || props.social_account.apiType === "FACEBOOK_PAGE" || props.social_account.apiType === "FACEBOOK_AD_ACCOUNT") {
            setApiIcon(<SvgFacebookColor className="bottom-[-5px] left-7 absolute w-[20px] h-[21px] rounded-full" />)
        } else if (props.social_account.apiType === "GOOGLE_ADS") {
            setApiIcon(<SvgGoogleAdsColor className="bottom-[-5px] left-7 absolute w-[20px] h-[21px] rounded-full" />)
        } else if (props.social_account.apiType === "INSTAGRAM") {
            setApiIcon(<SvgInstagramColor className="bottom-[-5px] left-7 absolute w-[20px] h-[21px] rounded-full" />)
        } else if (props.social_account.apiType === "LINKEDIN") {
            setApiIcon(<SvgLinkedinColor className="bottom-[-5px] left-7 absolute w-[20px] h-[21px] rounded-full" />)
        } else if (props.social_account.apiType === "X") {
            setApiIcon(<SvgTwitterColor className="bottom-[-5px] left-7 absolute w-[20px] h-[21px] rounded-full" />)
        }
    }, [props])


    useEffect(() => {
        if (props.social_account.connectionParameters?.profilePictureUrl !== undefined) {
            setMainIcon(<img src={`${props.social_account.connectionParameters?.profilePictureUrl}`} className="w-[35px] h-[35px] rounded-full" />)
        }
        else if (props.social_account.apiType === "FACEBOOK" || props.social_account.apiType === "META_ADS" || props.social_account.apiType === "FACEBOOK_PAGE") {
            setMainIcon(<SvgFacebookColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.social_account.apiType === "FACEBOOK_AD_ACCOUNT") {
            setMainIcon(<SvgMetaSocialIcon className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.social_account.apiType === "GOOGLE_ADS") {
            setMainIcon(<SvgGoogleAdsColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.social_account.apiType === "INSTAGRAM") {
            setMainIcon(<SvgInstagramColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.social_account.apiType === "LINKEDIN") {
            setMainIcon(<SvgLinkedinColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.social_account.apiType === "X") {
            setMainIcon(<SvgTwitterColor className="w-[35px] h-[35px] rounded-full" />)
        }
    }, [props])

    useEffect(() => {
        if (props.social_account.apiType === "FACEBOOK" || props.social_account.apiType === "FACEBOOK_PAGE") {
            setSubText(`Facebook Page ID : ${props.social_account.connectionParameters?.pageId}`)
        } else if (props.social_account.apiType === "META_ADS" || props.social_account.apiType === "FACEBOOK_AD_ACCOUNT") {
            setSubText(`Meta Ad Account ID : ${props.social_account.connectionParameters?.accountId}`)
        } else if (props.social_account.apiType === "GOOGLE_ADS") {
            setSubText(`Google Ad Account ID : ${props.social_account.connectionParameters?.accountId}`)
        } else if (props.social_account.apiType === "LINKEDIN") {
            setSubText(`LinkedIn`)
        } else if (props.social_account.apiType === "INSTAGRAM") {
            setSubText(`Instagram Bussiness`)
        } else if (props.social_account.apiType === "X") {
            setSubText(`X (Twitter)`)
        }

    }, [props])


    const items: MenuProps['items'] = [
        {
            icon: <SvgRefresh fill="black" />,
            label: 'Refresh Connection',
            key: '0',
        },
        {
            icon: <SvgDisconnectSocials fill="black" />,
            label: 'Disconnect',
            key: '1',
        },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === '1') { //disconnect
            deleteApiKey({ apiKey: props.social_account.id ?? "" })
        } else if (e.key === '0') { //refresh
            if (props.social_account.apiType === "FACEBOOK" || props.social_account.apiType === "FACEBOOK_PAGE" || props.social_account.apiType === "FACEBOOK_AD_ACCOUNT") {
                login();
            }
            else if (props.social_account.apiType === "GOOGLE_ADS") {
                googleLogin()
            }
        }
    };


    return (<>
        <ContentContainer
            hoverable={false}
            className="w-full h-fit overflow-hidden p-[14px]"

        >
            <div className="flex flex-row h-[66px] justify-between">
                <div className="flex flex-row shrink-1 items-center gap-[10px]">
                    <div className="relative w-[45px]">
                        {mainIcon}
                        {apiIcon}
                    </div>
                    <div className="flex flex-col gap-[2px]">
                        <div className="text-black">{props.social_account.name}</div>
                        <div className="text-gray text-BodyMedium">{subText}</div>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <Dropdown menu={{ items, onClick }} trigger={['click']} >
                        <a onClick={(e) => e.preventDefault()}>
                            <SvgMoreIcon />
                        </a>
                    </Dropdown>
                </div>
            </div>
        </ContentContainer >

    </>)
}

export default SocialAccountConnectionCard;
