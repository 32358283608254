import * as React from "react";
const SvgLeftMenuBackgroundRemoverButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    {...props}
  >
    <path
      d="M2.5 8.542V6.77L6.27 3h1.772L2.5 8.542Zm0-4.084V3h1.458L2.5 4.458Zm10.98 1.396a6.796 6.796 0 0 0-.449-.448 4.106 4.106 0 0 0-.49-.385L14.563 3h1.771L13.48 5.854ZM4.582 12.98l1.604-1.604c.098.153.198.292.303.417a3.3 3.3 0 0 0 .343.354c-.389.097-.78.219-1.177.364a9.184 9.184 0 0 0-1.073.47Zm10-4.104v-.042c0-.264-.02-.52-.062-.77a4.308 4.308 0 0 0-.188-.73L17.5 4.167v1.791l-2.917 2.917Zm-5.5-4.542L10.437 3h1.771l-1.333 1.333a5.527 5.527 0 0 0-.438-.062 5.06 5.06 0 0 0-1.354.063ZM2.5 12.687v-1.77l3-3a5.184 5.184 0 0 0-.083.916 4.4 4.4 0 0 0 .083.854l-3 3Zm14.77 1.73a3.796 3.796 0 0 0-.854-.896l1.084-1.083v1.77l-.23.209Zm-2.416-1.709a5.126 5.126 0 0 0-.583-.208 22.126 22.126 0 0 1-.365-.125 3.136 3.136 0 0 0-.364-.104L17.5 8.29v1.792l-2.646 2.625ZM10 12.167a3.21 3.21 0 0 1-2.354-.98 3.21 3.21 0 0 1-.98-2.354c0-.916.327-1.701.98-2.354A3.21 3.21 0 0 1 10 5.5a3.21 3.21 0 0 1 2.354.98 3.21 3.21 0 0 1 .98 2.353 3.21 3.21 0 0 1-.98 2.354 3.21 3.21 0 0 1-2.354.98Zm0-1.667c.458 0 .85-.163 1.177-.49.327-.326.49-.718.49-1.177 0-.458-.163-.85-.49-1.177A1.605 1.605 0 0 0 10 7.166c-.458 0-.85.164-1.177.49-.326.327-.49.719-.49 1.177 0 .459.164.851.49 1.177.326.327.719.49 1.177.49Zm-6.667 6.667v-.646c0-.472.118-.91.354-1.313a2.31 2.31 0 0 1 .98-.916c.708-.361 1.51-.667 2.406-.917C7.969 13.125 8.944 13 10 13s2.031.125 2.927.375c.896.25 1.698.556 2.406.917.417.208.743.514.98.916.236.403.354.84.354 1.313v.646c0 .236-.08.434-.24.593a.807.807 0 0 1-.594.24H4.167a.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.593Zm1.688-.834h9.958a.81.81 0 0 0-.146-.323.768.768 0 0 0-.27-.218c-.5-.25-1.136-.5-1.907-.75-.77-.25-1.656-.375-2.656-.375-1 0-1.885.125-2.656.375-.771.25-1.407.5-1.907.75a.725.725 0 0 0-.27.229.917.917 0 0 0-.146.312Z"
    />
  </svg>
);
export default SvgLeftMenuBackgroundRemoverButton;
