import * as React from "react";
import type { SVGProps } from "react";
const SvgDefaultBrandLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M3.125 18.25c-.378 0-.702-.135-.971-.404a1.324 1.324 0 0 1-.404-.971V6.906c0-.378.135-.702.404-.97.27-.27.593-.405.971-.405h2.406V3.125c0-.378.135-.702.404-.971.27-.27.593-.404.971-.404h6.188c.378 0 .702.135.97.404.27.27.405.593.405.971v6.188h2.406c.378 0 .702.134.971.403.27.27.404.593.404.972v6.187c0 .378-.135.702-.404.971-.27.27-.593.404-.971.404h-5.66v-3.781h-2.43v3.781h-5.66Zm0-1.375h2.406v-2.406H3.125v2.406Zm0-3.781h2.406v-2.406H3.125v2.406Zm0-3.781h2.406V6.905H3.125v2.407Zm3.781 3.78h2.407v-2.405H6.905v2.406Zm0-3.78h2.407V6.905H6.905v2.407Zm0-3.782h2.407V3.125H6.905v2.406Zm3.782 7.563h2.406v-2.406h-2.406v2.406Zm0-3.781h2.406V6.905h-2.406v2.407Zm0-3.782h2.406V3.125h-2.406v2.406Zm3.78 11.344h2.407v-2.406h-2.406v2.406Zm0-3.781h2.407v-2.406h-2.406v2.406Z"
    />
  </svg>
);
export default SvgDefaultBrandLogo;
