import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { useLazySurpriseMeQuery } from "../../../services/SubjectApi";
import { ContentType } from "../../../constants/ContentType";
import ToggleButton from "../../../components/ToggleButton";
import { setNewContent } from "../../../store/contentSlice";
import SvgSupriseIcon from "../../../assets/IconComponents/SupriseIcon";
import { INewContent } from "../../../models/NewContent";
import { t } from "i18next";
import SvgTritoneWhatsYourContentAbout from "../../../assets/IconComponents/TritoneWhatsYourContentAbout";
import { ContentSize } from "../../../constants/ContentSize";
import { useLazyRefreshQuery } from "../../../services/KeywordApi";


interface SubjectProps {
    className?: string
    briefContent?: INewContent
    contentUpdated?: (updatedContent: INewContent) => void
    isCreativePages?: boolean
    useKeywords?: boolean
    hideTitle?: boolean
    callingParent?: string
}

const Subject: FunctionComponent<SubjectProps> = (props: SubjectProps) => {

    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);
    const latestBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId)
    const [supriseMe, { data: supriseMeData, isLoading: randomDataLoading, isError: isSurpriseMeError }] =
        useLazySurpriseMeQuery();
    const [getKeywords, { data: keywords }] = useLazyRefreshQuery();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>("");
    const [brandKeywords, setBrandKeywords] = useState<string[]>([]);

    let interval: any;

    useEffect(() => {
        setSubject('')
        console.log("Content to write is: ", newContent);
        dispatch(setNewContent({
            ...newContent,
            contentAbout: ''
        } as INewContent))
    }, [])

    useEffect(() => {
      if (props.callingParent) {
        if (props.callingParent === "productphotoshoot" && !props.briefContent ) {
          setCollapsed(true);
        }
      }
    }, [props.callingParent]);



    function triggerSurpriseMe(trigger: boolean) {
        if (trigger) {
            clearTimeout(interval);
            setSubject("");
            if (!props.useKeywords) {
                supriseMe({
                    brandId: props.briefContent ? latestBrandId : newContent?.brand?.id,
                    _noCache: Date.now()
                }, false);
            } else {
                if (brandKeywords.length > 0) {
                    const shuffledKeywords = brandKeywords.map(value => ({ value, sort: Math.random() }))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({ value }) => value);
                    setBrandKeywords(shuffledKeywords);
                }
                else {
                    if (props.briefContent) {
                        getKeywords({ brandId: props.briefContent?.brand?.id ?? "", count: 30, onlyBrand: true });
                    } else {
                        getKeywords({ brandId: newContent?.brand?.id ?? "", count: 30, onlyBrand: true });
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (supriseMeData) {
            const sentenceArray = supriseMeData.value.split("");
            let currentIndex = 0;

            const typeNextCharacter = () => {
                if (currentIndex < sentenceArray.length + 1) {
                    let sent = sentenceArray.slice(0, currentIndex);
                    setSubject(sent.join(""));
                    currentIndex++;
                    interval = setTimeout(typeNextCharacter, 50); // Delay between characters (ms)
                }
            };
            typeNextCharacter();
        }
    }, [supriseMeData]);

    useEffect(() => {
        if (keywords && keywords.length > 0) {
            const shuffledKeywords = keywords.map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value.name)
            setBrandKeywords(shuffledKeywords);
        }
    }, [keywords]);

    useEffect(() => {
        if (brandKeywords && brandKeywords.length > 0) {
            const sentenceArray = brandKeywords.slice(0, 2).join(", ").split("");
            let currentIndex = 0;

            const typeNextCharacter = () => {
                if (currentIndex < sentenceArray.length + 1) {
                    let sent = sentenceArray.slice(0, currentIndex);
                    setSubject(sent.join(""));
                    currentIndex++;
                    interval = setTimeout(typeNextCharacter, 50); // Delay between characters (ms)
                }
            };
            typeNextCharacter();
        }
    }, [brandKeywords]);

    useEffect(() => {
        if (props.briefContent) {
            if (props.contentUpdated) {
                if (!props.briefContent.isCreative) {
                    props.contentUpdated({ ...props.briefContent, contentAbout: subject })
                } else {
                    props.contentUpdated({ ...props.briefContent, creative_topic: subject })
                }
            }
        } else {
          console.log("Content to write1 is: ", newContent);
            dispatch(setNewContent({
                ...newContent,
                contentAbout: subject
            } as INewContent))
        }

    }, [subject])

    useEffect(() => {
        if (props.briefContent?.contentAbout || props.briefContent?.creative_topic) {
            if (!props.briefContent.isCreative) {
                if (subject.length === 0 && props.briefContent.contentAbout) {
                    setSubject(props.briefContent.contentAbout)
                }
            }
            else {
                if (subject.length === 0 && props.briefContent.creative_topic) {
                    setSubject(props.briefContent.creative_topic ?? "")
                }
            }
        }
    }, [props.briefContent])

    useEffect(() => {
        if (!props.briefContent) {
            if (subject.length === 0 && newContent?.contentAbout && newContent?.contentAbout.length > 0) {
                setCollapsed(false);
                setSubject(newContent?.contentAbout ?? "")
            }
        }
    }, [newContent])

    return (
      <div
        className={`flex lf-stretch justify-start items-start gap-3 ${
          props.className ?? `inline-flex`
        } w-full`}
      >
        {!props.hideTitle && (
          <div className="w-[240px] h-7 justify-start items-center gap-2.5 flex">
            <SvgTritoneWhatsYourContentAbout />
            <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
              What’s your content about?
            </div>
          </div>
        )}

        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex w-full">
          {props.briefContent === undefined &&
            (props.callingParent ?? "") === "productphotoshoot" && (
              <div className="flex w-full items-end justify-end mb-3">
                <ToggleButton
                  isChecked={!collapsed}
                  handleCheckBoxChange={function (checked: boolean): void {
                    setCollapsed(!checked);
                    if (!checked) {
                      setSubject("");
                    }
                  }}
                  checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                  checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                  uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                  uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
                />
              </div>
            )}
          {!collapsed && (
            <>
              <textarea
                value={subject}
                className={`self-stretch h-[110px] p-2.5 rounded-tl-md rounded-tr-md border border-stone-200 flex-col justify-start items-start gap-2.5 flex bg-light text-BodyMedium text-black placeholder:text-BodyMedium placeholder:text-gray overflow-scroll scrollbar-hide  outline-none resize-none`}
                placeholder={
                  randomDataLoading
                    ? "Typing..."
                    : (props.callingParent ?? "") === "productphotoshoot"
                    ? "Enter the essence of the offering, the discount, or special offer here."
                    : props.isCreativePages
                    ? "What would you like to highlight in your ad? e.g sustainable living, healthy lifestyle"
                    : "What’s your content about?"
                }
                onChange={(e) => {
                  if (subject !== e.target.value) {
                    setSubject(e.target.value);
                  }
                }}
              />
              <div
                className={`self-stretch pl-2.5 pr-[5px] py-[5px] rounded-bl-md rounded-br-md border border-stone-200 justify-between items-center inline-flex`}
              >
                {(props.briefContent
                  ? props.briefContent?.contentType === ContentType.image
                  : newContent?.contentType === ContentType.image) &&
                  (props.briefContent
                    ? props.briefContent.contentSize !==
                        ContentSize.mobileleaderb &&
                      props.briefContent.contentSize !==
                        ContentSize.mobilemedr &&
                      !props.briefContent.isCreative
                    : newContent?.contentSize !== ContentSize.mobileleaderb &&
                      newContent?.contentSize !== ContentSize.mobilemedr) &&
                  !newContent?.isCreative &&
                  (props.callingParent ?? "") !== "productphotoshoot" && (
                    <div className="justify-end items-center gap-2 flex">
                      <ToggleButton
                        isChecked={
                          props.briefContent
                            ? props.briefContent?.cta ?? false
                            : newContent?.cta ?? false
                        }
                        handleCheckBoxChange={(e) => {
                          if (props.briefContent) {
                            if (props.contentUpdated) {
                              props.contentUpdated({
                                ...props.briefContent,
                                cta: e,
                              });
                            }
                          } else {
                            dispatch(
                              setNewContent({
                                ...newContent,
                                cta: e,
                              })
                            );
                          }
                        }}
                        checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                        checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                        uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                        uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
                        tooltip={"Add Call to Action button to your design"}
                      />
                      <div className="text-neutral-400 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                        Add CTA
                      </div>
                    </div>
                  )}
                {(props.briefContent
                  ? props.briefContent.contentType === ContentType.text
                  : newContent?.contentType === ContentType.text ||
                    newContent?.isCreative) && <div></div>}
                {(props.briefContent
                  ? props.briefContent.contentSize ===
                      ContentSize.mobileleaderb ||
                    props.briefContent.contentSize !== ContentSize.mobilemedr
                  : newContent?.contentSize === ContentSize.mobileleaderb ||
                    newContent?.contentSize == ContentSize.mobilemedr) && (
                  <div></div>
                )}
                <div className="justify-end items-center gap-[5px] flex">
                  <button
                    className={`${
                      props.isCreativePages ||
                      (props.callingParent ?? "") === "productphotoshoot"
                        ? ""
                        : "w-[115px]"
                    } h-7 p-[5px] ${
                      props.isCreativePages ||
                      (props.callingParent ?? "") === "productphotoshoot"
                        ? ""
                        : "bg-zinc-100"
                    }  rounded-[3px] justify-end items-center gap-2 inline-flex`}
                    onClick={() => {
                      triggerSurpriseMe(true);
                    }}
                  >
                    <SvgSupriseIcon
                      fill={`${
                        props.isCreativePages ||
                        (props.callingParent ?? "") === "productphotoshoot"
                          ? "#999"
                          : "#ff5555"
                      }`}
                    />
                    {!props.isCreativePages &&
                      (props.callingParent ?? "") !== "productphotoshoot" && (
                        <text className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                          {t("surprise-me")}
                        </text>
                      )}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
}

export default Subject;
