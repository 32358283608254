import { DeleteOutlined, FileImageOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, CollapseProps, Input, Popover, Select, Spin } from "antd";
import SvgGenerateSimilarIcon from "../../../assets/IconComponents/GenerateSimilarIcon";
import { useEffect, useState } from "react";
import OrganicImages from "./OrganicImages";
import SecondaryButton from "../../SecondaryButton";
import GenerateImageWithAI from "./GenerateImageWithAI";
import AddCustomImageStep from "./AddCustomImageStep";
import {
    ActivityResponse,
    ContentDetails,
    useCreateActivityFromCampaignsMutation,
    useLazyRegenerateForActivityQuery
} from "../../../services/Activity";
import { toggleLoading } from "../../../store/appSlice";
import { useAppDispatch } from "../../../helpers/hooks";


const sideModalSteps = {
    addCustomImage: 'addCustomImage',
    addAIImage: 'addAIImage',
}

const sideModals = {
    [sideModalSteps.addCustomImage]: {
        title: 'Add Image',
        description: 'Select in order to add your images',
    },
    [sideModalSteps.addAIImage]: {
        title: 'Generate with AI',
        description: '',
    },
}

const EditCreativesStep = (props) => {
    const dispatch = useAppDispatch();
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [activity, setActivity] = useState<ActivityResponse>()
    const [shortHeadLines, setShortHeadLines] = useState<ContentDetails[]>([])
    const [longHeadLines, setLongHeadLines] = useState<ContentDetails[]>([])
    const [descriptions, setDescriptions] = useState<ContentDetails[]>([])
    const [CTAOptions, setCTAOptions] = useState<any[]>([
        { label: 'Apply now', value: 'Apply now' },
        { label: 'Book now', value: 'Book now' },
        { label: 'Buy tickets', value: 'Buy tickets' },
        { label: 'Call now', value: 'Call now' },
        { label: 'Contact us', value: 'Contact us' },
        { label: 'Donate now', value: 'Donate now' },
        { label: 'Download', value: 'Download' },
        { label: 'Get directions', value: 'Get directions' },
        { label: 'Get offer', value: 'Get offer' },
        { label: 'Get quote', value: 'Get quote' },
        { label: 'Get showtimes', value: 'Get showtimes' },
        // { label: 'Install now', value: 'Install now' },
        { label: 'Learn more', value: 'Learn more' },
        { label: 'Like Page', value: 'Like Page' },
        { label: 'Listen now', value: 'Listen now' },
        { label: 'Open link', value: 'Open link' },
        { label: 'Order now', value: 'Order now' },
        { label: 'Play game', value: 'Play game' },
        { label: 'Request time', value: 'Request time' },
        // { label: 'Save', value: 'Save' },
        // { label: 'See menu', value: 'See menu' },
        // { label: 'Send message', value: 'Send message' },
        // { label: 'Send WhatsApp message', value: 'Send WhatsApp message' },
        { label: 'Shop now', value: 'Shop now' },
        { label: 'Sign up', value: 'Sign up' },
        { label: 'Subscribe', value: 'Subscribe' },
        { label: 'Use app', value: 'Use app' },
        // { label: 'View event', value: 'View event' },
        { label: 'Watch more', value: 'Watch more' }
    ])

    const [selectedCTA, setSelectedCTA] = useState<string>('')

    const [selectedImages, setSelectedImages] = useState<any[]>(props?.activity?.imageContentDetails ?? []);
    const [isSideModalOpen, setIsSideModalOpen] = useState<boolean>(false)
    const [activeSideModalStep, setActiveSideModalStep] = useState<string>('')
    const [generatedAIImages, setGeneratedAIImages] = useState<any[]>([])
    const [selectedProductImages, setSelectedProductImages] = useState<string[]>([]);
    const [regenerateForActivity, { data: createdActivitiesFromCampaigns, isLoading: createActivityIsLoading }] = useLazyRegenerateForActivityQuery()

    const genExtra = (params) => (
        <div className={'flex w-full justify-start'}>
            <div className={'mr-2'}>{params.description}</div>
            {params?.withAI &&
                <Popover content={'Regenerate this creative set.'}>
                    <SvgGenerateSimilarIcon
                        onClick={(event) => {
                            regenerateTexts({ type: params.type })
                            dispatch(toggleLoading(true));
                            event.stopPropagation();
                        }}
                    />
                </Popover>}
        </div>
    );

    const regenerateTexts = (param) => {
        regenerateForActivity({ regenerationType: param.type, activityId: activity?.id ?? '' }).then(response => {
            dispatch(toggleLoading(false));
            switch (param.type) {
                case 'SHORT_HEADLINE':
                    if (response.data?.length) {
                        setShortHeadLines(response.data ?? [])
                    }
                    break;
                case 'LONG_HEADLINE':
                    if (response.data?.length) {
                        setLongHeadLines(response.data ?? [])
                    }
                    break;
                case 'DESCRIPTION':
                    if (response.data?.length) {
                        setDescriptions(response.data ?? [])
                    }
                    break;
            }
        })
        return [] as ContentDetails[]
    }

    useEffect(() => {
        if (props.activity && !firstLoaded) {
            let act = props.activity as ActivityResponse
            setActivity(act)
            setShortHeadLines(act.creatives?.shortHeadlines ?? [])
            setLongHeadLines(act.creatives?.longHeadlines ?? [])
            setDescriptions(act.creatives?.description ?? [])
            if (act.creatives?.ctas?.[0]) {
                setSelectedCTA(act.creatives?.ctas?.[0].textContent ?? '')
            }
            setSelectedImages(act.imageContentDetails ?? [])
            setFirstLoaded(true)
        }
    }, [props.activity])

    useEffect(() => {
        if (activity == undefined) return

        var crea = { ...activity?.creatives }
        if (crea != undefined)
            crea.shortHeadlines = shortHeadLines;

        setActivity(k => ({ ...k, creatives: crea }))
    }, [shortHeadLines])

    useEffect(() => {
        if (activity == undefined) return

        var crea = { ...activity?.creatives }
        if (crea != undefined)
            crea.longHeadlines = longHeadLines;

        setActivity(k => ({ ...k, creatives: crea }))
    }, [longHeadLines])

    useEffect(() => {
        if (activity == undefined) return

        var crea = { ...activity?.creatives }
        if (crea != undefined)
            crea.description = descriptions;

        setActivity(k => ({ ...k, creatives: crea }))
    }, [descriptions])

    useEffect(() => {
        if (activity == undefined) return

        var crea = { ...activity?.creatives }
        if (crea != undefined)
            crea.ctas = [{ textContent: selectedCTA }];

        setActivity(k => ({ ...k, creatives: crea }))
    }, [selectedCTA])


    useEffect(() => {
        if (activity) {
            props.creativesChanged(activity);
        }
    }, [activity])

    useEffect(() => {
        if (activity == undefined) return
        setActivity(k => ({ ...k, imageContentDetails: selectedImages }))
    }, [selectedImages])

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: (activity?.postCategory === 'PAID_META' ? 'Primary Text' : 'Short Headlines'),
            children: <div>
                {activity?.postCategory && shortHeadLines.map((shortHeadLine, i) => (
                    <>
                        <div className={'flex mb-2'}>
                            <Input
                                status={(shortHeadLine?.textContent && shortHeadLine?.textContent?.length > 0 && shortHeadLine?.textContent?.length < 3) ? "error" : ''}
                                placeholder="short headLine"
                                value={shortHeadLine.textContent}
                                onChange={(e) => {
                                    setShortHeadLines(prevState => {
                                        const tempArray: ContentDetails[] = [];
                                        prevState.forEach((prevItem, index) => {
                                            if (i === index) {
                                                tempArray.push({ textContent: e.target.value })
                                            } else {
                                                tempArray.push(prevItem)
                                            }
                                        })
                                        return tempArray;
                                    })
                                }}
                                showCount
                                minLength={3}
                                maxLength={(activity?.postCategory === 'PAID_META' ? 125 : 30)} />
                            {i > 2 && i !== shortHeadLines.length - 1 && <Button
                                className={'ml-1'}
                                onClick={(e) => {
                                    setShortHeadLines(prevState => {
                                        return prevState.filter((state, index) => index !== i);
                                    })
                                }}
                                icon={<DeleteOutlined />} />}
                            {i === shortHeadLines.length - 1 && (activity?.postCategory === 'PAID_META' ? shortHeadLines.length < 5 : shortHeadLines.length < 15) &&
                                <Button
                                    className={'ml-1'}
                                    onClick={(e) => {
                                        setShortHeadLines(prevState => {
                                            const tempArray: ContentDetails[] = prevState.map(state => state);
                                            tempArray.push({ textContent: "" })
                                            return tempArray;
                                        })
                                    }}
                                    icon={<PlusOutlined />} />}
                        </div>
                        {shortHeadLine?.textContent && shortHeadLine?.textContent?.length > 0 && shortHeadLine?.textContent?.length < 3 && (
                            <p style={{ color: "red" }}>{`You must enter at least 3 characters`}</p>
                        )}
                    </>
                ))}
            </div>,
            extra: genExtra({ withAI: true, description: 'Add at least 3 short headlines', type: 'SHORT_HEADLINE' }),
        },
        {
            key: '2',
            label: (activity?.postCategory === 'PAID_META' ? 'Headlines' : 'Long Headlines'),
            children: <div>
                {longHeadLines.map((longHeadLine, i) => (
                    <>
                        <div className={'flex mb-2'}>
                            <Input
                                status={(longHeadLine?.textContent && longHeadLine?.textContent?.length > 0 && longHeadLine?.textContent?.length < 3) ? "error" : ''}
                                placeholder="long headLine"
                                value={longHeadLine.textContent}
                                onChange={(e) => {
                                    setLongHeadLines(prevState => {
                                        const tempArray: ContentDetails[] = [];
                                        prevState.forEach((prevItem, index) => {
                                            if (i === index) {
                                                tempArray.push({ textContent: e.target.value })
                                            } else {
                                                tempArray.push(prevItem)
                                            }
                                        })
                                        return tempArray;
                                    })
                                }}
                                showCount
                                minLength={3}
                                maxLength={(activity?.postCategory === 'PAID_META' ? 255 : 90)} />
                            {i > 0 && i !== longHeadLines.length - 1 && <Button
                                className={'ml-1'}
                                onClick={(e) => {
                                    setLongHeadLines(prevState => {
                                        return prevState.filter((state, index) => index !== i);
                                    })
                                }}
                                icon={<DeleteOutlined />} />}
                            {i === longHeadLines.length - 1 && longHeadLines.length < 5 && <Button
                                className={'ml-1'}
                                onClick={(e) => {
                                    setLongHeadLines(prevState => {
                                        const tempArray: ContentDetails[] = prevState.map(state => state);
                                        tempArray.push({ textContent: "" })
                                        return tempArray;
                                    })
                                }}
                                icon={<PlusOutlined />} />}
                        </div>
                        {longHeadLine?.textContent && longHeadLine?.textContent?.length > 0 && longHeadLine?.textContent?.length < 3 && (
                            <p style={{ color: "red" }}>{`You must enter at least 3 characters`}</p>
                        )}
                    </>
                ))}
            </div>,
            extra: activity?.postCategory === 'PAID_META' ? genExtra({ withAI: true, description: 'Add at least 1 long headline', type: 'LONG_HEADLINE' }) : undefined,
        },
        {
            key: '3',
            label: 'Description',
            children: <div>
                {descriptions.map((description, i) => (
                    <>
                        <div className={'flex mb-2'}>
                            <Input
                                status={(description?.textContent && description?.textContent?.length > 0 && description?.textContent?.length < 3) ? "error" : ''}
                                placeholder="description"
                                value={description.textContent}
                                onChange={(e) => {
                                    setDescriptions(prevState => {
                                        const tempArray: ContentDetails[] = [];
                                        prevState.forEach((prevItem, index) => {
                                            if (i === index) {
                                                tempArray.push({ textContent: e.target.value })
                                            } else {
                                                tempArray.push(prevItem)
                                            }
                                        })
                                        return tempArray;
                                    })
                                }}
                                showCount
                                minLength={3}
                                maxLength={activity?.postCategory === 'PAID_META' ? 1000 : 90} />
                            {i > 1 && i !== descriptions.length - 1 && <Button
                                className={'ml-1'}
                                onClick={(e) => {
                                    setDescriptions(prevState => {
                                        return prevState.filter((state, index) => index !== i);
                                    })
                                }}
                                icon={<DeleteOutlined />} />}
                            {(i === descriptions.length - 1 && descriptions.length < 5) && <Button
                                className={'ml-1'}
                                onClick={(e) => {
                                    setDescriptions(prevState => {
                                        const tempArray: ContentDetails[] = prevState.map(state => state);
                                        tempArray.push({ textContent: "" })
                                        return tempArray;
                                    })
                                }}
                                icon={<PlusOutlined />} />}
                        </div>
                        {description?.textContent && description?.textContent?.length > 0 && description?.textContent?.length < 3 && (
                            <p style={{ color: "red" }}>{`You must enter at least 3 characters`}</p>
                        )}
                    </>
                ))}
            </div>,
            extra: genExtra({ withAI: true, description: 'Add at least 2 descriptions', type: 'DESCRIPTION' }),
        },
        {
            key: '4',
            label: 'CTA',
            children: <Select
                className={'w-full'}
                value={selectedCTA}
                onChange={(val) => {
                    setSelectedCTA(val)
                }}
                options={CTAOptions}
                placeholder={'select CTA'} />,
            extra: genExtra({ description: 'Select one' }),
        },
    ];

    useEffect(() => {
        console.log(generatedAIImages)
        setSelectedImages([...props?.activity?.imageContentDetails, ...generatedAIImages.map(img => (
            {
                imageFileId: img?.generated_content,
                revisionId: img.id.revision_id,
                contentId: img.id.content_id,
                generated: true,
                active: false
            })), ...selectedProductImages.map(id => (
                {
                    imageFileId: id,
                    generated: false,
                    active: false
                }))])
    }, [selectedProductImages, generatedAIImages]);

    useEffect(() => {
        console.log(selectedImages)
        props.setImageContentDetails(selectedImages)
    }, [selectedImages]);

    return (
        <>
            {!isSideModalOpen ? <>
                <div className={'w-full min-h-[80vh]'}>
                    {props.title && (
                        <div>
                            <div className="p-3 flex justify-between items-center">
                                <div className={'flex items-center'}>
                                    <span className="text-black mr-1 cursor-pointer"
                                        onClick={props.closeSideModal}><LeftOutlined /></span>
                                    <span className="text-H8 campaign-name"> {props.title}</span>
                                </div>
                                <div>
                                    <span className="text-gray text-xs"> {props.description ?? ''}</span>
                                </div>
                            </div>
                            <hr className="bg-border mx-1" />
                        </div>
                    )}
                    <div className={' w-full h-[calc(100%-55px)] px-5 mt-5 flex max-h-[85vh] overflow-auto'}>
                        <div className={''}>
                            <Collapse
                                accordion
                                defaultActiveKey={['1']}
                                onChange={onChange}
                                expandIconPosition={'end'}
                                items={items}
                            />
                        </div>
                        <div className={'min-w-[300px] '}>
                            {selectedImages.length ? (
                                <div className={'ml-2 p-1'}>
                                    <OrganicImages
                                        images={selectedImages}
                                        deleteItemImage={(id) => {
                                            const newList = selectedImages.filter(k => k?.imageFileId !== id)
                                            if (newList.length && !newList.filter(c => c.active).length) {
                                                newList[0].active = true
                                            }
                                            setSelectedImages(newList)
                                        }}
                                        setActiveImage={(id) => {
                                            setSelectedImages(prevState => (prevState.map(k => ({ ...k, active: k?.imageFileId === id }))))
                                        }}
                                        openSide={(type) => {
                                            setIsSideModalOpen(true)
                                            props.isSubSideOpen(true)
                                            if (type === 'custom') {
                                                setActiveSideModalStep(sideModalSteps.addCustomImage)
                                            } else {
                                                setActiveSideModalStep(sideModalSteps.addAIImage)
                                            }
                                        }} />
                                </div>
                            ) : (
                                <div
                                    className={'ml-2 p-10 border-1 rounded-[5px] w-full h-[250px] flex items-center justify-center'}>
                                    <div className={'flex flex-col items-center'}>
                                        <SecondaryButton
                                            noFill
                                            className="h-[30px] border-1 border-border radius-[6px] px-2"
                                            icon={<FileImageOutlined />}
                                            title={'Add Image'}
                                            onClick={() => {
                                                //props.onGenerateWithAiClicked && props.onGenerateWithAiClicked()
                                                setIsSideModalOpen(true)
                                                props.isSubSideOpen(true)
                                                setActiveSideModalStep(sideModalSteps.addCustomImage)
                                            }}
                                        />
                                        <div className={'text-gray text-center text-sm'}>or</div>
                                        <SecondaryButton
                                            className="h-[30px] border-1 border-border radius-[6px] px-2"
                                            icon={<SvgGenerateSimilarIcon />}
                                            title={'Generate with AI'}
                                            onClick={() => {
                                                setIsSideModalOpen(true)
                                                props.isSubSideOpen(true)
                                                setActiveSideModalStep(sideModalSteps.addAIImage)
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </> :
                <div className={'min-h-[80vh]'}>
                    {activeSideModalStep === sideModalSteps.addAIImage && (
                        <GenerateImageWithAI
                            editCreativeStep
                            title={sideModals[activeSideModalStep].title}
                            description={sideModals[activeSideModalStep].description}
                            closeSideModal={() => {
                                setIsSideModalOpen(false)
                                props.isSubSideOpen(false)
                            }}
                            setGeneratedAIImages={k => {
                                console.log(k)
                                setGeneratedAIImages([...k, ...selectedProductImages])
                            }}
                        />
                    )}
                    {activeSideModalStep === sideModalSteps.addCustomImage && (
                        <AddCustomImageStep
                            title={sideModals[activeSideModalStep].title}
                            description={sideModals[activeSideModalStep].description}
                            closeSideModal={() => {
                                setIsSideModalOpen(false)
                                props.isSubSideOpen(false)
                            }}
                            selectedImages={selectedProductImages}
                            setSelectedProductImages={k => {
                                setSelectedProductImages([...k, ...selectedProductImages])
                            }}
                        />
                    )}
                </div>
            }
        </>
    )
}

export default EditCreativesStep;
