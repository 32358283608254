import { FunctionComponent, useEffect } from "react";
import ControlButtons from "./ControlButtons";
import Header from "./Header";
import CreativeContent from "./Content";
import { useAppSelector } from "../../../helpers/hooks";
import { AudienceResponse } from "../../../services/AudiencetApi";

interface CreativeDetailsProps {
    logoSelectionList: any[]
    productSelectionList: any[]
    onAudienceEdit: (data: AudienceResponse) => void
    onAudienceCreate: () => void;
    recentlyAddedAudience: AudienceResponse[]

}

const CreativeDetails: FunctionComponent<CreativeDetailsProps> = (props: CreativeDetailsProps) => {


    useEffect(() => {
        console.log(JSON.stringify(props.productSelectionList))
    }, [props.productSelectionList])


    return <div className="flex flex-col w-full h-full justify-between">
        <div className="flex flex-col w-full">
            <Header />
            <CreativeContent
                logoSelectionList={props.logoSelectionList}
                productSelectionList={props.productSelectionList}
                onAudienceEdit={props.onAudienceEdit}
                onAudienceCreate={props.onAudienceCreate}
                recentlyAddedAudience={props.recentlyAddedAudience} />
        </div>
        <ControlButtons />
    </div>
}

export default CreativeDetails;
