import * as React from "react";
import type { SVGProps } from "react";
const SvgLandscapeImageSizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={70}
    height={50}
    viewBox="0 0 71 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity="0.1"
      x="0.5"
      y="0.5"
      width="70"
      height="50"
      rx="8"
      fill="#999999"
    />
    <path
      opacity="0.35"
      d="M28.0833 16.8335H12.9167C11.1217 16.8335 9.66667 18.2886 9.66667 20.0835V30.9168C9.66667 32.7118 11.1217 34.1668 12.9167 34.1668H28.0833C29.8783 34.1668 31.3333 32.7118 31.3333 30.9168V20.0835C31.3333 18.2886 29.8783 16.8335 28.0833 16.8335Z"
      fill="#999999"
    />
    <path
      d="M25.9167 23.3333C27.1133 23.3333 28.0833 22.3633 28.0833 21.1667C28.0833 19.97 27.1133 19 25.9167 19C24.72 19 23.75 19.97 23.75 21.1667C23.75 22.3633 24.72 23.3333 25.9167 23.3333Z"
      fill="#999999"
    />
    <path
      d="M11.8333 29.8333C11.8333 30.1207 11.9475 30.3962 12.1506 30.5994C12.3538 30.8025 12.6293 30.9167 12.9167 30.9167H28.0833C28.3707 30.9167 28.6462 30.8025 28.8494 30.5994C29.0525 30.3962 29.1667 30.1207 29.1667 29.8333C29.1667 28.3557 27.338 26.5833 26.4583 26.5833C25.5787 26.5833 24.7813 27.6667 22.6667 27.6667C20.0212 27.6667 18.3333 22.25 16.1667 22.25C14 22.25 11.8333 26.9755 11.8333 29.8333Z"
      fill="#999999"
    />
    <path
      d="M59.875 21.875H38.125C37.228 21.875 36.5 21.1481 36.5 20.25C36.5 19.3519 37.228 18.625 38.125 18.625H59.875C60.772 18.625 61.5 19.3519 61.5 20.25C61.5 21.1481 60.772 21.875 59.875 21.875Z"
      fill="#999999"
    />
    <path
      d="M59.875 27.125H38.125C37.228 27.125 36.5 26.3981 36.5 25.5C36.5 24.6019 37.228 23.875 38.125 23.875H59.875C60.772 23.875 61.5 24.6019 61.5 25.5C61.5 26.3981 60.772 27.125 59.875 27.125Z"
      fill="#999999"
    />
    <path
      d="M44.625 32.375H38.125C37.228 32.375 36.5 31.6481 36.5 30.75C36.5 29.8519 37.228 29.125 38.125 29.125H44.625C45.522 29.125 46.25 29.8519 46.25 30.75C46.25 31.6481 45.522 32.375 44.625 32.375Z"
      fill="#999999"
    />
  </svg>
);
export default SvgLandscapeImageSizeIcon;
