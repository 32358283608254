import { t } from "i18next";
import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import { Blog, BlogColor, Facebook, FacebookColor, Instagram, InstagramColor, Linkedin, LinkedinColor, Pinterest, PinterestColor, Settings, TwitterColor } from "../../assets/IconComponents";
import Text from "../../assets/IconComponents/Text";
import Twitter from "../../assets/IconComponents/Twitter";
import ChannelSelectorPopup from "./ChannelSelectorPopup";
import Tag from "../Tag";

export interface IChannelTypeData {
    id: string,
    title: string,
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    colorIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element

}

export const ChannelTypeDatas: IChannelTypeData[] = [
    { id: "1", title: "Facebook", icon: Facebook, colorIcon: FacebookColor },
    { id: "2", title: "Twitter", icon: Twitter, colorIcon: TwitterColor },
    { id: "3", title: "Instagram", icon: Instagram, colorIcon: InstagramColor },
    { id: "4", title: "LinkedIn", icon: Linkedin, colorIcon: LinkedinColor },
    { id: "5", title: "Pinterest", icon: Pinterest, colorIcon: PinterestColor },
    { id: "6", title: "Blog", icon: Blog, colorIcon: BlogColor },
    { id: "7", title: "Banner", icon: Text, colorIcon: Text },
]

export interface IChannelSelectorProps {
    channelsSelected: (data: IChannelTypeData[] | undefined) => void
    customChannels?: IChannelTypeData[]
    selectedChannelName?: string
    deselectable?: boolean
    defaultValues?: string[]
    closeOnSelect?: boolean
    className?: string
}

function ChannelSelector(props: IChannelSelectorProps) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedChannels, setSelectedChannels] = useState<IChannelTypeData[] | undefined>()
    const [channels, setChannels] = useState<IChannelTypeData[]>(ChannelTypeDatas)

    useEffect(() => {
        setChannels(props.customChannels ?? ChannelTypeDatas)
        if (props.selectedChannelName) {
            const selectedChannel = props.customChannels?.find(k => k.title.toLowerCase() === props.selectedChannelName)
            if (selectedChannel) {
                setSelectedChannels([selectedChannel])
            }
        }
    }, [props.customChannels, props.selectedChannelName])

    useEffect(() => {
        if (props.defaultValues) {
            const filteredSelectedChannels = channels.filter(k => props.defaultValues?.map(a => a.toLowerCase()).findIndex(a => a === k.title.toLowerCase()) !== -1)
            if (filteredSelectedChannels && selectedChannels === undefined) {
                setSelectedChannels(filteredSelectedChannels)
            }
        }
    }, [props.defaultValues, selectedChannels, channels])

    return (
        <>
            <Popover
                isOpen={isPopoverOpen}
                positions={['bottom']}
                align="start"
                padding={10}
                onClickOutside={() => { setIsPopoverOpen(false) }}
                content={
                    <ChannelSelectorPopup
                        deselectable={props.deselectable}
                        data={channels}
                        closePopup={() => setIsPopoverOpen(false)}
                        selectedChannels={selectedChannels}
                        selectedChannelsChanged={(e) => {
                            if (e) {
                                setSelectedChannels(e)
                                props.channelsSelected(e)
                                if (props.closeOnSelect) {
                                    setIsPopoverOpen(false)
                                }
                            }
                        }}
                    />}
            >
                <div className={`bg-white border rounded-[7px] px-2 py-2.5 justify-start items-center gap-[5px] flex flex-row ${props.className}`} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                    <button
                        onClick={() => {
                        }}>
                        {/* <SvgContentType width={20} height={20} fill={selectedContentType !== undefined ? "#121315" : "#999999"} /> */}
                        <div className="flex flex-row gap-[5px]">
                            <Settings width={20} height={20} fill={selectedChannels !== undefined ? "#121315" : "#999999"} ></Settings>
                            <text className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">{t('channel')}</text>
                            {(selectedChannels !== undefined && selectedChannels.length > 0) &&
                                <div className="w-[20px] h-[20px] rounded-full bg-red inline-flex justify-center items-center text-H7">{selectedChannels.length}</div>}
                        </div>
                    </button>
                    {/* <div className="flex flex-row gap-[7px] h-[30px] justify-start items-center ">

                        {(selectedChannels !== undefined && selectedChannels.length > 0) &&
                            selectedChannels.map((channel) => {
                                return <Tag tagText={channel.title} onClose={() => {
                                    const reducedChannels = selectedChannels.filter(c => c.id !== channel.id)
                                    setSelectedChannels([...reducedChannels])
                                    props.channelsSelected([...reducedChannels])
                                }} />
                            })
                        }
                    </div> */}
                </div >
            </Popover >
        </>
    );
}

export default ChannelSelector;
