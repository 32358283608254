import * as React from "react";
import type { SVGProps } from "react";
const SvgRefreshIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#F55"
      d="M9 2.375c-2.89 0-5.25 2.36-5.25 5.25v3.44l-.595-.595a.75.75 0 1 0-1.06 1.06l1.808 1.809a.75.75 0 0 0 1.197-.003l1.805-1.806a.75.75 0 1 0-1.06-1.06l-.595.595v-3.44c0-2.08 1.67-3.75 3.75-3.75.82 0 1.567.262 2.186.708a.749.749 0 1 0 .878-1.216A5.233 5.233 0 0 0 9 2.375Zm4.489 2.99a.75.75 0 0 0-.587.297L11.095 7.47a.75.75 0 1 0 1.06 1.06l.595-.594v3.439c0 2.08-1.67 3.75-3.75 3.75-.82 0-1.568-.262-2.186-.708a.75.75 0 1 0-.878 1.216c.86.62 1.921.992 3.064.992 2.89 0 5.25-2.36 5.25-5.25v-3.44l.595.595a.75.75 0 1 0 1.06-1.06l-1.81-1.81a.75.75 0 0 0-.606-.295Z"
    />
  </svg>
);
export default SvgRefreshIcon;
