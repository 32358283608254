import { Close, Search } from "../assets/IconComponents";
import Icon from "./Icon";
import { useEffect, useState } from "react";

export interface ISearchInputProps {
    placeholder?: string
    onChange: (value: string) => void
    className?: string
    text?: string
    onEnter?: (value: string) => void
}

function SearchInput(props: ISearchInputProps) {
    const { placeholder, onChange, className, text } = props
    const [isActive, setActive] = useState(false)
    const [enteredText, setEnteredText] = useState("")

    useEffect(() => {
        setEnteredText(text ?? "")
    }, [text])

    return (
        <div className={`border-border border-1 bg-white h-12 rounded-[7px] overflow-hidden ${className}`}>
            <div className="w-full h-full flex justify-center items-center">
                <div className="flex flex-row w-full justify-center items-center ml-3 h-full">
                    <Search width="20" height="20" fill={isActive ? "#000000" : "#999999"} />
                    <input
                        className={`flex flex-1 ml-2 h-full outline-none text-BodyMedium ${isActive ? "text-black" : "text-gray"}`}
                        placeholder={placeholder}
                        onFocus={() => { setActive(true) }}
                        onBlur={() => { setActive(false) }}
                        onChange={(e) => {
                            e.stopPropagation();
                            setEnteredText(e.target.value)
                            onChange(e.target.value)
                        }}
                        value={enteredText}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                if (props.onEnter)
                                    props.onEnter(enteredText)
                            }
                        }}
                    />
                    <button
                        onClick={() => {
                            setEnteredText("")
                            onChange("")
                        }}
                        className={`${enteredText != "" ? "mr-3 flex items-center justify-center rounded-full w-[30px] h-[30px] border-border border-1 p-[4px] hover:bg-bg" : "hidden"}`}>
                        <Close fill="#999999" width="20" height="20" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchInput;
