import * as React from "react";
const SvgSidebarNotificationsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#FFFFFF"}
      d="M4.167 16.614a.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.593c0-.237.08-.434.24-.594.16-.16.357-.24.594-.24H5V9.114A4.88 4.88 0 0 1 6.042 6.04a4.806 4.806 0 0 1 2.708-1.76v-.584c0-.347.121-.642.364-.885.244-.243.539-.365.886-.365s.642.122.885.365.365.538.365.885v.584a4.807 4.807 0 0 1 2.708 1.76A4.88 4.88 0 0 1 15 9.114v5.833h.833c.236 0 .434.08.594.24.16.16.24.357.24.594 0 .236-.08.434-.24.593a.806.806 0 0 1-.594.24H4.167Zm5.833 2.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177h3.334c0 .459-.164.851-.49 1.177-.326.327-.719.49-1.177.49Zm-3.333-4.167h6.666V9.114a3.21 3.21 0 0 0-.979-2.354A3.21 3.21 0 0 0 10 5.78a3.21 3.21 0 0 0-2.354.98 3.21 3.21 0 0 0-.98 2.354v5.833Z"
    />
  </svg>
);
export default SvgSidebarNotificationsIcon;
