import * as React from "react";
const SoloCampaign = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.5"
              d="M8.42029 12.8333C8.42029 11.5447 9.46496 10.5 10.7536 10.5H16.587C17.8756 10.5 18.9203 11.5447 18.9203 12.8333V15.1667C18.9203 16.4553 17.8756 17.5 16.587 17.5H10.7536C9.46496 17.5 8.42029 16.4553 8.42029 15.1667V12.8333Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.25364 12.833C6.60931 12.833 6.08698 13.3553 6.08698 13.9997C6.08698 14.644 6.60931 15.1663 7.25364 15.1663H8.42031V12.833H7.25364ZM18.9203 12.833V15.1663H20.087C20.7313 15.1663 21.2536 14.644 21.2536 13.9997C21.2536 13.3553 20.7313 12.833 20.087 12.833H18.9203Z"
              fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default SoloCampaign;
