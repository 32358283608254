import * as React from "react";
import type { SVGProps } from "react";
const SvgErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#ErrorIcon_svg__a)">
      <path
        fill="#F55"
        d="M9 .9C4.533.9.9 4.534.9 9c0 4.466 3.633 8.1 8.1 8.1 4.466 0 8.1-3.634 8.1-8.1C17.1 4.534 13.466.9 9 .9Zm0 4.263a.64.64 0 0 1 .64.64V9.64a.64.64 0 1 1-1.28 0V5.803a.64.64 0 0 1 .64-.64Zm0 5.969a.852.852 0 1 1 0 1.704.852.852 0 0 1 0-1.704Z"
      />
    </g>
    <defs>
      <clipPath id="ErrorIcon_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgErrorIcon;
