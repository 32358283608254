import { useEffect, useState } from "react";
import { Radio } from "antd";
import UploadTextToSpeechFile from "../../TextToSpeech/UploadTextToSpeechTextFile";
import { FileProps } from "../../../services/BrandApi";
import { useRemoveBackgroundPaidMutation } from "../../../services/ImageopsApi";
import { useLazyDownloadOriginalQuery, useLazyDownloadQuery } from "../../../services/FileApi";
import { toggleLoading } from "../../../store/appSlice";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../components/PrimaryButton";
import SvgTextToSpeechDownload from "../../../assets/IconComponents/TextToSpeechDownloadIcon";
import SvgBackgroundRemoverUpload from "../../../assets/IconComponents/SvgBackgroundRemoverUpload";
import { setActivePage } from "../../../store/appSlice";

function BackgroundRemoverPage() {
  const [currentTab, setCurrentTab] = useState<string>("Removed");
  const [
    removeBackground,
    { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress },
  ] = useRemoveBackgroundPaidMutation();
  const [originalFile, setOriginalFile] = useState<FileProps | undefined>(
    undefined
  );
  const [removedFileSrc, setRemovedFileSrc] = useState<string | undefined>(
    undefined
  );
  const [downloadOriginalImage, { data: originalImageFileContent, isSuccess: originalFileDownloaded }] = useLazyDownloadOriginalQuery();



  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage("background-remover"));

  }, [])

  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();



  useEffect(() => {
    dispatch(toggleLoading(backgroundRemovalInProgress || fileDownloading));
  }, [backgroundRemovalInProgress, fileDownloading]);

  /*
  useEffect(() => {
    if(backgroundRemovalError){
      alert("An error was encountered during removing background process")
      setOriginalFile(undefined);
      setRemovedFileSrc(undefined);
    }
  }, [backgroundRemovalError]);
  */



  const downloadFileToDesktop = (image) => {
    if (originalFile) {
      var link = document.createElement("a");
      var newFileName = "removed.jpeg";
      if (originalFile.name) {
        // Extract the original file name without the extension
        let originalFileName = originalFile.name
          .split(".")
          .slice(0, -1)
          .join(".");

        // Get the file extension
        let fileExtension = originalFile.name.split(".").pop();
        // Set the new file name
        newFileName = `${originalFileName}_removed.${fileExtension}`;
      }

      link.download = newFileName;
      link.href = "data:image/png;base64," + image;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    if (
      backgroundRemovedImage?.backgroundRemovedFile?.id
    ) {
      const backgroundRemovedFile = backgroundRemovedImage?.backgroundRemovedFile;
      try {
        downloadFile({
          id: backgroundRemovedFile.id ?? "",
        })
          .unwrap()
          .then(async (k) => {
            const url = URL.createObjectURL(k.file);
            setRemovedFileSrc(url);
          });
      } catch (error) {
        console.error("Error fetching audio:", error);
      }
    }
  }, [backgroundRemovedImage]);
  return (
    <>
      <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border items-center justify-between">
        <span className="text-H6 text-black px-5 ">Background Remover</span>
      </div>
      <div className="w-full flex h-[calc(100vh-60px)] p-2 bg-white ">
        <div className="w-full gap-[20px] px-0 md:px-[30px] mt-2 md:mt-5 flex flex-col">
          <div className="flex flex-col gap-[10px]">
            <div className="w-full pl-60 pr-60">
              <div className="w-full h-[40px]">
                {originalFile && (
                  <Radio.Group
                    onChange={(e) => setCurrentTab(e.target.value)}
                    defaultValue="Removed"
                  >
                    <Radio.Button value="Original">Original</Radio.Button>
                    <Radio.Button value="Removed">Removed</Radio.Button>
                  </Radio.Group>
                )}
              </div>

              {!originalFile && (
                <UploadTextToSpeechFile
                  maxFileSize={10}
                  accept=".png, .jpeg, .jpg"
                  description=".png, .jpg or .jpeg files. MAX 10MB"
                  fileChanged={(imageFile) => {
                    console.log("Files changed: ", imageFile);
                    setOriginalFile(imageFile);
                    removeBackground({
                      removeBackgroundRequest: { imageFileId: imageFile?.id ?? "" },
                    });
                  }}
                />
              )}
              {originalFile && (
                <>
                  {currentTab === "Original" && (
                    <div className="flex grow w-full h-[400px] relative">
                      <img
                        src={originalFile.preview ?? ""}
                        alt={""}
                        className="w-full h-full left-0 top-0 absolute rounded-md border border-stone-200 object-contain"
                      />
                    </div>
                  )}
                  {currentTab === "Removed" && (
                    <>
                      <div className="flex grow w-full h-[400px] relative">
                        <img
                          src={removedFileSrc}
                          alt={""}
                          className="w-full h-full left-0 top-0 absolute rounded-md border border-stone-200 object-contain"
                        />
                      </div>

                      <div className="flex items-center justify-center gap-2">
                        <PrimaryButton
                          innerClassName="gap-2"
                          noFill
                          onClick={() => {
                            setOriginalFile(undefined);
                            setRemovedFileSrc(undefined);
                          }}
                          icon={<SvgBackgroundRemoverUpload />}
                          title={"Upload New"}
                          className="h-[37px] w-[128px] mt-2 mb-2"
                        />
                        <PrimaryButton
                          innerClassName="gap-2"
                          onClick={() => {
                            downloadOriginalImage({
                              id: backgroundRemovedImage?.backgroundRemovedFile?.id ?? ""
                            }).unwrap().then(async (k) => {
                              downloadFileToDesktop(k);
                            });
                          }
                          }
                          icon={<SvgTextToSpeechDownload />}
                          title={"Download"}
                          className="h-[37px] w-[128px] mt-2 mb-2"
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BackgroundRemoverPage;
