import * as React from "react";
const SalesConversionIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.5"
              d="M20 8H4C3.20459 8.00079 2.44199 8.31712 1.87956 8.87956C1.31712 9.44199 1.00079 10.2046 1 11V19C1.00079 19.7954 1.31712 20.558 1.87956 21.1204C2.44199 21.6829 3.20459 21.9992 4 22H20C20.7954 21.9992 21.558 21.6829 22.1204 21.1204C22.6829 20.558 22.9992 19.7954 23 19V11C22.9992 10.2046 22.6829 9.44199 22.1204 8.87956C21.558 8.31712 20.7954 8.00079 20 8ZM12 18C11.2044 18 10.4413 17.6839 9.87868 17.1213C9.31607 16.5587 9 15.7956 9 15C9 14.2044 9.31607 13.4413 9.87868 12.8787C10.4413 12.3161 11.2044 12 12 12C12.7956 12 13.5587 12.3161 14.1213 12.8787C14.6839 13.4413 15 14.2044 15 15C15 15.7956 14.6839 16.5587 14.1213 17.1213C13.5587 17.6839 12.7956 18 12 18Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12 10C11.7348 10 11.4804 9.89464 11.2929 9.70711C11.1054 9.51957 11 9.26522 11 9V3C11 2.73478 11.1054 2.48043 11.2929 2.29289C11.4804 2.10536 11.7348 2 12 2C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3V9C13 9.26522 12.8946 9.51957 12.7071 9.70711C12.5196 9.89464 12.2652 10 12 10Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M14.2499 6.24979C14.1186 6.24992 13.9885 6.22411 13.8672 6.17383C13.7459 6.12354 13.6357 6.04978 13.5429 5.95679L11.9999 4.41379L10.4569 5.95679C10.2683 6.13894 10.0157 6.23974 9.75352 6.23746C9.49132 6.23518 9.24051 6.13001 9.0551 5.9446C8.86969 5.7592 8.76452 5.50838 8.76224 5.24619C8.75997 4.98399 8.86076 4.73139 9.04292 4.54279L11.2929 2.29279C11.4804 2.10532 11.7348 2 11.9999 2C12.2651 2 12.5194 2.10532 12.7069 2.29279L14.9569 4.54279C15.0967 4.68264 15.1919 4.8608 15.2305 5.05475C15.2691 5.24871 15.2493 5.44974 15.1736 5.63244C15.0979 5.81514 14.9698 5.9713 14.8054 6.08119C14.641 6.19107 14.4477 6.24974 14.2499 6.24979Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.2"
              d="M12 18C11.2044 18 10.4413 17.6839 9.87868 17.1213C9.31607 16.5587 9 15.7956 9 15C9 14.2044 9.31607 13.4413 9.87868 12.8787C10.4413 12.3161 11.2044 12 12 12C12.7956 12 13.5587 12.3161 14.1213 12.8787C14.6839 13.4413 15 14.2044 15 15C15 15.7956 14.6839 16.5587 14.1213 17.1213C13.5587 17.6839 12.7956 18 12 18Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M6 16C6.55228 16 7 15.5523 7 15C7 14.4477 6.55228 14 6 14C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M18 16C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14C17.4477 14 17 14.4477 17 15C17 15.5523 17.4477 16 18 16Z"
            fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default SalesConversionIcon;
