import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useLazyGetLatestActivitiesQuery } from "../../services/Activity";
import { blogSVG, fbSVG, googleSVG, instagramSVG, linkedInSVG, metaSVG, newsletterSVG, xSVG } from "../../assets/IconComponents/svgs";
import ActivityCard from "./components/ActivityCard";


const Activities = () => {
    const navigate = useNavigate();
    const [getLatestActivities, { data: latestActivities, isLoading: latestActivityIsLoading }] = useLazyGetLatestActivitiesQuery()

    function getSocialIcon(event) {
        const channels = event.channels
        if (channels != undefined && channels.length > 0) {
            var channel = channels[0];

            switch (channel) {
                case "FACEBOOK":
                    return fbSVG

                case "X":
                    return xSVG

                case "INSTAGRAM":
                    return instagramSVG

                case "LINKEDIN":
                    return linkedInSVG

                case "BLOG":
                    return blogSVG

                case "NEWSLETTER":
                    return newsletterSVG

                case "GOOGLE_ADS":
                    return googleSVG

                case "META_ADS":
                    return metaSVG

                default:
                    return ""
            }
        }

        return ""
    }

    var getColorByStatus = (status: string) => {
        if (status == "Awaiting Confirmation") {
            return {
                background: "#e3c7ff",
                border: "#6001AF",
                textColor: "#121315"
            }
        }
        else if (status == "Awaiting Approval") {
            return {
                background: "#F59D1A",
                border: "#F59D1A80",
                textColor: "#121315"
            }
        }
        else if (status == "Content Generation") {
            return {
                background: "#e3c7ff",
                border: "#6001AF",
                textColor: "#121315"
            }
        }
        else if (status == "Scheduled") {
            return {
                background: "#1A8CF5",
                border: "#1A8CF580",
                textColor: "#121315"
            }
        }
        else if (status == "Draft") {
            return {
                background: "#CECECE",
                border: "#8E8E8E80",
                textColor: "#121315"
            }
        }
        else if (status == "Posted") {
            return {
                background: "#16C26C",
                border: "#16C26C80",
                textColor: "#121315"
            }
        }
    }

    useEffect(() => {
        getLatestActivities({ count: 8 })
    }, [latestActivities])


    return (
        <>
            {latestActivities && latestActivities?.length > 0 && (
                <div className="w-full flex flex-col gap-5">
                    <div className="mt-[50px] mb-[20px]">
                        <div className="flex justify-between items-center w-full">
                            <span className="text-H4 font-medium text-black   truncate text-ellipsis overflow-hidden">
                                Activities
                            </span>
                            <button
                                className="text-red text-BodyMedium underline font-inter-tight"
                                onClick={() => {
                                    navigate("/app/content-calendar")
                                }}
                            >
                                {t("see-all")}
                            </button>
                        </div>

                        <hr className="bg-border mt-1" />
                    </div>
                    <div className="flex flex-row flex-wrap my-2">
                        {
                            latestActivities && latestActivities.map(event => (
                                <ActivityCard
                                    key={event.id}
                                    event={event}
                                    getSocialIcon={getSocialIcon}
                                    getColorByStatus={getColorByStatus}
                                />
                            ))
                        }
                    </div>
                </div>
            )}
        </>
    );
};

export default Activities;
