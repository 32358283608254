import * as React from "react";
import type { SVGProps } from "react";
const SvgThumbsUpTritoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="56" height="56" rx="4" fill="#FF5555" />
    <path
      opacity="0.5"
      d="M30.1 24.85V20.65C30.1 20.2363 30.0185 19.8267 29.8602 19.4445C29.7019 19.0624 29.4699 18.7151 29.1774 18.4226C28.8849 18.1301 28.5376 17.8981 28.1555 17.7398C27.7733 17.5815 27.3637 17.5 26.95 17.5L22.75 26.95V38.5H34.951C35.4574 38.5057 35.9489 38.3282 36.3348 38.0002C36.7207 37.6722 36.9751 37.2158 37.051 36.715L38.5 27.265C38.5457 26.964 38.5254 26.6567 38.4405 26.3644C38.3556 26.072 38.2082 25.8016 38.0085 25.5719C37.8087 25.3422 37.5614 25.1586 37.2837 25.034C37.0059 24.9093 36.7044 24.8466 36.4 24.85H30.1Z"
      fill="#FF5555"
    />
    <path
      d="M22.75 26.8333H19.9465C18.7163 26.8117 17.6651 27.8308 17.5 29.05V36.4C17.5797 36.989 17.8728 37.5282 18.3238 37.9153C18.7748 38.3024 19.3522 38.5105 19.9465 38.5H22.75V26.8333Z"
      fill="#FF5555"
    />
  </svg>
);
export default SvgThumbsUpTritoneIcon;
  