import { FunctionComponent, useEffect } from "react";
import { Back } from "../../../assets/IconComponents";
import IconButton from "../../../components/IconButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { setNewContent } from "../../../store/contentSlice";

interface HeaderProps {
  mode: "logo" | "product" | "referred_image" | "product_photoshoot";
}

const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);

     useEffect(() => {
       console.log("Mode is: ", props.mode)
     }, [props.mode]);
    return <><div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1">
        <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
            <div className="w-5 h-5 relative">
                <IconButton
                    title={""}
                    icon={Back}
                    onClick={function (e: any): void {
                        if (props.mode === "product_photoshoot") {
                          dispatch(
                            setNewContent({
                              ...newContent,
                              wizardView: ContentWizardView.aiProductPhotoshoot,
                            })
                          );
                        } else {
                          dispatch(
                            setNewContent({
                              ...newContent,
                              wizardView: ContentWizardView.contentDetails,
                            })
                          );
                        }
                          
                    }} />
            </div>
            {props.mode !== "referred_image" &&
                <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">{`View ${props.mode === "logo" ? "Logo" : "Product"} Library`}</div>
            }
            {props.mode === "referred_image" &&
                <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">{`View Gallery`}</div>
            }
        </div>
    </div></>
}

export default Header;