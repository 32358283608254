export const periods = [
    {
        label: "1 day",
        value: 24*60*60*1000,
    },
    {
        label: "2 days",
        value: 2*24*60*60*1000,
    },
    {
        label: "3 days",
        value: 3*24*60*60*1000,
    },
    {
        label: "4 days",
        value: 4*24*60*60*1000,
    },
    {
        label: "5 days",
        value: 5*24*60*60*1000,
    },
    {
        label: "6 days",
        value: 6*24*60*60*1000,
    },
    {
        label: "7 days",
        value: 7*24*60*60*1000,
    }
    /*,
    {
        label: "8 days",
        value: 8*24*60*60*1000,
    },
    {
        label: "9 days",
        value: 9*24*60*60*1000,
    },
    {
        label: "10 days",
        value: 10*24*60*60*1000,
    },
    {
        label: "11 days",
        value: 11*24*60*60*1000,
    },
    {
        label: "12 days",
        value: 12*24*60*60*1000,
    },
    {
        label: "13 days",
        value: 13*24*60*60*1000,
    },
    {
        label: "2 weeks",
        value: 14*24*60*60*1000,
    },*/
];
