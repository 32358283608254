import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../components/PrimaryButton";
import { toggleLoading } from "../../store/appSlice";
import { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../services/ContentApi.old/ContentApi";
import { Password } from "../../assets/IconComponents";
import { Alert, Input } from "antd";


function SetNewPassworPage() {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isChanged, setChanged] = useState(false)
    const [setResetPassword, { isSuccess: isResetPassword, isError: actionError, error }] = useResetPasswordMutation()
    const [warningMessage, setWarningMessage] = useState("")



    const isFormValid = () => {
        if (confirmPassword?.trim() === "" ||
            password?.trim() === "") {
            setWarningMessage(t('please-enter-all-informations') ?? "")
            return false
        }

        if (confirmPassword?.trim() !== password?.trim()) {
            setWarningMessage(t('please-control-passwords-entered') ?? "")
            return false
        }



        return true
    }

    const changePassword = () => {
        if (!isFormValid()) { return }
        dispatch(toggleLoading(true))

        setResetPassword({
            password: password,
            token: token ?? ""

        })
    }

    useEffect(() => {
        dispatch(toggleLoading(false))
        if (error)
            setWarningMessage((error as any)?.data?.message)
    }, [actionError])

    useEffect(() => {
        if (isResetPassword) {
            dispatch(toggleLoading(false))
            setChanged(true)

            setTimeout(() => {
                redirectToLogin()
            }, 2000);
        }
        dispatch(toggleLoading(false))

    }, [isResetPassword])


    const redirectToLogin = () => {
        navigate("/login")
    }

    return (<>
        <div className="flex flex-col w-full items-center justify-center">
            <img src={require("../../assets/Logo_Dark.png")} className="mb-10" />
            <div
                className={`w-[calc(100vw-40px)] md:w-1/3 rounded-xl bg-white p-6 flex items-center justify-center flex-col border`}>
                <div className="flex justify-between items-start w-full">
                    <div className="flex flex-col">
                        <text
                            className="text-H3 font-medium text-black"
                        >
                            {t('set-new-password')}
                        </text>
                        {isChanged &&
                            <text
                                className="text-BodyMedium text-gray"
                            >
                                {t('were-redirecting-you-to-login')}
                            </text>
                        }
                    </div>
                    <button className="text-red text-BodyMedium underline"
                        onClick={() => { redirectToLogin() }}>{t('back-to-login')}</button>
                </div>

                {(warningMessage != "") && !isResetPassword &&
                    <Alert message={warningMessage ? warningMessage : t("warning")} type="error" showIcon className="w-full mt-5" />
                }

                {!isChanged &&
                    <div className="flex flex-col items-stretch w-full mt-5">
                        <Input size="large" placeholder={t('new-password') ?? ""} prefix={<Password width={16} height={16} fill="" />}
                            className="mb-2 bg-inputBg"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }} />

                        <Input size="large" placeholder={t('confirm-new-password') ?? ""} prefix={<Password width={16} height={16} fill="" />}
                            className="mb-2 bg-inputBg"
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }} />

                        <PrimaryButton
                            title={t('change-password')}
                            onClick={() => { changePassword() }}
                            className="mt-5" />
                    </div>
                }
            </div>
        </div>
    </>);
}

export default SetNewPassworPage;
