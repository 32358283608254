import type { SVGProps } from "react";
const SvgQuestionMark = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 20}
        height={props.height ?? 20}
        fill={props.fill ?? "none"}
        {...props}
    >
        <path d="M8.0013 15.3333L3.2013 11.7333C3.03464 11.611 2.90408 11.4555 2.80964 11.2666C2.71519 11.0777 2.66797 10.8777 2.66797 10.6666V2.66659C2.66797 2.29992 2.79852 1.98603 3.05964 1.72492C3.32075 1.46381 3.63464 1.33325 4.0013 1.33325H12.0013C12.368 1.33325 12.6819 1.46381 12.943 1.72492C13.2041 1.98603 13.3346 2.29992 13.3346 2.66659V10.6666C13.3346 10.8777 13.2874 11.0777 13.193 11.2666C13.0985 11.4555 12.968 11.611 12.8013 11.7333L8.0013 15.3333ZM8.0013 13.6666L12.0013 10.6666V2.66659H4.0013V10.6666L8.0013 13.6666ZM8.0013 2.66659H4.0013H12.0013H8.0013Z"
              fill="#FF5555"/>
        <path
            d="M6.77817 5.56834L7.56413 4.5508C7.62027 4.47594 7.68694 4.42097 7.76413 4.38588C7.84132 4.3508 7.92203 4.33325 8.00624 4.33325C8.09045 4.33325 8.17115 4.3508 8.24834 4.38588C8.32553 4.42097 8.3922 4.47594 8.44834 4.5508L9.23431 5.56834L10.4273 5.96834C10.5489 6.00577 10.6448 6.07477 10.715 6.17536C10.7852 6.27594 10.8203 6.38705 10.8203 6.50869C10.8203 6.56483 10.8121 6.62097 10.7957 6.67711C10.7793 6.73325 10.7524 6.78705 10.715 6.83852L9.94308 7.93325L9.97115 9.08413C9.97583 9.24787 9.92203 9.38588 9.80974 9.49816C9.69746 9.61045 9.56647 9.66659 9.41676 9.66659C9.40741 9.66659 9.35594 9.65957 9.26238 9.64553L8.00624 9.29466L6.7501 9.64553C6.7267 9.65489 6.70097 9.66074 6.6729 9.66308C6.64483 9.66542 6.6191 9.66659 6.59571 9.66659C6.446 9.66659 6.31501 9.61045 6.20273 9.49816C6.09045 9.38588 6.03665 9.24787 6.04132 9.08413L6.06939 7.92623L5.30448 6.83852C5.26705 6.78705 5.24015 6.73325 5.22378 6.67711C5.20741 6.62097 5.19922 6.56483 5.19922 6.50869C5.19922 6.39173 5.23314 6.28296 5.30097 6.18237C5.36881 6.08179 5.46355 6.01044 5.58518 5.96834L6.77817 5.56834Z"
            fill="#FF5555"/>
    </svg>
);
export default SvgQuestionMark;
