import * as React from "react";
import type { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}
  >
    <path
      d="M16.308 10.95a1.165 1.165 0 0 1-.475-.936c0-.369.178-.719.475-.936l1.372-1.006a.626.626 0 0 0 .224-.702 8.28 8.28 0 0 0-1.662-2.879.626.626 0 0 0-.72-.158l-1.545.68a1.165 1.165 0 0 1-1.622-.937l-.186-1.696a.626.626 0 0 0-.496-.545 8.395 8.395 0 0 0-3.32-.004.624.624 0 0 0-.499.545L7.67 4.062a1.164 1.164 0 0 1-1.622.936l-1.554-.683a.625.625 0 0 0-.719.157 8.277 8.277 0 0 0-1.67 2.873.625.625 0 0 0 .223.704l1.364 1c.297.218.475.568.475.937 0 .369-.178.719-.474.936L2.32 11.928a.626.626 0 0 0-.224.702 8.28 8.28 0 0 0 1.662 2.879c.18.204.472.268.72.158l1.545-.68a1.165 1.165 0 0 1 1.048.058c.32.184.534.512.574.879l.186 1.696c.03.27.23.49.496.545a8.384 8.384 0 0 0 3.32.004.624.624 0 0 0 .498-.545l.185-1.686A1.164 1.164 0 0 1 13.953 15l1.553.684c.248.11.539.046.719-.158a8.275 8.275 0 0 0 1.67-2.873.625.625 0 0 0-.223-.703l-1.364-1ZM10 12.918a2.917 2.917 0 1 1 0-5.834 2.917 2.917 0 0 1 0 5.834Z"
    />
  </svg>
);
export default SvgSettings;
