import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useAppDispatch } from "../../helpers/hooks";
import {
  setCollapsedModeEnabled,
  setDialog,
  setMenuCollapsed,
  toggleDialog,
  toggleLoading,
} from "../../store/appSlice";
import RevisionEditorHeader from "./RevisionEditorHeader/RevisionEditorHeader";
import { Content, useLazyFindByContentId1Query } from "../../services/ContentApi";
import { Revision, useDeleteRevisionByIdMutation, useLazyFindRevisionByIdQuery } from "../../services/RevisionApi";
import RevisionEditorUpdateImage from "./RevisionEditorUpdateImage/RevisionEditorUpdateImage";
import { useLazyDownloadQuery } from "../../services/ImageApi.old/ImageApi";
import { ContentSize, SizeConstants } from "../../constants/ContentSize";
import PrimaryButton from "../../components/PrimaryButton";
import { useLazyFindById4Query } from "../../services/BrandApi";
import { useNavigate } from "react-router-dom";
import { useLazyDownloadOriginalQuery } from "../../services/FileApi";




const RevisionEditor: FunctionComponent = () => {
  let params = useParams();
  let dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [contentSize, setContentSize] = useState<ContentSize>(ContentSize.post);
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [downloadImage, { data: imageFileContent, isLoading: imageLoading }] =
    useLazyDownloadQuery();
  const [revision, setRevision] = useState<Revision | null>(null)
  const [getBrand, { data: brand, isLoading: brandLoading }] = useLazyFindById4Query()
  const [getRevisionDetails, { data: revisionDetail, isLoading: revisionLoading },] = useLazyFindRevisionByIdQuery();
  const [getContent, { data: content, isLoading: contentLoading }] = useLazyFindByContentId1Query()
  const [
    deleteRevisionItem, { isSuccess: deleteSuccess }
  ] = useDeleteRevisionByIdMutation();
  const [imageClass, setImageClass] = useState<string>(`relative overflow-hidden m-[15px] p-[18px] flex flex-col`)

  const [downloadOriginalImage, { data: originalImageFileContent, isSuccess: originalFileDownloaded }] = useLazyDownloadOriginalQuery();



  useEffect(() => {
    if (originalFileDownloaded) {
      downloadImageToLocal();
    }
  }, [originalFileDownloaded])

  useEffect(() => {
    setLoadingData(brandLoading || contentLoading || revisionLoading || imageLoading)
  }, [brandLoading, contentLoading, revisionLoading, imageLoading])

  useEffect(() => { dispatch(toggleLoading(loadingData)) }, [loadingData])

  useEffect(() => { if (deleteSuccess) { navigate(-1) } }, [deleteSuccess])

  useEffect(() => {
    dispatch(setCollapsedModeEnabled(true));
    dispatch(setMenuCollapsed(false));
    return () => {
      dispatch(setMenuCollapsed(true));
      dispatch(setCollapsedModeEnabled(false));
    };
  }, []);

  useEffect(() => {
    if (content?.brand_id) {
      getBrand({ id: content?.brand_id })
    }
  }, [content])

  useEffect(() => {
    if (params?.revisionId) getRevisionObj();
  }, [params.revisionId]);

  useEffect(() => {
    if ((revision?.generated_content ?? "") === "") {
      // console.log("No image to download")
      return
    }
    else {
      // console.log(`downloading regenerated content ${revision?.generated_content}`)
      downloadImage({ id: revision?.generated_content ?? "" })
    }
  }, [revision])

  useEffect(() => {
    setRevision(revisionDetail ?? null)
  }, [revisionDetail]);

  useEffect(() => {
    if (revisionDetail?.id?.content_id) getContentObj();
  }, [revisionDetail]);

  useEffect(() => {
    let contentSize = ContentSize.story
    if (content) {
      contentSize = getContentSizeFromContent(content);
      var imageClass = `relative overflow-hidden m-[15px] p-[18px] flex flex-col`;
      if ((content?.image_height ?? 1080) === 250) {
        imageClass = `relative overflow-hidden m-[180px] p-[0px] flex flex-col`;
      }
      if ((content?.image_height ?? 1080) === 50) {
        imageClass = `relative overflow-hidden m-[300px] p-[0px] flex flex-col`;
      }
      setImageClass(imageClass);
    }
    setContentSize(contentSize);
  }, [content]);

  const getContentObj = () => {
    getContent({
      contentId: revisionDetail?.id?.content_id ?? "",
    });
  };

  const getContentSizeFromContent = (content: Content) => {
    let contentSize = ContentSize.post;
    const width = content.image_width ?? 1080;
    const height = content.image_height ?? 1080;
    if (width === 1200 && height == 628) {
      contentSize = ContentSize.landscape;
    } else if (width == 1080 && height == 1920) {
      contentSize = ContentSize.story;
    }
    return contentSize;
  };

  const getRevisionObj = () => {
    getRevisionDetails({
      revisionId: params.revisionId ?? "",
    });
  };
  const sizeValues = SizeConstants[contentSize];

  const showDeleteItemDialog = (revision: Revision) => {
    dispatch(
      setDialog({
        actions: (
          <>
            <PrimaryButton
              className="h-12 w-[100px]"
              noFill
              title={t("cancel")}
              onClick={() => {
                dispatch(toggleDialog(false));
              }}
            />
            <PrimaryButton
              className="h-12 w-[150px] ml-2"
              title={t("yes-delete")}
              onClick={() => {
                dispatch(toggleDialog(false));

                deleteRevisionItem({
                  revisionId: revision.id?.revision_id ?? "",
                });
              }}
            />
          </>
        ),
        title: t("delete-content"),
        message: t(
          "are-you-sure-that-you-would-like-to-delete-this-content-this-cant-be-undone"
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );
    dispatch(toggleDialog(true));
  };

  const downloadImageToLocal = () => {
    const link = document.createElement("a");
    link.href = "data:image/png;base64," + originalImageFileContent;
    link.download = (revision?.id?.revision_id ?? "") + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="">
        <RevisionEditorHeader
          revision={revisionDetail}
          deleteItem={() => {
            if (revisionDetail) {
              showDeleteItemDialog(revisionDetail);
            } else {
              // console.log("no revision to delete")
              return;
            }
          }}
          downloadImageToLocal={() => {
            if (imageFileContent) {
              downloadOriginalImage({ id: revision?.generated_content ?? "" }).unwrap().then(async (k) => {
              })
            }
          }}
        />
        <div className="flex flex-col items-center lg:flex-row">
          <div
            id="revisionImageContainer"
            className="flex flex-1 flex-col w-full h-[calc(min-h-screen-ios-200px)] lg:h-[calc(100vh-60px)] m-[30px] mx-[20px] md:mx-[30px] my-0 pb-[30px] overflow-scroll scrollbar-hide order-0 lg:order-1"
          >
            {imageFileContent && (
              <>
                <div
                  className={imageClass}
                >
                  <div style={{ paddingTop: "100%" }}></div>
                  <img
                    src={`data:image/png;base64,${imageFileContent}`}
                    alt="Scaled Image"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                      borderRadius: "15px",
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <RevisionEditorUpdateImage
            revision={revisionDetail}
            content={content}
            brand={brand}
            onRevisionUpdate={(revision) => {
              setRevision(revision);
            }}
            onRevisionSaved={(revision) => {
              navigate(-1);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RevisionEditor;
