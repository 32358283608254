import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { Copy, Delete, History, Share } from "../../../assets/IconComponents";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setDialog, toggleDialog } from "../../../store/appSlice";
import ShareLoop from "../../../components/ShareLoop/ShareLoop";
import DuplicateLoopPopup from "../../../components/DuplicateLoopPopup/DuplicateLoopPopup";
import { Content } from "../../../services/ContentApi";
import SvgEyeIcon from "../../../assets/IconComponents/EyeIcon";
import SvgMoreIcon from "../../../assets/IconComponents/MoreIcon";
import Icon from "../../../components/Icon";
import ActionSheet from "../../../components/ActionSheet/ActionSheet";

interface LoopDetailHeaderActionButtonsProps {
  deleteLoop: () => void
  content?: Content
  duplicateLoopWithName: (name: string | undefined) => void
  isOuterPopoverOpen: boolean
  onPopoverOpen: () => void
}

const LoopDetailHeaderActionButtons: FunctionComponent<LoopDetailHeaderActionButtonsProps> = (props: LoopDetailHeaderActionButtonsProps) => {
  const isContentLoading = useAppSelector(k => k.content.isContentLoading)
  const dispatch = useAppDispatch()
  const [actionSheetOpened, setActionSheetOpened] = useState(false)
  const [isDuplicatePopoverOpen, setIsDuplicatePopoverOpen] = useState(false)
  const [isSharePopoverOver, setIsSharePopoverOver] = useState(false)

  useEffect(() => {
    if (props.isOuterPopoverOpen) {
      setIsDuplicatePopoverOpen(false)
      setIsSharePopoverOver(false)
    }
  }, [props.isOuterPopoverOpen])
  const showDeleteDialog = () => {
    dispatch(setDialog({
      actions: <>
      <div className="flex w-full flex-col lg:flex-row">
        <PrimaryButton className="max-lg:w-full max-lg:h-10 lg:h-12 lg:w-[100px]" noFill title={t('cancel')} onClick={() => { dispatch(toggleDialog(false)) }} />
        <PrimaryButton className="max-lg:w-full max-lg:h-10 lg:h-12 lg:w-28 lg:ml-2" title={t('yes-delete-loop')} onClick={() => { dispatch(toggleDialog(false)); props.deleteLoop() }} />
        </div>
      </>,
      title: t('deleteLoopTitle'),
      message: t('delete-loop-message'),
      onClose: () => { dispatch(toggleDialog(false)) }
    }))
    dispatch(toggleDialog(true))
  }

  return (
    <>
      <div className="max-lg:hidden lg:flex flex-row items-center">
        {!isContentLoading && (
          <PrimaryButton
            icon={<Delete fill={isContentLoading ? "#ffffff" : "#121315"} />}
            noFill
            className=" w-[36px] h-[36px]"
            isDisabled={isContentLoading}
            tooltip="Delete Content"
            onClick={() => {
              showDeleteDialog();
            }}
          />
        )}
        {!isContentLoading && !props.content?.read_only && (
          <DuplicateLoopPopup
            duplicateLoopWithName={(e) => {
              props.duplicateLoopWithName(e);
            }}
            isPopoverOpen={isDuplicatePopoverOpen}
            setIsDuplicatePopoverOpen={(isOpen) => {
              if (isOpen) {
                setIsSharePopoverOver(false)
                setIsDuplicatePopoverOpen(isOpen)
                props.onPopoverOpen()
              } else {
                setIsDuplicatePopoverOpen(isOpen)
              }
            }} />
        )}
        {!isContentLoading && !props.content?.read_only && (
          <ShareLoop content={props.content} isPopoverOpen={isSharePopoverOver} setIsSharePopoverOpen={(isOpen: boolean) => {
            if (isOpen) {
              setIsDuplicatePopoverOpen(false)
              setIsSharePopoverOver(isOpen)
              props.onPopoverOpen()
            } else {
              setIsSharePopoverOver(isOpen)
            }
          }} />
        )}
        {props.content?.read_only && (
          <PrimaryButton
            icon={<SvgEyeIcon className="mr-2" />}
            noFill
            className="h-10 w-[140px] mr-[25px] ml-1 text-gray"
            isDisabled={false}
            title="View Only"
            tooltip="You can only view this loop."
            onClick={() => { }}
          />
        )}
        {isContentLoading && <div className={`h-10 w-16`}></div>}
      </div>
      <div className="lg:hidden max-lg:flex ">
        <ActionSheet
          isExpanded={actionSheetOpened}
          setIsExpanded={setActionSheetOpened}
          triggerView={
            <button
              className={`lg:hidden
                                    mr-2 md:mr-5
                                    bg-white p-[10px]
                                    rounded-lg max-lg:flex flex-row items-center
                                    border-1 border-border
                                `}
              onClick={() => { }}
            >
              <div className=" rounded-sm p-[2px]">
                <Icon component={SvgMoreIcon} className="w-[20px]" />
              </div>
            </button>
          }
          title="Actions"
          body={
            <div className="w-full p-[15px] pt-[5px] flex flex-col">
              <div className="h-full">
                <ShareLoop
                  className="mt-2"
                  content={props.content}
                  newActionSheetOpened={() => {
                    setActionSheetOpened(false);
                  }} isPopoverOpen={false} />
                <DuplicateLoopPopup
                  className="mt-2 w-full"
                  duplicateLoopWithName={(e) => {
                    props.duplicateLoopWithName(e);
                    setActionSheetOpened(false);
                  }} isPopoverOpen={false} />
                <PrimaryButton
                  icon={
                    <Delete
                      fill={isContentLoading ? "#ffffff" : "#121315"}
                      className="mr-2"
                    />
                  }
                  noFill
                  className="h-10 w-full md:w-10 min-w-[40px] min-h-[40px] mt-2"
                  isDisabled={isContentLoading}
                  title={t("delete")}
                  tooltip="Delete Content"
                  onClick={() => {
                    showDeleteDialog();
                    setActionSheetOpened(false);
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

export default LoopDetailHeaderActionButtons;
