import * as React from "react";
import type { SVGProps } from "react";
const SvgPassword = (props: SVGProps<SVGSVGElement>) => (
<svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    fill="none"
    {...props}>
  <path d="M5.83331 11.6667C5.37498 11.6667 4.98262 11.5035 4.65623 11.1771C4.32984 10.8507 4.16665 10.4583 4.16665 10C4.16665 9.54167 4.32984 9.14931 4.65623 8.82292C4.98262 8.49653 5.37498 8.33333 5.83331 8.33333C6.29165 8.33333 6.68401 8.49653 7.0104 8.82292C7.33679 9.14931 7.49998 9.54167 7.49998 10C7.49998 10.4583 7.33679 10.8507 7.0104 11.1771C6.68401 11.5035 6.29165 11.6667 5.83331 11.6667ZM5.83331 15C4.44442 15 3.26387 14.5139 2.29165 13.5417C1.31942 12.5694 0.833313 11.3889 0.833313 10C0.833313 8.61111 1.31942 7.43056 2.29165 6.45833C3.26387 5.48611 4.44442 5 5.83331 5C6.76387 5 7.60762 5.22917 8.36456 5.6875C9.12151 6.14583 9.7222 6.75 10.1666 7.5H17.1458C17.2569 7.5 17.3646 7.52083 17.4687 7.5625C17.5729 7.60417 17.6666 7.66667 17.75 7.75L19.4166 9.41667C19.5 9.5 19.559 9.59028 19.5937 9.6875C19.6285 9.78472 19.6458 9.88889 19.6458 10C19.6458 10.1111 19.6285 10.2153 19.5937 10.3125C19.559 10.4097 19.5 10.5 19.4166 10.5833L16.7708 13.2292C16.7014 13.2986 16.618 13.3542 16.5208 13.3958C16.4236 13.4375 16.3264 13.4653 16.2291 13.4792C16.1319 13.4931 16.0347 13.4861 15.9375 13.4583C15.8403 13.4306 15.75 13.3819 15.6666 13.3125L14.5833 12.5L13.3958 13.3958C13.3264 13.4514 13.25 13.4931 13.1666 13.5208C13.0833 13.5486 13 13.5625 12.9166 13.5625C12.8333 13.5625 12.7465 13.5486 12.6562 13.5208C12.566 13.4931 12.4861 13.4514 12.4166 13.3958L11.1458 12.5H10.1666C9.7222 13.25 9.12151 13.8542 8.36456 14.3125C7.60762 14.7708 6.76387 15 5.83331 15ZM5.83331 13.3333C6.61109 13.3333 7.29512 13.0972 7.8854 12.625C8.47567 12.1528 8.86804 11.5556 9.06248 10.8333H11.6666L12.875 11.6875L14.5833 10.4167L16.0625 11.5625L17.625 10L16.7916 9.16667H9.06248C8.86804 8.44444 8.47567 7.84722 7.8854 7.375C7.29512 6.90278 6.61109 6.66667 5.83331 6.66667C4.91665 6.66667 4.13192 6.99306 3.47915 7.64583C2.82637 8.29861 2.49998 9.08333 2.49998 10C2.49998 10.9167 2.82637 11.7014 3.47915 12.3542C4.13192 13.0069 4.91665 13.3333 5.83331 13.3333Z" fill="black"/>
</svg>

);
export default SvgPassword;
