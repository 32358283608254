import { t } from "i18next";
import ContentContainer from "../../../components/ContentContainer";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setEvent, setPlan } from "../../../store/plannerSlice";
import { Button, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import { useLazyGetCampaignObjectiveTypeListQuery } from "../../../services/MarketingPlanner";
import Search from "antd/es/input/Search";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { reformatText } from "../../../helpers/Utils";

const DescribeEvent = (props) => {
    const dispatch = useAppDispatch()
    const [highlightedThings, setHighlightedThings] = useState<string[]>(props[props.type === 'event' ? 'event' : 'plan']?.highlight ? props[props.type === 'event' ? 'event' : 'plan']?.highlight : [''])
    const [mainObjectiveOptions, setMainObjectiveOptions] = useState<any[]>()
    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)
    const [processData, setProcessData] = useState({
        name: props[props.type === 'event' ? 'event' : 'plan']?.name,
        about: props[props.type === 'event' ? 'event' : 'plan']?.about,
        keyMessage: props[props.type === 'event' ? 'event' : 'plan']?.keyMessage,
        startDate: props[props.type === 'event' ? 'event' : 'plan']?.startDate ?? dayjs().valueOf(),
        endDate: props[props.type === 'event' ? 'event' : 'plan']?.endDate,
        eventPeriod: props[props.type === 'event' ? 'event' : 'plan']?.eventPeriod,
        brandId: lastSelectedBrandId,
        campaignObjectiveType: props[props.type === 'event' ? 'event' : 'plan'].campaignObjectiveType,
        highlightedThings: props[props.type === 'event' ? 'event' : 'plan']?.highlight
    })
    const [getCampaignObjectiveTypeList1] = useLazyGetCampaignObjectiveTypeListQuery()
    const [isDisabled, setIsDisabled] = useState<boolean>(true)

    const handleForm = (field, value) => {
        setProcessData(prevState => ({ ...prevState, [field]: value }))
    }

    useEffect(() => {
        setHighlightedThings(processData.highlightedThings)
        if (props.type === 'campaign') {
            getCampaignObjectiveTypeList1({ campaignTypeText: props.plan.campaignType })
                .then(data => setMainObjectiveOptions(data?.data?.map(item => ({
                    value: item, label: reformatText(item)
                }))))
        }
    }, []);

    useEffect(() => {
        if (!highlightedThings) {
            setHighlightedThings([''])
        }
    }, [highlightedThings]);

    useEffect(() => {
        if (props.type === 'event' && processData.name?.length >= 3 && processData.about?.length >= 3 && processData.keyMessage?.length >= 3) {
            setIsDisabled(false)
        }
        else if (props.type !== 'event' && processData.name?.length >= 3 && processData.about?.length >= 3 && processData.campaignObjectiveType) {
            setIsDisabled(false)
        }
        else {
            setIsDisabled(true)
        }

    }, [processData]);

    return (
        <div className={'w-7/12'}>
            <div className={'mb-7 ml-2'}>
                <div className={'text-H4'}>
                    <h2>Describe your {props.type}</h2>
                </div>
                <div className={'text-BodySmall mt-2'}>
                    Tell us a bit more about the event so Evercopy can personalize the campaign details.
                </div>
            </div>
            <ContentContainer
                ref={props.ref}
                className={`w-full`}
                hoverable={false}
                children={
                    <>
                        <div className="flex flex-col w-full p-[20px]">
                            <Form.Item colon={false} label={<p className={'text-H8 text-black'}>Event Name*</p>}
                                tooltip="Event name will only appear on the platform while labeling activities for quicker access. This name will not appear on the final activities." labelCol={{ span: 10 }} labelAlign={'left'}>
                                <Input
                                    onChange={(e) => {
                                        handleForm('name', e.target.value)
                                    }}
                                    value={processData.name}
                                    placeholder={'e.g. Product Launch - May 2024'}
                                    size="large"
                                    minLength={3}
                                    maxLength={100}
                                />
                                {processData.name?.length > 0 && processData.name?.length < 3 && (
                                    <p style={{ color: "red" }}>{`You must enter at least 3 characters`}</p>
                                )}
                            </Form.Item>

                            <Form.Item colon={false} label={<p className={'text-H8 text-black'}>What’s your event about?*</p>}
                                tooltip="Specify the nature and focus of your event. Include the main industry (e.g., tech, finance), and any relevant themes (e.g., innovation, sustainability). This helps tailor the campaign content effectively."
                                labelCol={{ span: 10 }} labelAlign={'left'}>
                                <Input
                                    onChange={(e) => {
                                        handleForm('about', e.target.value)
                                    }}
                                    value={processData.about}
                                    placeholder={'e.g. Unveiling Espresso Master 5000'}
                                    size="large"
                                    minLength={3}
                                    maxLength={100}
                                />
                                {processData.about?.length > 0 && processData.about?.length < 3 && (
                                    <p style={{ color: "red" }}>{`You must enter at least 3 characters`}</p>
                                )}
                            </Form.Item>
                            {props.type !== 'event' ?
                                <Form.Item colon={false} label={<p className={'text-H8 text-black'}>Main Objectives*</p>}
                                    tooltip="Determine the campaign's active duration with a start and end date. This scheduling ensures a structured approach for campaign planning before, during, and after the event."
                                    labelCol={{ span: 10 }} labelAlign={'left'}>
                                    <Select
                                        placeholder={'Choose main objectives'}
                                        className={'default-page-sizes'}
                                        onChange={(val) => {
                                            console.log(val)
                                            handleForm('campaignObjectiveType', val)
                                        }}
                                        value={processData.campaignObjectiveType}
                                        options={mainObjectiveOptions}
                                    />
                                </Form.Item>
                                :
                                <Form.Item colon={false} label={<p className={'text-H8 text-black'}>Key Message*</p>}
                                    tooltip="Articulate the primary message of your event. What pivotal idea or statement do you want to resonate with the audience? This will direct the overarching message of your campaign."
                                    labelCol={{ span: 10 }} labelAlign={'left'}>
                                    <Input
                                        onChange={(e) => {
                                            handleForm('keyMessage', e.target.value)
                                        }}
                                        value={processData.keyMessage}
                                        placeholder={'e.g. Wake up with high-quality espresso'}
                                        size="large"
                                        minLength={3}
                                        maxLength={100}

                                    />
                                    {processData.keyMessage?.length > 0 && processData.keyMessage?.length < 3 && (
                                        <p style={{ color: "red" }}>{`You must enter at least 3 characters`}</p>
                                    )}
                                </Form.Item>
                            }
                            {highlightedThings?.length && highlightedThings.map((highlightedThing, i) => (
                                <Form.Item colon={false} label={<p className={'text-H8 text-black'}>{i === 0 ? 'Things to Highlight' : ''}</p>}
                                    tooltip={i === 0 ? "Identify particular aspects of the event that should be accentuated in the campaign, such as keynote announcements, milestones, or exclusive benefits for attendees." : ''}
                                    labelCol={{ span: 10 }} labelAlign={'left'}>
                                    {i < 4 ? <Search
                                        placeholder="e.g. 15 cups of coffee in brew"
                                        onSearch={(a) => console.log(a)}
                                        value={highlightedThing}
                                        maxLength={100}
                                        onChange={(e) => {
                                            setHighlightedThings(prevState => {
                                                const tempArray: string[] = [];
                                                prevState.forEach((prevItem, index) => {
                                                    if (i === index) {
                                                        tempArray.push(e.target.value)
                                                    } else {
                                                        tempArray.push(prevItem)
                                                    }
                                                })
                                                return tempArray;
                                            })
                                        }}
                                        enterButton={<Button icon={highlightedThings.length - 1 == i ? <PlusOutlined /> :
                                            <DeleteOutlined />} onClick={(e) => {
                                                if (highlightedThings.length - 1 == i) {
                                                    setHighlightedThings(prevState => {
                                                        const tempArray: string[] = prevState.map(state => state);
                                                        tempArray.push('')
                                                        return tempArray;
                                                    })
                                                } else {
                                                    setHighlightedThings(prevState => {
                                                        return prevState.filter((state, index) => index !== i);
                                                    })
                                                }
                                            }}></Button>}
                                        size="large" /> :
                                        <Input
                                            placeholder="e.g. 15 cups of coffee in brew"
                                            onChange={(e) => {
                                                setHighlightedThings(prevState => {
                                                    const tempArray: string[] = [];
                                                    prevState.forEach((prevItem, index) => {
                                                        if (i === index) {
                                                            tempArray.push(e.target.value)
                                                        } else {
                                                            tempArray.push(prevItem)
                                                        }
                                                    })
                                                    return tempArray;
                                                })
                                            }}
                                            maxLength={100}
                                            value={highlightedThing}
                                            size="large"
                                        />
                                    }
                                </Form.Item>
                            ))}

                            <Form.Item colon={false} label={<p className={'text-H8 text-black'}>Start Date*</p>}
                                tooltip="Determine the campaign's active duration with a start date. This scheduling ensures a structured approach for campaign planning before, during, and after the event."
                                labelCol={{ span: 10 }} labelAlign={'left'} rules={[{ required: true, message: 'Please enter timeline!' }]}>
                                <DatePicker
                                    title={"Start Date*"}
                                    value={dayjs(processData.startDate)}
                                    minDate={dayjs(dayjs().valueOf())}
                                    className={'default-page-sizes'}
                                    format="DD MMM YYYY"
                                    onChange={(e) => {
                                        handleForm('startDate', dayjs(e).valueOf())
                                        if (processData.eventPeriod) {
                                            handleForm('endDate', dayjs(e).valueOf() + processData.eventPeriod)
                                        }
                                    }}
                                    allowClear={false}
                                />
                            </Form.Item>

                        </div>
                    </>
                }
            />
            <div className="flex justify-between mt-3">
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]"
                    noFill
                    title={"Back"}
                    onClick={() => {
                        props.onCancel();
                    }}
                />
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]   max-lg:mt-1 mr-5"
                    title={t("continue")}
                    isDisabled={isDisabled}
                    onClick={() => {
                        props.onComplate();
                        if (props.type === 'event') {
                            dispatch(setEvent({
                                ...props.event,
                                ...processData,
                                highlight: highlightedThings
                            }))
                        } else {
                            dispatch(setPlan({
                                ...props.plan,
                                ...processData,
                                highlight: highlightedThings
                            }))
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default DescribeEvent
