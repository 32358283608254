import { t } from "i18next";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close } from "../../../assets/IconComponents";
import SvgChannel from "../../../assets/IconComponents/Channel";
import SvgLogoIcon from "../../../assets/IconComponents/LogoIcon";
import LoadingBarAnimation from "../../../components/LoadingBarAnimation";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { Content, ContentRequest, Revision, useCloneMutation, useSave4Mutation } from "../../../services/ContentApi";
import { setCollapsedModeEnabled, setDialog, setMenuCollapsed, toggleDialog, toggleLoading, updateMenu } from "../../../store/appSlice";
import LoopDetailBrandSelector from "./Brand/LoopDetailBrandSelector";
import LoopDetailChannels, { LoopDetailChannel } from "./Channel/LoopDetailChannels";
import LoopDetailHeaderActionButtons from "./LoopDetailHeaderActionButtons";
import LoopDetailHeaderContentName from "./LoopDetailHeaderContentName";
import { BrandKeywords, BrandRequest, useRefreshKeywordMutation, useSave5Mutation } from "../../../services/BrandApi";
import PopupRadio, { ChangeBrandPopupItems, changeBrandPopupItemModel } from "../../../components/PopupRadio/PopupRadio";
import { ContentType } from "../../../constants/ContentType";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import moment from "moment";
import BrandHubBrandInfo from "../../BrandHub/CreateNew/BrandHubBrandInfo";
import BrandHubBrandTone from "../../BrandHub/CreateNew/BrandHubBrandTone";
import BrandHubBrandVisuals from "../../BrandHub/CreateNew/BrandHubBrandVisuals";
import { setNewBrandInfo } from "../../../store/brandSlice";
import SvgBrandTritoneBig from "../../../assets/IconComponents/BrandTritoneBig";
import { setLastSelectedBrandId } from "../../../store/persistedSlice";
import FilterSelector, { IFilterSelectorData } from "../../../components/Filters/FilterSelector";

interface LoopDetailsHeaderProps {
  content?: Content
  showCopyLoop?: () => void
  animating?: boolean
  loopChannels?: LoopDetailChannel[]
  filterItems: (filter: RevisionsFilterModel[]) => void
  updateLoop: (newBrand: BrandRequest | undefined, shouldCreateRevision: boolean) => void
  deleteLoop: (content?: Content) => void
}

export interface RevisionsFilterModel {
  id: string
  title: string
  filter: (items: Revision[]) => Revision[]
}

const RevisionFilterItems: RevisionsFilterModel[] = [
  {
    id: "favorites",
    title: "Favorites",
    filter: (items: Revision[]) => {
      return items.filter(k => k.is_favorite === true)
    }
  },
  {
    id: "originals",
    title: "Originals",
    filter: (items: Revision[]) => {
      return items.filter(k => k.original === true)
    }
  }
  /*,
  {
    id: "showall",
    title: "Show All",
    filter: (items: Revision[]) => {
      return items
    }
  }
  */
]

const LoopDetailsHeader: FunctionComponent<LoopDetailsHeaderProps> = (props: LoopDetailsHeaderProps) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector(k => k.content.newContent)
  const brandInfo = useAppSelector(k => k.brand.newBrand)
  const navigate = useNavigate();
  const isContentLoading = useAppSelector(k => k.content.isContentLoading)
  const [selectedRevisionFilters, setSelectedRevisionFilters] = useState<IFilterSelectorData[]>(RevisionFilterItems.find(k => k.id === "showall") ? [RevisionFilterItems.find(k => k.id === "showall")!] : [])
  const [selectedBrand, setSelectedBrand] = useState<BrandRequest | undefined>()
  const [selectedPopupBrandItem, setSelectedPopupBrandItem] = useState<changeBrandPopupItemModel>(ChangeBrandPopupItems[0])
  const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>()
  const [isBrandPopoverOpen, setIsBrandPopoverOpen] = useState<boolean>(false)
  const [isActionSheetBrandPopoverOpen, setIsActionSheetBrandPopoverOpen] = useState<boolean>(false)
  const [isFilterSelectorPopoverOpen, setIsFilterSelectorPopoverOpen] = useState<boolean>(false)
  const [isActionSheetFilterPopoverOpen, setIsActionSheetFilterPopoverOpen] = useState<boolean>(false)
  const [createContent, { isSuccess: contentCreationSuccess,
    data: contentCreationData }] = useSave4Mutation()
  const [duplicateContent, { isLoading: isContentDuplicateLoading,
    isSuccess: contentDuplicateSuccess,
    data: contentDuplicateData }] = useCloneMutation()
  const [refreshKeywords, { data: keywords }] = useRefreshKeywordMutation()
  const [saveBrandInfo, { isSuccess: brandSaved, data: savedBrandData }] = useSave5Mutation()
  const BrandInfoRef = useRef<HTMLDivElement>(null)
  const BrandToneRef = useRef<HTMLDivElement>(null)
  const BrandVisualsRef = useRef<HTMLDivElement>(null)
  const brandInfoObjectRef = useRef(brandInfo)
  const [error, setError] = useState<string>("")

  useEffect(() => {
    brandInfoObjectRef.current = brandInfo;
  }, [brandInfo])


  useEffect(() => {
    if (contentDuplicateSuccess) {
      dispatch(updateMenu())
      window.location.href =
        "/app/loopdetails/" + (contentDuplicateData?.id?.content_id ?? "");
    }
  }, [contentDuplicateSuccess])

  useEffect(() => {
    dispatch(toggleLoading(isContentDuplicateLoading))
  }, [isContentDuplicateLoading])

  const showChangeBrandDialog = (newBrand: BrandRequest | undefined) => {
    if (newBrand?.id === props.content?.brand_id)
      return

    if (props.content?.brand_id === undefined) {
      setSelectedBrand(newBrand);
      continueWithNewBrand(newBrand, true);
      return
    }

    dispatch(setDialog({
      actions: <></>,
      title: "",
      message: "",
      customTemplate: (<>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center h-screen" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
          <div className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-5 rounded-xl">
            <>
              <div className="h-14 items-center flex justify-between">
                <span className="text-black text-H5">Change Brand</span>
                <button onClick={() => { dispatch(toggleDialog(false)) }}>
                  <Close width={20} height={20} color="#000000" />
                </button>
              </div>
              <hr className="bg-border -ml-5 -mr-5" />
              <PopupRadio
                selectedItem={(item) => {
                  setSelectedPopupBrandItem(item)
                }} />
              <hr className="bg-border -ml-5 -mr-5" />
              <div className="mt-3 flex justify-end">
                <>
                  <PrimaryButton className="h-12 w-[100px]" noFill title={t('cancel')} onClick={() => { dispatch(toggleDialog(false)) }} />
                  <PrimaryButton className="h-12 w-28 ml-2" title={t('continue')} onClick={() => {
                    setTimeout(() => {
                      setSelectedBrand(newBrand); continueWithNewBrand(newBrand); dispatch(toggleDialog(false));
                    }, 500);
                  }} />
                </>
              </div>
            </>

          </div>
        </div>
      </>),
    }))
    dispatch(toggleDialog(true))
  }

  const showEditBrandDialog = (newBrand: BrandRequest | undefined) => {
    if (newBrand) {
      dispatch(setNewBrandInfo({
        id: newBrand.id,
        brandName: newBrand.brand_name,
        webSiteUrl: newBrand.web_url,
        twitter: newBrand.twitter_url,
        instagram: newBrand.instagram_url,
        linkedIn: newBrand.linkedin_url,
        facebook: newBrand.facebook_url,
        aboutCompany: newBrand.about_company,
        brandColors: newBrand.brand_colors?.split(","),
        brandTones: newBrand.brand_tone?.split(",").map(k => (TonOfVoices.find(l => l.key === k))?.value ?? "") ?? [],
        selectedCompanyLogos: newBrand.logo_file_ids,
        selectedCompanyAccentFont: newBrand.accent_font_file_id,
        selectedCompanyFiles: newBrand.company_materials_file_ids,
        selectedCompanyIcons: newBrand.icon_file_ids,
        selectedProductPhotos: newBrand.product_file_ids,
        selectedCompanyPrimaryFont: newBrand.primary_font_file_id,
        selectedCompanySecondaryFont: newBrand.secondary_font_file_id,
        sector: newBrand.sector,
        subSector: newBrand.sub_sector,
        selectedKeywords: newBrand.keywords?.filter(k => k.source === "AI"),
        userEnteredKeywords: newBrand.keywords?.filter(k => k.source === "USER")
      }))
    }


    dispatch(setDialog({
      actions: <></>,
      title: "",
      message: "",
      customTemplate: (<>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center h-screen" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
          <div className="w-full mx-2 md:mx-0 md:w-3/5 flex flex-col justify-center bg-white p-5 rounded-xl content-stretch">
            <>
              <div className="h-14 items-center flex justify-between">
                <span className="text-black text-H5">Brand Edit</span>
              </div>
              <hr className="bg-border -ml-5 -mr-5" />
              <div className="inline-flex flex-col w-full h-[calc(100vh-420px)] md:h-[calc(100vh-180px)] justify-start items-start overflow-scroll scrollbar-hide">
                <BrandHubBrandInfo
                  ref={BrandInfoRef}
                  expanded={() => {
                    BrandInfoRef?.current?.scrollIntoView();
                  }}
                  brandAboutEntered={() => {
                    refreshBrandKeywords();
                  }}
                  errorOccured={(e) => {
                    setError(e);
                    setTimeout(() => {
                      setError("");
                    }, 3000);
                  }}
                />
                <BrandHubBrandTone
                  keywords={(keywords?.keywords?.split(",") ?? []).map(
                    (k) => ({ source: "AI", value: k } as BrandKeywords)
                  )}
                  refreshKeywords={() => {
                    refreshBrandKeywords();
                  }}
                  ref={BrandToneRef}
                  expanded={() => {
                    BrandToneRef?.current?.scrollIntoView();
                  }}
                />
                <BrandHubBrandVisuals
                  ref={BrandVisualsRef}
                  expanded={() => {
                    BrandVisualsRef?.current?.scrollIntoView();
                  }}
                />
              </div>
              <div className="mt-3 flex justify-end gap-[10px] h-[51px] pt-[15px]">
                <PrimaryButton className="h-9 w-[66px]" noFill title={t('cancel')} onClick={() => { dispatch(toggleDialog(false)); }} />
                <PrimaryButton className="h-9 w-[94px]" title={t('save-brand')} onClick={() => {
                  saveBrand();
                }} />
              </div>
            </>

          </div>
        </div>
      </>),
    }))
    dispatch(toggleDialog(true))
  }

  const continueWithNewBrand = (newBrand: BrandRequest | undefined, noCreate: boolean = false) => {
    setSelectedBrand(newBrand);
    if (selectedPopupBrandItem.id === 1 && !noCreate) {
      createLoop(newBrand)
    } else {
      props.updateLoop(newBrand, !noCreate)
    }
  }

  useEffect(() => {
    if (contentCreationSuccess) {
      console.log('navigate("/app')
      navigate("/app/loopdetails/" + contentCreationData?.id?.content_id ?? "", { state: { newLoopCreated: true } })
    }

  }, [contentCreationSuccess])

  const createLoop = (newBrand: BrandRequest | undefined) => {
    if (newContent?.medium?.name !== "Custom") {
      var payload = {
        name: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
        type: "text",
        platform: props.content?.platform,
        filters: JSON.parse(props.content?.filters ?? ""),
        brand_id: newBrand?.id
      } as ContentRequest

      // if (newContent?.contentType === ContentType.image) {
      //     payload["image_width"] = newContent?.mediumOption?.width
      //     payload["image_height"] = newContent?.mediumOption?.height
      // }
      createContent({
        contentRequest: payload
      })
    } else {
      var payload = {
        name: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
        type: "text",
        platform: props.content?.platform,
        image_width: newContent.imageWidth,
        image_height: newContent.imageHeight,
        filters: JSON.parse(props.content?.filters ?? ""),
        brand_id: newBrand?.id
      } as ContentRequest

      // if (newContent?.contentType === ContentType.text) {
      //     if (newContent?.mediumOption?.description == "Words") {
      //         payload["words_limit"] = newContent?.mediumOption?.value
      //     } else if (newContent?.mediumOption?.description == "Sentences") {
      //         payload["sentences_limit"] = newContent?.mediumOption?.value
      //     }

      // }
      createContent({
        contentRequest: payload
      })
    }
  }

  const getFilters = () => {
    var payload = {

      age_group: newContent?.ageGroup?.map(k => k.title).join(" & ") ?? "",
      bot_type: "marketing",
      gender_group: newContent?.genderGroup?.map(k => k.title).join(" & ") ?? "",
      education_level: newContent?.education?.map(k => k.title).join(" & ") ?? "",
      objective: newContent?.contentObjective?.map(k => k.title).join(" & ") ?? "",
      tone_of_voice: getTonOfVoices(),
      message: newContent?.contentAbout ?? "",

    }
    if (newContent?.contentType === ContentType.image) {
      payload["purpose"] = "Background"
      payload["bot_type"] = "image-gen-prompt"

    }

    return payload
  }

  const getTonOfVoices = () => {
    const values = newContent?.tonOfVoice?.map(k => k.value)
    const filteredVoices = TonOfVoices.filter(k => values?.includes(k.value)!)
    return filteredVoices.map(k => k.key).join(" & ")
  }

  useEffect(() => {
    setSelectedBrandId(props.content?.brand_id)
  }, [props.content])

  const refreshBrandKeywords = () => {
    refreshKeywords({
      aiRefreshKeywordRequest: {
        about: brandInfoObjectRef?.current?.aboutCompany,
        instagram: brandInfoObjectRef?.current?.instagram,
        linkedin: brandInfoObjectRef?.current?.linkedIn,
        twitter: brandInfoObjectRef?.current?.twitter
      }
    }).unwrap()
      .then(k => {
        let l = (((k.keywords ?? "").split(",") ?? []).map(k => ({ source: "AI", value: k } as BrandKeywords)))

        if (l.length !== 0) {
          dispatch(setNewBrandInfo({
            ...brandInfoObjectRef?.current,
            selectedKeywords: [...l],
          }))
        }
      })
  }

  const isFormValid = () => {

    if (!brandInfoObjectRef?.current?.aboutCompany || brandInfoObjectRef?.current?.aboutCompany === "") {
      setError("Please Check About Company Field")
      return false
    }
    if (!brandInfoObjectRef?.current?.webSiteUrl || brandInfoObjectRef?.current?.webSiteUrl === "") {
      setError("Please Check Web Site Url Field")
      return false
    }
    if (!brandInfoObjectRef?.current?.brandName || brandInfoObjectRef?.current?.brandName === "") {
      setError("Please Check Brand Name Field")
      return false
    }

    return true
  }

  function saveBrand() {
    if (!isFormValid()) {
      setTimeout(() => {
        setError("")
      }, 3000);
      return
    }

    saveBrandInfo({
      brandRequest: {
        id: brandInfoObjectRef.current.id,
        about_company: brandInfoObjectRef.current?.aboutCompany ?? "",
        web_url: brandInfoObjectRef.current?.webSiteUrl ?? "f",
        brand_name: brandInfoObjectRef.current?.brandName ?? "",
        twitter_url: brandInfoObjectRef.current?.twitter,
        linkedin_url: brandInfoObjectRef.current?.linkedIn,
        instagram_url: brandInfoObjectRef.current?.instagram,
        facebook_url: brandInfoObjectRef.current?.facebook,
        logo_file_ids: brandInfoObjectRef.current?.selectedCompanyLogos,
        icon_file_ids: brandInfoObjectRef.current?.selectedCompanyIcons,
        product_file_ids: brandInfo.selectedProductPhotos,
        accent_font_file_id:
          brandInfoObjectRef.current?.selectedCompanyAccentFont,
        primary_font_file_id:
          brandInfoObjectRef.current?.selectedCompanyPrimaryFont,
        secondary_font_file_id:
          brandInfoObjectRef.current?.selectedCompanySecondaryFont,
        company_materials_file_ids:
          brandInfoObjectRef.current?.selectedCompanyFiles,
        brand_tone: TonOfVoices.find((k) =>
          brandInfoObjectRef.current?.brandTones?.find((l) => l === k.value)
        )?.key,
        brand_colors: brandInfoObjectRef.current?.brandColors
          ?.filter((k) => k !== "")
          .join(","),
        keywords: [
          ...(brandInfoObjectRef.current?.selectedKeywords ?? []),
          ...(brandInfoObjectRef.current?.userEnteredKeywords ?? []),
        ],
        sector: brandInfoObjectRef.current?.sector,
        sub_sector: brandInfoObjectRef.current?.subSector,
        technical_links: brandInfoObjectRef.current?.technicalLinks,
        audience_ids: brandInfoObjectRef.current?.associatedAudiences?.map(
          (audience) => audience.audience_id ?? ""
        ),
      },
    });
  }

  useEffect(() => {
    if (brandSaved) {
      showSuccessDialog()
      dispatch(setLastSelectedBrandId(savedBrandData?.id))
    }
  }, [brandSaved])


  const showSuccessDialog = () => {
    dispatch(setDialog({
      actions: <></>,
      title: "",
      message: "",
      customTemplate: (<>
        <div className="flex flex-col items-center justify-center py-[40px]">
          <SvgBrandTritoneBig width={72} height={72} viewBox="0 0 72 72" />
          <p className="mt-[30px] text-H6 text-black">Your brand template successfully updated!</p>
          <p className="mt-[10px] text-BodyMedium text-gray text-center">Now you can use your brand template while creating contents.</p>
          <div className="mt-[30px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
            <PrimaryButton
              className="h-full w-full md:w-[80px]"
              noFill
              onClick={() => { dispatch(toggleDialog(false)); }}
              title="Close" />
          </div>
        </div>
      </>),
    }))
    dispatch(toggleDialog(true))
  }


  return (
    <>
      <div className="h-[56px] min-w-full text-xl flex flex-row bg-white -ml-[0px] md:-ml-[10px]  md:-mr-[12px] border-1 border-border">
        <div className="w-full h-full flex flex-row justify-between items-center ">
          <LoopDetailHeaderContentName content={props.content} />,
          {!isContentLoading &&
            !props.content?.read_only &&
            props.content?.type !== "image" && (
              <div className="max-lg:hidden lg:flex flex-row justify-center mt-5 sm:mt-0 px-2">
                <LoopDetailChannels
                  loopChannels={props.loopChannels}
                  isDisabled={false}
                />
              </div>
            )}
          {!isContentLoading && (
            <>
              <div className="flex flex-row items-center">
                <div
                  className={`max-lg:hidden lg:flex flex-row justify-center mt-5 sm:mt-0 mr-1`}
                >
                  <FilterSelector
                    icon={SvgChannel}
                    subTitle={t("filter-revisions")}
                    Items={RevisionFilterItems}
                    selectedItems={selectedRevisionFilters}
                    tooltip={t("apply-filters-0") ?? ""}
                    ItemsSelected={(selectedItems) => {
                      props.filterItems(
                        selectedItems.map(
                          (item) =>
                            RevisionFilterItems.find((k) => k.id === item.id)!
                        )
                      );
                      setSelectedRevisionFilters(selectedItems);
                    }}
                    isPopoverOpen={isFilterSelectorPopoverOpen}
                    setIsFilterPopoverOpen={(isOpen) => {
                      setIsFilterSelectorPopoverOpen(isOpen);
                      if (isOpen) {
                        setIsBrandPopoverOpen(false);
                        setIsActionSheetBrandPopoverOpen(false);
                        setIsActionSheetFilterPopoverOpen(false);
                      }
                    }}
                  />
                </div>

                {!props.content?.read_only &&
                  props.content?.type !== "image" && (
                    <div className="max-lg:hidden lg:flex flex-row justify-center mt-5 sm:mt-0 px-2 items-center">
                      <div
                        className="mr-3"
                        style={{
                          width: "1px",
                          height: "20px",
                          backgroundColor: "#EAEBE6",
                        }}
                      ></div>

                      <LoopDetailBrandSelector
                        selectedBrandId={selectedBrandId}
                        selectedBrand={selectedBrand}
                        BrandSelected={(e) => {
                          showChangeBrandDialog(e);
                        }}
                        OnBrandEdit={(e) => {
                          showEditBrandDialog(e);
                        }}
                        tooltip={t("choose-brand-voice") ?? ""}
                        brandUpdated={brandSaved}
                        isPopoverOpen={isBrandPopoverOpen}
                        setIsBrandPopoverOpen={(isOpen) => {
                          setIsBrandPopoverOpen(isOpen);
                          if (isOpen) {
                            setIsActionSheetBrandPopoverOpen(false);
                            setIsFilterSelectorPopoverOpen(false);
                            setIsActionSheetFilterPopoverOpen(false);
                          }
                        }}
                      />
                    </div>
                  )}

                <div
                  className="mr-1 ml-1"
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "#EAEBE6",
                  }}
                ></div>
                <div className="flex-row justify-center sm:mt-0 pl-2">
                  <LoopDetailHeaderActionButtons
                    content={props.content}
                    deleteLoop={() => {
                      props.deleteLoop(props.content);
                    }}
                    duplicateLoopWithName={(e) => {
                      duplicateContent({
                        cloneContentRequest: {
                          content_id: props.content?.id?.content_id ?? "",
                          content_name:
                            e ?? `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                        },
                      });
                    }}
                    onPopoverOpen={() => {
                      setIsBrandPopoverOpen(false);
                      setIsActionSheetBrandPopoverOpen(false);
                      setIsFilterSelectorPopoverOpen(false);
                      setIsActionSheetFilterPopoverOpen(false);
                    }}
                    isOuterPopoverOpen={
                      isBrandPopoverOpen ||
                      isActionSheetBrandPopoverOpen ||
                      isFilterSelectorPopoverOpen ||
                      isActionSheetFilterPopoverOpen
                    }
                  />
                </div>
              </div>
            </>
          )}
          <LoadingBarAnimation animating={props.animating} />
        </div>
      </div>
      <div className="h-[56px] min-w-full text-xl max-lg:flex lg:hidden flex-row justify-between items-center  bg-white -ml-[0px] border-1 border-t-0 border-border">
        <div className="lg:hidden max-lg:flex flex-row justify-center">
          <LoopDetailChannels
            isDisabled={false}
            loopChannels={props.loopChannels}
          />
        </div>
        <div className="flex flex-row">
          <div className={`flex-row justify-center sm:mt-0 px-2`}>
            <FilterSelector
              icon={SvgChannel}
              subTitle={t("filter-revisions")}
              Items={RevisionFilterItems}
              selectedItems={selectedRevisionFilters}
              tooltip={t("apply-filters-0") ?? ""}
              ItemsSelected={(selectedItems) => {
                props.filterItems(
                  selectedItems.map(
                    (item) => RevisionFilterItems.find((k) => k.id === item.id)!
                  )
                );
                setSelectedRevisionFilters(selectedItems);
              }}
              isPopoverOpen={isActionSheetFilterPopoverOpen}
              setIsFilterPopoverOpen={(isOpen) => {
                setIsActionSheetFilterPopoverOpen(isOpen);
                if (isOpen) {
                  setIsFilterSelectorPopoverOpen(false);
                  setIsBrandPopoverOpen(false);
                  setIsActionSheetBrandPopoverOpen(false);
                }
              }}
            />
          </div>

          {!props.content?.read_only && (
            <div className="flex-row justify-center sm:mt-0 px-2 border-l-1 border-border">
              <LoopDetailBrandSelector
                selectedBrandId={selectedBrandId}
                selectedBrand={selectedBrand}
                BrandSelected={(e) => {
                  showChangeBrandDialog(e);
                }}
                OnBrandEdit={(e) => {
                  showEditBrandDialog(e);
                }}
                tooltip={t("choose-brand-voice") ?? ""}
                isPopoverOpen={isActionSheetBrandPopoverOpen}
                setIsBrandPopoverOpen={(isOpen) => {
                  setIsActionSheetBrandPopoverOpen(isOpen);
                  if (isOpen) {
                    setIsBrandPopoverOpen(false);
                    setIsFilterSelectorPopoverOpen(false);
                    setIsActionSheetFilterPopoverOpen(false);
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LoopDetailsHeader;
