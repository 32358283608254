import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setDialog, toggleDialog, toggleLoading } from "../../../store/appSlice";
import SvgAddPlusIcon from "../../../assets/IconComponents/SvgAddPlus";
import { AudienceResponse, useDeleteAudienceMutation, useLazyGetAudienceQuery } from "../../../services/AudiencetApi";
import IconButton from "../../../components/IconButton";
import { Delete, Edit } from "../../../assets/IconComponents";
import BrandHubCreateNewAudience from "./BrandHubCreateNewAudience";
import PrimaryButton from "../../../components/PrimaryButton";
import { setNewBrandInfo } from "../../../store/brandSlice";

interface IBrandHubAudiencesProps {
  className?: string;
}

const BrandHubAudiences: FunctionComponent<IBrandHubAudiencesProps> = (
  props: IBrandHubAudiencesProps
) => {
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const dispatch = useAppDispatch();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedAudience, setSelectedAudience] = useState<AudienceResponse | undefined>(undefined);
  const [getAudiencesList, { isLoading: audiencesListLoading, data: audiencesListApi }] = useLazyGetAudienceQuery();
  const [
    deleteAudience,
    { isSuccess: audienceDeleted, isLoading: audienceDeleting, isError: audienceDeleteFailed },
  ] = useDeleteAudienceMutation();

  const [deletingAudienceId, setDeletingAudienceId] = useState<string>();


  useEffect(() => {
    if (brandInfo.id) {
      getAudiencesList({ brandId: brandInfo.id ?? "" });
    }
  }, []);


  useEffect(() => {
    dispatch(toggleLoading(audiencesListLoading || audienceDeleting));
  }, [audiencesListLoading, audienceDeleting]);

  useEffect(() => {
    if (audienceDeleted && deletingAudienceId && brandInfo.associatedAudiences) {
      const modifiedList = brandInfo.associatedAudiences.filter(a => a.audience_id !== deletingAudienceId)
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          associatedAudiences: modifiedList,
        })
      );
    }
  }, [audienceDeleted])

  useEffect(() => {
    setDeletingAudienceId(undefined);
  }, [audienceDeleteFailed])

  useEffect(() => {
    if (audiencesListApi) {
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          associatedAudiences: audiencesListApi,
        })
      );
    }
  }, [audiencesListApi]);

  useEffect(() => {
    if (brandInfo.associatedAudiences) {
      console.log("Associated Aud: ", brandInfo.associatedAudiences);
    }
  }, [brandInfo.associatedAudiences]);



  const deleteAudienceDialog = (audience) => {
    dispatch(
      setDialog({
        message: t("are-you-sure-you-want-to-delete-this-audience") ?? "",
        title: t("delete-audience"),
        actions: (
          <>
            <div className="flex w-full flex-col lg:flex-row lg:justify-end">
              <PrimaryButton
                className="h-10 lg:h-12 w-full lg:w-[140px]"
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                }}
              />
              <PrimaryButton
                className="h-10 lg:h-12 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                title={t("yes-delete")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  deleteAudience({
                    audienceId: audience.audience_id ?? "",
                  });
                }}
              />
            </div>
          </>
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );

    dispatch(toggleDialog(true));
  };
  return (
    <div className="flex flex-col w-full  mt-6">
      <div className="mb-2 mt-3  flex flex-row w-full items-center justify-between">
        <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
          {t("saved-audiences")}
        </span>
        <div
          className="flex flex-row w-fit cursor-pointer"
          onClick={() => {
            setIsCollapsed(false);
            setSelectedAudience(undefined);
          }}
        >
          <SvgAddPlusIcon />
          <span className="text-H8 text-red ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
            {t("create-new")}
          </span>
        </div>
      </div>

      <div
        className={`overflow-hidden transition-all duration-300 ${(!isCollapsed && !selectedAudience) ||
          brandInfo.associatedAudiences === undefined ||
          brandInfo.associatedAudiences.length === 0
          ? "h-[800px]"
          : "h-0"
          }`}
      >
        {((!isCollapsed && !selectedAudience) ||
          brandInfo.associatedAudiences === undefined ||
          brandInfo.associatedAudiences.length === 0) && (
            <BrandHubCreateNewAudience
              hideMetaAdsSettings
              onAudienceSaved={(audience) => { }}
              onClose={() => {
                setSelectedAudience(undefined);
                setIsCollapsed(true);
              }}
              audience={undefined}
            />
          )}
      </div>
      <div className="flex flex-col mt-2 gap-1">
        {brandInfo.associatedAudiences?.map((audience) => (
          <>
            {selectedAudience &&
              audience.audience_id === selectedAudience?.audience_id && (
                <BrandHubCreateNewAudience
                  hideMetaAdsSettings
                  onAudienceSaved={(audience) => { }}
                  onClose={() => {
                    setSelectedAudience(undefined);
                  }}
                  audience={selectedAudience}
                />
              )}
            {(!selectedAudience ||
              audience.audience_id !== selectedAudience?.audience_id) && (
                <div className="flex flex-row w-full h-10 bg-white border-1 rounded-[10px] items-center">
                  <span className="text-H7 text-black ml-4 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                    {audience.name ?? ""}
                  </span>
                  <div className="flex flex-row md:mr-3">
                    <div className="ml-3 mr-3">
                      <IconButton
                        className="hover:w-16"
                        title={t("edit")}
                        icon={Edit}
                        onClick={(e) => {
                          setSelectedAudience(audience);
                        }}
                      />
                    </div>
                    <div className="md:mr-5">
                      <IconButton
                        className="hover:w-20"
                        title={t("delete")}
                        icon={Delete}
                        onClick={(e) => {
                          setDeletingAudienceId(audience.audience_id);
                          deleteAudienceDialog(audience);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
          </>
        ))}
      </div>
    </div>
  );
};

export default BrandHubAudiences;
