import * as React from "react";
import type { SVGProps } from "react";
const SvgPlacementIcon = (props: SVGProps<SVGSVGElement>) => (


<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555"/>
<path opacity="0.2" d="M12.6673 15.833H8.00065C7.9131 15.833 7.8264 15.8158 7.7455 15.7823C7.66461 15.7488 7.59111 15.6997 7.5292 15.6378C7.46729 15.5759 7.41818 15.5024 7.38469 15.4215C7.35119 15.3406 7.33396 15.2539 7.33398 15.1663V8.49967C7.33396 8.41212 7.35119 8.32542 7.38469 8.24453C7.41818 8.16363 7.46729 8.09013 7.5292 8.02822C7.59111 7.96631 7.66461 7.9172 7.7455 7.88371C7.8264 7.85021 7.9131 7.83299 8.00065 7.83301H12.6673C12.7549 7.83299 12.8416 7.85021 12.9225 7.88371C13.0034 7.9172 13.0769 7.96631 13.1388 8.02822C13.2007 8.09013 13.2498 8.16363 13.2833 8.24453C13.3168 8.32542 13.334 8.41212 13.334 8.49967V15.1663C13.334 15.2539 13.3168 15.3406 13.2833 15.4215C13.2498 15.5024 13.2007 15.5759 13.1388 15.6378C13.0769 15.6997 13.0034 15.7488 12.9225 15.7823C12.8416 15.8158 12.7549 15.833 12.6673 15.833Z" fill="#FF5555"/>
<path d="M12.6673 21.167H8.00065C7.9131 21.167 7.8264 21.1498 7.7455 21.1163C7.66461 21.0828 7.59111 21.0337 7.5292 20.9718C7.46729 20.9099 7.41818 20.8364 7.38469 20.7555C7.35119 20.6746 7.33396 20.5879 7.33398 20.5003V17.8337C7.33396 17.7461 7.35119 17.6594 7.38469 17.5785C7.41818 17.4976 7.46729 17.4241 7.5292 17.3622C7.59111 17.3003 7.66461 17.2512 7.7455 17.2177C7.8264 17.1842 7.9131 17.167 8.00065 17.167H12.6673C12.7549 17.167 12.8416 17.1842 12.9225 17.2177C13.0034 17.2512 13.0769 17.3003 13.1388 17.3622C13.2007 17.4241 13.2498 17.4976 13.2833 17.5785C13.3168 17.6594 13.334 17.7461 13.334 17.8337V20.5003C13.334 20.5879 13.3168 20.6746 13.2833 20.7555C13.2498 20.8364 13.2007 20.9099 13.1388 20.9718C13.0769 21.0337 13.0034 21.0828 12.9225 21.1163C12.8416 21.1498 12.7549 21.167 12.6673 21.167Z" fill="#FF5555"/>
<path opacity="0.2" d="M19.9993 21.1667H15.3327C15.2451 21.1667 15.1584 21.1495 15.0775 21.116C14.9966 21.0825 14.9231 21.0334 14.8612 20.9715C14.7993 20.9095 14.7502 20.836 14.7167 20.7551C14.6832 20.6743 14.666 20.5876 14.666 20.5V15.1667C14.666 15.0791 14.6832 14.9924 14.7167 14.9115C14.7502 14.8306 14.7993 14.7571 14.8612 14.6952C14.9231 14.6333 14.9966 14.5842 15.0775 14.5507C15.1584 14.5172 15.2451 14.5 15.3327 14.5H19.9993C20.0869 14.5 20.1736 14.5172 20.2545 14.5507C20.3354 14.5842 20.4089 14.6333 20.4708 14.6952C20.5327 14.7571 20.5818 14.8306 20.6153 14.9115C20.6488 14.9924 20.666 15.0791 20.666 15.1667V20.5C20.666 20.5876 20.6488 20.6743 20.6153 20.7551C20.5818 20.836 20.5327 20.9095 20.4708 20.9715C20.4089 21.0334 20.3354 21.0825 20.2545 21.116C20.1736 21.1495 20.0869 21.1667 19.9993 21.1667Z" fill="#FF5555"/>
<path opacity="0.5" d="M19.9993 13.1663H15.3327C15.2451 13.1664 15.1584 13.1491 15.0775 13.1156C14.9966 13.0821 14.9231 13.033 14.8612 12.9711C14.7993 12.9092 14.7502 12.8357 14.7167 12.7548C14.6832 12.6739 14.666 12.5872 14.666 12.4997V8.49967C14.666 8.41212 14.6832 8.32542 14.7167 8.24453C14.7502 8.16363 14.7993 8.09013 14.8612 8.02822C14.9231 7.96631 14.9966 7.9172 15.0775 7.88371C15.1584 7.85021 15.2451 7.83299 15.3327 7.83301H19.9993C20.0869 7.83299 20.1736 7.85021 20.2545 7.88371C20.3354 7.9172 20.4089 7.96631 20.4708 8.02822C20.5327 8.09013 20.5818 8.16363 20.6153 8.24453C20.6488 8.32542 20.666 8.41212 20.666 8.49967V12.4997C20.666 12.5872 20.6488 12.6739 20.6153 12.7548C20.5818 12.8357 20.5327 12.9092 20.4708 12.9711C20.4089 13.033 20.3354 13.0821 20.2545 13.1156C20.1736 13.1491 20.0869 13.1664 19.9993 13.1663Z" fill="#FF5555"/>
</svg>

);
export default SvgPlacementIcon;
