import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrandRequest } from "../../services/BrandApi";
import { useDispatch } from "react-redux";
import MenuBrandSelectorItem from "./MenuBrandSelectorItem";
import { setSelectedBrandInfo } from "../../store/brandSlice";
import { setActiveItem, setActivePage } from "../../store/appSlice";


export interface IMenuBrandSelectorPopupProps {
  data: BrandRequest[];
  closePopup: () => void;
  selectedBrand: BrandRequest | undefined;
  selectedBrandChanged: (data: BrandRequest | undefined) => void;
}

function MenuBrandSelectorPopup(props: IMenuBrandSelectorPopupProps) {
  const [selectedItem, setSelectedItem] = useState<BrandRequest>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedItem(props.selectedBrand);
  }, [props.selectedBrand]);

  return (
    <div className="flex flex-col w-52 bg-menuBg border border-border rounded-xl h-52">
      <div className="p-2 gap-[12px] h-full overflow-scroll scrollbar-hide">
        <div className="flex flex-col gap-[5px]">
          {props.data.map((k) => (
            <MenuBrandSelectorItem
              data={k}
              selected={selectedItem?.id === k.id}
              itemSelected={(e) => {
                dispatch(setSelectedBrandInfo(e))
                props.selectedBrandChanged(e);
                props.closePopup();
              }}
            />
          ))}
        </div>
      </div>
      <div className="w-full flex justify-center items-center py-4" onClick={() => {
        dispatch(setActivePage("brandhub"));
        dispatch(setActiveItem(""));
        navigate(`brandhub`);
        props.closePopup();
      }}>
        <text className="text-BodyMedium text-red underline items-center  cursor-pointer">
          {t("go-to-brandhub")}
        </text>
      </div>
    </div>
  );
}

export default MenuBrandSelectorPopup;
