import * as React from "react";
import type { SVGProps } from "react";
const SvgContentMenuCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" fill="#121315" />
    <path
      d="M19.9902 11.6577C19.8245 11.6603 19.6667 11.7285 19.5514 11.8474C19.436 11.9663 19.3725 12.126 19.3749 12.2917V12.9167H16.4583C15.4245 12.9167 14.5833 13.7579 14.5833 14.7917V18.5417C14.5833 19.5754 15.4245 20.4167 16.4583 20.4167H23.5416C24.5753 20.4167 25.4166 19.5754 25.4166 18.5417V14.7917C25.4166 13.7579 24.5753 12.9167 23.5416 12.9167H20.6249V12.2917C20.6261 12.208 20.6105 12.125 20.579 12.0475C20.5475 11.97 20.5008 11.8996 20.4416 11.8404C20.3824 11.7813 20.312 11.7347 20.2344 11.7033C20.1569 11.6719 20.0738 11.6564 19.9902 11.6577ZM18.1249 15.4167C18.7003 15.4167 19.1666 15.8829 19.1666 16.4583C19.1666 17.0338 18.7003 17.5 18.1249 17.5C17.5495 17.5 17.0833 17.0338 17.0833 16.4583C17.0833 15.8829 17.5495 15.4167 18.1249 15.4167ZM21.8749 15.4167C22.4503 15.4167 22.9166 15.8829 22.9166 16.4583C22.9166 17.0338 22.4503 17.5 21.8749 17.5C21.2995 17.5 20.8333 17.0338 20.8333 16.4583C20.8333 15.8829 21.2995 15.4167 21.8749 15.4167ZM15.2083 21.6667C14.1745 21.6667 13.3333 22.5079 13.3333 23.5417V23.859C13.3333 24.8403 13.7414 25.7828 14.453 26.4453C15.3776 27.3066 17.0903 28.3333 19.9999 28.3333C22.9095 28.3333 24.6226 27.3066 25.5468 26.4453C26.2589 25.7828 26.6666 24.8399 26.6666 23.859V23.5417C26.6666 22.5079 25.8253 21.6667 24.7916 21.6667H15.2083Z"
      fill={props.fill ?? "#8E8E8E"}
    />
  </svg>
);
export default SvgContentMenuCollapsedIcon;

