import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    findByContentId2: build.query<
      FindByContentId2ApiResponse,
      FindByContentId2ApiArg
    >({
      query: (queryArg) => ({ url: `/configuration/list/${queryArg["type"]}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type FindByContentId2ApiResponse =
  /** status 200 OK */ ConfigurationDto[];
export type FindByContentId2ApiArg = {
  type: string;
};
export type JsonNode = object;
export type ConfigurationDto = {
  type?: string;
  id?: string;
  data?: JsonNode;
};
export const { useFindByContentId2Query, useLazyFindByContentId2Query } =
  injectedRtkApi;
