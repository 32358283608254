import * as React from "react";
import type { SVGProps } from "react";
const SvgThumbsUpIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="thumbs-up 1">
            <g id="Mask group">
                <g id="Group">
                    <g id="Group_2">
                        <path id="Vector" opacity="0.5" d="M16.1 10.85V6.65C16.1 6.23634 16.0185 5.82672 15.8602 5.44455C15.7019 5.06237 15.4699 4.71512 15.1774 4.42261C14.8849 4.13011 14.5376 3.89808 14.1555 3.73978C13.7733 3.58148 13.3637 3.5 12.95 3.5L8.75 12.95V24.5H20.951C21.4574 24.5057 21.9489 24.3282 22.3348 24.0002C22.7207 23.6722 22.9751 23.2158 23.051 22.715L24.5 13.265C24.5457 12.964 24.5254 12.6567 24.4405 12.3644C24.3556 12.072 24.2082 11.8016 24.0085 11.5719C23.8087 11.3422 23.5614 11.1586 23.2837 11.034C23.0059 10.9093 22.7044 10.8466 22.4 10.85H16.1Z" fill="#FF5555" />
                        <path id="Union" d="M8.75 12.8333H5.9465C4.71625 12.8118 3.66508 13.8308 3.5 15.05V22.4C3.57967 22.989 3.87282 23.5282 4.32382 23.9154C4.77481 24.3025 5.35224 24.5105 5.9465 24.5H8.75V12.8333Z" fill="#FF5555" />
                    </g>
                </g>
            </g>
        </g>
    </svg>

);
export default SvgThumbsUpIcon;
