import LogoDark from '../assets/icons/logo-dark.svg';
import LogoLight from '../assets/icons/logo-light.svg';
import Icon from './Icon';

export enum LogoType {
    dark,
    light
}

export interface LogoProps {
    type: LogoType
    className?: string
}

function Logo(props: LogoProps) {
    const { type, className } = props
    return (<>
        {
            type == LogoType.dark ?
                <Icon data={LogoDark} className={className} /> :
                <Icon data={LogoLight}  className={className} />
        }
    </>);
}

export default Logo;