import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M5.072 15c-1.435 0-2.64-.48-3.612-1.438C.487 12.603 0 11.417 0 10.003c0-1.413.487-2.6 1.46-3.562C2.433 5.481 3.637 5 5.072 5h3.283a.81.81 0 0 1 .597.242c.16.162.242.36.242.595 0 .234-.081.43-.242.586a.823.823 0 0 1-.597.236H5.072c-.973 0-1.782.317-2.427.953-.645.635-.967 1.432-.967 2.39 0 .96.322 1.757.967 2.392.645.636 1.454.953 2.427.953h3.283c.236 0 .435.08.597.24a.8.8 0 0 1 .242.59c0 .235-.081.431-.242.588a.823.823 0 0 1-.597.235H5.072Zm1.782-4.235a.755.755 0 0 1-.554-.218.74.74 0 0 1-.22-.548c0-.22.073-.402.22-.545a.763.763 0 0 1 .554-.214h6.286c.223 0 .407.073.554.218a.74.74 0 0 1 .22.55c0 .22-.073.401-.22.544a.763.763 0 0 1-.554.213H6.854ZM11.645 15a.822.822 0 0 1-.845-.83c0-.235.081-.43.242-.588a.83.83 0 0 1 .603-.235h3.277c.973 0 1.782-.317 2.427-.953.645-.635.967-1.432.967-2.391 0-.959-.322-1.756-.967-2.391-.645-.636-1.454-.953-2.427-.953h-3.277a.822.822 0 0 1-.845-.831c0-.235.081-.431.242-.59A.825.825 0 0 1 11.645 5h3.277c1.435 0 2.64.48 3.615 1.44C19.512 7.403 20 8.59 20 10.003c0 1.414-.488 2.6-1.463 3.56-.975.959-2.18 1.438-3.615 1.438h-3.277Z"
    />
  </svg>
);
export default SvgLinkIcon;
