
import type { SVGProps } from "react";
const SvgTranslate = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        {...props}
    >
        <path id="Vector" d="M9.4375 11.8438L8.89062 13.3594C8.84896 13.474 8.77604 13.5677 8.67188 13.6406C8.56771 13.7135 8.45312 13.75 8.32812 13.75C8.11979 13.75 7.95052 13.6641 7.82031 13.4922C7.6901 13.3203 7.66667 13.1302 7.75 12.9219L10.125 6.64062C10.1771 6.52604 10.2552 6.43229 10.3594 6.35938C10.4635 6.28646 10.5781 6.25 10.7031 6.25H11.1719C11.2969 6.25 11.4115 6.28646 11.5156 6.35938C11.6198 6.43229 11.6979 6.52604 11.75 6.64062L14.125 12.9375C14.2083 13.1354 14.1875 13.3203 14.0625 13.4922C13.9375 13.6641 13.7708 13.75 13.5625 13.75C13.4271 13.75 13.3099 13.7109 13.2109 13.6328C13.112 13.5547 13.0365 13.4531 12.9844 13.3281L12.4688 11.8438H9.4375ZM9.8125 10.75H12.0625L10.9688 7.65625H10.9062L9.8125 10.75ZM5.625 8.75L2.9375 11.4375C2.82292 11.5521 2.67708 11.6094 2.5 11.6094C2.32292 11.6094 2.17708 11.5521 2.0625 11.4375C1.94792 11.3229 1.89062 11.1771 1.89062 11C1.89062 10.8229 1.94792 10.6771 2.0625 10.5625L4.78125 7.84375C4.38542 7.40625 4.03906 6.95312 3.74219 6.48438C3.44531 6.01562 3.1875 5.52083 2.96875 5H4.28125C4.46875 5.375 4.66927 5.71354 4.88281 6.01562C5.09635 6.31771 5.35417 6.63542 5.65625 6.96875C6.11458 6.46875 6.49479 5.95573 6.79688 5.42969C7.09896 4.90365 7.35417 4.34375 7.5625 3.75H1.25C1.07292 3.75 0.924479 3.6901 0.804688 3.57031C0.684896 3.45052 0.625 3.30208 0.625 3.125C0.625 2.94792 0.684896 2.79948 0.804688 2.67969C0.924479 2.5599 1.07292 2.5 1.25 2.5H5V1.875C5 1.69792 5.0599 1.54948 5.17969 1.42969C5.29948 1.3099 5.44792 1.25 5.625 1.25C5.80208 1.25 5.95052 1.3099 6.07031 1.42969C6.1901 1.54948 6.25 1.69792 6.25 1.875V2.5H10C10.1771 2.5 10.3255 2.5599 10.4453 2.67969C10.5651 2.79948 10.625 2.94792 10.625 3.125C10.625 3.30208 10.5651 3.45052 10.4453 3.57031C10.3255 3.6901 10.1771 3.75 10 3.75H8.8125C8.59375 4.48958 8.29688 5.20833 7.92188 5.90625C7.54688 6.60417 7.08333 7.26042 6.53125 7.875L8.03125 9.40625L7.5625 10.6875L5.625 8.75Z" fill="#8E8E8E" />
    </svg>
);
export default SvgTranslate;
