import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { Liked, Like } from "../../../assets/IconComponents";
import Icon from "../../../components/Icon";
import IconButton from "../../../components/IconButton";
import PrimaryButton from "../../../components/PrimaryButton";
import { newContent } from "../../../store/contentSlice";
import { Mediums } from "../../../constants/Mediums";
import { useEditNameMutation } from "../../../services/ContentApi.old/ContentApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentModel } from "../../../services/ContentApi.old/Model/ContentModel";
import { Content, useFavorite1Mutation, useUpdateNameMutation } from "../../../services/ContentApi";
import { updateMenu } from "../../../store/appSlice";

interface LoopDetailHeaderContentNameProps {
    content?: Content
}

const LoopDetailHeaderContentName: FunctionComponent<LoopDetailHeaderContentNameProps> = (props: LoopDetailHeaderContentNameProps) => {
    const [isFavori, setFavori] = useState(false)
    const [editName, { isSuccess: nameUpdated }] = useUpdateNameMutation()
    const [updateFav, { isSuccess: favUpdated }] = useFavorite1Mutation()
    const [editing, setEditing] = useState(false)
    const [focused, setFocused] = useState(false)
    const [newName, setNewName] = useState("")
    const newContent = useAppSelector(k => k.content.newContent)
    const dispatch = useAppDispatch()
    const isContentLoading = useAppSelector(k => k.content.isContentLoading)

    useEffect(() => {
        if (nameUpdated) {
            dispatch(updateMenu())
        }
    }, [nameUpdated])

    useEffect(() => {
        if (props.content) {
            setNewName(props.content.name ?? "")
            setFavori(props.content.is_favorite ?? false)
        }
    }, [props.content])


    const updateName = () => {
        editName({
            contentUpdateRequest: {
                content_id: props.content?.id?.content_id ?? "",
                updated_name: newName
            }
        })
    }

    const getMediaIcon = (mediaName: string) => {
        const platformName = mediaName.split(" | ")[0]
        const icon = Mediums.find(k => k.name === platformName)?.iconColor
        return icon
    }

    const updateFavorite = () => {
        updateFav({
            contentId: props.content?.id?.content_id ?? "",
            isFavorite: !isFavori
        })
        setFavori(!isFavori);
    }


    return (
        <>
            {
                <div className="flex flex-row items-center">
                    <Icon component={getMediaIcon(props.content?.platform ?? "")} width="20" height="20" className="ml-[20px] mr-2" />
                    <input className={`text-H6 text-black h-[30px] w-[150px]  border-1 ${editing ? " border-border" : "border-white"} rounded-[4px] outline-none p-2`}
                        
                        onMouseEnter={() => { (!isContentLoading && !props.content?.read_only) && setEditing(true) }}
                        defaultValue={newContent?.loopName}

                        ref={input => {
                            if (input && editing) {
                                if (!focused) {
                                    input.focus()
                                    // input.setSelectionRange(0, 0)
                                    setFocused(true)
                                }
                            }
                            else if (input && !editing) {
                                input.blur()
                            }
                        }}
                        onChange={(e) => {
                            setNewName(e.target.value)
                        }}
                        onBlur={() => {
                            setEditing(false)
                            setFocused(false)
                            if (props.content?.name !== newName) {
                                updateName()
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                setEditing(false)
                                setFocused(false)

                                if (props.content?.name !== newName) {
                                    updateName()
                                }
                            }
                        }}
                        value={newName}
                        data-tooltip-id="app-tooltip"
                        data-tooltip-html={!props.content?.read_only ? t('edit-title') : undefined}
                    />
                    {(!isContentLoading && !props.content?.read_only) &&
                        <IconButton className="hover:w-20 ml-[5px]"
                            title={""}
                            color={isFavori ? "red" : "gray"}
                            icon={isFavori ? Liked : Like}
                            tooltip={t('add-to-favorites') ?? ""}
                            onClick={(e) => {
                                e.stopPropagation()
                                updateFavorite()
                            }} />
                    }
                </div>
            }
        </>
    );
}

export default LoopDetailHeaderContentName;
