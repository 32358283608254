import { SVGProps } from "react";
import Icon from "../Icon";
import SvgRadioSelected from "../../assets/IconComponents/RadioSelected";
import SvgRadioUnselected from "../../assets/IconComponents/RadioUnselected";
import { IGenericSelectorData } from "./GenericSelector";


export interface IGenericSelectorItemProps {
    data: IGenericSelectorData
    selected: boolean
    itemSelected: (data: IGenericSelectorData) => void
}

function GenericSelectorItem(props: IGenericSelectorItemProps) {
    const { selected = false } = props

    return (
        <div className={`h-[42px] mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center p-2 justify-between
        ${selected ? "bg-lightBg border-1 border-border rounded-[5px]" : ""}
        `}
            onClick={() => {
                props.itemSelected(props.data)
            }}
        >
            <div className="flex flex-row items-center">
                <text className="text-BodyMedium text-black ml-2">{props.data.title}</text>
            </div>
            {selected &&
                <SvgRadioSelected />
            }
            {!selected &&
                <SvgRadioUnselected />
            }
        </div>
    );
}

export default GenericSelectorItem;