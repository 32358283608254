import GetStartedFreeBig from "./GetStartedFreeBig";
import "./Styles/Main.css"
import SupportedMedia from "./SupportedMedia";

function Main() {
    return (<>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center">
            <h1
                className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-100 sm:text-7xl relative"
            >
                The Content You Need Without The Hustle
                <div className="text-4xl text-red-200">10x Faster, 10x Better</div>
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-300">
                Use AI as your creative engine to generate the top-performing marketing contents tailored for
                your brand.
            </p>
            <div className="mt-10 flex justify-center gap-x-6">
                <GetStartedFreeBig />
            </div>
        </div>

        <SupportedMedia className="mt-20 lg:mt-30" />
    </>);
}

export default Main;