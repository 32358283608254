import * as React from "react";
const Rocket = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        {...props}
    >
      <path
          d="M17.9872 15.1207L15.5612 17.3507C14.3136 18.4383 12.8478 19.2464 11.2622 19.7207L11.9062 22.7247C11.9391 22.878 12.0076 23.0214 12.1062 23.1434C12.2047 23.2653 12.3306 23.3623 12.4736 23.4267C12.6165 23.491 12.7726 23.5208 12.9293 23.5137C13.0859 23.5066 13.2386 23.4628 13.3752 23.3857L17.2802 21.1837C17.8299 20.8498 18.2593 20.3498 18.5065 19.756C18.7536 19.1621 18.8057 18.5051 18.6552 17.8797L17.9872 15.1207ZM7.51619 9.27474L9.60419 6.79474L7.00019 6.16674C6.36694 5.94579 5.67513 5.96148 5.05255 6.21092C4.42997 6.46035 3.9187 6.92668 3.61319 7.52374L1.41319 11.4237C1.33616 11.5603 1.29232 11.7131 1.28521 11.8697C1.2781 12.0263 1.30791 12.1824 1.37225 12.3254C1.43658 12.4683 1.53363 12.5942 1.65557 12.6928C1.7775 12.7913 1.9209 12.8598 2.07419 12.8927L5.14719 13.5517C5.6567 11.988 6.46083 10.5362 7.51619 9.27474ZM16.9842 9.31474C17.2809 9.31474 17.5709 9.22676 17.8175 9.06194C18.0642 8.89712 18.2565 8.66285 18.37 8.38876C18.4835 8.11467 18.5132 7.81307 18.4554 7.5221C18.3975 7.23113 18.2546 6.96386 18.0448 6.75408C17.8351 6.5443 17.5678 6.40144 17.2768 6.34356C16.9859 6.28568 16.6843 6.31539 16.4102 6.42892C16.1361 6.54245 15.9018 6.73471 15.737 6.98138C15.5722 7.22806 15.4842 7.51807 15.4842 7.81474C15.4842 8.21256 15.6422 8.59409 15.9235 8.8754C16.2048 9.1567 16.5864 9.31474 16.9842 9.31474Z"
          fill={props.fill ?? "#FFFFFF"}/>
      <path opacity="0.5"
            d="M22.8505 2.56286C22.8055 2.39151 22.7158 2.2352 22.5905 2.10992C22.4652 1.98464 22.3089 1.89489 22.1375 1.84986C20.0863 1.31684 17.9262 1.37413 15.9062 2.01511C13.8861 2.65609 12.0882 3.8547 10.7195 5.47286L7.51553 9.27686C5.91522 11.1854 4.8999 13.5153 4.59153 15.9869C4.57157 16.1388 4.58679 16.2933 4.63604 16.4384C4.68528 16.5836 4.76722 16.7154 4.87553 16.8239L7.97553 19.9239C8.06848 20.0168 8.17886 20.0906 8.30035 20.1409C8.42184 20.1911 8.55205 20.217 8.68353 20.2169C8.71224 20.217 8.74095 20.2157 8.76953 20.2129C11.2793 20.0054 13.6577 19.0036 15.5595 17.3529L19.2235 13.9849C20.8473 12.6196 22.0501 10.8214 22.692 8.79939C23.3339 6.77739 23.3895 4.6147 22.8505 2.56286ZM16.9835 9.31686C16.6869 9.31686 16.3968 9.22889 16.1502 9.06407C15.9035 8.89925 15.7112 8.66498 15.5977 8.39089C15.4842 8.1168 15.4545 7.8152 15.5124 7.52423C15.5702 7.23326 15.7131 6.96598 15.9229 6.7562C16.1326 6.54642 16.3999 6.40356 16.6909 6.34569C16.9819 6.28781 17.2835 6.31751 17.5576 6.43104C17.8316 6.54458 18.0659 6.73683 18.2307 6.98351C18.3956 7.23018 18.4835 7.52019 18.4835 7.81686C18.4835 8.21469 18.3255 8.59622 18.0442 8.87752C17.7629 9.15883 17.3814 9.31686 16.9835 9.31686Z"
            fill="#8E8E8E"/>
    </svg>
);
export default Rocket;
