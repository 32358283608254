import type { SVGProps } from "react";
const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        {...props}
    >
        <path
            id="Vector" d="M7.5 12.0938C8.77083 10.9271 9.71354 9.86719 10.3281 8.91406C10.9427 7.96094 11.25 7.11458 11.25 6.375C11.25 5.23958 10.888 4.3099 10.1641 3.58594C9.4401 2.86198 8.55208 2.5 7.5 2.5C6.44792 2.5 5.5599 2.86198 4.83594 3.58594C4.11198 4.3099 3.75 5.23958 3.75 6.375C3.75 7.11458 4.05729 7.96094 4.67188 8.91406C5.28646 9.86719 6.22917 10.9271 7.5 12.0938ZM7.5 13.3281C7.35417 13.3281 7.20833 13.3021 7.0625 13.25C6.91667 13.1979 6.78646 13.1198 6.67188 13.0156C5.99479 12.3906 5.39583 11.7813 4.875 11.1875C4.35417 10.5938 3.91927 10.0182 3.57031 9.46094C3.22135 8.90365 2.95573 8.36719 2.77344 7.85156C2.59115 7.33594 2.5 6.84375 2.5 6.375C2.5 4.8125 3.0026 3.56771 4.00781 2.64063C5.01302 1.71354 6.17708 1.25 7.5 1.25C8.82292 1.25 9.98698 1.71354 10.9922 2.64063C11.9974 3.56771 12.5 4.8125 12.5 6.375C12.5 6.84375 12.4089 7.33594 12.2266 7.85156C12.0443 8.36719 11.7786 8.90365 11.4297 9.46094C11.0807 10.0182 10.6458 10.5938 10.125 11.1875C9.60417 11.7813 9.00521 12.3906 8.32812 13.0156C8.21354 13.1198 8.08333 13.1979 7.9375 13.25C7.79167 13.3021 7.64583 13.3281 7.5 13.3281ZM7.5 7.5C7.84375 7.5 8.13802 7.3776 8.38281 7.13281C8.6276 6.88802 8.75 6.59375 8.75 6.25C8.75 5.90625 8.6276 5.61198 8.38281 5.36719C8.13802 5.1224 7.84375 5 7.5 5C7.15625 5 6.86198 5.1224 6.61719 5.36719C6.3724 5.61198 6.25 5.90625 6.25 6.25C6.25 6.59375 6.3724 6.88802 6.61719 7.13281C6.86198 7.3776 7.15625 7.5 7.5 7.5Z" fill="#8E8E8E" />

    </svg>
);
export default SvgLocation;
