import CreatePresetContentCard from "./CreatePresetContentCard";
import { LoopsResponse, useGenerateContentFromRevisionMutation, useLazyLoopsQuery } from "../../services/ContentApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNewContent } from "../../store/contentSlice";
import { useAppSelector } from "../../helpers/hooks";
import { ContentType } from "../../constants/ContentType";
import { ContentWizardView } from "../../models/ContentWizardView";
import { Mediums } from "../../constants/Mediums";
import moment from "moment";
import { toggleContentWizard, toggleLoading } from "../../store/appSlice";
import { INewContent } from "../../models/NewContent";
import InspirationsForQuickstartGrid from "./InspirationsForQuickstartGrid";
import { InspirationResponse, useLazyGetTextInspirationQuery } from "../../services/InspirationApi";
import { useNavigate } from "react-router-dom";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import { Carousel } from "antd";
import { useWindowSize } from "@uidotdev/usehooks";
function ContentTextPresetsPage(props) {
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);
  const [items, setItems] = useState<LoopsResponse[]>([]);
  const [
    getContentList,
    { isLoading: contenListLoading, error, data: contentList },
  ] = useLazyLoopsQuery();
  const [
    generateContentFromRevision,
    { isLoading: generatingContentFromRevision, isSuccess: successGeneratingContentFromRevision },
  ] = useGenerateContentFromRevisionMutation();
  const dispatch = useDispatch();
  const [inspirations, setInspirations] = useState<InspirationResponse[]>([]);

  const [textInspirationPage, setTextInspirationPage] = useState<number>(1);
  const size = useWindowSize();

  const navigate = useNavigate();
  useEffect(() => {
    getInspirations({
      page: 0,
      size: PAGE_SIZE,
    });
  }, []);

  const [
    getInspirations,
    {
      isLoading: inspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetTextInspirationQuery();

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching || inspirationsLoading));
  }, [inspirationsLoading, inspirationsFetching])


  useEffect(() => {
    if (inspirationsList) {
      if (inspirationsList && inspirationsList.length > 0) {
        setInspirations(inspirationsList);
      }
      if (inspirationsList?.length === 0 || inspirationsList?.length < PAGE_SIZE) {
        setTextInspirationPage(0);
      }
    }
  }, [inspirationsList]);


  useEffect(() => {
    dispatch(toggleLoading(generatingContentFromRevision));
  }, [generatingContentFromRevision]);

  const PAGE_SIZE = 10;

  const isMobile = () => {
    return size.width && size.width <= 500;
  };

  return (
    <div className="flex-col  w-full h-full mt-2">
      <div className="flex-col w-[1050px]">
        <Carousel
          slidesToShow={isMobile() ? 1 : 6}
          nextArrow={<CustomRightArrow />}
          prevArrow={<CustomLeftArrow />}
          dots={false}
          arrows={true}
        >
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="X (Twitter) Post"
            sampleImagePath="content-x-post-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  )?.options?.at(1),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="X (Twitter) Thread"
            sampleImagePath="content-x-thread-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "LinkedIn" &&
                      k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "LinkedIn" &&
                      k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Linkedin Post"
            sampleImagePath="content-linkedin-post-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Blog" && k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Blog" && k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Blog"
            sampleImagePath="create-blog-sample.png"
          />

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Instagram" &&
                      k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Instagram" &&
                      k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Instagram Post"
            sampleImagePath="content-instagram-template-post-sample.png"
          />

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Pinterest" &&
                      k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Pinterest" &&
                      k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Pinterest Post"
            sampleImagePath="content-post-pinterest-sample.png"
          />

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Facebook" &&
                      k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Facebook" &&
                      k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Facebook Post"
            sampleImagePath="content-post-sample-facebook.png"
          />
        </Carousel>
      </div>

      {inspirations?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              Inspirations For Quick Start
            </span>
            <hr className="bg-border mt-1" />
          </div>
          <InspirationsForQuickstartGrid
            onRefresh={() => {
              getInspirations({
                page: textInspirationPage,
                size: PAGE_SIZE,
              });
              setTextInspirationPage(textInspirationPage + 1)
            }}
            onClick={(revisionId) => {
              generateContentFromRevision({ revisionId: revisionId })
                .unwrap()
                .then((k) => {
                  const contentId = k.content_id ?? "";
                  navigate(`/app/loopdetails/` + contentId);
                });
            }}
            inspirations={inspirations}
          />
        </>
      )}
    </div>
  );
}

export default ContentTextPresetsPage;
