import { t } from 'i18next'
import { Plan } from '../../../services/PlanApi'
interface IPlanBenefitGroupHeading {
    groupId: string
    plans: Plan[]
}

function PlanBenefitGroupHeading(props: IPlanBenefitGroupHeading) {
    return (
        <div className="w-full h-[70px] justify-start items-start inline-flex">
            <div className="w-[360px] h-[70px] px-2.5 pt-[30px] pb-[15px] justify-start items-start gap-2.5 inline-flex">
                <div className="text-black text-lg font-semibold font-['Inter Tight'] leading-[25.20px]">{t(props.groupId)}</div>
            </div>
            {
                props.plans.map(plan =>
                    <div key={`${props.groupId}_group_id_heading_${plan.id}`} className={`grow shrink basis-0 h-[70px] px-2.5 py-[15px] justify-center items-center gap-2.5 flex ${plan.mostPopular ? "border-l-1 border-l-rose-400 border-r-1 border-r-rose-400" : ""}`}>
                    </div>
                )
            }

        </div >
    )
}

export default PlanBenefitGroupHeading