import * as React from "react";
import type { SVGProps } from "react";
const SvgTwitterColor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icons=Twitter">
      <path id="Vector" d="M11.3174 8.77491L17.1457 2H15.7646L10.7039 7.88256L6.66193 2H2L8.11224 10.8955L2 18H3.38119L8.72542 11.7878L12.994 18H17.656L11.3171 8.77491H11.3174ZM9.42569 10.9738L8.80639 10.0881L3.87886 3.03974H6.00029L9.97687 8.72795L10.5962 9.61374L15.7652 17.0075H13.6438L9.42569 10.9742V10.9738Z" fill="#121315" />
    </g>
  </svg>

);
export default SvgTwitterColor;
