import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Liked, Like, Copy, Delete } from "../../assets/IconComponents";
import ContentContainer from "../../components/ContentContainer";
import CopyLoop from "../../components/CopyLoop";
import Icon from "../../components/Icon";
import IconButton from "../../components/IconButton";
import PrimaryButton from "../../components/PrimaryButton";
import { Mediums } from "../../constants/Mediums";
import { useAppDispatch } from "../../helpers/hooks";
import { Content, LoopsResponse, Revision } from "../../services/ContentApi";
import { useEditNameMutation, useDeleteContentMutation } from "../../services/ContentApi.old/ContentApi";
import { useFavoriteImageMutation } from "../../services/ImageApi.old/ImageApi";
import { useEditTextMutation, useFavoriteMutation } from "../../services/RevisionApi";
import { setDialog, toggleDialog } from "../../store/appSlice";
import { SelectedLoopsPageListType } from "../Loops/LoopsPageListTypeSelector";
import SvgDefaultBrandLogo from "../../assets/IconComponents/DefaultBrandLogo";
import SvgShare from "../../assets/IconComponents/Share";
import SvgLinkIcon from "../../assets/IconComponents/LinkIcon";
import SvgLike from "../../assets/IconComponents/Like";
import SvgRetweetIcon from "../../assets/IconComponents/RetweetIcon";
import SvgViewIcon from "../../assets/IconComponents/ViewIcon";
import SvgGenerateSimilarIcon from "../../assets/IconComponents/GenerateSimilarIcon";
import moment from "moment";
import BrandLogo from "../../components/BrandLogo";
import { useCopyToClipboard } from "@uidotdev/usehooks";

interface ContentInspirationCardProps {
    loop: any;
    updateLoops: () => void
    onSelect: (contentId: string) => void
    generateSimilar: () => void
}

const ContentInspirationCard: FunctionComponent<ContentInspirationCardProps> = (props: ContentInspirationCardProps) => {
    const [hover, setHover] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()


    const [updateText, { isSuccess: textUpdated }] = useEditTextMutation()
    const [editName, { isSuccess: nameUpdated }] = useEditNameMutation()

    const [deleteContent, { isSuccess: contentDeleted }] = useDeleteContentMutation()
    const [setTextFavorite, { isSuccess: textFavoriteUpdated }] = useFavoriteMutation()
    const [setImageFavorite, { isSuccess: imageFavoriteUpdated }] = useFavoriteImageMutation()
    const [copyButtonText, setCopyButtonText] = useState(t('copy'))
    const [isFavori, setFavori] = useState(false)
    const [showCopyRevision, setShowCopyRevision] = useState(false)
    const [copiedText, copyToClipboard] = useCopyToClipboard();

    useEffect(() => {
        if (textUpdated || nameUpdated || textFavoriteUpdated || imageFavoriteUpdated || contentDeleted) {
            props.updateLoops()
        }
    }, [textUpdated, nameUpdated, contentDeleted])

    useEffect(() => {
        setFavori(props.loop?.is_favorite ?? false)
    }, [props.loop])

    const getMediaIcon = (mediaName: string) => {
        const platformName = mediaName.split(" | ")[0]
        const icon = Mediums.find(k => k.name.toLowerCase() === platformName.toLowerCase())?.iconColor
        return icon
    }

    const updateFavorite = () => {
        setTextFavorite({
            isFavorite: !isFavori ?? false,
            revisionId: props.loop?.id?.revision_id ?? ""
        })

        setFavori(!isFavori);
    }

    const showDeleteDialog = () => {
        dispatch(setDialog({
            actions: <>
                <PrimaryButton className="h-12 w-[100px]" noFill title={t('cancel')} onClick={() => { dispatch(toggleDialog(false)) }} />
                <PrimaryButton className="h-12 w-20 ml-2" title={t('delete')} onClick={() => { dispatch(toggleDialog(false)); deleteRevision() }} />
            </>,
            title: t('delete'),
            message: t('are-you-sure-you-would-like-to-delete-content'),
            onClose: () => { dispatch(toggleDialog(false)) }
        }))
        dispatch(toggleDialog(true))
    }

    const deleteRevision = () => {
        deleteContent({
            id: props.loop?.id?.content_id ?? ""
        })
    }

    const copyContent = () => {
        setCopyButtonText(t('copied-0'))
        copyToClipboard(props.loop.data)
        setTimeout(() => {
            setCopyButtonText(t('copy'))
        }, 2000);
    }

    return (
        <ContentContainer className={`p-3 h-[425px] cursor-pointer`}
            onClick={() => { props.onSelect(props.loop?.id?.content_id ?? "") }}
            hoverable={false}
            onHover={() => setHover(true)}
            onBlur={() => setHover(false)}>
            <div className={`flex w-full flex-col items-start`}>
                <div className="relative flex flex-col w-full">
                    <div className="flex flex-row items-center justify-between p-2 pb-0 mb-[10px]" >
                        <div className={`flex flex-row items-center justify-between  w-full`}>
                            <div className="flex flex-row">
                                <div className="h-[38px] w-[38px] rounded-full flex items-center justify-center border-1 border-border flex-row">
                                    <SvgDefaultBrandLogo fill="#000" />
                                    <img src={props.loop["author.profile_image_url"]} className="rounded-full p-[1px]" />
                                </div>
                                <div className="ml-2">
                                    <p className="text-black text-BodySmall">{props.loop["author.name"].slice(0, 20)}</p>
                                    <p className="text-gray text-BodySmall">@{props.loop["author.screen_name"].slice(0, 20)}</p>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <p className="text-gray text-BodySmall">{moment(props.loop.created_at).format("MM.DD.YYYY")}</p>
                                <Icon component={getMediaIcon("twitter")} width="20" height="20" className="ml-2" />
                            </div>
                        </div>
                    </div>
                    <text className={`mt-2 h-[300px] w-full overflow-scroll scrollbar-hide border-1 border-border rounded-[6px] text-BodySmall text-black p-4`} style={{ whiteSpace: "pre-wrap", lineHeight: "180%" }}>
                        {props.loop.data}
                    </text>
                    <div className="absolute bottom-0 left-0 right-0 w-full h-[43px] bg-lightBg rounded-b-[6px] border-1 border-border flex flex-row p-[12px]">
                        <p className="flex flex-row text-gray text-H8 items-center"><SvgLike fill="#999999" width={16} height={16} viewBox="0 0 20 20" /> <span className="ml-1">{props.loop.favorites}</span></p>
                        <p className="flex flex-row text-gray text-H8 items-center ml-2"><SvgRetweetIcon fill="#999999" width={16} height={16} /> <span className="ml-1">{props.loop.retweets}</span></p>
                        {props.loop.views &&
                            <p className="flex flex-row text-gray text-H8 items-center ml-2"><SvgViewIcon fill="#999999" width={16} height={16} /> <span className="ml-1">{props.loop.views}</span></p>
                        }
                    </div>
                </div>

                <div className={`flex w-full  h-[20px] mt-[10px] bg-white  justify-between items-center`}>
                    <IconButton
                        className="hover:w-[250px] ml-[10px] !text-red outline-none"
                        title={t('generate-similar')}
                        icon={SvgGenerateSimilarIcon}
                        onClick={(e) => {
                            e.stopPropagation()
                            props.generateSimilar()
                        }} />
                    <div className="flex flex-row justify-end items-center">
                        <div className="ml-3">
                            <IconButton className="hover:w-20" title={copyButtonText ?? t('copy')} icon={Copy} onClick={(e) => {
                                copyContent()
                            }} />
                        </div>
                        {/* <div className="ml-3">
                            <IconButton className="hover:w-28" title={t('download')} icon={Download} onClick={() => { }} />
                        </div> */}
                        <div className="ml-3">
                            <IconButton className="hover:w-20" title={"Link"} icon={SvgLinkIcon} onClick={(e) => {
                                let l = props.loop.link
                                if (!l.startsWith("http"))
                                    l = "https://" + l
                                window.open(l, "_blank", "noreferrer");

                            }} />
                        </div>
                    </div>
                </div>
            </div>

        </ContentContainer >
    );
}

export default ContentInspirationCard;
