import { FunctionComponent, useEffect, useState } from "react";
import PickerView from "../PickerView";
import { PickerModel } from "../../models/PickerModel";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import { t } from "i18next";
import { Font } from "../../services/FontApi";
import { FileProps } from "../../services/BrandApi";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import { useDropzone } from "react-dropzone";
import SvgErrorIcon from "../../assets/IconComponents/ErrorIcon";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading, setDialog, toggleDialog } from "../../store/appSlice";
import { useDeleteFileDeleteByIdMutation, useLazyDownloadQuery, useUploadMutation } from "../../services/FileApi";
import { blobToBase64String, blobToDataURL, dataURLToBlob } from "blob-util";
import PrimaryButton from "../PrimaryButton";
import ContentContainer from "../ContentContainer";
import InputText from "../InputText";
import { useRef } from "react";
import { refreshPage } from "../../store/appSlice";

interface FontSelectorProps {
  className?: string
  title: string
  fonts: Font[]
  fontSelected: (font: FileProps | undefined) => void
  selectedFont?: FileProps
}


const FontSelector: FunctionComponent<FontSelectorProps> = (props: FontSelectorProps) => {
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const uploadedFileRef = useRef<FileProps | undefined>(undefined);
  const newFontNameRef = useRef<string>("");
  const latestUploadedNameRef = useRef<string>("");
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const handleFilesChanged = (e) => {
    const file = Object.assign(e.target.files[0], {
      preview: URL.createObjectURL(e.target.files[0]),
    });

    selectFile([file]);
  };

  useEffect(() => {
    console.log("changed")
  }, [newFontNameRef.current]);

  const handleFinalizeFontSelection = () => {
    const updatedFile = { ...uploadedFileRef.current };

    // Modify the specific attribute in the copied object
    if (newFontNameRef.current !== "") {
      updatedFile.name = newFontNameRef.current;
    }

    props.fontSelected(updatedFile);
    dispatch(toggleDialog(false));
    latestUploadedNameRef.current = "";
    uploadedFileRef.current = undefined;
    newFontNameRef.current = "";
  };


  const downloadFileToDesktop = (uploadId: string) => {
    downloadFile({
      id: uploadId,
    })
      .unwrap()
      .then(async (k) => {
        const dataUrl = await blobToDataURL(k.file);

        var link = document.createElement("a");
        link.download = k.name;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const showSuccessDialog = () => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            {/*Header*/}
            <div className="h-[40px] w-full flex items-center mb-3 justify-between">
              <text className="text-H5 text-black ml-[15px] ">
                {"Upload Your Font"}
              </text>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />
            {/*Body*/}
            <div className="p-[15px] pt-[10px] h-full  overflow-scroll scrollbar-hide">
              <div className="flex flex-col w-full ">
                <InputText
                  title={t("give-your-font-a-name") ?? ""}
                  placeholder={t("font-name") ?? ""}
                  innerClassName="rounded-[4px]"
                  onChange={(e) => {
                    newFontNameRef.current = e;
                  }}
                />
                <p className="mt-3 text-H8 text-black mb-2">
                  {t("upload-your-font")}
                </p>
                <div
                  className={`flex flex-row w-full  border-border border-1 rounded-[4px] h-[60px] px-2 items-center justify-between
            `}
                >
                  <text className="text-BodyMedium text-gray overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {latestUploadedNameRef.current === ""
                      ? "Upload File"
                      : latestUploadedNameRef.current}
                  </text>
                  <div className="flex items-center">
                    <div className="ml-1 relative flex cursor-pointer  rounded-[3px]  border-border ">
                      <input
                        type="file"
                        accept=".ttf, .woff, .woff2, .otf"
                        id={"upload-file-font"}
                        style={{ display: "none" }}
                        onChange={(event) => handleFilesChanged(event)}
                      />

                      <label htmlFor={"upload-file-font"}>
                        <div className="flex flex-row border-1 border-border h-[40px] items-center ">
                          <SvgUploadIcon className="ml-2" fill="#121315" />
                          <text className="text-H8 ml-2 text-black mr-2">
                            {t("upload-file")}
                          </text>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-16 flex justify-end items-end mt-3">
                <PrimaryButton
                  noFill
                  className="h-10 w-16"
                  title={t("cancel") ?? ""}
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    latestUploadedNameRef.current = "";
                    uploadedFileRef.current = undefined;
                    newFontNameRef.current = "";
                  }}
                />
                <PrimaryButton
                  className="h-10 w-28 ml-2 bg-gray"
                  title={t("add-font") ?? ""}
                  isDisabled={true}
                  onClick={() => {
                    handleFinalizeFontSelection();
                  }}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const changeSuccessDialog = (fileName, isDisabled) => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <ContentContainer
              hoverable={false}
              className="w-full h-fit  overflow-hidden"
              children={
                <>
                  {/*Header*/}
                  <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
                    <text className="text-H6 text-black ml-[15px] ">
                      {"Upload Your Font"}
                    </text>
                  </div>
                  {/*Body*/}
                  <div className="p-[15px] pt-[10px] h-full  overflow-scroll scrollbar-hide">
                    <div className="flex flex-col w-full ">
                      <InputText
                        title={t("give-your-font-a-name") ?? ""}
                        placeholder={t("font-name") ?? ""}
                        onChange={(e) => {
                          newFontNameRef.current = e;
                          changeSuccessDialog(fileName, checkIsApplyButtonDisabled());
                        }}
                        innerClassName="rounded-[4px]"
                      />
                      <p className="mt-3 text-H8 text-black">
                        {t("upload-your-font")}
                      </p>
                      <div
                        className={`flex flex-row w-full  border-border border-1 rounded-[4px] h-[60px] px-2 items-center justify-between
            `}
                      >
                        <text className="text-BodyMedium text-black overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {fileName}
                        </text>
                        <div className="flex items-center">
                          <div className="ml-1 relative flex cursor-pointer  rounded-[3px]  border-border ">
                            <input
                              type="file"
                              accept=".ttf, .woff, .woff2, .otf"
                              id={"upload-file-font"}
                              style={{ display: "none" }}
                              onChange={(event) => handleFilesChanged(event)}
                            />

                            <label htmlFor={"upload-file-font"}>
                              <div className="flex flex-row border-1 border-border h-[40px] items-center ">
                                <SvgUploadIcon
                                  className="ml-2"
                                  fill="#121315"
                                />
                                <text className="text-H8 ml-2 text-black mr-2">
                                  {t("upload-file")}
                                </text>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="h-16 flex justify-end items-end mt-3">
                      <PrimaryButton
                        noFill
                        className="h-10 w-16"
                        title={t("cancel") ?? ""}
                        onClick={() => {
                          dispatch(toggleDialog(false));
                          latestUploadedNameRef.current = "";
                          uploadedFileRef.current = undefined;
                          newFontNameRef.current = "";
                        }}
                      />
                      <PrimaryButton
                        className="h-10 w-28 ml-2 bg-gray"
                        title={t("add-font") ?? ""}
                        isDisabled={isDisabled}
                        onClick={() => {
                          handleFinalizeFontSelection();
                        }}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </>
        ),
      })
    );
  };
  /*
          const checkIsApplyButtonDisabled = () => {
      return
    }
        */

  const checkIsApplyButtonDisabled = () => {
    console.log("Ff", newFontNameRef.current);
    return !uploadedFileRef.current || newFontNameRef.current === "";;
  };

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const selectFile = async (files: any[]) => {
    dispatch(toggleLoading(true));

    let selectedFile = files[0];

    let a = (await fileToBlob(selectedFile)) as string;
    let blob = dataURLToBlob(a);
    const formData: FormData = new FormData();
    formData.append("files", blob, selectedFile.name);
    uploadFiles({
      body: formData,
    })
      .unwrap()
      .then((k) => {
        selectedFile["uploadedId"] = k.ids ?? "";
        let f = {
          id: selectedFile.uploadedId,
          name: selectedFile.name,
          uploaded: true,
        } as FileProps;
        uploadedFileRef.current = f;
        changeSuccessDialog(selectedFile.name, checkIsApplyButtonDisabled());
        dispatch(refreshPage());
        dispatch(toggleLoading(false));
      });
  };

  return (
    <div className={`flex flex-row w-full ${props.className}`}>
      <p className="text-H8 text-black w-1/3 mt-2">{props.title}</p>
      <div className="flex flew-row w-full items-center">
        {props.selectedFont && props.selectedFont.uploaded === true && (
          <div
            className="flex flex-row mt-2 bg-lightBg rounded-[10px] w-full h-[40px] items-center justify-between px-2 border-1 border-border cursor-pointer"
            onClick={() => {
              downloadFileToDesktop(props.selectedFont?.id ?? "");
            }}
          >
            <p className="text-BodyMedium text-black">
              {props.selectedFont.name}
            </p>
            <button
              className="rounded-full w-[24] h-[24] bg-bg border-1 border-border p-1"
              onClick={(e) => {
                e.stopPropagation();

                const id = props.selectedFont?.id;

                props.fontSelected(undefined);
                deleteFile({
                  id: id ?? "",
                });
              }}
            >
              <SvgCloseLittle fill="#000" />
            </button>
          </div>
        )}
        {(!props.selectedFont || props.selectedFont.uploaded === false) && (
          <>
            <PickerView
              isFontPicker={true}
              allowMultiSelect={false}
              placeholder={t("select-a-font") ?? undefined}
              className="flex flex-1 mt-[8px]"
              selectedValue={[props.selectedFont?.id ?? ""]}
              items={props.fonts.map(
                (k) => ({ title: k.name, value: k.file_id } as PickerModel)
              )}
              onChange={(e) => {
                let s = props.fonts.find((k) => k.file_id === e[0].value)!;
                props.fontSelected({
                  id: s.file_id,
                  name: s.name,
                  uploaded: false,
                } as FileProps);
              }}
            />
            <text className="mx-[3px] text-BodyMedium text-gray">or</text>
            <button
              className="flex flex-row border-1 border-border rounded-[8px] h-[40px] items-center p-2 mt-2"
              onClick={showSuccessDialog}
            >
              <SvgUploadIcon fill="#121315" className="mr-2" />
              <span className="text-H7 text-black">
                {t("upload")}
              </span>
            </button>
          </>
        )}
      </div>
      {error && (
        <div className="flex flex-row mt-2 items-start">
          <SvgErrorIcon
            width="20"
            height="20"
            fill="#FF5555"
            className="mr-2"
          />
          <text className="text-red text-BodySmall">{error}</text>
        </div>
      )}
    </div>
  );
}

export default FontSelector;
