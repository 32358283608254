import {useState} from "react";
import Icon from "../Icon";

interface ICampaignCardProps {
    title: string;
    icon: any;
    onClick: () => void;
    description: string
}
const CampaignCard = (props: ICampaignCardProps) => {
    const [additionalClassAttr, setAdditionalClassAttr] = useState("");
    const [additionalClassAttr2, setAdditionalClassAttr2] = useState("");

    return (
        <div
            className={`w-[300px] flex flex-col justify-center items-center m-2 p-5 bg-white cursor-pointer rounded-[8px] border-1 ${additionalClassAttr}`}
            onMouseOver={() => {setAdditionalClassAttr("border-red") ;setAdditionalClassAttr2("bg-rose-50") }}
            onMouseLeave={() => { setAdditionalClassAttr("") ; setAdditionalClassAttr2("bg-neutral-100")}}
            onClick={() => props.onClick()}
            
            >
            <div className={`${additionalClassAttr2} inline-block text-center rounded-[4px] mt-3 `}
                
            >
                
                <Icon
                    width="25"
                    height="25"
                    component={props.icon}
                    className="m-3"
                    color={
                        additionalClassAttr === '' ? "#888888" : "#DD3344"
                    }
                />
            </div>
            <div className={'text-black text-H6 mt-3'}>
                <h2>{props.title}</h2>
            </div>
            <div className={'text-black text-BodySmall mt-2 text-center'}>
                {props.description}
            </div>
        </div>
    )
}

export default CampaignCard;
