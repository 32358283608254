import { useEffect, useState } from "react";
import { setDialog, toggleDialog } from "../../store/appSlice";
import { useAppDispatch } from "../../helpers/hooks";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { useCardSetupMutation, CardSetupApiArg } from "../../services/PaymentApi";
import { toggleLoading } from "../../store/appSlice";
import { t } from "i18next";
import PrimaryButton from "../../components/PrimaryButton";
import { useRef } from "react";
import { clickOutside } from "../../helpers/ClickOutside";

export interface IEditCreditCardPopupProps {
  onClose: () => void
  onCardSetupCompleted: () => void
}

function EditCreditCardPopupOpener(props: IEditCreditCardPopupProps) {
  const dispatch = useAppDispatch();
  useEffect(() => { showSubscriptionDialog(); }, [])

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "")

  const showSubscriptionDialog = () => {
    dispatch(setDialog({
      actions: <></>,
      title: "",
      message: "",
      customTemplate: (
        <Elements stripe={stripePromise ?? Promise.resolve(null)} options={{
          mode: "subscription",
          currency: "usd",
          amount: 100,
        }}>
          <EditCreditCardPopup {...props}></EditCreditCardPopup>
        </Elements>
      ),
    }));
    dispatch(toggleDialog(true));
  }

  return (<></>)
}

function EditCreditCardPopup(props: IEditCreditCardPopupProps) {

  const [errorMessage, setErrorMessage] = useState<string>();
  const [setupCard, { data: setupClientSecret }] = useCardSetupMutation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (setupClientSecret?.client_secret && elements && stripe) {
      elements.submit();
      (async () => {
        let result = false;
        result = await confirmSetup(setupClientSecret.client_secret)
          .then((res) => res)
          .catch((error) => {
            setErrorMessage(error);
            return false;
          });
        if (result) {
          setErrorMessage("");
          props.onCardSetupCompleted();
        }
      })();
    }
  }, [setupClientSecret]);



  const confirmSetup = async (clientSecret: string) => {
    if (!stripe || !elements) {
      dispatch(toggleLoading(false));
      return false;
    }

    dispatch(toggleLoading(true));

    return stripe
      .confirmSetup({
        elements,
        redirect: "if_required",
        clientSecret: clientSecret,
        confirmParams: { return_url: window.location.href },
      })
      .then((res) => {
        console.log("Response is,", res);
        dispatch(toggleLoading(false));
        if (res.error) {
          console.error(res.error);
          return false;
        } else {
          return true;
        }
      });
  };


  useEffect(() => {
    if (ref.current) {
      return clickOutside(ref.current, () => {
        dispatch(toggleDialog(false));
        props.onClose();
      });
    }
  }, [ref.current]);


  return (
    <>
      <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div
          ref={ref}
          className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-5 rounded-[10px]"
        >
          <>
            <div className="h-10 items-center flex justify-between">
              <text className="text-black text-H6 mb-3">
                {t("edit-credit-card")}
              </text>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />
            <div className="w-full mt-[20px] flex-col">
              <div className="w-full">
                <PaymentElement />
              </div>
            </div>
            <hr className="bg-border -ml-5 -mr-5 my-5" />
            <div className="flex-col lg:flex lg:flex-row lg:justify-end">
              <PrimaryButton
                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  props.onClose();
                }}
              />
              <PrimaryButton
                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                isDisabled={false}
                title={t("save")}
                onClick={() => {
                  setupCard({ cardSetupRequest: { referral: undefined } })
                }}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
}
export default EditCreditCardPopupOpener;

