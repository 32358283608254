import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Back } from "../../../assets/IconComponents";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import SvgForYouLightBulb from "../../../assets/IconComponents/ForYouLightBulb";
import Rocket from "../../../assets/IconComponents/Rocket";
import CampaignCard from "../../../components/MarketingPlanner/CampaignCard";
import DescribeEvent from "./DescribeEvent";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import ProductAbout from "./ProductAbout";
import AddPhotos from "./AddPhotos";
import ChooseChannels from "./ChooseChannels";
import PaidChannelDetails from "./PaidChannelDetails";
import DescribeYourAudience from "./DescribeYourAudience";
import ReviewCampaigns from "./ReviewCampaigns";
import {useDeleteEventAndCampaignsMutation, useSaveMarketingPlannerMutation} from "../../../services/MarketingPlanner";
import { setEvent, setPlan } from "../../../store/plannerSlice";
import Search2 from "../../../assets/IconComponents/Search2";
import PaperMoney1 from "../../../assets/IconComponents/PaperMoney1";
import Globe1 from "../../../assets/IconComponents/Globe1";
import Frame from "../../../assets/IconComponents/Frame";
import Snowman1 from "../../../assets/IconComponents/Snowman1";
import MicrophoneOne1 from "../../../assets/IconComponents/MicrophoneOne1";
import HandShake from "../../../assets/IconComponents/HandShake";
import RefreshOne1 from "../../../assets/IconComponents/RefreshOne1";
import PartyBalloon1 from "../../../assets/IconComponents/PartyBalloon1";
import Trophy1 from "../../../assets/IconComponents/Trophy1";
import AwarenessIcon from "../../../assets/IconComponents/AwarenessIcon";
import TrafficIcon from "../../../assets/IconComponents/TrafficIcon";
import EngagementIcon from "../../../assets/IconComponents/EngagementIcon";
import AppPromotionIcon from "../../../assets/IconComponents/AppPromotionIcon";
import LeadGenerationIcon from "../../../assets/IconComponents/LeadGenerationIcon";
import SalesConversionIcon from "../../../assets/IconComponents/SalesConversionIcon";
import ReviewActivities from "./ReviewActivities";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import Icon from "../../../components/Icon";
import SvgLogoIcon from "../../../assets/IconComponents/LogoIcon";
import {Button, Modal, Tooltip} from "antd";
import WarningIconRed from "../../../assets/IconComponents/WarningIconRed";

const MarketingPlannerDetail: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const queryParameters = new URLSearchParams(window.location.search);
    const type = queryParameters.get("type");

    const ref = useRef<HTMLDivElement>(null);
    const selectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const {activities} = useAppSelector((k) => k.activity);
    const [processLevel, setProcessLevel] = useState(0);

    const creditsAvailable = useAppSelector(k => k.userSettings.subscription?.credits_available)
    const creditsUsed = useAppSelector(k => k.userSettings.subscription?.credits_used)
    const { plan, event, savedData } = useAppSelector((k) => k.planner);

    const [save, { data: createdData, isLoading: createIsLoading }] = useSaveMarketingPlannerMutation();
    const [isLoading, setIsLoading] = useState(false)
    const [closeModal, setCloseModal] = useState(false);
    const [deleteEventAndCampaigns] = useDeleteEventAndCampaignsMutation();

    const eventCards = [
        {
            title: "Product Rollout",
            key: 'PRODUCT_ROLLOUT',
            icon: Rocket,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Launching your innovation to spark market interest.'
        },
        {
            title: "Feature Unveiling",
            key: 'FEATURE_UNVEILING',
            icon: Search2,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Showcasing enhancements to enrich the product experience.'
        },
        {
            title: "Raised Funding",
            key: 'RAISED_FUNDING',
            icon: PaperMoney1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Celebrating new investment milestones fueling growth.'
        },
        {
            title: "Market Expansion",
            key: 'MARKET_EXPANSION',
            icon: Globe1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Broadening your footprint in untapped regions or sectors.'
        },
        {
            title: "Hosting Learning Sessions",
            key: 'BRAND_MILESTONE_CELEBRATION',
            icon: Frame,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Sharing expertise in engaging educational formats.'
        },
        {
            title: "Seasonal Discount/Sales",
            key: 'SEASONAL_DISCOUNT',
            icon: Snowman1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Boosting revenue with seasonal or theme-based promotional offers.'
        },
        {
            title: "Event Participation",
            key: 'EVENT_PARTICIPATION',
            icon: MicrophoneOne1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Attending an industry event to expand your network and knowledge.'
        },
        {
            title: "Partnership Initiation",
            key: 'PARTNERSHIP_INITIATION',
            icon: HandShake,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Establishing new alliances to drive innovation and open new market avenues.'
        },
        {
            title: "Strategic Shift",
            key: 'STRATEGIC_SHIFT',
            icon: RefreshOne1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Adapting to market dynamics with strategic pivots.'
        },
        {
            title: "Team Onboarding Celebration",
            key: 'TEAM_ONBOARDING_CELEBRATION',
            icon: PartyBalloon1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Welcoming a new talent into your corporate community.'
        },
        {
            title: "Brand Milestone Celebration",
            key: 'BRAND_MILESTONE_CELEBRATION',
            icon: Trophy1,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Commemorating pivotal moments in your brand\'s journey.'
        }
    ]

    const campaignCards = [
        {
            title: "Awareness",
            key: 'AWARENESS',
            icon: AwarenessIcon,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Boost your brand\'s visibility to ensure you\'re seen by your target audience.'
        },
        {
            title: "Traffic",
            key: 'TRAFFIC',
            icon: TrafficIcon,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Drive more visitors to your site, increasing potential customer flow.'
        },
        {
            title: "Engagement",
            key: 'ENGAGEMENT',
            icon: EngagementIcon,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Get your content interacted with—likes, comments, shares.'
        },
        {
            title: "App Promotion",
            key: 'APP_PROMOTION',
            icon: AppPromotionIcon,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Get your mobile app noticed, reviewed and downloaded more.'
        },
        {
            title: "Lead Generation",
            key: 'LEAD_GENERATION',
            icon: LeadGenerationIcon,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Collect contact info from potential customers for future outreach.'
        },
        {
            title: "Sales Conversion",
            key: 'SALES_CONVERSION',
            icon: SalesConversionIcon,
            onClick: () => {
                setProcessLevel(1);
            },
            description: 'Persuade interested visitors to purchase and become customers.'
        },
    ]

    useEffect(() => {
        if (ref.current) {
            ref.current.style.width = `${(processLevel + 1) * 9 + 1}%`
        }

    }, [processLevel]);

    let barLoaderID = 0;

    const slidePageProgressBar = () => {
        let barPosition = 0;

        barLoaderID = Number(setInterval(() => {
            if (barPosition === 100) {
                barPosition = -70
            } else {
                barPosition++
            }
            if (ref.current) {
                ref.current.style.left = `${barPosition}%`
            }
        }, 2))
    }

    const stopPageProgressBar = () => {
        clearInterval(barLoaderID)
        if (ref.current) {
            ref.current.style.left = `0`
        }
    }

    useEffect(() => {
        if (processLevel === 7) {
            setIsLoading(true);
            setTimeout(() => setIsLoading(false), 3000)
        }

    }, [processLevel]);

    return (
        <>
            <div
                className="relative min-w-full h-[56px] text-xl flex flex-row bg-white border-1 border-border justify-start items-center">
                <div className="flex flex-row items-center ml-2 w-full">
                    {/*<Button onClick={slidePageProgressBar}>başla</Button>
                    <Button onClick={stopPageProgressBar}>dur</Button>*/}
                    <button
                        disabled={false}
                        className="w-[56px] h-[56px] border-border"
                        onClick={() => {
                            if(processLevel === 7 || (processLevel === 8 && type !== 'event')) {
                                setCloseModal(true)
                            } else {
                                setProcessLevel(prev => {
                                    if (prev === 0) {
                                        navigate("/app/marketing-planner");
                                        return prev;
                                    } else {
                                        return prev - 1;
                                    }
                                })
                            }

                        }}
                    >
                        <div className="flex justify-center items-center">
                            <Back
                                width="18"
                                height="18"
                                viewBox="0 0 20 20"
                                fill="#999999"
                                className=""
                            />
                        </div>
                    </button>
                    <span className="text-H6 text-black ">
                        {t(`create-new-${type === 'event' ? 'event' : 'campaign'}`)}
                    </span>
                </div>
                <div className={'w-full flex justify-end'}>
                    {processLevel === 8 && (
                        <div className={'border-1 border-gray-300 rounded-[20px] mr-4 py-2 px-3 text-sm text-gray'}>
                            Your Credits:{' '}
                            <span className={'text-black'}>
                                {(creditsAvailable ?? 10000) -
                                    (creditsUsed ?? 0)
                                }
                                <Tooltip title="Credit usage varies by content type: generating text costs 1 credit, creative ads cost 3 credits, and banners cost 5 credits.">
                                    {''}
                                    <Icon
                                        width="13"
                                        height="13"
                                        component={SvgQuestionMark}
                                        viewBox="0 0 15 15"
                                        className="ml-1 inline-block"
                                    />
                                </Tooltip>
                            </span>
                        </div>)}
                </div>
            </div>
            <div className={'w-full bg-neutral-200 relative h-[5px]'}>
                <div ref={ref} className={`h-[5px] bg-red absolute`}></div>
            </div>
            <div
                className="w-full p-12 text-black h-[calc(100vh-140px)] md:h-[calc(100vh-60px)] overflow-scroll scrollbar-hide">
                {processLevel === 0 && (
                    <>
                        <div className={'mb-7 ml-2 text-black'}>
                            <div className={'text-H4'}>
                                <h2>What’s happening with your brand?</h2>
                            </div>
                            <div className={'text-BodySmall mt-2'}>
                                Let’s start with selecting an event to create your cross-channel marketing plan.
                            </div>
                        </div>
                        <div className={'flex flex-wrap'}>
                            {type === 'campaign' ? campaignCards.map(card => (
                                <CampaignCard onClick={() => {
                                    dispatch(setPlan({
                                        campaignType: card.key,
                                        headerType: "CAMPAIGN"
                                    }));
                                    card.onClick();
                                }} title={card.title} key={card.title}
                                    icon={card.icon} description={card.description} />
                            )) : eventCards.map(card => (
                                <CampaignCard onClick={() => {
                                    dispatch(setEvent({
                                        eventType: card.key,
                                        headerType: "EVENT"
                                    }));
                                    card.onClick();
                                }} title={card.title} key={card.title}
                                    icon={card.icon} description={card.description} />
                            ))}
                        </div>
                    </>)}
                {processLevel === 1 && (
                    <DescribeEvent
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={() => setProcessLevel(prev => prev + 1)}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                    />
                )}
                {processLevel === 2 && (
                    <ProductAbout
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={() => setProcessLevel(prev => prev + 1)}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                    />
                )}
                {processLevel === 3 && (
                    <AddPhotos
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={() => setProcessLevel(prev => prev + 1)}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                    />
                )}
                {processLevel === 5 && (
                    <ChooseChannels
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={(param) => {

                            setProcessLevel(prev => prev + param?.pageCount)

                        }}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                        slidePageProgressBar={slidePageProgressBar}
                        stopPageProgressBar={stopPageProgressBar}
                    />
                )}
                {processLevel === 6 && (
                    <PaidChannelDetails
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={(param) => {
                            setProcessLevel(prev => prev + param?.pageCount)
                        }}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                        slidePageProgressBar={slidePageProgressBar}
                        stopPageProgressBar={stopPageProgressBar}
                    />
                )}
                {processLevel === 4 && (
                    <DescribeYourAudience
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={() => setProcessLevel(prev => prev + 1)}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                        slidePageProgressBar={() => { }}
                        stopPageProgressBar={() => { }}
                    />
                )}
                {processLevel === 7 && (
                    <>
                        {isLoading ?
                            <div className={'relative bg-white z-[9999]'}>
                                <div className={'fixed top-[0] left-[0] w-full h-full bg-lightBg'}>
                                    <div className={'flex justify-center items-center flex-col pt-[40vh]'}>
                                        <SvgLogoIcon />
                                        <h1 className={'text-H5 text-black mt-3'}>Planning your campaigns with AI
                                            ...</h1>
                                        <p className={'text-gray block mt-3'}>This may take a while.</p>
                                    </div>
                                </div>
                            </div> :
                            <ReviewCampaigns
                                selectedBrandId={selectedBrandId}
                                type={type}
                                icon={SvgForYouLightBulb}
                                onComplate={() => setProcessLevel(prev => prev + 1)}
                                onCancel={(param) => {

                                    setProcessLevel(prev => prev - param?.pageCount)
                                }}
                                plan={plan}
                                event={event}
                            />
                        }
                    </>

                )}
                {/*{processLevel === 8 && (
                    <EventCampaigns
                        selectedBrandId={selectedBrandId}
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={() => setProcessLevel(prev => prev + 1)}
                        onCancel={() => setProcessLevel(prev => prev - 1)}
                        plan={plan}
                        event={event}
                    />
                )}*/}
                {processLevel === 8 && (
                    <ReviewActivities
                        selectedBrandId={selectedBrandId}
                        userCredit={(creditsAvailable ?? 10000) - (creditsUsed ?? 0)
                        }
                        type={type}
                        icon={SvgForYouLightBulb}
                        onComplate={() => setProcessLevel(prev => prev + 1)}
                        onCancel={(param) => {
                            console.log("pageCount", param?.pageCount)
                            setProcessLevel(prev => prev - param?.pageCount)
                        }}
                        plan={plan}
                        event={event}
                    />
                )}
            </div>
            <Modal open={closeModal} onCancel={() => {}} closeIcon={false} footer={<div className={'flex justify-center'}>
                <Button size={'large'} type="default" className={'mr-3'} onClick={() => {
                    setCloseModal(false)
                    setProcessLevel(prev => {
                        if (prev === 0) {
                            navigate("/app/marketing-planner");
                            return prev;
                        } else {
                            if (type === 'campaign' && processLevel === 8) {
                                let hasPaid = false;
                                if (plan && plan.channels && plan.channels.length > 0) {
                                    plan.channels?.forEach(channel =>
                                        {
                                            if (channel === "GOOGLE_ADS" || channel === "META_ADS") {
                                                hasPaid = true
                                            }
                                        }
                                    )
                                }
                                return hasPaid ? prev - 2 : prev - 3;
                            } else {
                                let hasPaid = false;
                                if (event && event.channels && event.channels.length > 0) {
                                    event.channels?.forEach(channel =>
                                        {
                                            if (channel === "GOOGLE_ADS" || channel === "META_ADS") {
                                                hasPaid = true
                                            }
                                        }
                                    )
                                }
                                return hasPaid ? prev - 1 : prev - 2;
                            }
                        }
                    })
                    if (type === 'event') {
                        if(savedData) {
                            deleteEventAndCampaigns({body: savedData?.map(a => a.id ?? '')})
                        }
                    }
                    else {
                        if (activities && activities.activityResponseList) {
                            deleteEventAndCampaigns({body: [activities.activityResponseList[0].rootId ?? '']})
                        }
                    }
                }}>Yes, Delete</Button>
                <Button size={'large'} type="primary" onClick={() => setCloseModal(false)}>Go Back</Button>
            </div>} cancelText={'Back'} okText={'OK'}>
                <div className={'flex justify-center items-center flex-col text-center mb-6'}>
                    <WarningIconRed/>
                    <h1 className={'text-H5 text-black mt-3'}>Are you sure? Data will be lost.</h1>
                    <p className={'text-gray block mt-3'}>If you cancel, all campaign details and inputs entered so far
                        will be deleted, and you will need to start from scratch.</p>
                </div>
            </Modal>
        </>
    )
}

export default MarketingPlannerDetail;
