import { useEffect } from "react";
import { useAppDispatch } from "../../helpers/hooks";
import { setActivePage, toggleMenu } from "../../store/appSlice";

import MainNavigations from "./MainNavigations";
import MarketingSuites from "./MarketingSuites";
import ContinueYouLeft from "./ContinueYouLeft";
import Campaigns from "./Campaigns";
import Activities from "./Activities";
import './newesthomepage.css';


const SelectedContentHome = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActivePage("homepage"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleMenu(true));
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-center items-center w-full  bg-white px-16 overflow-scroll scrollbar-hide">
        <div className="flex-col justify-center items-center md:h-[calc(100vh)] bg-gray-100 rounded-lg">
          <MainNavigations />
          <MarketingSuites />
          <ContinueYouLeft />
          <Campaigns />
          <Activities />
        </div>
      </div>
    </>
  );
};

export default SelectedContentHome;
