import * as React from "react";
const SvgChevron = ({ fillPath, ...props }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path fill={fillPath} d="M16.4226 8.4226C16.5855 8.25969 16.6667 8.04677 16.6667 7.83344C16.6667 7.6201 16.5855 7.40719 16.4226 7.24427C16.0971 6.91885 15.5696 6.91885 15.2442 7.24427L10.8334 11.6551L10 12.5L9.16672 11.6551L4.75589 7.24427C4.43047 6.91885 3.90297 6.91885 3.57756 7.24427C3.25214 7.56969 3.25214 8.09719 3.57756 8.4226L9.41089 14.2559C9.73631 14.5814 10.2638 14.5814 10.5892 14.2559L16.4226 8.4226Z" />
  </svg>

);
export default SvgChevron;
