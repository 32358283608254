import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addAudience: build.mutation<AddAudienceApiResponse, AddAudienceApiArg>({
      query: (queryArg) => ({
        url: `/audience/`,
        method: "POST",
        body: queryArg.addAudienceRequest,
      }),
    }),
    getAudience: build.query<GetAudienceApiResponse, GetAudienceApiArg>({
      query: (queryArg) => ({ url: `/audience/${queryArg.brandId}` }),
    }),
    getAudienceById: build.query<
      GetAudienceByIdApiResponse,
      GetAudienceByIdApiArg
    >({
      query: (queryArg) => ({ url: `/audience/id/${queryArg.audienceId}` }),
    }),
    deleteAudience: build.mutation<
      DeleteAudienceApiResponse,
      DeleteAudienceApiArg
    >({
      query: (queryArg) => ({
        url: `/audience/${queryArg.audienceId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AddAudienceApiResponse = /** status 200 OK */ AddAudienceResponse;
export type AddAudienceApiArg = {
  addAudienceRequest: AddAudienceRequest;
};
export type GetAudienceApiResponse = /** status 200 OK */ AudienceResponse[];
export type GetAudienceApiArg = {
  brandId: string;
};
export type GetAudienceByIdApiResponse = /** status 200 OK */ AudienceResponse;
export type GetAudienceByIdApiArg = {
  audienceId: string;
};
export type DeleteAudienceApiResponse = unknown;
export type DeleteAudienceApiArg = {
  audienceId: string;
};
export type AddAudienceResponse = {
  audience_id: string;
};
export type Location = {
  name?: string;
  type?: "COUNTRY" | "CITY";
};
export type AddAudienceRequest = {
  brand_id?: string;
  audience_id?: string;
  name: string;
  location?: Location[];
  location_exclude?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  interest_exclude?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  ads_category?: string;
  beneficiary?: string;
  payer?: string;
};
export type AudienceResponse = {
  organization_id?: string;
  brand_id?: string;
  audience_id?: string;
  name?: string;
  location?: Location[];
  location_exclude?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  interest_exclude?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  ads_category?: string;
  beneficiary?: string;
  payer?: string;
};
export const {
  useAddAudienceMutation,
  useGetAudienceQuery,
  useLazyGetAudienceQuery,
  useGetAudienceByIdQuery,
  useLazyGetAudienceByIdQuery,
  useDeleteAudienceMutation,
} = injectedRtkApi;
