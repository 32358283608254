import * as React from "react";
import type { SVGProps } from "react";
const SvgCreativeAdGenerator = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 20"
    width={20}
    height={20}
    fill="none"
    {...props}>

    <path fill={props.fill ?? "#FFFFFF"} d="M4.66667 17.5C4.20833 17.5 3.81597 17.3368 3.48958 17.0104C3.16319 16.684 3 16.2917 3 15.8333V4.16667C3 3.70833 3.16319 3.31597 3.48958 2.98958C3.81597 2.66319 4.20833 2.5 4.66667 2.5H11.3333C11.5694 2.5 11.7674 2.57986 11.9271 2.73958C12.0868 2.89931 12.1667 3.09722 12.1667 3.33333C12.1667 3.56944 12.0868 3.76736 11.9271 3.92708C11.7674 4.08681 11.5694 4.16667 11.3333 4.16667H4.66667V15.8333H16.3333V9.16667C16.3333 8.93056 16.4132 8.73264 16.5729 8.57292C16.7326 8.41319 16.9306 8.33333 17.1667 8.33333C17.4028 8.33333 17.6007 8.41319 17.7604 8.57292C17.9201 8.73264 18 8.93056 18 9.16667V15.8333C18 16.2917 17.8368 16.684 17.5104 17.0104C17.184 17.3368 16.7917 17.5 16.3333 17.5H4.66667ZM9.875 13.3333L8.33333 11.2708C8.25 11.1597 8.13889 11.1042 8 11.1042C7.86111 11.1042 7.75 11.1597 7.66667 11.2708L6 13.5C5.88889 13.6389 5.875 13.7847 5.95833 13.9375C6.04167 14.0903 6.16667 14.1667 6.33333 14.1667H14.6667C14.8333 14.1667 14.9583 14.0903 15.0417 13.9375C15.125 13.7847 15.1111 13.6389 15 13.5L12.7083 10.4375C12.625 10.3264 12.5139 10.2708 12.375 10.2708C12.2361 10.2708 12.125 10.3264 12.0417 10.4375L9.875 13.3333Z" />
    <path fill={props.fill ?? "#FFFFFF"} d="M14.9701 7.35417L14.2617 5.79167L12.6992 5.08333L14.2617 4.375L14.9701 2.8125L15.6784 4.375L17.2409 5.08333L15.6784 5.79167L14.9701 7.35417Z" />
    <path fill={props.fill ?? "#FFFFFF"} d="M18 3.58331L17.5321 2.5512L16.5 2.08331L17.5321 1.61542L18 0.583313L18.4679 1.61542L19.5 2.08331L18.4679 2.5512L18 3.58331Z" />

  </svg>

);
export default SvgCreativeAdGenerator;