import { createApi } from '@reduxjs/toolkit/query/react'
import { Post } from '../../pages/LandingPage/Models/Post'
import { baseQuery, baseQueryWithReauth } from '../Core/BaseApi'
import { ContentModel } from '../ContentApi.old/Model/ContentModel'
import { CreateContentReq } from '../ContentApi.old/Model/CreateContentReqRes'
import { DefaultRequest } from '../Core/DefaultResponse'
import { EditNameReq } from '../ContentApi.old/Model/EditNameReq'
import { ApplyTextContent, ApplyTextReq } from './Models/ApplyTextReqRes'
import { SelectTextReq, SelectRes } from './Models/SelectTextReqRes'
import { EditTextReq } from './Models/EditTextReqRes'
import { GenerateSimiliarReq } from './Models/GenerateSimiliarTextReqRes'
import { SelectSimilarOneTextReq } from './Models/SelectSimilarOneReqRes'


export const TextApi = createApi({
    reducerPath: "Text",
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        applyText: builder.mutation<SelectRes[], ApplyTextReq>({
            query: (body) => ({
                url: `text/apply`,
                method: 'POST',
                body,
            }),
        }),
        editText: builder.mutation<ContentModel, EditTextReq>({
            query: ({ revision_id, updated_text_content }) => ({
                url: `text/edit/${revision_id}`,
                method: 'PUT',
                body: { updated_text_content: updated_text_content }
            }),
        }),
        generateSimiliarText: builder.mutation<SelectRes[], GenerateSimiliarReq>({
            query: (body) => ({
                url: `text/generate-similar`,
                method: 'POST',
                body: body
            }),
        }),
        selectSimilarOneText: builder.mutation<ContentModel, SelectSimilarOneTextReq>({
            query: (body) => ({
                url: `text/select`,
                method: 'POST',
                body: body
            }),
        }),
        findByRevisionId: builder.query<SelectRes[], DefaultRequest>({
            query: ({ revisionId }) => ({
                url: `text/${revisionId}`,
            })
        }),
        findByContentId: builder.query<SelectRes[], DefaultRequest>({
            query: ({ contentId }) => ({
                url: `text/content/${contentId}`,
            })
        }),
        favorite: builder.mutation<any, DefaultRequest>({
            query: ({revisionId, data}) => ({
                url: `text/favorite/${revisionId}?isFavorite=${data?.isFavorite}`,
                method: 'PUT',
            }),
        }),
        delete: builder.mutation<any, DefaultRequest>({
            query: ({revisionId}) => ({
                url: `text/${revisionId}`,
                method: 'DELETE',
            }),
        }),
    }),
})

export const {
    useApplyTextMutation,
    useSelectSimilarOneTextMutation,
    useEditTextMutation,
    useGenerateSimiliarTextMutation,
    useLazyFindByContentIdQuery,
    useLazyFindByRevisionIdQuery,
    useFavoriteMutation,
    useDeleteMutation,
} = TextApi
