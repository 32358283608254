import { FunctionComponent, useEffect, useState } from "react"
import { Checkbox, Input, Select, SelectProps } from "antd";
import { t } from "i18next";
import SvgQuestionMark from "../../assets/IconComponents/QuestionMark";
import IconSelect from "../../assets/IconComponents/IconSelect";
import { IBrandInfo } from "../../store/brandSlice";



interface IMarketingAudienceMetaAdsSettingsProps {
    adsCategory?: string
    beneficiary?: string
    payer?: string
    onAdsCategorySelection: (boolean) => void
    onAdsCategoryUpdated: (string) => void
    onBeneficiarySelection: (boolean) => void
    onBeneficiaryUpdated: (string) => void
    onPayerUpdated: (string) => void
    onBeneficiaryPayerUpdated: (beneficiary?: string, payer?: string) => void
    defaultAdsCategory?: string
    defaultBeneficiary?: string
    defaultPayer?: string
    brandName?: string
}


const MarketingMetaAdsSettings: FunctionComponent<
    IMarketingAudienceMetaAdsSettingsProps
> = (props) => {


    const adsCategoryOptions: SelectProps['options'] = [
        {
            label: 'Credit',
            value: 'Credit',

        },
        {
            label: 'Employment',
            value: 'Employment',

        },
        {
            label: 'Housing',
            value: 'Housing',

        },
    ];

    useEffect(() => {
        console.log(`${props.adsCategory} -- ${props.beneficiary} -- ${props.payer}`)
        setAdsCategorySelected(props.adsCategory !== undefined && props.adsCategory.length > 0)
        setBeneficiarySelected(props.beneficiary !== undefined && props.beneficiary.length > 0)
    }, [props])

    const [adsCategorySelected, setAdsCategorySelected] = useState<boolean | undefined>(undefined)
    const [beneficiarySelected, setBeneficiarySelected] = useState<boolean | undefined>(undefined)

    useEffect(() => { props.onAdsCategorySelection(adsCategorySelected) }, [adsCategorySelected])
    useEffect(() => { props.onBeneficiarySelection(beneficiarySelected) }, [beneficiarySelected])

    return (<div className="flex flex-col py-[12px] gap-[12px] w-full">

        <div className="border-1 h-[1px]"></div>

        <div className="flex flex-col gap-[24px]">
            {(props !== undefined) &&
                <div className="flex flex-col gap-[4px]">
                    <div className="flex flex-row gap-[2px]">
                        <div className="text-H7 text-black">Is Your Ad In A Special Category?</div>
                        <SvgQuestionMark
                            data-tooltip-id={"app-tooltip"}
                            data-tooltip-html={t("special-category-ads-tooltip")} />
                    </div>

                    <Checkbox className="text-[13px] font-normal" checked={props.adsCategory !== undefined && props.adsCategory.length > 0} onChange={(a) => {
                        if (!a.target.checked) {
                            props.onAdsCategoryUpdated(undefined)
                        }
                        else {
                            props.onAdsCategoryUpdated(props.defaultAdsCategory !== undefined ? props.defaultAdsCategory : "Credits")
                        }
                        setAdsCategorySelected(a.target.checked)
                    }}>Yes, my ad is related to credit, employment or housing.</Checkbox>

                </div>
            }
            {(adsCategorySelected) &&
                <div className="flex flex-row justify-between">
                    <div className="text-H8 font-semibold text-black">Category</div>
                    <Select className={"w-[400px]"} defaultValue={props.adsCategory} options={adsCategoryOptions} onChange={(a) => {
                        props.onAdsCategoryUpdated(a)
                    }} placeholder={"Select Ads Category"} suffixIcon={<IconSelect />} />
                </div>
            }
        </div>
        <div className="border-1 h-[1px]"></div>
        <div className="flex flex-col gap-[24px]">

            {(props !== undefined) &&
                < div className="flex flex-col gap-[4px]">
                    <div className="flex flex-row gap-[2px]">
                        <div className="text-H7 text-black">Do your ads target audiences in the EU?</div>
                        <SvgQuestionMark data-tooltip-id={"app-tooltip"}
                            data-tooltip-html={t("eu-audience-tooltip")} />
                    </div>
                    <Checkbox className="text-[13px] font-normal" checked={props.beneficiary !== undefined && props.beneficiary.length > 0} onChange={(a) => {
                        if (!a.target.checked) {
                            props.onBeneficiaryPayerUpdated(undefined, undefined)
                        } else {
                            props.onBeneficiaryPayerUpdated(props.defaultBeneficiary !== undefined ? props.defaultBeneficiary : (props.brandName ? props.brandName : " "), props.defaultPayer !== undefined ? props.defaultPayer : (props.brandName ? props.brandName : " "))
                        }
                        setBeneficiarySelected(a.target.checked)

                    }}>Yes, my ad sets target audiences in these areas.</Checkbox>
                </div>
            }
            {(beneficiarySelected) &&
                <div className="flex flex-col gap-[10px]">

                    <div className="flex flex-row justify-between">
                        <div className="text-H8 font-semibold text-black">Beneficiary & Payer</div>
                        <div className="flex flex-row gap-[12px]">
                            <Input className={"w-[200px]"} defaultValue={props.beneficiary} onChange={(a) => { props.onBeneficiaryUpdated(a.target.value); }} />
                            <Input className={"w-[200px]"} defaultValue={props.payer} onChange={(a) => { props.onPayerUpdated(a.target.value) }} />
                        </div>
                    </div>
                </div>
            }
        </div>
    </div >)
}

export default MarketingMetaAdsSettings;