import * as React from "react";
const SvgCreativeAdWand = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#FFFFFF"}
      d="m15.917 5.354-.709-1.562-1.562-.709 1.562-.708.709-1.563.708 1.563 1.563.708-1.563.709-.708 1.562Zm-10.042 0-.708-1.562-1.563-.709 1.563-.708.708-1.563.708 1.563 1.563.708-1.563.709-.708 1.562Zm10.042 10.042-.709-1.563-1.562-.708 1.562-.708.709-1.563.708 1.563 1.563.708-1.563.708-.708 1.563ZM2.896 18.083l-1.98-1.979a.812.812 0 0 1-.25-.531c-.013-.202.07-.392.25-.573l9.376-9.375a.823.823 0 0 1 .604-.25c.236 0 .437.083.604.25L13.375 7.5c.167.167.25.368.25.604a.823.823 0 0 1-.25.604L4 18.083c-.167.167-.35.25-.552.25-.201 0-.385-.083-.552-.25Zm.48-1.187 6.52-6.521-1.292-1.292-6.52 6.521 1.291 1.292Z"
    />
  </svg>
);
export default SvgCreativeAdWand;
