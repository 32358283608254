import { Close, InstagramColor } from "../../../assets/IconComponents";
import Icon from "../../../components/Icon";
import IconButton from "../../../components/IconButton";
import { ContentSize, SizeConstants } from "../../../constants/ContentSize";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { toggleContentWizard } from "../../../store/appSlice";
import { setNewContent } from "../../../store/contentSlice";


function Header() {
    const newContent = useAppSelector(k => k.content.newContent)
    const dispatch = useAppDispatch();

    const formatContentSizeEnum  = (contentSize) => {
        if(contentSize){
            if(contentSize === ContentSize.creative_post) {
                return "Post";
            }
            else if(contentSize === ContentSize.creative_landscape)
            {
                return "Landscape";
            }
            else if(contentSize === ContentSize.creative_story){
                return "Story";
            }
        }
        else{
            return "Post";
        }
    }
    return (
      <>
        <div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1">
          <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
            <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">{`Creative ${formatContentSizeEnum(
              newContent?.contentSize
            )}`}</div>
          </div>
          <div className="w-5 h-5 relative">
            <IconButton
              title={""}
              icon={Close}
              onClick={function (e: any): void {
                dispatch(setNewContent(undefined));
                dispatch(toggleContentWizard());
              }}
            />
          </div>
        </div>
      </>
    );
}

export default Header;