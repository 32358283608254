import * as React from "react";
import type { SVGProps } from "react";
const SvgMarketPlannerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M10 6.66667C10 6.43056 9.92014 6.23264 9.76042 6.07292C9.60069 5.91319 9.40278 5.83333 9.16667 5.83333L6.66667 5.83333C6.43056 5.83333 6.23264 5.91319 6.07292 6.07292C5.91319 6.23264 5.83333 6.43056 5.83333 6.66667C5.83333 6.90278 5.91319 7.10069 6.07292 7.26042C6.23264 7.42014 6.43056 7.5 6.66667 7.5L9.16667 7.5C9.40278 7.5 9.60069 7.42014 9.76042 7.26042C9.92014 7.10069 10 6.90278 10 6.66667ZM14.1667 13.3333C14.1667 13.0972 14.0868 12.8993 13.9271 12.7396C13.7674 12.5799 13.5694 12.5 13.3333 12.5L6.66667 12.5C6.43055 12.5 6.23264 12.5799 6.07292 12.7396C5.91319 12.8993 5.83333 13.0972 5.83333 13.3333C5.83333 13.5694 5.91319 13.7674 6.07292 13.9271C6.23264 14.0868 6.43055 14.1667 6.66667 14.1667L13.3333 14.1667C13.5694 14.1667 13.7674 14.0868 13.9271 13.9271C14.0868 13.7674 14.1667 13.5694 14.1667 13.3333ZM11.3333 10C11.3333 9.76389 11.2535 9.56597 11.0937 9.40625C10.934 9.24653 10.7361 9.16667 10.5 9.16667L9.66667 9.16667C9.43056 9.16667 9.23264 9.24653 9.07292 9.40625C8.91319 9.56597 8.83333 9.76389 8.83333 10C8.83333 10.2361 8.91319 10.434 9.07292 10.5937C9.23264 10.7535 9.43055 10.8333 9.66667 10.8333L10.5 10.8333C10.7361 10.8333 10.934 10.7535 11.0937 10.5937C11.2535 10.434 11.3333 10.2361 11.3333 10ZM2.5 4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5L15.8333 2.5C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667L17.5 15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5L4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333L2.5 4.16667ZM4.16667 4.16667L4.16667 15.8333L15.8333 15.8333L15.8333 4.16667L4.16667 4.16667Z" />
  </svg>
);
export default SvgMarketPlannerIcon;
