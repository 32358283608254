import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkedinColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    {...props}
  >
    <path
      fill="#0288D1"
      fillRule="evenodd"
      d="M15.778 18.5A2.222 2.222 0 0 0 18 16.278V4.722A2.222 2.222 0 0 0 15.778 2.5H4.222A2.222 2.222 0 0 0 2 4.722v11.556c0 1.227.995 2.222 2.222 2.222h11.556ZM6.888 8.278H4.668v7.555h2.222V8.278Zm-1.13-.89h.013c.689 0 1.118-.494 1.118-1.11-.013-.631-.429-1.111-1.105-1.111-.675 0-1.117.48-1.117 1.11 0 .618.429 1.112 1.092 1.112Zm7.353 8.445h2.222v-4.322c0-2.233-1.193-3.233-2.783-3.233-1.283 0-1.785.666-2.106 1.162V8.278H8.222v7.555h2.222v-4.127c-.004-.229-.008-.547.045-.676.175-.434.536-.884 1.203-.884.875 0 1.42.666 1.42 1.643v4.044Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkedinColor;
