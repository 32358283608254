import { useEffect, useState } from "react";
import { StompSessionProvider, useSubscription } from "react-stomp-hooks";
import { useAppSelector } from "../helpers/hooks";

export interface IWebsocketTopicListener {
    wsUrl: string,
    topic: string,
    onMessageReceived: (message) => void
}

function WebsocketTopicListener(props: IWebsocketTopicListener) {

    useSubscription(props.topic, (message) => { props.onMessageReceived(message.body) });

    return (
        <>
        </>
    );
}


function StompTopicListener(props: IWebsocketTopicListener) {

    return (
        <>
            <StompSessionProvider
                url={props.wsUrl}
            >
                <WebsocketTopicListener wsUrl={props.wsUrl} topic={props.topic} onMessageReceived={props.onMessageReceived}></WebsocketTopicListener>
            </StompSessionProvider>
        </>
    );
}



export default StompTopicListener;