function GetStartedFree() {
    return (<>
        <a
            className="group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 
	
	bg-gradient-to-b from-red-500 to-red-600 
 text-white   active:bg-red-800 active:text-red-100 focus-visible:outline-red-600 transition-colors text-shadow"
            href="/register"
        >
            <span>Access Beta</span>
        </a >

    </>);
}

export default GetStartedFree;