export class TextUtils {
    static calculateTextHeight(text: string, width: number, fontSize: string, fontFamily: string): number {
      const container = document.createElement('div');
      container.style.visibility = 'hidden';
      container.style.fontSize = fontSize;
      container.style.fontFamily = fontFamily;
      container.style.position = 'absolute';
      container.style.top = '-9999px';
      container.style.width = `${width}px`
      container.style.whiteSpace = "pre-wrap"
      container.innerHTML = text;
      
      document.body.appendChild(container);
      const height = container.offsetHeight;
      document.body.removeChild(container);
  
      return height;
    }

  static capitalizeFirstLetter(str) {
      if (str.length === 0) {
        return str;
      }
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    }
  }
  