import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandVoiceToneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <path opacity="0.5" d="M16.6666 9.30003C16.6666 7.82727 15.4727 6.63336 13.9999 6.63336C12.5272 6.63336 11.3333 7.82727 11.3333 9.30003V13.3667C11.3333 14.8395 12.5272 16.0334 13.9999 16.0334C15.4727 16.0334 16.6666 14.8395 16.6666 13.3667V9.30003Z" fill="#FF5555" />
    <path d="M19.3334 13.3334C19.3334 13.1565 19.2632 12.987 19.1382 12.8619C19.0131 12.7369 18.8436 12.6667 18.6667 12.6667C18.4899 12.6667 18.3204 12.7369 18.1953 12.8619C18.0703 12.987 18.0001 13.1565 18.0001 13.3334C18.0001 14.3942 17.5787 15.4116 16.8285 16.1618C16.0784 16.9119 15.0609 17.3334 14.0001 17.3334C12.9392 17.3334 11.9218 16.9119 11.1717 16.1618C10.4215 15.4116 10.0001 14.3942 10.0001 13.3334C10.0001 13.1565 9.92984 12.987 9.80482 12.8619C9.67979 12.7369 9.51023 12.6667 9.33341 12.6667C9.1566 12.6667 8.98703 12.7369 8.86201 12.8619C8.73699 12.987 8.66675 13.1565 8.66675 13.3334C8.66892 14.6313 9.14391 15.8839 10.0028 16.857C10.8618 17.83 12.0458 18.4568 13.3334 18.62V20H12.0001C11.8233 20 11.6537 20.0703 11.5287 20.1953C11.4037 20.3203 11.3334 20.4899 11.3334 20.6667C11.3334 20.8435 11.4037 21.0131 11.5287 21.1381C11.6537 21.2631 11.8233 21.3334 12.0001 21.3334H16.0001C16.1769 21.3334 16.3465 21.2631 16.4715 21.1381C16.5965 21.0131 16.6667 20.8435 16.6667 20.6667C16.6667 20.4899 16.5965 20.3203 16.4715 20.1953C16.3465 20.0703 16.1769 20 16.0001 20H14.6667V18.62C15.9544 18.4568 17.1384 17.83 17.9973 16.857C18.8563 15.8839 19.3312 14.6313 19.3334 13.3334Z" fill="#FF5555" />
  </svg>

);
export default SvgBrandVoiceToneIcon;
