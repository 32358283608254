import * as React from "react";
const SvgRoundAudioPauseButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={42}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <rect width={40} height={40} fill="#F55" rx={20} />
      <rect width={39} height={39} x={0.5} y={0.5} stroke="#F55" rx={19.5} />
      <g clipPath="url(#b)">
        <path fill="#F55" d="M11 11h18v18H11z" />
        <path
          fill="#fff"
          d="M22.25 25.25c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06v-7.5c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.5c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06v7.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-1.5Zm-6 0c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06v-7.5c0-.412.147-.766.44-1.06.294-.293.648-.44 1.06-.44h1.5c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06v7.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-1.5Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M11 11h18v18H11z" />
      </clipPath>
      <filter
        id="a"
        width={40}
        height={42}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_34_753" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_34_753"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgRoundAudioPauseButton;
