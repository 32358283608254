import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import { Popover } from "react-tiny-popover";
import { Copy, Delete } from "../../assets/IconComponents";
import { useAppSelector } from "../../helpers/hooks";
import PrimaryButton from "../PrimaryButton";
import DuplicateLoopPop from "./DuplicateLoopPop";
import SvgMoreIcon from "../../assets/IconComponents/MoreIcon";
import ActionSheet from "../ActionSheet/ActionSheet";
import Icon from "../Icon";
import ShareLoop from "../ShareLoop/ShareLoop";
import InputText from "../InputText";

interface DuplicateLoopPopupProps {
    duplicateLoopWithName: (name: string | undefined) => void
    className?: string
    isPopoverOpen: boolean
    setIsDuplicatePopoverOpen?: (boolean) => void
}

const DuplicateLoopPopup: FunctionComponent<DuplicateLoopPopupProps> = (props: DuplicateLoopPopupProps) => {
    const isContentLoading = useAppSelector(k => k.content.isContentLoading)
    const [name, setName] = useState<string | undefined>()
    const [actionSheetOpened, setActionSheetOpened] = useState(false)

    return (
        <>
            <div className="max-lg:hidden lg:flex flex-row items-center">
                <Popover
                    isOpen={props.isPopoverOpen}
                    positions={['bottom']}
                    align="end"
                    padding={10}
                    onClickOutside={() => { props.setIsDuplicatePopoverOpen && props.setIsDuplicatePopoverOpen(false) }}
                    content={
                        <DuplicateLoopPop
                            closePopup={() => props.setIsDuplicatePopoverOpen && props.setIsDuplicatePopoverOpen(false)}
                            duplicateLoopWithName={e => {
                                props.duplicateLoopWithName(e)
                                props.setIsDuplicatePopoverOpen && props.setIsDuplicatePopoverOpen(false)
                            }}
                        />}
                >
                    <div className={`h-[36px] w-full  ${props.className}`} onClick={() => props.setIsDuplicatePopoverOpen && props.setIsDuplicatePopoverOpen(!props.isPopoverOpen)}>
                        <PrimaryButton
                            noFill
                            icon={<Copy fill={isContentLoading ? "#ffffff" : "#121315"} className="mr-2" />}
                            className="w-[130px] ml-1 md:ml-2 h-full"
                            isDisabled={isContentLoading}
                            title={t('duplicate')}
                            onClick={() => {
                                props.setIsDuplicatePopoverOpen && props.setIsDuplicatePopoverOpen(!props.isPopoverOpen)
                            }} />
                    </div>
                </Popover>
            </div>
            <div className="lg:hidden max-lg:flex w-full">
                <ActionSheet
                    className="w-full"
                    isExpanded={actionSheetOpened}
                    setIsExpanded={setActionSheetOpened}
                    triggerClassName="w-full mt-2"
                    triggerView={(
                        <PrimaryButton
                            noFill
                            icon={<Copy fill={isContentLoading ? "#ffffff" : "#121315"} className="mr-2" />}
                            className="w-full h-[36px] "
                            isDisabled={isContentLoading}
                            title={t('duplicate')}
                            onClick={() => {
                                setActionSheetOpened(true)
                            }} />
                    )}
                    title="Duplicate"
                    body={(
                        <div className="w-full p-[15px] pt-[5px] flex flex-col">
                            <div className="flex flex-col">
                                <InputText
                                    title="Content Name"
                                    placeholder="Name Content (Optional)"
                                    onChange={e => {
                                        setName(e)
                                    }}
                                />
                                <PrimaryButton
                                    className="mt-2 h-[40px]"
                                    title={t('duplicate-loop-0') ?? ""}
                                    onClick={() => {
                                        props.duplicateLoopWithName(name)
                                        setActionSheetOpened(false)
                                    }}
                                />
                            </div>
                        </div>
                    )}

                />

            </div>
        </>
    );
}

export default DuplicateLoopPopup;