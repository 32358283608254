import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import dayjs from "dayjs";
import { MarketingPlannerResponse, useLazyGetLatestCampaignsQuery } from "../../services/MarketingPlanner";
import CampaignTable from "./components/CampaingTable";
import './newesthomepage.css';




const Campaigns = () => {
    const navigate = useNavigate();
    const [getLatestCampaigns, { data: latestCampaigns, isLoading: latestCampaignIsLoading }] = useLazyGetLatestCampaignsQuery()
    const [latestCampaignList, setLatestCampaigns] = useState<(MarketingPlannerResponse & { startTime: string; duration: string; status: string | undefined })[]>([]);


    useEffect(() => {
        getLatestCampaigns({ count: 5 });
        if (latestCampaigns?.length) {
            const formattedCampaigns = latestCampaigns.map(campaign => {
                const startTime = dayjs(campaign.startDate).format('DD MMM');
                const durationInDays = dayjs(campaign.endDate).diff(dayjs(campaign.startDate), 'day');
                const duration = `${durationInDays} Days`;
                const status = campaign?.status?.replace('%', '')

                return {
                    ...campaign,
                    startTime,
                    duration,
                    status
                };
            });

            setLatestCampaigns(formattedCampaigns);
        }
    }, [latestCampaigns])

    return (
        <>
            {
                latestCampaignList?.length > 0 && (
                    <div className="w-full flex flex-col gap-5">
                        <div className="mt-[50px] mb-[20px]">
                            <div className="flex justify-between items-center w-full">
                                <span className="text-H4 font-medium text-black   truncate text-ellipsis overflow-hidden">
                                    Campaigns
                                </span>
                                <button
                                    className="text-red text-BodyMedium underline font-inter-tight"
                                    onClick={() => {
                                        navigate("/app/marketing-planner")
                                    }}
                                >
                                    {t("see-all")}
                                </button>
                            </div>

                            <hr className="bg-border mt-1" />
                        </div>

                        <CampaignTable campaigns={latestCampaignList} />
                    </div>

                )
            }
        </>
    );
};

export default Campaigns;
