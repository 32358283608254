import ContentContainer from "../ContentContainer";
import { useEffect, useState } from "react";
import { IContentTypeData } from "./ContentTypeSelector";
import ContentTypeSelectorItem from "./ContentTypeSelectorItem";

export interface IContentTypeSelectorPopupProps {
    data: IContentTypeData[]
    closePopup: () => void
    selectedContentTypes: IContentTypeData[]
    selectedContentTypesChanged: (data: IContentTypeData[]) => void
}

function ContentTypeSelectorPopup(props: IContentTypeSelectorPopupProps) {
    const [selectedItems, setSelectedItems] = useState<IContentTypeData[]>()

    useEffect(() => {
        setSelectedItems(props.selectedContentTypes)
    }, [props.selectedContentTypes])


    return (
        <ContentContainer
            hoverable={false}
            className="w-[250px] h-[120px]  overflow-auto"
            children={
                <>
                    {/*Body*/}
                    <div className="p-[12px]">
                        {
                            props.data.map(k => (
                                <ContentTypeSelectorItem
                                    data={k}
                                    selected={!!(selectedItems?.find((items) => items.id === k.id))}
                                    itemSelected={(e, isSelected) => {
                                        if (isSelected) {
                                            if (selectedItems) {
                                                const newSelectedItems = [...selectedItems, e]
                                                setSelectedItems(newSelectedItems)
                                                props.selectedContentTypesChanged(newSelectedItems)
                                            } else {
                                                setSelectedItems([e])
                                                props.selectedContentTypesChanged([e])
                                            }
                                        } else {
                                            if (selectedItems) {
                                                const newSelectedItems = selectedItems.filter((a) => a.id !== e.id)
                                                setSelectedItems([...newSelectedItems])
                                                props.selectedContentTypesChanged([...newSelectedItems])
                                            } else {
                                                console.log(`can not remove from empty array`)
                                            }
                                        }
                                    }} />
                            ))
                        }
                    </div>
                </>
            } />
    );
}

export default ContentTypeSelectorPopup;
