import { PickerModel } from "../models/PickerModel";

export const Educations: PickerModel[] = [
    {
        title: "All",
        value: "0"
    },
    {
        title: "Primary",
        value: "1"
    },
    {
        title: "Secondary",
        value: "2"
    },
    {
        title: "Bachelor",
        value: "3"
    },
]



