import { useEffect, useState } from "react";
import Modal from 'react-modal';
import SvgPlusTwo from "../../../assets/IconComponents/PlusTwo";
import SvgCloseLittle from "../../../assets/IconComponents/CloseLittle";
import InputText from "../../../components/InputText";
import { TechnicalDocumentTypes } from "../../../constants/TechnicalDocumentTypes";
import Select from 'react-select'
import UploadCompanyMaterialNew from "../../../components/UploadFile/UploadCompanyMaterialNew";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";
import SvgDelete from "../../../assets/IconComponents/Delete";
import SvgDownload from "../../../assets/IconComponents/Download";
import { useLazyDownloadQuery, useLazySummarizeQuery } from "../../../services/FileApi";
import { blobToDataURL } from "blob-util";
import { toggleLoading } from "../../../store/appSlice";
import { t } from "i18next"

const BrandHubCustomTraining = () => {
    const dispatch = useAppDispatch();
    const brandInfo = useAppSelector((k) => k.brand.newBrand);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentName, setDocumentName] = useState("")
    const [documentType, setDocumentType] = useState("")
    const [documentNameError, setDocumentNameError] = useState("")
    const [documentTypeError, setDocumentTypeError] = useState(false);
    const [file, setFile] = useState<any>()

    const handleAddTraining = () => {
        const date = new Date();
        const time = date.getTime();
        const isValid = handleValidation();
        if (isValid) {
            dispatch(
                setNewBrandInfo({
                    ...brandInfo,
                    technicalLinks: [...brandInfo.technicalLinks ?? [], { id: file.uploadedId, name: file.name, file_type: documentType, uploaded: true, file_name: documentName, create_date: time, summary: file.summary }]
                })
            );
            setDocumentName("");
            setDocumentType("");
            setFile(undefined);
            setIsModalOpen(false);
        }
    }

    useEffect(() => {
        if (file) {
            if (!documentName)
                setDocumentName(file.name)
        }
    }, [file])

    const [
        downloadFile,
        {
            data: fileDownloaddData,
            isSuccess: fileDownloaded,
            isLoading: fileDownloading,
            currentData: dt,
        },
    ] = useLazyDownloadQuery();



    const handleItemRemoval = (id) => {
        dispatch(
            setNewBrandInfo({
                ...brandInfo,
                technicalLinks: brandInfo.technicalLinks?.filter(obj => obj.id !== id),
            })
        )
    }

    const downloadFileToDesktop = (uploadId: any) => {
        downloadFile({
            id: uploadId,
        })
            .unwrap()
            .then(async (k) => {
                const dataUrl = await blobToDataURL(k.file);

                var link = document.createElement("a");
                link.download = k.name;
                link.href = dataUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    const handleValidation = () => {
        !documentName ? setDocumentNameError("Document Name can't be empty.") : setDocumentNameError("");
        !documentType ? setDocumentTypeError(true) : setDocumentTypeError(false)
        if (!file || !documentType || !documentName) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <>
            <div className="flex flex-col w-full gap-2 m-2  mt-6">
                <div className="flex flex-row justify-between">
                    <div className="h7 text-black">
                        Trained Documents
                    </div>
                    <div className="flex flex-row h8 text-red cursor-pointer" onClick={() => {
                        setIsModalOpen(true)
                    }}>
                        <SvgPlusTwo className="mr-2 self-center" />
                        Add New
                    </div>
                </div>
                <div className="flex items-start gap-1 self-stretch flex-wrap">
                    {brandInfo.technicalLinks?.map((link, index) => {
                        let date = new Date(link.create_date ? link.create_date : "")
                        let localeDate = date.toLocaleDateString('en-GB')
                        return (
                            <div key={index} className="flex p-[14px] min-w-[350px] flex-col content-center items-start gap-[20px] flex-1 bg-white rounded" style={{ border: "1px solid #EAEBE6", borderRadius: "6px" }}>
                                <div className="flex flex-col content-center items-start gap-[4px]">
                                    <div className="h7 text-black">
                                        {link.file_name}
                                    </div>
                                    <div className="body-small text-gray">
                                        {`${t(link.file_type ? link.file_type : "")} - ${localeDate ? localeDate : ""}`}
                                    </div>
                                </div>
                                <div className="flex items-center gap-[10px] self-stretch cursor-pointer" style={{ justifyContent: "end" }}>
                                    <SvgDownload fill="#8E8E8E" onClick={() => downloadFileToDesktop(link.id)} />
                                    <SvgDelete fill="#8E8E8E" onClick={() => handleItemRemoval(link.id)} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                ariaHideApp={false}
                className=" absolute top-2/4 left-2/4 bg-white inline-flex flex-col w-[450px] h-[443px]"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.2)',
                    },
                    content: {
                        transform: `translate(-50%, -50%)`,
                        border: '1px solid #EAEBE6',
                        borderRadius: "12px"
                    }
                }}
            >
                <div className="flex self-stretch p-[15px] justify-between items-center">
                    <div className="h6 text-black">
                        Add New
                    </div>
                    <SvgCloseLittle fill="#000" viewBox="0 0 20 20" className="mt-[3px] ml-[3px] cursor-pointer" onClick={() => {
                        setIsModalOpen(false)
                        setFile(null)
                        setDocumentName("")
                        setDocumentType("")
                    }} />
                </div>
                <hr />
                <div className="flex self-stretch p-[15px] flex-col items-start gap-3">
                    <div className="flex flex-col items-start gap-[12px] self-stretch">
                        <InputText
                            title={"Document Name"}
                            placeholder={"Document Name"}
                            onChange={(e) => {
                                setDocumentName(e)
                            }}
                            value={documentName}
                            innerClassName="rounded-[4px] h-[40px] p-[12px] bg-white"
                            forcedBg="bg-white"
                            error={documentNameError}
                            maxLengthAllowed={100}
                        />
                        <div className="flex flex-col items-start gap-2 self-stretch">
                            <div className="h8 text-black">
                                Choose a Type
                            </div>
                            <Select
                                className={`!rounded-[8px] self-stretch h-[40px] bg-white text-black border-solid border-1 border-white ${documentTypeError ? 'border-red' : ''}`}
                                placeholder={"Please Select"}
                                options={TechnicalDocumentTypes}
                                onChange={(e) => {
                                    if (e) {
                                        setDocumentType(e.value)
                                    }
                                }}
                            />
                        </div>
                        <div className="flex flex-col w-full md:flex-row">
                            <UploadCompanyMaterialNew
                                maxFileCount={1}
                                description="Upload .doc or .pdf .ppt or .txt"
                                filesChanged={async (f: any) => {
                                    setFile(f[0])
                                }}
                                uploadEnabled={file ? false : true}
                                brandHubUpload={true}
                                deleteEnabled={true}
                            />
                        </div>


                    </div>


                </div>
                <div className="flex self-stretch justify-end items-center gap-[10px] p-[15px]">
                    <div className="flex h8 cursor-pointer px-[8px] py-[10px] justify-center h-[36px] items-center gap-1 rounded self-stretch text-black bg-white cursor-pointer"
                        onClick={() => {
                            setIsModalOpen(false)
                            setFile(null)
                            setDocumentName("")
                            setDocumentType("")
                        }
                        }
                        style={{ border: "1px solid #EAEBE6" }}
                    >
                        Cancel
                    </div>
                    <div className={`flex h8 px-[8px] py-[10px] justify-center items-center gap-1 rounded h-[36px]  self-stretch text-white  cursor-pointer ${(file && documentName && documentType) ? "bg-red" : "bg-gray"}`} onClick={() => {
                        handleAddTraining()
                    }}>
                        Add Training
                    </div>
                </div>

            </Modal>
        </>

    )
}

export default BrandHubCustomTraining;