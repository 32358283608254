import React, { Component } from 'react';
import { Checkbox, Radio,ConfigProvider } from 'antd';
export default class Toolbar extends Component {

    state = {
        currentZoom: 'Weekly'
    };
    handleZoomChange = (e) => {
        if (this.props.onZoomChange) {
            this.props.onZoomChange(e.target.value)
        }
        this.setState({
            currentZoom: e.target.value
        });
    }
    render() {

        return (
            <div className="tool-bar">
                 <ConfigProvider
                        theme={{
                        components: {
                            Radio: {
                                buttonSolidCheckedBg: '#FFE9E9',
                                buttonSolidCheckedColor: '#FF5555',
                                buttonSolidCheckedHoverBg: '#FFE9E9'
                            },
                        },
                        }}
                    >
                <Radio.Group  buttonStyle="solid" value={this.state.currentZoom} onChange={(e) => this.handleZoomChange(e)}>
                    {['Daily', 'Weekly'].map(value => (
                        <Radio.Button value={ value } key={value}>{ value }</Radio.Button>
                    ))}
                </Radio.Group>
                </ConfigProvider>
                {this.props.isFilterEnabled &&            
                    <Checkbox className={'ml-3'} onChange={(e) => this.props.onActiveCampaignFilterChange(e)}>Show only active campaigns</Checkbox>
                }
            </div>
        );
    }
}
