import { PickerModel } from "../models/PickerModel";

export const AgeGroups: PickerModel[] = [
    {
        title: "All",
        value: "0"
    },
    {
        title: "0-19",
        value: "1"
    },
    {
        title: "20-34",
        value: "2"
    },
    {
        title: "35-54",
        value: "3"
    },
    {
        title: "55-64",
        value: "4"
    },
    {
        title: "65+",
        value: "5"
    }
]





