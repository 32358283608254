import * as React from "react";
const SvgTritoneSelectSizeLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M12.667 15.333H8a.667.667 0 0 1-.667-.666V8A.667.667 0 0 1 8 7.333h4.667a.667.667 0 0 1 .666.667v6.667a.667.667 0 0 1-.666.666Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M12.667 20.667H8A.667.667 0 0 1 7.333 20v-2.667A.667.667 0 0 1 8 16.667h4.667a.667.667 0 0 1 .666.666V20a.667.667 0 0 1-.666.667Z"
    />
    <path
      fill="#F55"
      d="M20 20.667h-4.667a.667.667 0 0 1-.666-.667v-5.333a.667.667 0 0 1 .666-.667H20a.667.667 0 0 1 .667.667V20a.666.666 0 0 1-.667.667Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M20 12.667h-4.667a.667.667 0 0 1-.666-.667V8a.667.667 0 0 1 .666-.667H20a.667.667 0 0 1 .667.667v4a.667.667 0 0 1-.667.667Z"
      opacity={0.5}
    />
  </svg>
);
export default SvgTritoneSelectSizeLogo;
