import { t } from "i18next";
import { FunctionComponent, MutableRefObject, RefObject, useEffect, useState } from "react";
import SvgArrowUp from "../../../assets/IconComponents/ArrowUp";
import SvgImportIcon from "../../../assets/IconComponents/ImportIcon";
import SvgUploadIcon from "../../../assets/IconComponents/UploadIcon";
import ContentContainer from "../../../components/ContentContainer";
import InputText from "../../../components/InputText";
import PrimaryButton from "../../../components/PrimaryButton";
import TextView from "../../../components/TextView";
import UploadFile from "../../../components/UploadFile/UploadFile";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import FontSelector from "../../../components/FontSelector/FontSelector";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";
import { useLazyListFontsQuery } from "../../../services/FontApi";

interface BrandHubBrandVisualsProps {
  ref: RefObject<HTMLDivElement>
  expanded: () => void
  className?: string
}

const BrandHubBrandVisuals: FunctionComponent<BrandHubBrandVisualsProps> = (props: BrandHubBrandVisualsProps) => {
  const [collapsed, setCollapsed] = useState(false)
  const brandInfo = useAppSelector(k => k.brand.newBrand)
  const dispatch = useAppDispatch()
  const [getFonts, { data: fontsToShow }] = useLazyListFontsQuery()
  const [colors, setColors] = useState({
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: ""
  })

  useEffect(() => {
    let c = Object.values(colors)
    dispatch(setNewBrandInfo({ ...brandInfo, brandColors: c }))
  }, [colors])

  useEffect(() => {
    getFonts()
  }, [])

  const getColor = (at: number) => {
    if (brandInfo.brandColors && brandInfo.brandColors) {
      let colors = brandInfo.brandColors
      if (colors.length > at) {
        return colors[at]
      }
    }

    return undefined
  }
  return (
    <ContentContainer
      ref={props.ref}
      className={`mt-[20px] w-full ${props.className}`}
      hoverable={false}
      children={
        <>
          <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
            <span className="text-H6 text-black ml-[15px] ">
              {t("brand-visuals")}
            </span>
            <button
              onClick={(e) => {
                setCollapsed(!collapsed);
                props.expanded();
              }}
            >
              <SvgArrowUp
                width={22}
                height={22}
                className={`mr-3 transition-all transform-gpu ${collapsed ? "rotate-180" : "rotate-0"
                  }`}
              />
            </button>
          </div>
          {!collapsed && (
            <div className="flex flex-col w-full p-[20px]">
              <div className="flex flex-col w-full md:flex-row">
                <UploadFile
                  uploadedFiles={brandInfo.selectedCompanyLogos}
                  title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                  description="Upload .jpg, .png or .svg files. Max 25mb."
                  filesChanged={(f) => {
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedCompanyLogos: f.map((k: any) => ({
                          id: k.uploadedId,
                          name: k.name,
                          uploaded: true,
                        })),
                      })
                    );
                  }}
                />
                <UploadFile
                  uploadedFiles={brandInfo.selectedCompanyIcons}
                  className="mt-2"
                  title="Add up to 2 Icon Files (Ex. Light and Dark Icons)"
                  description="Upload .jpg, .png or .svg files. Max 25mb."
                  filesChanged={(f) => {
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedCompanyIcons: f.map((k: any) => ({
                          id: k.uploadedId,
                          name: k.name,
                          uploaded: true,
                        })),
                      })
                    );
                  }}
                />
              </div>

              <div className="flex flex-col mt-[20px]">
                <p className="text-H8 text-black">{t("brand-colors")}</p>
                <div className="flex flex-row mt-[10px]">
                  <ColorPicker
                    popoverClassName="max-md:ml-[150px]"
                    className="h-[60px] w-[60px]"
                    selectedColor={getColor(0)}
                    colorSelected={(e) => {
                      setColors((k) => ({ ...k, color1: e ?? "" }));
                    }}
                  />
                  <ColorPicker
                    selectedColor={getColor(1)}
                    className="ml-2 h-[60px] w-[60px]"
                    colorSelected={(e) => {
                      setColors((k) => ({ ...k, color2: e ?? "" }));
                    }}
                  />
                  <ColorPicker
                    selectedColor={getColor(2)}
                    className="ml-2 h-[60px] w-[60px]"
                    colorSelected={(e) => {
                      setColors((k) => ({ ...k, color3: e ?? "" }));
                    }}
                  />
                  <ColorPicker
                    selectedColor={getColor(3)}
                    className="ml-2 h-[60px] w-[60px]"
                    colorSelected={(e) => {
                      setColors((k) => ({ ...k, color4: e ?? "" }));
                    }}
                  />
                  <ColorPicker
                    selectedColor={getColor(4)}
                    popoverClassName="max-md:mr-[150px]"
                    className="ml-2 h-[60px] w-[60px]"
                    colorSelected={(e) => {
                      setColors((k) => ({ ...k, color5: e ?? "" }));
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-[20px]">
                <FontSelector
                  selectedFont={brandInfo.selectedCompanyPrimaryFont}
                  fonts={fontsToShow ?? []}
                  title={t("primary-font")}
                  fontSelected={(e) => {
                    console.log("Primary Font is", e);
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedCompanyPrimaryFont: e,
                      })
                    );
                  }}
                />
                <FontSelector
                  selectedFont={brandInfo.selectedCompanySecondaryFont}
                  fonts={fontsToShow ?? []}
                  title={t("secondary-font")}
                  className="mt-[20px]"
                  fontSelected={(e) => {
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedCompanySecondaryFont: e,
                      })
                    );
                  }}
                />
                <FontSelector
                  selectedFont={brandInfo.selectedCompanyAccentFont}
                  fonts={fontsToShow ?? []}
                  title={t("accent-font")}
                  className="mt-[20px]"
                  fontSelected={(e) => {
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedCompanyAccentFont: e,
                      })
                    );
                  }}
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
}

export default BrandHubBrandVisuals;
