import * as React from "react";
import type { SVGProps } from "react";
const SvgBlog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M4.222 18h11.556A2.222 2.222 0 0 0 18 15.778V4.222A2.222 2.222 0 0 0 15.778 2H4.222A2.222 2.222 0 0 0 2 4.222v11.556C2 17.005 2.995 18 4.222 18Zm9.556-8.889h.444c.368 0 .667.299.667.667v2a3.111 3.111 0 0 1-3.111 3.11H8.222a3.111 3.111 0 0 1-3.11-3.11V8.222a3.111 3.111 0 0 1 3.11-3.11H10a3.111 3.111 0 0 1 3.111 3.11v.222c0 .368.299.667.667.667Zm-3.334-1.333H8.222a.444.444 0 1 0 0 .889h2.222a.444.444 0 1 0 0-.89Zm-2.222 4.444h3.556a.444.444 0 1 0 0-.889H8.222a.444.444 0 1 0 0 .89Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBlog;
