import { Button, DatePicker, Modal, Select } from "antd";
import Icon from "../Icon";
import questionMark from "../../assets/IconComponents/QuestionMark";
import dayjs from "dayjs";
import { periods } from "../MarketingPlanner/periodData";
import { useState,useEffect } from "react";
import ActivitiesIcon from "../../assets/IconComponents/ActivitiesIcon";
import {
    DeleteMarketingPlannerByIdApiArg,
    MarketingPlannerResponse,
    useDeleteMarketingPlannerByIdMutation as useDeleteByIdMutation, useLazyGetAllMarketingPlannerQuery, useUpdateMarketingPlannerMutation
} from "../../services/MarketingPlanner";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import {setGanttData, setSavedData} from "../../store/plannerSlice";
import {useNavigate} from "react-router-dom";
import {reformatText} from "../../helpers/Utils";

const GanttEditModal = ({selectedItem, close, isOpen, isEditMode, ganttRef, marketingData}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [datePeriods, setDatePeriods] = useState(periods)
    const [processData, setProcessData] = useState({
        startDate: selectedItem?.start_date ? dayjs(selectedItem?.start_date) : dayjs(),
        endDate: selectedItem?.end_date ? dayjs(selectedItem?.end_date) : dayjs(),
        eventPeriod:  dayjs(selectedItem?.end_date).diff(dayjs(selectedItem?.start_date),"day")*periods[0].value
    })
    const [deleteById, { data: deletedData, isLoading: deleteIsLoading }] = useDeleteByIdMutation()
    const [updateMarketingPlanner, { data: updatedData, isLoading: updateIsLoading }] = useUpdateMarketingPlannerMutation()
    const {ganttData, savedData} = useAppSelector((k) => k.planner);
    const [getAll, { data: createdData, isLoading:createIsLoading }] = useLazyGetAllMarketingPlannerQuery()
    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)

    useEffect(() => {
        console.log("useEffect",processData.startDate.valueOf())
        console.log("useEffect2",processData.endDate.valueOf())
        calculatePeriod(processData.startDate.valueOf(), processData.endDate.valueOf())
    }, []);

    const handleOk = () => {

        if (!isEditMode) {
            navigate(`/app/content-calendar?selectedCampaign=${selectedItem.id}`);
        } else {

            const updatedmarketingData = { ...marketingData, startDate:processData.startDate.valueOf(),endDate:processData.endDate.valueOf() };
            updateMarketingPlanner({marketingPlannerRequest: updatedmarketingData}).then(aa => {
                dispatch(setSavedData(aa))
                close();
            })

        }
        close();
    };

    const handleCancel = () => {
        close();
    };
    const handleDelete = () => {
        console.log("selectedItem",selectedItem)
        const param: DeleteMarketingPlannerByIdApiArg = {
            rootId:  selectedItem.rootId ,
            id: selectedItem.id
        }

        console.log("param",param)
        deleteById(param).then(aa => {
            console.log(aa)
            if (isEditMode)
            {
                dispatch(setSavedData(aa))
                close();
            }else {
                // @ts-ignore
                getAll({brandId:lastSelectedBrandId}).then(aa=> {
                    dispatch(setSavedData(aa))
                    close();
                    //console.log(aa)
                })

            }

        })


    };

    function refreshData() {
        const newData = savedData.filter(item => item.id !== selectedItem.id)
        dispatch(setGanttData(newData))

        // @ts-ignore
        if (ganttRef.current?.clearAll) {
            // @ts-ignore
            ganttRef.current?.clearAll()
        }
        // @ts-ignore
        if (ganttRef.current?.parse) {
            // @ts-ignore
            ganttRef.current?.parse({
                data: newData.map((item, i) => {
                    const ganttObj = {
                        realId: item.id,
                        id: item.id,
                        text: item.headerType === "EVENT" ? item.eventType : item.campaignType,
                        activity: item.activitySize,
                        start_date: dayjs(item.startDate).format('YYYY-MM-DD'),
                        end_date: dayjs(item.endDate).format('YYYY-MM-DD')
                    } as any
                    if (item.rootId !== item.id) {
                        savedData.forEach((baseItem, index) => {
                            if (item.rootId === baseItem.id) {
                                ganttObj.parent = baseItem.id
                            }
                        })
                    }
                    if (item.rootId === item.id) {
                        ganttObj.open = true
                    }

                    return ganttObj as MarketingPlannerResponse
                })
            });
        }
        close();
    }

    const handleForm = (field, value) => {
        console.log(value)
        setProcessData(prevState => ({...prevState, [field]: value}))
    }

    const calculatePeriod = (startDate, endDate) => {
        const period = ((endDate - startDate) - ((endDate - startDate) % periods[0].value))/periods[0].value
        const periodVal = period * periods[0].value
        let hasPeriod = false;
        periods.forEach(item => {
            if (item.value === periodVal) {
                hasPeriod = true;
            }
        })
        if (!hasPeriod) {
            periods.push({label: period + ' days', value: period*periods[0].value})
            setDatePeriods(periods)
        }
        handleForm('eventPeriod', periodVal)
        console.log('eventPeriod', periodVal)
        console.log('eventPeriods', endDate - startDate)
    }

    return (
        <Modal
            title={<div className={'flex items-center'}>
                <div className={'w-[4px] h-[40px] bg-acti'}></div>
                <div className={'ml-2'}>
                    <div>{reformatText(selectedItem?.text)}</div>
                    <div className={'font-thin'}>{selectedItem?.description || 'description'}</div>
                </div>
            </div>}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={() => (
                <div className={'flex justify-between'}>
                    <div>
                        <Button danger onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleCancel} className={'mr-2'}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleOk}>
                            {isEditMode ? 'Save': 'See Activities'}
                        </Button></div>
                </div>
            )}
        >
            <div className={'py-3 w-full'}>
                <hr className={'mb-4'} />
                <div className="flex flex-row w-[37.3%]">
                    <div className=" text-H8 text-black mb-2 flex items-center"
                         title={"Determine the campaign's active duration with a start and end date. This scheduling ensures a structured approach for campaign planning before, during, and after the event."}
                    >
                        Timeline*
                        <Icon
                            width="20"
                            height="20"
                            component={questionMark}
                            viewBox="0 0 20 20"
                            className="ml-1 inline-block"
                        />
                    </div>
                </div>
                <div className={'flex w-full justify-between'}>
                    <div className={'w-1/3 mr-2'}>
                        <DatePicker
                            title={"Timeline*"}
                            value={dayjs(processData.startDate)}
                            disabled={!isEditMode}
                            className={'w-full'}
                            onChange={(e) => {
                                let val = e
                                if (!e?.valueOf()) {
                                    val = dayjs()
                                }
                                handleForm('startDate', dayjs(val))
                                handleForm('endDate', dayjs(val.valueOf() + processData.eventPeriod) )
                                /*if (processData.endDate) {
                                    calculatePeriod(dayjs(e).valueOf(), processData.endDate)
                                }*/
                            }}
                        />
                    </div>
                    <div className={'w-1/3 mr-2'}>
                        <Select
                            placeholder={'Choose period'}
                            className={'w-full'}
                            value={processData.eventPeriod}
                            disabled={!isEditMode}
                            onChange={(e) => {
                                console.log(e)
                                handleForm('endDate', dayjs(dayjs(processData.startDate).valueOf() + e))
                                handleForm('eventPeriod', e)
                            }}
                            options={datePeriods}
                        />
                    </div>
                    <div className={'w-1/3'}>
                        <DatePicker placeholder="End date" value={dayjs(processData.endDate)}
                                    disabled
                                    onChange={(e) => {
                                        handleForm('endDate', dayjs(e))
                                        if (processData.startDate) {
                                            //console.log(((dayjs(e).valueOf() - dayjs(processData.startDate).valueOf()) - ((dayjs(e).valueOf() - dayjs(processData.startDate).valueOf()) % periods[0].value))/periods[0].value)
                                            //handleForm('eventPeriod', dayjs(e).valueOf() - processData.eventPeriod)
                                            calculatePeriod(processData.startDate, dayjs(e).valueOf())
                                        }
                                    }}
                                    className={'w-full'}/>
                    </div>
                </div>
                <div className={'p-3 flex mt-4 border-1 rounded-[6px] items-center'}>
                    <div><ActivitiesIcon className={'w-[30px] h-[30px]'} /></div>
                    <div className={'ml-2'}>
                        <span className={'font-bold'}>You’ve {selectedItem?.activitySize || 0} activities within this campaign.</span>
                        {isEditMode &&
                            <span className={'font-inter-tight'}>You’ll prompt to review and approve campaign activities in the next step.</span>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default GanttEditModal;
