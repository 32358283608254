import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkSocialAccounts = (props: SVGProps<SVGSVGElement>) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
        <path d="M12.5 17.75H10.25C9.25544 17.75 8.30161 17.3549 7.59835 16.6517C6.89509 15.9484 6.5 14.9946 6.5 14C6.5 13.0054 6.89509 12.0516 7.59835 11.3483C8.30161 10.6451 9.25544 10.25 10.25 10.25H12.5C12.6989 10.25 12.8897 10.329 13.0303 10.4697C13.171 10.6103 13.25 10.8011 13.25 11C13.25 11.1989 13.171 11.3897 13.0303 11.5303C12.8897 11.671 12.6989 11.75 12.5 11.75H10.25C9.65326 11.75 9.08097 11.9871 8.65901 12.409C8.23705 12.831 8 13.4033 8 14C8 14.5967 8.23705 15.169 8.65901 15.591C9.08097 16.0129 9.65326 16.25 10.25 16.25H12.5C12.6989 16.25 12.8897 16.329 13.0303 16.4697C13.171 16.6103 13.25 16.8011 13.25 17C13.25 17.1989 13.171 17.3897 13.0303 17.5303C12.8897 17.671 12.6989 17.75 12.5 17.75ZM17.75 17.75H15.5C15.3011 17.75 15.1103 17.671 14.9697 17.5303C14.829 17.3897 14.75 17.1989 14.75 17C14.75 16.8011 14.829 16.6103 14.9697 16.4697C15.1103 16.329 15.3011 16.25 15.5 16.25H17.75C18.3467 16.25 18.919 16.0129 19.341 15.591C19.7629 15.169 20 14.5967 20 14C20 13.4033 19.7629 12.831 19.341 12.409C18.919 11.9871 18.3467 11.75 17.75 11.75H15.5C15.3011 11.75 15.1103 11.671 14.9697 11.5303C14.829 11.3897 14.75 11.1989 14.75 11C14.75 10.8011 14.829 10.6103 14.9697 10.4697C15.1103 10.329 15.3011 10.25 15.5 10.25H17.75C18.7446 10.25 19.6984 10.6451 20.4017 11.3483C21.1049 12.0516 21.5 13.0054 21.5 14C21.5 14.9946 21.1049 15.9484 20.4017 16.6517C19.6984 17.3549 18.7446 17.75 17.75 17.75Z" fill="#FF5555" />
        <path opacity="0.5" d="M16.25 14.75H11.75C11.5511 14.75 11.3603 14.671 11.2197 14.5303C11.079 14.3897 11 14.1989 11 14C11 13.8011 11.079 13.6103 11.2197 13.4697C11.3603 13.329 11.5511 13.25 11.75 13.25H16.25C16.4489 13.25 16.6397 13.329 16.7803 13.4697C16.921 13.6103 17 13.8011 17 14C17 14.1989 16.921 14.3897 16.7803 14.5303C16.6397 14.671 16.4489 14.75 16.25 14.75Z" fill="#FF5555" />
    </svg>

);
export default SvgLinkSocialAccounts;
