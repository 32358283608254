import * as React from "react";
import type { SVGProps } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M10.667 10A.667.667 0 0 1 10 9.333V8a.666.666 0 1 1 1.333 0v1.333a.667.667 0 0 1-.666.667Zm6.666 0a.667.667 0 0 1-.666-.667V8A.667.667 0 1 1 18 8v1.333a.667.667 0 0 1-.667.667Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M18.667 8.667H18v.667a.667.667 0 1 1-1.333 0v-.667h-5.334v.667a.667.667 0 0 1-1.333 0v-.667h-.667a2 2 0 0 0-2 2V12h13.334v-1.333a2 2 0 0 0-2-2ZM10.667 15.333a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM10.667 18a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM14 15.333A.667.667 0 1 0 14 14a.667.667 0 0 0 0 1.333ZM14 18a.667.667 0 1 0 0-1.333A.667.667 0 0 0 14 18ZM17.333 15.333a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM17.333 18a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Z"
    />
    <path
      fill="#F55"
      d="M7.333 12v6.667a2 2 0 0 0 2 2h9.334a2 2 0 0 0 2-2V12H7.333Zm3.334 6a.666.666 0 1 1 0-1.333.666.666 0 0 1 0 1.333Zm0-2.667a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333ZM14 18a.667.667 0 1 1 0-1.333A.667.667 0 0 1 14 18Zm0-2.667A.667.667 0 1 1 14 14a.667.667 0 0 1 0 1.333ZM17.333 18a.666.666 0 1 1 0-1.333.666.666 0 0 1 0 1.333Zm0-2.667a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333Z"
      opacity={0.25}
    />
  </svg>
);
export default SvgCalendar;
