import Testimonial from "../../components/Testimonial/Testimonial";
import GetStartedFree from "./GetStartedFree";
import "./Styles/PR.scss"

export interface IPRProps {
    className: string
}

function PR(props: IPRProps) {
    return (<>
        <section className={`pr ${props.className || ''}`}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex md:flex-row gap-16">
                <div className="flex-[0_1_50%] flex flex-col gap-8">
                    <h2 className="font-display text-4xl font-medium">Supercharging Growth for Ambitious Brands</h2>
                    <div className="desc text-slate-300 ">
                        With Evercopy, brands achieve hyper-growth by consistently producing high-quality, tailored
                        content customized to their desired style and audience preferences.
                    </div>
                    <Testimonial user={{ name: 'Leo Glazow', company: 'Business Development @Cointelegraph' }}>
                        Picture this: a powerful engagement-driven AI copywriter as your trusty sidekick, leading
                        you to outperform your competitors at every step of your marketing game. The result? Your
                        copy shines brighter than the rest, captivating your audience's attention and propelling you
                        to the top of the industry.
                    </Testimonial>
                    <div>
                        <GetStartedFree />
                    </div>
                </div>
                <div
                    className="brands relative flex-[0_1_50%] flex flex-row gap-24 justify-center max-h-[30rem] overflow-hidden"
                >
                    <div>
                        <div className="brand-list flex flex-col">
                            <img src={require("../../assets/logos/asics-logo.png")} alt="asics" />
                            <img src={require("../../assets/logos/coursera-logo.png")} alt="coursera" />
                            <img src={require("../../assets/logos/espn-logo.png")} alt="espn" />
                            <img src={require("../../assets/logos/hershey-logo.png")} alt="hershey" />
                        </div>
                    </div>
                    <div>
                        <div className="brand-list flex flex-col">
                            <img src={require("../../assets/logos/latchel-logo.png")} alt="latchel" />
                            <img src={require("../../assets/logos/mens-journal-logo.png")} alt="men's journal" />
                            <img src={require("../../assets/logos/tinder-logo.png")} alt="tinder" />
                            <img src={require("../../assets/logos/uncrate-logo.png")} alt="uncrate" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default PR;