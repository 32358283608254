import React from "react";
import StarRating from "./StarRating"

export interface ReviewProps {
    title?: string;
    body: string;
    author: string;
    rating?: number;
    className?: string
}

function Review(props: ReviewProps) {

    const possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s'];
    const delay =
        possibleAnimationDelays[Math.floor(Math.random() * possibleAnimationDelays.length)];

    return (<>
        <figure
            className={`}
        'animate-fade-in rounded-3xl bg-white p-6 opacity-1 shadow-md shadow-gray-900/5 {$$props.class ||
		''} ${props.className}`}

            style={{ animationDelay: delay }}
        >
            <blockquote className="text-gray-900">
                <StarRating rating={props.rating} />
                <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
                    {props.title}
                </p>
                <p className="mt-3 text-base leading-7">{props.body}</p>
            </blockquote>
            <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_']">
                {props.author}
            </figcaption>
        </figure>
    </>
    );
}

export default Review;