import { createApi } from '@reduxjs/toolkit/query/react'
import { Post } from '../../pages/LandingPage/Models/Post'
import { baseQuery } from '../Core/BaseApi'
import { DefaultResponse } from '../Core/DefaultResponse'
import { LoginRequest, LoginResponse, SocialLoginRequest } from '../ContentApi.old/Model/LoginRequestModel'
import { RegisterRequest } from '../ContentApi.old/Model/RegisterRequestModel'

export const AuthenticationApi = createApi({
    reducerPath: "Authentication",
    baseQuery: baseQuery,
    endpoints: builder => ({
        register: builder.mutation<DefaultResponse, RegisterRequest>({
            query: (body) => ({
                url: `auth/register`,
                method: 'POST',
                body,
              }),
              transformErrorResponse: (response) => response.data
        }),
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (body) => ({
                url: `auth/login`,
                method: 'POST',
                body,
              }),
              transformErrorResponse: (response) => response.data
        }),
        loginWithSocial: builder.mutation<LoginResponse, SocialLoginRequest>({
            query: (body) => ({
                url: `auth/social-login`,
                method: 'POST',
                body,
              }),
              transformErrorResponse: (response) => response.data
        }),
        refreshToken: builder.mutation<LoginResponse, void>({
            query: (body) => ({
                url: `auth/refresh-token`,
                method: 'POST',
                body,
                headers: {"Authorization" : `Bearer `}
              }),
              transformErrorResponse: (response) => response.data
        }),
        logout: builder.query<Post[], void>({
            query: () => ({
                url:  "auth/logout",
            })
        }),
        getPostById: builder.query<Post, number>({
            query: id => `posts/${id}`
        }),
    }),
})

export const {
    useLoginMutation,
    useRegisterMutation,
    useLazyLogoutQuery,
    useLazyGetPostByIdQuery,
    useRefreshTokenMutation,
    useLoginWithSocialMutation
} = AuthenticationApi