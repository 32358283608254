import { Button } from "antd"
import Icon from "../../../components/Icon"

export interface IConnectSocialAccountCardProps {
    color?: string
    icon?: any
    name: string
    onClicked: () => void
}

function ConnectSocialAccountCard(props: IConnectSocialAccountCardProps) {
    return (
        <Button className="flex flex-row items-center h-[124px] w-full border-1 rounded-[6px]" onClick={props.onClicked}>
            <div className="flex flex-col flex-grow items-center gap-[10px]">
                <Icon
                    width="20"
                    height="20"
                    component={props.icon}
                    viewBox="0 0 20 20"
                    color={
                        props.color
                            ? props.color
                            : "#999999"
                    }
                    className="mr-3"
                />
                <div className="text-H7 text-black">{props.name}</div>
            </div>
        </Button>)
}

export default ConnectSocialAccountCard;