import { FunctionComponent, useEffect, useState } from "react";
import { useLazyDownloadQuery } from "../../services/FileApi";
import { blobToDataURL } from "blob-util";
import { BrandRequest } from "../../services/BrandApi";
import { t } from "i18next";
import SvgDefaultBrandLogoNew from "../../assets/IconComponents/DefaultBrandLogoNew";

interface BrandLogoProps {
    brand?: BrandRequest
    width?: number
    height?: number
    bgColor?: string
}

const SidebarBrandLogo: FunctionComponent<BrandLogoProps> = (props: BrandLogoProps) => {
    const [downloadFile, { data: fileDownloaddData, isSuccess: fileDownloaded, isLoading: fileDownloading, currentData: dt }] = useLazyDownloadQuery()

    const [useIcon, setUseIcon] = useState(false)
    const [logoImage, setLogoImage] = useState<string>("")

    useEffect(() => {
        var logo = props.brand?.logo_file_ids
        if (logo && (logo?.length ?? 0) - 1 > -1) {
            downloadFile({
                id: logo[(logo?.length ?? 0) - 1].id ?? ""
            })
                .unwrap()
                .then(async k => {
                    let image = await blobToDataURL(k.file)
                    setLogoImage(image)
                    setUseIcon(false)
                })
        } else {
            setUseIcon(true)
        }

    }, [props.brand])

    return (
        <>
            <div
                className={`border-border rounded h-[40px] w-[40px] flex items-center align-middle overflow-hidden
                    ${useIcon ? "border-1" : "border-2"}
                    ${props.bgColor}
                    `}
            >
                {(useIcon || props.brand === undefined) && (
                    <div
                        className={`w-full  flex items-center justify-center`}
                    >
                        <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                    </div>
                )}
                {!useIcon && !logoImage && (
                    <div
                        className={`w-full  flex items-center justify-center `}
                    >
                        <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                    </div>
                )}
                {!useIcon && logoImage && (
                    <div
                        className=" flex items-center justify-center ">
                        <img
                            src={logoImage}
                            className="object-scale-down "
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default SidebarBrandLogo;
