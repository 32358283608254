import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandTritoneBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={72}
    fill="none"
    {...props}
  >
    <rect width={72} height={72} fill="#F55" opacity={0.1} rx={8} />
    <g fill="#F55">
      <path
        d="M32 27.1H20v24h12v-24ZM52 53.1H34v-32c0-2.21 1.79-4 4-4h12c2.21 0 4 1.79 4 4v30a2 2 0 0 1-2 2Z"
        opacity={0.35}
      />
      <path d="M50 25.1h-4v-4h4v4ZM42 25.1h-4v-4h4v4ZM50 33.1h-4v-4h4v4ZM42 33.1h-4v-4h4v4ZM50 41.1h-4v-4h4v4ZM42 41.1h-4v-4h4v4ZM50 49.1h-4v-4h4v4ZM42 49.1h-4v-4h4v4ZM30 23.1h-8c-2.21 0-4 1.79-4 4v24a2 2 0 0 0 2 2h14v-26c0-2.21-1.79-4-4-4Zm0 26h-8v-4h8v4Zm0-8h-8v-4h8v4Zm0-8h-8v-4h8v4Z" />
    </g>
  </svg>
);
export default SvgBrandTritoneBig;
