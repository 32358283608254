import * as React from "react";
import type { SVGProps } from "react";
const SvgImportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M9.996 16.906a.69.69 0 0 1-.706-.71V9.251L7.313 11.23a.65.65 0 0 1-.495.205.704.704 0 0 1-.495-.223.7.7 0 0 1-.212-.51.7.7 0 0 1 .212-.508l3.18-3.18a.723.723 0 0 1 .24-.163.702.702 0 0 1 .518 0 .73.73 0 0 1 .236.163l3.201 3.2a.688.688 0 0 1 0 .998.677.677 0 0 1-.505.212.7.7 0 0 1-.505-.215L10.71 9.251v6.945c0 .2-.069.369-.206.505a.692.692 0 0 1-.508.205Zm-6.2-9.294a.68.68 0 0 1-.505-.204.69.69 0 0 1-.202-.505v-2.5c0-.384.141-.717.423-1 .281-.282.613-.424.997-.424h10.978c.38 0 .71.142.994.425.283.283.425.617.425 1.002v2.497c0 .2-.069.368-.206.505a.692.692 0 0 1-.507.204.68.68 0 0 1-.504-.204.69.69 0 0 1-.202-.505v-2.5H4.509v2.5c0 .2-.069.368-.206.505a.692.692 0 0 1-.508.204Z"
    />
  </svg>
);
export default SvgImportIcon;
