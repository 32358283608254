import SvgChevron from "../../assets/IconComponents/Chevron";

const CustomLeftArrow = ({ onClick }: any) => {
    return (<>
        <button className="custom-left-arrow border-gray-300 rounded-full bg-red hover:bg-gray p-1.5" onClick={onClick} >
            <SvgChevron fillPath={"#fff"} />
        </button>
    </>)
};

export default CustomLeftArrow;