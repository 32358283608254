import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateOrganisationDetails: build.mutation<
      UpdateOrganisationDetailsApiResponse,
      UpdateOrganisationDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/update`,
        method: "POST",
        body: queryArg.organisationDetailUpdateRequest,
      }),
    }),
    findByContentId: build.query<
      FindByContentIdApiResponse,
      FindByContentIdApiArg
    >({
      query: () => ({ url: `/organization` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateOrganisationDetailsApiResponse = unknown;
export type UpdateOrganisationDetailsApiArg = {
  organisationDetailUpdateRequest: OrganisationDetailUpdateRequest;
};
export type FindByContentIdApiResponse =
  /** status 200 OK */ OrganizationResponse;
export type FindByContentIdApiArg = void;
export type OrganisationDetailUpdateRequest = {
  description_tags?: string[];
  website?: string;
};
export type OrganizationResponse = {
  id?: string;
  name?: string;
  ai_models?: string;
  stripe_customer?: string;
  description_tags?: string[];
  website?: string;
};
export const {
  useUpdateOrganisationDetailsMutation,
  useFindByContentIdQuery,
  useLazyFindByContentIdQuery,
} = injectedRtkApi;
