import { useEffect, useState } from "react";


export interface ICheckBoxProps {
    title?: string
    state?: boolean
    value?: boolean
    onChange?: (e: boolean) => void
}

function CheckBox(props: ICheckBoxProps) {
    var { title,  onChange, state ,value } = props
    const [localState, setLocalState] = useState(state)

    useEffect(() => {
        setLocalState(state)
    }, [state] )


    return (
        <div className="flex flex-col mt-2">
            <div className="w-full">

            <div className={`flex flex-row w-full h-5 rounded-xl px-3 items-center`}>

                <input

                    style={{width:16 , height:16 , accentColor :"red"    }}
                    className={`text-BodyMedium text-black flex flex-1 h-full outline-none`}
                    height={"48"}
                    onChange={(event) =>  { setLocalState(event.target.checked); if(onChange) onChange(event.target.checked ?? false); }}
                    checked={localState}

                    type={ "checkbox"}


                />
                <text className=" text-BodyMedium text-black   ml-2">{title}</text>

            </div>

            </div>
        </div>
    );
}

export default CheckBox;
