import { FunctionComponent, useState } from "react";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import { t } from "i18next";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading } from "../../store/appSlice";
import { Radio, message, Upload, UploadProps } from "antd";
import {
  useDeleteFileDeleteByIdMutation,
  useLazyDownloadQuery,
  useUploadMutation,
} from "../../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { FileProps } from "../../services/BrandApi";
import SvgTextToSpeechInbox from "../../assets/IconComponents/TextToSpeechInboxIcon";
import { InboxOutlined } from "@ant-design/icons";

interface IUploadTextToSpeechFileProps {
  description: string;
  className?: string;
  accept? : string;
  maxFileSize?: number;
  disabled?: boolean;
  fileChanged: (file: FileProps) => void;
  uploadedFile?: FileProps;
}

const UploadTextToSpeechFile: FunctionComponent<
  IUploadTextToSpeechFileProps
> = (props: IUploadTextToSpeechFileProps) => {

  const { Dragger } = Upload;


  const dispatch = useAppDispatch();
  const [uploadedFileId, setUploadedFileId] = useState<FileProps | undefined>(undefined);
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();


const uploadProps: UploadProps = {
  name: "file",
  multiple: false,
  disabled: !!uploadedFileId,
  showUploadList: false,
  accept: props.accept ?? ".txt, .pdf, .docx, .doc, .ppt, .pptx",
  action: (file) => {
    handleFilesChanged(file);
    return "";
  },
  onDrop(file) {
    handleFilesChanged(file);
    return "";
  },
};

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const handleFilesChanged = (e) => {
    console.log("Handle files changed: ",e);
    selectFile(e);
  };

  const selectFile = async (file: any) => {
    if (file) {
      const MAX_FILE_SIZE = (props.maxFileSize ?? 5) * 1024 * 1024;
      console.log("MAX FILE SIZE IS:", MAX_FILE_SIZE )
      if(file.size > MAX_FILE_SIZE){
        alert(
          "File size exceeds the maximum limit of" +
            (props.maxFileSize ?? 5) +
            "MB"
        );
        return;
      }
      dispatch(toggleLoading(true));

      let selectedFile = file;
      let a = (await fileToBlob(selectedFile)) as string;
      let blob = dataURLToBlob(a);
      const formData: FormData = new FormData();
      formData.append("files", blob, selectedFile.name);
      uploadFiles({
        body: formData,
      })
        .unwrap()
        .then((k) => {
          selectedFile["id"] = k.ids ?? "";
          selectedFile["preview"] = a;
          props.fileChanged(selectedFile);
          setUploadedFileId(selectedFile);
          dispatch(toggleLoading(false));
        });
    }
  };

  return (
    <>

      {uploadedFileId && (
        <div className="absolute  left-0 pl-3 pt-3">
          <text className="text-black text-BodyMedium mb-3 truncate text-ellipsis overflow-hidden">
            {uploadedFileId.name}
          </text>
        </div>
      )}
      <div className="flex flex-col mt-2">
        <Dragger {...uploadProps}>
          <div className="flex flex-col w-full h-[190px] items-center justify-center rounded-[4px] cursor-pointer">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <text className="text-H8 mt-2 text-black truncate text-ellipsis overflow-hidden">
              Click or drag file to this area to upload
            </text>
            <text className="text-gray text-BodyMedium  truncate text-ellipsis overflow-hidden">
              {props.description ?? ".txt, .pdf or .doc files. MAX 5MB"}
            </text>
          </div>
        </Dragger>
      </div>
    </>
  );
};

export default UploadTextToSpeechFile;
