import * as React from "react";
import type {SVGProps} from "react";

const WarnIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.1" x="0.5" width="56" height="56" rx="4" fill="#FFA841"/>
        <g clip-path="url(#clip0_9540_6600)">
            <path opacity="0.5"
                  d="M28.9998 16C29.9333 16.0001 30.8347 16.3401 31.5357 16.9565L31.7205 17.1305L32.5582 17.9682C32.7881 18.1966 33.0883 18.3408 33.4103 18.3775L33.5723 18.3871H34.7725C35.7533 18.387 36.6969 18.7622 37.4099 19.4357C38.1228 20.1092 38.5511 21.0299 38.6069 22.0091L38.6129 22.2275V23.4277C38.6129 23.7517 38.7233 24.0673 38.9226 24.3194L39.0306 24.4394L39.8671 25.2771C40.5604 25.9665 40.9647 26.8944 40.9975 27.8716C41.0303 28.8488 40.6891 29.8017 40.0435 30.5361L39.8695 30.7209L39.0318 31.5586C38.8034 31.7885 38.6592 32.0887 38.6225 32.4107L38.6129 32.5727V33.7728C38.613 34.7536 38.2378 35.6973 37.5643 36.4102C36.8908 37.1232 35.9701 37.5515 34.9909 37.6073L34.7725 37.6133H33.5723C33.2488 37.6134 32.9346 37.7225 32.6806 37.9229L32.5606 38.0309L31.7229 38.8674C31.0335 39.5608 30.1056 39.9651 29.1284 39.9978C28.1512 40.0306 27.1983 39.6894 26.4639 39.0439L26.2791 38.8698L25.4414 38.0321C25.2115 37.8037 24.9113 37.6596 24.5893 37.6229L24.4273 37.6133H23.2271C22.2464 37.6133 21.3027 37.2382 20.5898 36.5647C19.8768 35.8912 19.4485 34.9705 19.3927 33.9913L19.3867 33.7728V32.5727C19.3866 32.2491 19.2775 31.935 19.0771 31.681L18.9691 31.561L18.1326 30.7233C17.4392 30.0339 17.0349 29.106 17.0022 28.1288C16.9694 27.1515 17.3106 26.1986 17.9561 25.4643L18.1302 25.2795L18.9679 24.4418C19.1963 24.2119 19.3404 23.9117 19.3771 23.5897L19.3867 23.4277V22.2275L19.3927 22.0091C19.4463 21.0675 19.8445 20.1786 20.5113 19.5117C21.1782 18.8448 22.0672 18.4467 23.0087 18.3931L23.2271 18.3871H24.4273C24.7509 18.387 25.065 18.2779 25.319 18.0774L25.439 17.9694L26.2767 17.1329C26.6335 16.774 27.0578 16.4891 27.5252 16.2946C27.9925 16.1002 28.4937 16.0001 28.9998 16Z"
                  fill="#FFA841"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M28.9996 25.9C29.3709 25.9 29.727 26.0475 29.9896 26.3101C30.2521 26.5726 30.3996 26.9287 30.3996 27.3V32.2C30.3996 32.5713 30.2521 32.9274 29.9896 33.19C29.727 33.4525 29.3709 33.6 28.9996 33.6C28.6283 33.6 28.2722 33.4525 28.0097 33.19C27.7471 32.9274 27.5996 32.5713 27.5996 32.2V27.3C27.5996 26.9287 27.7471 26.5726 28.0097 26.3101C28.2722 26.0475 28.6283 25.9 28.9996 25.9Z"
                  fill="#FFA841"/>
            <path
                d="M30.3996 23.8C30.3996 24.1713 30.2521 24.5274 29.9896 24.79C29.727 25.0525 29.3709 25.2 28.9996 25.2C28.6283 25.2 28.2722 25.0525 28.0097 24.79C27.7471 24.5274 27.5996 24.1713 27.5996 23.8C27.5996 23.4287 27.7471 23.0726 28.0097 22.8101C28.2722 22.5475 28.6283 22.4 28.9996 22.4C29.3709 22.4 29.727 22.5475 29.9896 22.8101C30.2521 23.0726 30.3996 23.4287 30.3996 23.8Z"
                fill="#FFA841"/>
        </g>
        <defs>
            <clipPath id="clip0_9540_6600">
                <rect width="24" height="24" fill="white" transform="translate(17 16)"/>
            </clipPath>
        </defs>
    </svg>
);
export default WarnIcon;
