import * as React from "react";
import type { SVGProps } from "react";
const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M9.583 18.333A2.086 2.086 0 0 1 7.5 16.25c0-1.15.935-2.084 2.083-2.084 1.15 0 2.084.935 2.084 2.084a2.086 2.086 0 0 1-2.084 2.083Zm-1.25-16.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-5.416 7.5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667Zm1.875 4.167a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Zm8.75-7.917a1.46 1.46 0 0 1-1.459-1.458 1.46 1.46 0 0 1 1.459-1.459A1.46 1.46 0 0 1 15 4.375a1.46 1.46 0 0 1-1.458 1.458Zm-9.584 1.25a1.042 1.042 0 1 0 0-2.083 1.042 1.042 0 0 0 0 2.083Zm10.834 8.75a1.877 1.877 0 0 1-1.875-1.875c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875a1.877 1.877 0 0 1-1.875 1.875Zm1.458-5.416c-.92 0-1.667-.748-1.667-1.667 0-.92.748-1.667 1.667-1.667.92 0 1.667.748 1.667 1.667 0 .92-.748 1.667-1.667 1.667Z"
    />
  </svg>
);
export default SvgLoading;
