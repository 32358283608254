import { FunctionComponent, useEffect, useState } from "react";
import { LoopDetailChannel } from "./LoopDetailChannels";
import { isDisabled } from "@testing-library/user-event/dist/utils";



interface LoopDetailChannelButtonProps {
    channel: LoopDetailChannel
    onClick: (channel: LoopDetailChannel) => void
    toggled: (status: boolean) => void
    isDisabled: boolean
    isTurnedOn: boolean
}

const LoopDetailChannelButton: FunctionComponent<LoopDetailChannelButtonProps> = (props: LoopDetailChannelButtonProps) => {
    const [isActive, setActive] = useState(true)
    const [isTurnedOn, turnOn] = useState(true)

    useEffect(() => {
        turnOn(props.isTurnedOn)
    }, [props.isTurnedOn])

    return (
        <button className={`
            w-[40px] h-[40px]
            rounded-[8px]
            mr-1
            flex items-center justify-center
        `}
            data-tooltip-id="app-tooltip"
            data-tooltip-html={`<p style="font-size: 12px; font-weight: 600;">${props.channel.tooltip}:   
                <span style="${isTurnedOn ? "color: #00CB2D;" : "color: #999999;"}">${isTurnedOn ? "ON" : "OFF"}</span></p>`}
            style={{
                background: props.isDisabled || !isTurnedOn ? "#fff" : props.channel.bgColor,
                border: props.isDisabled || !isTurnedOn ? "1px solid var(--border, #EAEBE6)" : ""
            }}
            disabled={props.isDisabled}
            onClick={() => {
                props.toggled(!isTurnedOn)
                turnOn(!isTurnedOn)
            }}
        >
            {(props.isDisabled || !isTurnedOn) && <props.channel.disabledIcon />}
            {(!props.isDisabled && isTurnedOn) && <props.channel.icon />}
        </button>
    );
}

export default LoopDetailChannelButton;