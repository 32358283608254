import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { setDialog, toggleDialog, toggleLoading, toggleMenu } from "../../store/appSlice";
import { setAccessToken, setRefreshToken, toggleLoggedIn } from "../../store/persistedSlice";
import PrimaryButton from "../PrimaryButton";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Mediums } from "../../constants/Mediums";
import { useLazyLogoutQuery } from "../../services/AuthenticationApi.old/AuthenticationApi";
import { useLazyGetListQuery } from "../../services/ContentApi.old/ContentApi";
import { ContentId } from "../../services/ContentApi.old/Model/ContentModel";
import IconButton from "../IconButton";
import { List, LogoLight } from "../../assets/IconComponents";

function TopMenu() {
    const [logoutUser, { isLoading: isLogoutLoading, isSuccess: isLogoutSuccess, isError: isLogoutError }] = useLazyLogoutQuery()
    const updateMenuEvent = useAppSelector(state => state.app.updateMenu)
    const [getContentList, { isLoading: contenListLoading, data: contentList }] = useLazyGetListQuery();
    const dispatch = useAppDispatch()
    const [isDialogDisplay, setDialogDisplay] = useState(false)
    const navigate = useNavigate()
    const isLoggedIn = useAppSelector(state => state.persisted.isLoggedIn)
    const showMenu = useAppSelector(state => state.app.showMenu)

    const showLoop = (id: ContentId) => {
        navigate(`revisionhisory`, { state: { content_id: id.content_id } })
    }


    const logout = () => {
        dispatch(setDialog({
            actions: <>
                <PrimaryButton noFill title={t('cancel')} onClick={() => { dispatch(toggleDialog(false)) }} />
                <PrimaryButton className="ml-2" title={t('logOut')} onClick={() => { dispatch(toggleDialog(false)); logoutUser() }} />
            </>,
            title: t('log-out'),
            message: t('are-you-sure-you-would-like-to-log-out-of-your-evercopy-account'),
            onClose: () => { dispatch(toggleDialog(false)) }
        }))
        dispatch(toggleDialog(true))
    }

    useEffect(() => {
        if (isLogoutSuccess || isLogoutError) {
            dispatch(setAccessToken(""))
            dispatch(setRefreshToken(""))
            dispatch(toggleLoggedIn(false))
        }
    }, [isLogoutSuccess, isLogoutError])

    useEffect(() => {
        dispatch(toggleLoading(isLogoutLoading))
    }, [isLogoutLoading])

    return (
        <div className="block lg:hidden bg-black p-1 h-[70px] w-full overflow-hidden flex-col items-stretch justify-between z-50">
            <div className="flex flex-row items-center h-full">
                <div className="p-2 h-full flex justify-center items-center w-16" >
                    {/* style={{pointerEvents: showMenu ? "none" : "all"}} */}
                    <IconButton
                        title=""
                        hoverColor="#ffffff"
                        icon={List}
                        onClick={() => {
                            dispatch(toggleMenu())
                        }} />
                </div>
                <div className="w-full h-full flex items-center justify-center">
                    <LogoLight width={150} height={24} preserveAspectRatio="xMinYMin meet" viewBox="0 0 150 75" className="-ml-[50px]" />
                </div>
            </div>
        </div >
    );
}

export default TopMenu;