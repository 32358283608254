import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    markAsRead: build.mutation<MarkAsReadApiResponse, MarkAsReadApiArg>({
      query: (queryArg) => ({
        url: `/notification/mark-as-read`,
        method: "POST",
        body: queryArg.markAsReadRequest,
      }),
    }),
    getNotifications: build.query<
      GetNotificationsApiResponse,
      GetNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/notification`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          brandId: queryArg.brandId,
          group: queryArg.group,
          isRead: queryArg.isRead,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MarkAsReadApiResponse = unknown;
export type MarkAsReadApiArg = {
  markAsReadRequest: MarkAsReadRequest;
};
export type GetNotificationsApiResponse =
  /** status 200 OK */ NotificationResponse[];
export type GetNotificationsApiArg = {
  page?: number;
  size?: number;
  brandId?: string[];
  group?: string;
  isRead?: boolean;
};
export type MarkAsReadRequest = {
  notification_ids?: string[];
};
export type ActivityPlannerNotificationData = {
  eventId?: string;
  campaignId?: string;
  activityId?: string;
  textContent?: string;
  imageFileId?: string;
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  postCategory?: string;
};
export type NotificationResponse = {
  organizationId?: string;
  notificationId?: string;
  brandId?: string;
  brandName?: string;
  group?: "MARKETING_PLANNER" | "SUBSCRIPTION";
  actionStatus?: "AWAITING_APPROVAL" | "INFO";
  type?:
    | "ACTIVITY_PLANNER_ALL_ACTIVITIES_READY"
    | "ACTIVITY_PLANNER_SINGLE_ACTIVITY_WAITING_APPROVAL"
    | "ACTIVITY_PLANNER_ACTIVITY_POSTED"
    | "ACTIVITY_PLANNER_ACTIVITIES_GETTING_READY"
    | "SUBSCRIPTION_CREDIT_LOW"
    | "SUBSCRIPTION_SUBSCRIPTION_RENEWED"
    | "SUBSCRIPTION_CREDIT_CARD_DUE_DATE"
    | "SUBSCRIPTION_TRIAL_PERIOD_CLOSE_TO_END";
  text?: string;
  cta?: string;
  isRead?: boolean;
  activityPlannerNotificationData?: ActivityPlannerNotificationData;
  createdAt?: string;
};
export const {
  useMarkAsReadMutation,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
} = injectedRtkApi;
