import { FunctionComponent, useEffect, useState } from "react";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import Icon from "../Icon";
import { Close } from "../../assets/IconComponents";

interface ActionSheetProps {
    title: string
    triggerView: JSX.Element
    body: JSX.Element
    isExpanded: boolean
    setIsExpanded: (status: boolean) => void
    className?: string
    triggerClassName?: string

}

const ActionSheet: FunctionComponent<ActionSheetProps> = (props: ActionSheetProps) => {

    return (
        <>
            <div className={`${props.isExpanded ? "top-0" : "hidden"} fixed left-0 right-0 bottom-0  bg-gray/30 backdrop-blur-sm z-50 ${props.className}`}>
                <div className="absolute left-0 right-0 bottom-0 flex justify-between items-center lg:hidden flex-col bg-white ">
                    <div className="w-full flex justify-between items-center flex-row h-[56px] p-[10px] pl-[12px] border-1 border-border">
                        <p className="text-H6 text-black">{props.title}</p>
                        <button
                            className={`lg:hidden 
                                    m-1
                                    mx-2
                                    p-[10px]
                                    rounded-lg flex flex-row items-center
                                `}
                            onClick={() => {
                                props.setIsExpanded(false)
                            }}>
                            <div className={`rounded-sm p-[2px] transition-all transform-gpu flex items-center`}>
                                <Icon component={Close} viewBox="0 0 20 20" className="w-[30px]" color={"#000"} />
                            </div>
                        </button>
                    </div>
                    {props.body}
                </div>
            </div>
            <button
                className={props.triggerClassName}
                onClick={() => {
                    props.setIsExpanded(true)
                }}>
                {props.triggerView}
            </button>
        </>
    );
}

export default ActionSheet;