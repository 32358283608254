import * as React from "react";
import type { SVGProps } from "react";
const SvgVerticalImageSizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity="0.1"
      x="6.5"
      y="0.5"
      width="38"
      height="50"
      rx="8"
      fill="#999999"
    />
    <path
      opacity="0.35"
      d="M33.0833 11.8335H17.9167C16.1217 11.8335 14.6667 13.2886 14.6667 15.0835V25.9168C14.6667 27.7118 16.1217 29.1668 17.9167 29.1668H33.0833C34.8782 29.1668 36.3333 27.7118 36.3333 25.9168V15.0835C36.3333 13.2886 34.8782 11.8335 33.0833 11.8335Z"
      fill="#999999"
    />
    <path
      d="M30.9167 18.3333C32.1133 18.3333 33.0833 17.3633 33.0833 16.1667C33.0833 14.97 32.1133 14 30.9167 14C29.72 14 28.75 14.97 28.75 16.1667C28.75 17.3633 29.72 18.3333 30.9167 18.3333Z"
      fill="#999999"
    />
    <path
      d="M16.8333 24.8333C16.8333 25.1207 16.9475 25.3962 17.1506 25.5994C17.3538 25.8025 17.6294 25.9167 17.9167 25.9167H33.0833C33.3707 25.9167 33.6462 25.8025 33.8494 25.5994C34.0525 25.3962 34.1667 25.1207 34.1667 24.8333C34.1667 23.3557 32.338 21.5833 31.4583 21.5833C30.5787 21.5833 29.7813 22.6667 27.6667 22.6667C25.0212 22.6667 23.3333 17.25 21.1667 17.25C19 17.25 16.8333 21.9755 16.8333 24.8333Z"
      fill="#999999"
    />
    <path
      d="M26.875 36.75H17.125C16.228 36.75 15.5 36.0231 15.5 35.125C15.5 34.2269 16.228 33.5 17.125 33.5H26.875C27.772 33.5 28.5 34.2269 28.5 35.125C28.5 36.0231 27.772 36.75 26.875 36.75Z"
      fill="#999999"
    />
    <path
      d="M23.625 42H17.125C16.228 42 15.5 41.2731 15.5 40.375C15.5 39.4769 16.228 38.75 17.125 38.75H23.625C24.522 38.75 25.25 39.4769 25.25 40.375C25.25 41.2731 24.522 42 23.625 42Z"
      fill="#999999"
    />
  </svg>
);
export default SvgVerticalImageSizeIcon;
