
interface PageLoadingProps {
    isShowing: boolean
}

const PageLoading = (props: PageLoadingProps) => {
    const { isShowing } = props
    return (
        <>
            {
                isShowing &&
                <div className="fixed top-0 left-0 right-0 bottom-0 bg-bg/60  flex items-center justify-center h-screen" style={{pointerEvents: "none", zIndex: "2000"}} >
                    <div className="">
                        <img src={require("../assets/loading.gif")} className="w-[64px] h-[64px]" />
                    </div>
                </div>
            }
        </>
    );
}

export default PageLoading;