import { t } from "i18next";
import { FunctionComponent } from "react";
import { Add } from "../../assets/IconComponents";
import SvgBrandTritoneColor from "../../assets/IconComponents/BrandTritoneColor";
import ContentContainer from "../../components/ContentContainer";
import PrimaryButton from "../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

interface BrandHubNoBrandLineProps {
    
}

const BrandHubNoBrandLine: FunctionComponent<BrandHubNoBrandLineProps> = () => {
    const navigate = useNavigate()
    return (
        <ContentContainer
            hoverable={false}
            className="mt-[40px] w-full md:h-[160px] border-1 border-red !rounded-[10px] p-[18px] flex items-center justify-between"
            children={(
                <div className="w-full flex flex-col md:flex-row justify-between items-center">
                    <div className="flex flex-row items-center">
                        <div><SvgBrandTritoneColor /></div>
                        <div className="ml-[20px]">
                            <p className="text-H6 text-black">Get started with brand templates!</p>
                            <p className="text-BodyMedium text-gray mt-1">Instantly create contents tailored to your brand's style and voice using templates.</p>
                        </div>
                    </div>
                    <PrimaryButton
                        onClick={() => { navigate("/app/brandhub/new") }}
                        icon={<Add fill="#ffffff" className="mr-2" />}
                        title={t('add-new-brand')}
                        className="h-[40px] w-full md:w-[175px] mt-5 md:mt-0"
                    />
                </div>
            )} />
    );
}

export default BrandHubNoBrandLine;