import { t } from "i18next";
import { OnboardingStyle } from "./OnboardingChooseStyle";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleMenu } from "../../store/appSlice";
export interface IOnboardingChooseStyleCardProps {
  item: OnboardingStyle;
  isSelected: boolean;
  toggleItem: () => void;
}

function OnboardingChooseStyleCard(props: IOnboardingChooseStyleCardProps) {
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => props.toggleItem()}
      className={`flex flex-row h-[60px] bg-white  relative  rounded-[4px] border border-solid border-border items-center hover:border-red  ${props.isSelected ? "border-red " : ""
        }`}
    >
      <div
        className={`flex ml-2 w-[30px] h-[30px]  rounded-[4px] items-center justify-center bg-lightBg`}
        style={{ background: `${props.isSelected ? "#FFEEEE" : "#f4f4f4"}` }}
      >
        <img
          alt="Coupon Image"
          src={require(`../../assets/images/${props.item.path}${props.isSelected ? "_colored" : ""
            }.png`)}
          style={{
            width: "15px",
            height: "15px",
            backgroundSize: "cover", // Cover the 15x15 area
          }}
        />
      </div>
      <span className="p-2 text-H7 font-medium text-black ">{t(props.item.name)}</span>
    </div>
  );
}

export default OnboardingChooseStyleCard;
