import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveActivity: build.mutation<SaveActivityApiResponse, SaveActivityApiArg>({
      query: (queryArg) => ({
        url: `/activity`,
        method: "POST",
        body: queryArg.activityRequest,
      }),
    }),
    scheduleOrDraftActivity: build.mutation<
      ScheduleOrDraftActivityApiResponse,
      ScheduleOrDraftActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/schedule-or-draft-activity`,
        method: "POST",
        body: queryArg.activityRequest,
      }),
    }),
    generateContent: build.mutation<
      GenerateContentApiResponse,
      GenerateContentApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/generate-content`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createActivityFromCampaigns: build.mutation<
      CreateActivityFromCampaignsApiResponse,
      CreateActivityFromCampaignsApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/create-activity-from-campaigns`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    regenerateForActivity: build.query<
      RegenerateForActivityApiResponse,
      RegenerateForActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/regenerate-for-activity/${queryArg.activityId}/${queryArg.regenerationType}`,
      }),
    }),
    getLatestActivities: build.query<
      GetLatestActivitiesApiResponse,
      GetLatestActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/latest`,
        params: { count: queryArg.count },
      }),
    }),
    getAllActivity: build.query<
      GetAllActivityApiResponse,
      GetAllActivityApiArg
    >({
      query: (queryArg) => ({ url: `/activity/get-all/${queryArg.brandId}` }),
    }),
    getActivitiesFromCampaign: build.query<
      GetActivitiesFromCampaignApiResponse,
      GetActivitiesFromCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/get-activities-from-campaign/${queryArg.campaignId}`,
      }),
    }),
    deleteActivityById: build.mutation<
      DeleteActivityByIdApiResponse,
      DeleteActivityByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/activity/${queryArg.id}/${queryArg.rootId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type SaveActivityApiResponse = /** status 200 OK */ ActivityResponse;
export type SaveActivityApiArg = {
  activityRequest: ActivityRequest;
};
export type ScheduleOrDraftActivityApiResponse =
  /** status 200 OK */ ActivityResponse;
export type ScheduleOrDraftActivityApiArg = {
  activityRequest: ActivityRequest;
};
export type GenerateContentApiResponse = unknown;
export type GenerateContentApiArg = {
  body: string[];
};
export type CreateActivityFromCampaignsApiResponse =
  /** status 200 OK */ GetAllResponse;
export type CreateActivityFromCampaignsApiArg = {
  body: MarketingPlannerRequest[];
};
export type RegenerateForActivityApiResponse =
  /** status 200 OK */ ContentDetails[];
export type RegenerateForActivityApiArg = {
  activityId: string;
  regenerationType:
    | "TEXT"
    | "IMAGE"
    | "SHORT_HEADLINE"
    | "LONG_HEADLINE"
    | "DESCRIPTION";
};
export type GetLatestActivitiesApiResponse =
  /** status 200 OK */ ActivityResponse[];
export type GetLatestActivitiesApiArg = {
  count?: number;
};
export type GetAllActivityApiResponse = /** status 200 OK */ GetAllResponse;
export type GetAllActivityApiArg = {
  brandId: string;
};
export type GetActivitiesFromCampaignApiResponse =
  /** status 200 OK */ GetAllResponse;
export type GetActivitiesFromCampaignApiArg = {
  campaignId: string;
};
export type DeleteActivityByIdApiResponse = /** status 200 OK */ GetAllResponse;
export type DeleteActivityByIdApiArg = {
  id: string;
  rootId: string;
};
export type BudgetDistribution = {
  googleAdsDistribution?: number;
  metaAdsDistribution?: number;
};
export type PaidProperties = {
  budgetType?: "DAILY_BUDGET" | "TOTAL_BUDGET";
  budget?: number;
  budgetDistribution?: BudgetDistribution;
};
export type ContentDetails = {
  imageFileId?: string;
  textContent?: string;
  contentId?: string;
  revisionId?: string;
  active?: boolean;
  generated?: boolean;
};
export type Creative = {
  shortHeadlines?: ContentDetails[];
  longHeadlines?: ContentDetails[];
  headlines?: ContentDetails[];
  description?: ContentDetails[];
  selectedCta?: ContentDetails;
  ctas?: ContentDetails[];
};
export type PlatformBudget = {
  channel?:
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS";
  budget?: number;
  budgetPrice?: number;
};
export type Location = {
  name?: string;
  type?: "COUNTRY" | "CITY";
};
export type AudienceResponse = {
  organization_id?: string;
  brand_id?: string;
  audience_id?: string;
  name?: string;
  location?: Location[];
  location_exclude?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  interest_exclude?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  ads_category?: string;
  beneficiary?: string;
  payer?: string;
};
export type ActivityResponse = {
  id?: string;
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  status?: string;
  type?: string;
  paidProperties?: PaidProperties;
  startDate?: string;
  endDate?: string;
  credit?: number;
  creatives?: Creative;
  rootId?: string;
  campaignObjectiveType?:
    | "BRAND_EDUCATION"
    | "BRAND_RECALL"
    | "POSITIONING"
    | "NEW_REACH"
    | "DRIVE_VISITS"
    | "BOOST_CLICKS"
    | "REPEAT_VISITS"
    | "INCREASE_PAGE_VIEWS"
    | "FOSTER_INTERACTION"
    | "SESSION_DURATION"
    | "CONTENT_INTERACTION"
    | "ACTIVE_PARTICIPATION"
    | "BOOST_DOWNLOADS"
    | "POSITIVE_RATINGS"
    | "FEATURE_PROMOTION"
    | "USER_RETENTION"
    | "COLLECT_CONTACTS"
    | "QUALIFY_LEADS"
    | "SIGN_UP_DRIVE"
    | "BUILD_DATABASE"
    | "DRIVE_SALES"
    | "ENCOURAGE_UPSELLS"
    | "REDUCE_ABANDONMENT"
    | "INCREASE_ORDER_VALUE";
  imageContentDetails?: ContentDetails[];
  textContentDetails?: ContentDetails[];
  eventType?:
    | "PRODUCT_ROLLOUT"
    | "FEATURE_UNVEILING"
    | "RAISED_FUNDING"
    | "MARKET_EXPANSION"
    | "HOSTING_LEARNING_SESSIONS"
    | "SEASONAL_DISCOUNT_SALES"
    | "EVENT_PARTICIPATION"
    | "PARTNERSHIP_INITIATION"
    | "STRATEGIC_SHIFT"
    | "TEAM_ONBOARDING_CELEBRATION"
    | "BRAND_MILESTONE_CELEBRATION";
  campaignType?:
    | "AWARENESS"
    | "TRAFFIC"
    | "ENGAGEMENT"
    | "APP_PROMOTION"
    | "LEAD_GENERATION"
    | "SALES_CONVERSION";
  productAbout?:
    | "MOBILE_APP"
    | "WEB_SITE"
    | "PHYSICAL_PRODUCT"
    | "OTHER"
    | "DEFAULT"
    | "APP";
  postCategory?: "ORGANIC" | "HOLIDAY" | "PAID_GOOGLE" | "PAID_META" | "PAID";
  adType?:
    | "SEARCH_ENGINE_MARKETING"
    | "DISPLAY_ADS"
    | "VIDEOS"
    | "SOCIAL_MEDIA_ADVERTISING";
  totalBudget?: number;
  budgetPrice?: number;
  budgetDetails?: PlatformBudget[];
  objective?: string;
  priority?: "HIGH" | "NORMAL" | "LOW";
  brandId?: string;
  companyName?: string;
  productAboutUrl?: string;
  audience?: AudienceResponse;
  budgetPriceInAccountCurrency?: number;
  exchangeRate?: number;
  accountCurrency?: string;
  createDate?: string;
  publisherAccountName?: string;
  publisherAccountProfileImageUrl?: string;
  actionRequired?: boolean;
};
export type AddAudienceRequest = {
  brand_id?: string;
  audience_id?: string;
  name: string;
  location?: Location[];
  location_exclude?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  interest_exclude?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  ads_category?: string;
  beneficiary?: string;
  payer?: string;
};
export type ActivityRequest = {
  id?: string;
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  status?: string;
  type?: string;
  paidProperties?: PaidProperties;
  startDate?: string;
  endDate?: string;
  credit?: number;
  creatives?: Creative;
  rootId?: string;
  campaignObjectiveType?:
    | "BRAND_EDUCATION"
    | "BRAND_RECALL"
    | "POSITIONING"
    | "NEW_REACH"
    | "DRIVE_VISITS"
    | "BOOST_CLICKS"
    | "REPEAT_VISITS"
    | "INCREASE_PAGE_VIEWS"
    | "FOSTER_INTERACTION"
    | "SESSION_DURATION"
    | "CONTENT_INTERACTION"
    | "ACTIVE_PARTICIPATION"
    | "BOOST_DOWNLOADS"
    | "POSITIVE_RATINGS"
    | "FEATURE_PROMOTION"
    | "USER_RETENTION"
    | "COLLECT_CONTACTS"
    | "QUALIFY_LEADS"
    | "SIGN_UP_DRIVE"
    | "BUILD_DATABASE"
    | "DRIVE_SALES"
    | "ENCOURAGE_UPSELLS"
    | "REDUCE_ABANDONMENT"
    | "INCREASE_ORDER_VALUE";
  imageContentDetails?: ContentDetails[];
  textContentDetails?: ContentDetails[];
  eventType?:
    | "PRODUCT_ROLLOUT"
    | "FEATURE_UNVEILING"
    | "RAISED_FUNDING"
    | "MARKET_EXPANSION"
    | "HOSTING_LEARNING_SESSIONS"
    | "SEASONAL_DISCOUNT_SALES"
    | "EVENT_PARTICIPATION"
    | "PARTNERSHIP_INITIATION"
    | "STRATEGIC_SHIFT"
    | "TEAM_ONBOARDING_CELEBRATION"
    | "BRAND_MILESTONE_CELEBRATION";
  campaignType?:
    | "AWARENESS"
    | "TRAFFIC"
    | "ENGAGEMENT"
    | "APP_PROMOTION"
    | "LEAD_GENERATION"
    | "SALES_CONVERSION";
  productAbout?:
    | "MOBILE_APP"
    | "WEB_SITE"
    | "PHYSICAL_PRODUCT"
    | "OTHER"
    | "DEFAULT"
    | "APP";
  postCategory?: "ORGANIC" | "HOLIDAY" | "PAID_GOOGLE" | "PAID_META" | "PAID";
  adType?:
    | "SEARCH_ENGINE_MARKETING"
    | "DISPLAY_ADS"
    | "VIDEOS"
    | "SOCIAL_MEDIA_ADVERTISING";
  totalBudget?: number;
  budgetPrice?: number;
  budgetDetails?: PlatformBudget[];
  objective?: string;
  priority?: "HIGH" | "NORMAL" | "LOW";
  brandId?: string;
  companyName?: string;
  productAboutUrl?: string;
  audience?: AddAudienceRequest;
  budgetPriceInAccountCurrency?: number;
  actionRequired?: boolean;
};
export type ActionHeaderFilter = {
  value?: string;
  title?: string;
};
export type GetAllResponse = {
  activityResponseList?: ActivityResponse[];
  actionHeaderFilterList?: ActionHeaderFilter[];
  channelTypeList?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
};
export type MarketingPlannerRequest = {
  id?: string;
  headerType?: "CAMPAIGN" | "EVENT";
  campaignType?:
    | "AWARENESS"
    | "TRAFFIC"
    | "ENGAGEMENT"
    | "APP_PROMOTION"
    | "LEAD_GENERATION"
    | "SALES_CONVERSION";
  eventType?:
    | "PRODUCT_ROLLOUT"
    | "FEATURE_UNVEILING"
    | "RAISED_FUNDING"
    | "MARKET_EXPANSION"
    | "HOSTING_LEARNING_SESSIONS"
    | "SEASONAL_DISCOUNT_SALES"
    | "EVENT_PARTICIPATION"
    | "PARTNERSHIP_INITIATION"
    | "STRATEGIC_SHIFT"
    | "TEAM_ONBOARDING_CELEBRATION"
    | "BRAND_MILESTONE_CELEBRATION";
  name?: string;
  about?: string;
  keyMessage?: string;
  highlight?: string[];
  startDate?: string;
  endDate?: string;
  productAbout?:
    | "MOBILE_APP"
    | "WEB_SITE"
    | "PHYSICAL_PRODUCT"
    | "OTHER"
    | "DEFAULT"
    | "APP";
  productAboutUrl?: string;
  visuals?: string[];
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  paidProperties?: PaidProperties;
  audience?: AddAudienceRequest;
  rootId?: string;
  status?: string;
  activitySize?: number;
  campaignObjectiveType?:
    | "BRAND_EDUCATION"
    | "BRAND_RECALL"
    | "POSITIONING"
    | "NEW_REACH"
    | "DRIVE_VISITS"
    | "BOOST_CLICKS"
    | "REPEAT_VISITS"
    | "INCREASE_PAGE_VIEWS"
    | "FOSTER_INTERACTION"
    | "SESSION_DURATION"
    | "CONTENT_INTERACTION"
    | "ACTIVE_PARTICIPATION"
    | "BOOST_DOWNLOADS"
    | "POSITIVE_RATINGS"
    | "FEATURE_PROMOTION"
    | "USER_RETENTION"
    | "COLLECT_CONTACTS"
    | "QUALIFY_LEADS"
    | "SIGN_UP_DRIVE"
    | "BUILD_DATABASE"
    | "DRIVE_SALES"
    | "ENCOURAGE_UPSELLS"
    | "REDUCE_ABANDONMENT"
    | "INCREASE_ORDER_VALUE";
  brandId?: string;
  active?: boolean;
};
export const {
  useSaveActivityMutation,
  useScheduleOrDraftActivityMutation,
  useGenerateContentMutation,
  useCreateActivityFromCampaignsMutation,
  useRegenerateForActivityQuery,
  useLazyRegenerateForActivityQuery,
  useGetLatestActivitiesQuery,
  useLazyGetLatestActivitiesQuery,
  useGetAllActivityQuery,
  useLazyGetAllActivityQuery,
  useGetActivitiesFromCampaignQuery,
  useLazyGetActivitiesFromCampaignQuery,
  useDeleteActivityByIdMutation,
} = injectedRtkApi;
