import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { ILoop } from '../models/Loop'
import { INewContent } from '../models/NewContent'
import { BrandKeywords, FileProps, BrandRequest, MetaAdsSettings } from '../services/BrandApi'
import { AddAudienceRequest, AudienceResponse, Location } from '../services/AudiencetApi'

export interface IBrandInfo {
  id?: string;
  brandName?: string;
  webSiteUrl?: string;
  twitter?: string;
  instagram?: string;
  linkedIn?: string;
  facebook?: string;
  aboutCompany?: string;
  selectedCompanyFiles?: FileProps[];
  selectedCompanyLogos?: FileProps[];
  selectedProductPhotos?: FileProps[];
  selectedCompanyIcons?: FileProps[];
  uploadedCompanyPrimaryFont?: any[];
  uploadedCompanySecondaryFont?: any[];
  uploadedCompanyAccentFont?: any[];
  technicalLinks?: FileProps[];
  referenceFiles?: FileProps[];
  brandTones?: string[];
  brandContentLanguages?: string[];
  selectedKeywords?: BrandKeywords[];
  brandColors?: string[];
  selectedCompanyPrimaryFont?: FileProps;
  selectedCompanySecondaryFont?: FileProps;
  selectedCompanyAccentFont?: FileProps;
  userEnteredKeywords?: BrandKeywords[];
  associatedAudiences?: AudienceResponse[];
  sector?: string;
  subSector?: string;
  audienceIds?: string[];
  metaAdsSettings?: MetaAdsSettings
}

export interface IAudienceInfo {
  brand_id?: string;
  audience_id?: string;
  name?: string;
  location?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  location_exclude?: Location[];
  interest_exclude?: string[];
  ads_category?: string
  beneficiary?: string
  payer?: string
}



interface BrandState {
  newBrand: IBrandInfo,
  selectedBrand: any,
  organizationAIModels: string[],
  newAudience: IAudienceInfo
}

// Define the initial state using that type
const initialState: BrandState = {
  newBrand: {},
  selectedBrand: {},
  organizationAIModels: [],
  newAudience: {}
}

export const brandSlice = createSlice({
  name: "brand",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNewBrandInfo: (state, brand: PayloadAction<IBrandInfo>) => {
      console.log('state.newBrand = brand.payload;', brand.payload)
      state.newBrand = brand.payload;
    },
    setSelectedBrandInfo: (state, brand: PayloadAction<BrandRequest>) => {
      //console.log('state.newBrand = brand.payload;', brand.payload)
      state.selectedBrand = brand.payload;
    },
    setOrganizationAIModels: (state, brand: PayloadAction<string[]>) => {
      state.organizationAIModels = brand.payload;
    },
    setNewAudienceInfo: (state, brand: PayloadAction<IAudienceInfo>) => {
      state.newAudience = brand.payload;
    },
  },
});

export const { setNewBrandInfo, setSelectedBrandInfo, setOrganizationAIModels, setNewAudienceInfo } = brandSlice.actions

export default brandSlice.reducer
