import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateMarketingPlanner: build.mutation<
      UpdateMarketingPlannerApiResponse,
      UpdateMarketingPlannerApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner/update-marketing-planner`,
        method: "PUT",
        body: queryArg.marketingPlannerRequest,
      }),
    }),
    saveMarketingPlanner: build.mutation<
      SaveMarketingPlannerApiResponse,
      SaveMarketingPlannerApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner`,
        method: "POST",
        body: queryArg.marketingPlannerRequest,
      }),
    }),
    saveCampaign: build.mutation<SaveCampaignApiResponse, SaveCampaignApiArg>({
      query: (queryArg) => ({
        url: `/marketing_planner/save-campaign`,
        method: "POST",
        body: queryArg.marketingPlannerRequest,
      }),
    }),
    deleteEventAndCampaigns: build.mutation<
      DeleteEventAndCampaignsApiResponse,
      DeleteEventAndCampaignsApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner/delete-event-and-campaigns`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getLatestCampaigns: build.query<
      GetLatestCampaignsApiResponse,
      GetLatestCampaignsApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner/latest`,
        params: { count: queryArg.count },
      }),
    }),
    getCampaignObjectiveTypeList: build.query<
      GetCampaignObjectiveTypeListApiResponse,
      GetCampaignObjectiveTypeListApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner/get-campaign-type-list/${queryArg.campaignTypeText}`,
      }),
    }),
    getAllMarketingPlanner: build.query<
      GetAllMarketingPlannerApiResponse,
      GetAllMarketingPlannerApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner/get-all/${queryArg.brandId}`,
      }),
    }),
    deleteMarketingPlannerById: build.mutation<
      DeleteMarketingPlannerByIdApiResponse,
      DeleteMarketingPlannerByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/marketing_planner/${queryArg.id}/${queryArg.rootId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateMarketingPlannerApiResponse =
  /** status 200 OK */ MarketingPlannerResponse[];
export type UpdateMarketingPlannerApiArg = {
  marketingPlannerRequest: MarketingPlannerRequest;
};
export type SaveMarketingPlannerApiResponse =
  /** status 200 OK */ MarketingPlannerResponse[];
export type SaveMarketingPlannerApiArg = {
  marketingPlannerRequest: MarketingPlannerRequest;
};
export type SaveCampaignApiResponse = /** status 200 OK */ GetAllResponse;
export type SaveCampaignApiArg = {
  marketingPlannerRequest: MarketingPlannerRequest;
};
export type DeleteEventAndCampaignsApiResponse = unknown;
export type DeleteEventAndCampaignsApiArg = {
  body: string[];
};
export type GetLatestCampaignsApiResponse =
  /** status 200 OK */ MarketingPlannerResponse[];
export type GetLatestCampaignsApiArg = {
  count?: number;
};
export type GetCampaignObjectiveTypeListApiResponse = /** status 200 OK */ (
  | "BRAND_EDUCATION"
  | "BRAND_RECALL"
  | "POSITIONING"
  | "NEW_REACH"
  | "DRIVE_VISITS"
  | "BOOST_CLICKS"
  | "REPEAT_VISITS"
  | "INCREASE_PAGE_VIEWS"
  | "FOSTER_INTERACTION"
  | "SESSION_DURATION"
  | "CONTENT_INTERACTION"
  | "ACTIVE_PARTICIPATION"
  | "BOOST_DOWNLOADS"
  | "POSITIVE_RATINGS"
  | "FEATURE_PROMOTION"
  | "USER_RETENTION"
  | "COLLECT_CONTACTS"
  | "QUALIFY_LEADS"
  | "SIGN_UP_DRIVE"
  | "BUILD_DATABASE"
  | "DRIVE_SALES"
  | "ENCOURAGE_UPSELLS"
  | "REDUCE_ABANDONMENT"
  | "INCREASE_ORDER_VALUE"
)[];
export type GetCampaignObjectiveTypeListApiArg = {
  campaignTypeText: string;
};
export type GetAllMarketingPlannerApiResponse =
  /** status 200 OK */ MarketingPlannerResponse[];
export type GetAllMarketingPlannerApiArg = {
  brandId: string;
};
export type DeleteMarketingPlannerByIdApiResponse =
  /** status 200 OK */ MarketingPlannerResponse[];
export type DeleteMarketingPlannerByIdApiArg = {
  id: string;
  rootId: string;
};
export type BudgetDistribution = {
  googleAdsDistribution?: number;
  metaAdsDistribution?: number;
};
export type PaidProperties = {
  budgetType?: "DAILY_BUDGET" | "TOTAL_BUDGET";
  budget?: number;
  budgetDistribution?: BudgetDistribution;
};
export type Location = {
  name?: string;
  type?: "COUNTRY" | "CITY";
};
export type AudienceResponse = {
  organization_id?: string;
  brand_id?: string;
  audience_id?: string;
  name?: string;
  location?: Location[];
  location_exclude?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  interest_exclude?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  ads_category?: string;
  beneficiary?: string;
  payer?: string;
};
export type MarketingPlannerResponse = {
  id?: string;
  headerType?: "CAMPAIGN" | "EVENT";
  campaignType?:
    | "AWARENESS"
    | "TRAFFIC"
    | "ENGAGEMENT"
    | "APP_PROMOTION"
    | "LEAD_GENERATION"
    | "SALES_CONVERSION";
  description?: string;
  eventType?:
    | "PRODUCT_ROLLOUT"
    | "FEATURE_UNVEILING"
    | "RAISED_FUNDING"
    | "MARKET_EXPANSION"
    | "HOSTING_LEARNING_SESSIONS"
    | "SEASONAL_DISCOUNT_SALES"
    | "EVENT_PARTICIPATION"
    | "PARTNERSHIP_INITIATION"
    | "STRATEGIC_SHIFT"
    | "TEAM_ONBOARDING_CELEBRATION"
    | "BRAND_MILESTONE_CELEBRATION";
  name?: string;
  about?: string;
  keyMessage?: string;
  highlight?: string[];
  startDate?: string;
  endDate?: string;
  productAbout?:
    | "MOBILE_APP"
    | "WEB_SITE"
    | "PHYSICAL_PRODUCT"
    | "OTHER"
    | "DEFAULT"
    | "APP";
  productAboutUrl?: string;
  visuals?: string[];
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  paidProperties?: PaidProperties;
  audience?: AudienceResponse;
  rootId?: string;
  status?: string;
  activitySize?: number;
  campaignObjectiveType?:
    | "BRAND_EDUCATION"
    | "BRAND_RECALL"
    | "POSITIONING"
    | "NEW_REACH"
    | "DRIVE_VISITS"
    | "BOOST_CLICKS"
    | "REPEAT_VISITS"
    | "INCREASE_PAGE_VIEWS"
    | "FOSTER_INTERACTION"
    | "SESSION_DURATION"
    | "CONTENT_INTERACTION"
    | "ACTIVE_PARTICIPATION"
    | "BOOST_DOWNLOADS"
    | "POSITIVE_RATINGS"
    | "FEATURE_PROMOTION"
    | "USER_RETENTION"
    | "COLLECT_CONTACTS"
    | "QUALIFY_LEADS"
    | "SIGN_UP_DRIVE"
    | "BUILD_DATABASE"
    | "DRIVE_SALES"
    | "ENCOURAGE_UPSELLS"
    | "REDUCE_ABANDONMENT"
    | "INCREASE_ORDER_VALUE";
  brandId?: string;
  endedSize?: number;
  createDate?: string;
  active?: boolean;
};
export type AddAudienceRequest = {
  brand_id?: string;
  audience_id?: string;
  name: string;
  location?: Location[];
  location_exclude?: Location[];
  income_level?: string[];
  age_group?: string[];
  education_level?: string[];
  occupation?: string[];
  family?: string[];
  type_of_area?: string[];
  interest?: string[];
  interest_exclude?: string[];
  loyalty?: string[];
  gender?: string[];
  language?: string[];
  parenting_status?: string[];
  technology_status?: string[];
  connection_type?: string[];
  ads_category?: string;
  beneficiary?: string;
  payer?: string;
};
export type MarketingPlannerRequest = {
  id?: string;
  headerType?: "CAMPAIGN" | "EVENT";
  campaignType?:
    | "AWARENESS"
    | "TRAFFIC"
    | "ENGAGEMENT"
    | "APP_PROMOTION"
    | "LEAD_GENERATION"
    | "SALES_CONVERSION";
  eventType?:
    | "PRODUCT_ROLLOUT"
    | "FEATURE_UNVEILING"
    | "RAISED_FUNDING"
    | "MARKET_EXPANSION"
    | "HOSTING_LEARNING_SESSIONS"
    | "SEASONAL_DISCOUNT_SALES"
    | "EVENT_PARTICIPATION"
    | "PARTNERSHIP_INITIATION"
    | "STRATEGIC_SHIFT"
    | "TEAM_ONBOARDING_CELEBRATION"
    | "BRAND_MILESTONE_CELEBRATION";
  name?: string;
  about?: string;
  keyMessage?: string;
  highlight?: string[];
  startDate?: string;
  endDate?: string;
  productAbout?:
    | "MOBILE_APP"
    | "WEB_SITE"
    | "PHYSICAL_PRODUCT"
    | "OTHER"
    | "DEFAULT"
    | "APP";
  productAboutUrl?: string;
  visuals?: string[];
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  paidProperties?: PaidProperties;
  audience?: AddAudienceRequest;
  rootId?: string;
  status?: string;
  activitySize?: number;
  campaignObjectiveType?:
    | "BRAND_EDUCATION"
    | "BRAND_RECALL"
    | "POSITIONING"
    | "NEW_REACH"
    | "DRIVE_VISITS"
    | "BOOST_CLICKS"
    | "REPEAT_VISITS"
    | "INCREASE_PAGE_VIEWS"
    | "FOSTER_INTERACTION"
    | "SESSION_DURATION"
    | "CONTENT_INTERACTION"
    | "ACTIVE_PARTICIPATION"
    | "BOOST_DOWNLOADS"
    | "POSITIVE_RATINGS"
    | "FEATURE_PROMOTION"
    | "USER_RETENTION"
    | "COLLECT_CONTACTS"
    | "QUALIFY_LEADS"
    | "SIGN_UP_DRIVE"
    | "BUILD_DATABASE"
    | "DRIVE_SALES"
    | "ENCOURAGE_UPSELLS"
    | "REDUCE_ABANDONMENT"
    | "INCREASE_ORDER_VALUE";
  brandId?: string;
  active?: boolean;
};
export type ContentDetails = {
  imageFileId?: string;
  textContent?: string;
  contentId?: string;
  revisionId?: string;
  active?: boolean;
  generated?: boolean;
};
export type Creative = {
  shortHeadlines?: ContentDetails[];
  longHeadlines?: ContentDetails[];
  headlines?: ContentDetails[];
  description?: ContentDetails[];
  selectedCta?: ContentDetails;
  ctas?: ContentDetails[];
};
export type PlatformBudget = {
  channel?:
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS";
  budget?: number;
  budgetPrice?: number;
};
export type ActivityResponse = {
  id?: string;
  channels?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
  status?: string;
  type?: string;
  paidProperties?: PaidProperties;
  startDate?: string;
  endDate?: string;
  credit?: number;
  creatives?: Creative;
  rootId?: string;
  campaignObjectiveType?:
    | "BRAND_EDUCATION"
    | "BRAND_RECALL"
    | "POSITIONING"
    | "NEW_REACH"
    | "DRIVE_VISITS"
    | "BOOST_CLICKS"
    | "REPEAT_VISITS"
    | "INCREASE_PAGE_VIEWS"
    | "FOSTER_INTERACTION"
    | "SESSION_DURATION"
    | "CONTENT_INTERACTION"
    | "ACTIVE_PARTICIPATION"
    | "BOOST_DOWNLOADS"
    | "POSITIVE_RATINGS"
    | "FEATURE_PROMOTION"
    | "USER_RETENTION"
    | "COLLECT_CONTACTS"
    | "QUALIFY_LEADS"
    | "SIGN_UP_DRIVE"
    | "BUILD_DATABASE"
    | "DRIVE_SALES"
    | "ENCOURAGE_UPSELLS"
    | "REDUCE_ABANDONMENT"
    | "INCREASE_ORDER_VALUE";
  imageContentDetails?: ContentDetails[];
  textContentDetails?: ContentDetails[];
  eventType?:
    | "PRODUCT_ROLLOUT"
    | "FEATURE_UNVEILING"
    | "RAISED_FUNDING"
    | "MARKET_EXPANSION"
    | "HOSTING_LEARNING_SESSIONS"
    | "SEASONAL_DISCOUNT_SALES"
    | "EVENT_PARTICIPATION"
    | "PARTNERSHIP_INITIATION"
    | "STRATEGIC_SHIFT"
    | "TEAM_ONBOARDING_CELEBRATION"
    | "BRAND_MILESTONE_CELEBRATION";
  campaignType?:
    | "AWARENESS"
    | "TRAFFIC"
    | "ENGAGEMENT"
    | "APP_PROMOTION"
    | "LEAD_GENERATION"
    | "SALES_CONVERSION";
  productAbout?:
    | "MOBILE_APP"
    | "WEB_SITE"
    | "PHYSICAL_PRODUCT"
    | "OTHER"
    | "DEFAULT"
    | "APP";
  postCategory?: "ORGANIC" | "HOLIDAY" | "PAID_GOOGLE" | "PAID_META" | "PAID";
  adType?:
    | "SEARCH_ENGINE_MARKETING"
    | "DISPLAY_ADS"
    | "VIDEOS"
    | "SOCIAL_MEDIA_ADVERTISING";
  totalBudget?: number;
  budgetPrice?: number;
  budgetDetails?: PlatformBudget[];
  objective?: string;
  priority?: "HIGH" | "NORMAL" | "LOW";
  brandId?: string;
  companyName?: string;
  productAboutUrl?: string;
  audience?: AudienceResponse;
  budgetPriceInAccountCurrency?: number;
  exchangeRate?: number;
  accountCurrency?: string;
  createDate?: string;
  actionRequired?: boolean;
};
export type ActionHeaderFilter = {
  value?: string;
  title?: string;
};
export type GetAllResponse = {
  activityResponseList?: ActivityResponse[];
  actionHeaderFilterList?: ActionHeaderFilter[];
  channelTypeList?: (
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
  )[];
};
export const {
  useUpdateMarketingPlannerMutation,
  useSaveMarketingPlannerMutation,
  useSaveCampaignMutation,
  useDeleteEventAndCampaignsMutation,
  useGetLatestCampaignsQuery,
  useLazyGetLatestCampaignsQuery,
  useGetCampaignObjectiveTypeListQuery,
  useLazyGetCampaignObjectiveTypeListQuery,
  useGetAllMarketingPlannerQuery,
  useLazyGetAllMarketingPlannerQuery,
  useDeleteMarketingPlannerByIdMutation,
} = injectedRtkApi;
