import TenX from '../../assets/icons/ten-x.svg';
import Branding from '../../assets/icons/branding.svg';
import Engagement from '../../assets/icons/engagement.svg';
import TargetOpt from '../../assets/icons/target-opt.svg';
import DocLight from '../../assets/icons/doc-light.svg';
import Revision from '../../assets/icons/revision.svg';
import Icon from '../../components/Icon';


export interface IFeaturesProp {
    className: string
}
function Features(props: IFeaturesProp) {
    return (<>
        <section className={`features white-elevated-section relative ${props.className}`}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2
                        className="mx-auto max-w-4xl font-display text-4xl font-medium text-sky-800 sm:text-4xl relative"
                    >
                        Maximize Content Output With Minimum Input
                    </h2>
                    <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-600">
                        Use AI as your creative engine to generate the top-performing marketing contents tailored
                        for your brand.
                    </p>
                </div>

                <ul
                    className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-16 text-sm sm:mt-20 sm:grid-cols-2 md:gap-20 lg:max-w-none lg:grid-cols-3"
                // style="--bg: #38bdf8; --fg: #6366f1; --inter: #075985;"
                >
                    <li className="">
                        <Icon data={TenX} color="none" className="h-10 w-10" />
                        <h3 className="mt-4 font-semibold text-sky-900">10x Content Output</h3>
                        <p className="mt-2 text-gray-700 text-justify">
                            Highly efficient content production even for non-marketers. Achieve 10x content output
                            without breaking a sweat.
                        </p>
                    </li>
                    <li className="">
                        <Icon data={Branding} color="none" className="h-10 w-10" />
                        <h3 className="mt-4 font-semibold text-sky-900">Automatic Branding</h3>
                        <p className="mt-2 text-gray-700 text-justify">
                            Seamlessly incorporate branding elements from your company materials and website with just
                            a simple click.
                        </p>
                    </li>
                    <li className="">
                        <Icon data={Engagement} color="none" className="h-10 w-10" />
                        <h3 className="mt-4 font-semibold text-sky-900">Engagement Optimization</h3>
                        <p className="mt-2 text-gray-700 text-justify">
                            Content with the highest conversion potential across platforms. Let AI ensure the maximum
                            visibility for your content.
                        </p>
                    </li>
                    <li className="">
                        <Icon data={TargetOpt} color="none" className="h-10 w-10" />
                        <h3 className="mt-4 font-semibold text-sky-900">Target Optimization</h3>
                        <p className="mt-2 text-gray-700 text-justify">
                            Effortlessly speak to your target audience and drive success. Tailor your content for your
                            campaign' needs.
                        </p>
                    </li>
                    <li className="">
                        <Icon data={DocLight} color="none" className="h-10 w-10" />
                        <h3 className="mt-4 font-semibold text-sky-900">Adapt Other Brands' Style</h3>
                        <p className="mt-2 text-gray-700 text-justify">
                            Easily adapt your favorite brands' style to create similar contents while still reflecting
                            your brands' voice.
                        </p>
                    </li>
                    <li className="">
                        <Icon data={Revision} color="none" className="h-10 w-10" />
                        <h3 className="mt-4 font-semibold text-sky-900">Quick Revisions</h3>
                        <p className="mt-2 text-gray-700 text-justify">
                            Make quick revisions and play with results to get what you need. Use AI as a creative
                            engine to offer you new ideas.
                        </p>
                    </li>
                </ul>
            </div>
        </section>
    </>);
}

export default Features;