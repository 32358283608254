import * as React from "react";
const SvgCompanyMaterialsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M18.667 18H9.334c-1.133 0-2-.867-2-2V9.333c0-.4.267-.666.667-.666h12c.4 0 .666.266.666.666V16c0 1.133-.866 2-2 2Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M14.667 8.667V8c0-.4-.267-.667-.667-.667s-.667.267-.667.667v.667h1.334ZM15.6 18h-3.2l-1.533 1.533a.605.605 0 0 0-.2.467c0 .4.266.667.666.667.2 0 .334-.067.467-.2l1.533-1.534V20c0 .4.267.667.667.667s.667-.267.667-.667v-1.067l1.533 1.534c.133.133.267.2.467.2s.333-.067.466-.2a.644.644 0 0 0 0-.934L15.6 18ZM12 12.667h-1.333c-.4 0-.667-.267-.667-.667s.267-.667.667-.667H12c.4 0 .667.267.667.667s-.267.667-.667.667Zm2.667 2.666h-4c-.4 0-.667-.266-.667-.666s.267-.667.667-.667h4c.4 0 .666.267.666.667s-.266.666-.666.666Z"
    />
  </svg>
);
export default SvgCompanyMaterialsIcon;
