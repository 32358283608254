import React, { FunctionComponent, LegacyRef, useState } from "react";
import SvgSwipeUpIcon from "../assets/IconComponents/SwipeUpIcon";
import Icon from "./Icon";
import UpdateBrief from "./UpdateBrief";
import { Content } from "../services/ContentApi";
import { useClickAway } from "@uidotdev/usehooks";

interface BottomUpdateBriefProps {
    content: Content | undefined
    getContentDetails: () => void
    onContentCreated: () => void
}

const BottomUpdateBrief: FunctionComponent<BottomUpdateBriefProps> = (props: BottomUpdateBriefProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const ref = useClickAway(() => {
        setIsExpanded(false);
    });

    return (
        <>
            <div className={`${isExpanded ? "top-0" : ""} fixed left-0 right-0 bottom-0  bg-gray/30 backdrop-blur-sm md:hidden`}>
                <div ref={ref as LegacyRef<HTMLDivElement>} className="absolute left-0 right-0 bottom-0 flex justify-between items-center lg:hidden flex-col bg-white ">
                    <div className="w-full flex justify-between items-center flex-row h-[56px] p-[10px] pl-[12px] border-1 border-border">
                        <p className="text-H6 text-black">Update Brief</p>
                        <button
                            className={`lg:hidden 
                                    m-1
                                    mx-2
                                    ${isExpanded ? "border-1 border-border" : "bg-red"}
                                    p-[10px]
                                    rounded-lg flex flex-row items-center
                                `}
                            onClick={() => {
                                setIsExpanded(!isExpanded)
                            }}>
                            <div className={`rounded-sm p-[2px] transition-all transform-gpu ${isExpanded ? "rotate-180 pb-1" : "rotate-0"}`}>
                                <Icon component={SvgSwipeUpIcon} viewBox="0 0 20 20" className="w-[30px]" color={isExpanded ? "#000" : "#FFF"} />
                            </div>
                        </button>
                    </div>
                    <UpdateBrief
                        content={props.content}
                        className={`md:hidden transition-all transform-gpu w-full ${isExpanded ? "flex " : "hidden"}`}
                        updateBrief={() => {
                            props.getContentDetails();
                            setIsExpanded(false)
                        }}
                        onClose={() => {
                            setIsExpanded(false)
                        }}
                        onContentCreated={props.onContentCreated}
                    />
                </div>
            </div>
        </>
    );
}

export default BottomUpdateBrief;