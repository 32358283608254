import * as React from "react";
import type { SVGProps } from "react";
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      d="M8.333 1.667 7.5 2.5H3.167a.833.833 0 1 0 0 1.667h1v12.5c0 .435.16.878.473 1.193.315.314.758.473 1.193.473h8.334c.435 0 .879-.16 1.193-.473.314-.314.473-.758.473-1.193v-12.5h1a.833.833 0 0 0 0-1.667H12.5l-.833-.833H8.333Zm-2.5 2.5h8.334v12.5H5.833v-12.5ZM7.5 5.833V15h1.667V5.833H7.5Zm3.333 0V15H12.5V5.833h-1.667Z"
    />
  </svg>
);
export default SvgDelete;
