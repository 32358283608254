import * as React from "react";
const SvgAIProductPhotoshootHeaderLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#121315"
      d="M4.167 17.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177v-2.5c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.357.24.593v2.5h2.5c.236 0 .434.08.593.24.16.16.24.358.24.594s-.08.434-.24.593a.806.806 0 0 1-.593.24h-2.5Zm11.666 0h-2.5a.806.806 0 0 1-.593-.24.806.806 0 0 1-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h2.5v-2.5c0-.236.08-.434.24-.593.16-.16.358-.24.594-.24s.434.08.593.24c.16.16.24.357.24.593v2.5c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49ZM2.5 6.667v-2.5c0-.459.163-.851.49-1.177.326-.327.718-.49 1.177-.49h2.5c.236 0 .434.08.593.24.16.16.24.357.24.593s-.08.434-.24.594a.806.806 0 0 1-.593.24h-2.5v2.5c0 .236-.08.434-.24.593a.806.806 0 0 1-.594.24.806.806 0 0 1-.593-.24.806.806 0 0 1-.24-.593Zm13.333 0v-2.5h-2.5a.806.806 0 0 1-.593-.24.806.806 0 0 1-.24-.594c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h2.5c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177v2.5c0 .236-.08.434-.24.593a.806.806 0 0 1-.593.24.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.593ZM10 14.167c-1.153 0-2.135-.407-2.948-1.22-.812-.812-1.219-1.794-1.219-2.947s.407-2.135 1.22-2.948C7.864 6.24 8.846 5.833 10 5.833s2.135.407 2.948 1.22c.812.812 1.219 1.794 1.219 2.947s-.407 2.135-1.22 2.948c-.812.812-1.794 1.219-2.947 1.219Zm0-1.667c.694 0 1.285-.243 1.77-.73.487-.485.73-1.076.73-1.77s-.243-1.285-.73-1.77A2.41 2.41 0 0 0 10 7.5a2.41 2.41 0 0 0-1.77.73A2.41 2.41 0 0 0 7.5 10c0 .694.243 1.285.73 1.77.485.487 1.076.73 1.77.73Z"
    />
  </svg>
);
export default SvgAIProductPhotoshootHeaderLogo;
