import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateUserSettings: build.mutation<
      UpdateUserSettingsApiResponse,
      UpdateUserSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/user/update`,
        method: "POST",
        body: queryArg.userSettingsUpdateRequest,
      }),
    }),
    updateOwnPassword: build.mutation<
      UpdateOwnPasswordApiResponse,
      UpdateOwnPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/user/update-own-password`,
        method: "POST",
        body: queryArg.updateOwnPasswordRequest,
      }),
    }),
    revokeUser: build.mutation<RevokeUserApiResponse, RevokeUserApiArg>({
      query: () => ({ url: `/user/revoke`, method: "POST" }),
    }),
    freezeUser: build.mutation<FreezeUserApiResponse, FreezeUserApiArg>({
      query: (queryArg) => ({
        url: `/user/freeze`,
        method: "POST",
        body: queryArg.freezeUserRequest,
      }),
    }),
    userSettings: build.query<UserSettingsApiResponse, UserSettingsApiArg>({
      query: () => ({ url: `/user` }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/user/delete`,
        method: "DELETE",
        body: queryArg.deleteUserRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateUserSettingsApiResponse = unknown;
export type UpdateUserSettingsApiArg = {
  userSettingsUpdateRequest: UserSettingsUpdateRequest;
};
export type UpdateOwnPasswordApiResponse = /** status 200 OK */ ApiResponse;
export type UpdateOwnPasswordApiArg = {
  updateOwnPasswordRequest: UpdateOwnPasswordRequest;
};
export type RevokeUserApiResponse = unknown;
export type RevokeUserApiArg = void;
export type FreezeUserApiResponse = unknown;
export type FreezeUserApiArg = {
  freezeUserRequest: FreezeUserRequest;
};
export type UserSettingsApiResponse = /** status 200 OK */ UserSettingsResponse;
export type UserSettingsApiArg = void;
export type DeleteUserApiResponse = unknown;
export type DeleteUserApiArg = {
  deleteUserRequest: DeleteUserRequest;
};
export type UserSettingsUpdateRequest = {
  allow_notification_email?: boolean;
};
export type ApiResponse = {
  message?: string;
};
export type UpdateOwnPasswordRequest = {
  old_password: string;
  new_password: string;
};
export type FreezeUserRequest = {
  reason:
    | "TooExpensive"
    | "DifficultToUse"
    | "MissingFeatures"
    | "LowerThanExpected"
    | "PoorSupport";
  explanation?: string;
};
export type UserSubscription = {
  plan_id?: string;
  plan_name?: string;
  credits_used?: number;
  credits_available?: number;
  brands_used?: number;
  brands_available?: number;
  current_period_end_date?: number;
  cancels_at?: number;
  is_discounted?: boolean;
  status?:
    | "INCOMPLETE"
    | "INCOMPLETE_EXPIRED"
    | "TRIALING"
    | "ACTIVE"
    | "PAST_DUE"
    | "CANCELED"
    | "UNPAID";
};
export type UserPaymentMethod = {
  brand?: string;
  exp_month?: number;
  exp_year?: number;
  last4?: string;
};
export type UserAddress = {
  city?: string;
  country?: string;
  line1: string;
  line2?: string;
  postal_code?: string;
  state?: string;
  name?: string;
};
export type UserSettingsResponse = {
  email_address: string;
  allow_notification_email: boolean;
  subscription?: UserSubscription;
  payment_method?: UserPaymentMethod;
  billing_address?: UserAddress;
  auth_provider?: string;
  trialed_before?: boolean;
};
export type DeleteUserRequest = {
  reason:
    | "TooExpensive"
    | "DifficultToUse"
    | "MissingFeatures"
    | "LowerThanExpected"
    | "PoorSupport";
  explanation?: string;
};
export const {
  useUpdateUserSettingsMutation,
  useUpdateOwnPasswordMutation,
  useRevokeUserMutation,
  useFreezeUserMutation,
  useUserSettingsQuery,
  useLazyUserSettingsQuery,
  useDeleteUserMutation,
} = injectedRtkApi;
