import * as React from "react";
import type { SVGProps } from "react";
const SvgWarningIconRed = (props: SVGProps<SVGSVGElement>) => (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.1" x="0.5" width="56" height="56" rx="4" fill="#FF5555"/>
      <path opacity="0.5"
            d="M36.057 38.0005H19.943C19.4124 38.0005 18.8912 37.8608 18.4317 37.5955C17.9722 37.3302 17.5907 36.9486 17.3254 36.4891C17.0601 36.0297 16.9204 35.5084 16.9203 34.9779C16.9202 34.4473 17.0598 33.9261 17.325 33.4665L25.382 19.5115C25.6473 19.052 26.0289 18.6703 26.4885 18.405C26.9481 18.1397 27.4694 18 28 18C28.5307 18 29.052 18.1397 29.5115 18.405C29.9711 18.6703 30.3527 19.052 30.618 19.5115L38.675 33.4665C38.9402 33.9261 39.0798 34.4473 39.0797 34.9779C39.0797 35.5084 38.94 36.0297 38.6746 36.4891C38.4093 36.9486 38.0278 37.3302 37.5683 37.5955C37.1088 37.8608 36.5876 38.0005 36.057 38.0005Z"
            fill="#FF5555"/>
      <path
          d="M28 34C28.5523 34 29 33.5523 29 33C29 32.4477 28.5523 32 28 32C27.4477 32 27 32.4477 27 33C27 33.5523 27.4477 34 28 34Z"
          fill="#FF5555"/>
      <path
          d="M28 30C27.7348 30 27.4804 29.8946 27.2929 29.7071C27.1054 29.5196 27 29.2652 27 29V25C27 24.7348 27.1054 24.4804 27.2929 24.2929C27.4804 24.1054 27.7348 24 28 24C28.2652 24 28.5196 24.1054 28.7071 24.2929C28.8946 24.4804 29 24.7348 29 25V29C29 29.2652 28.8946 29.5196 28.7071 29.7071C28.5196 29.8946 28.2652 30 28 30Z"
          fill="#FF5555"/>
    </svg>

);
export default SvgWarningIconRed;
