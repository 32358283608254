import "./Styles/GetStartedFreeBig.scss"
import { clickOutside } from "../../helpers/ClickOutside";
import { useEffect, useState } from "react";

function GetStartedFreeBig() {
    const [isFormShowing, setShowForm] = useState(false)
    let inputEl: HTMLInputElement,
        form: HTMLFormElement;

    function showForm() {
        setShowForm(true)
        if (inputEl) {
            inputEl.focus();
        }
    }
    
    function hideForm() {
        setShowForm(false)
    }

    useEffect(() => {
        clickOutside(form, () => {
            hideForm()
        })
    }, [])


    return (<>

        <div className="get-started inline relative">
            <button
                className="group inline-flex items-center justify-center rounded-xl 
	py-3 px-8 text-lg font-semibold focus:outline-none 
	focus-visible:outline-2 focus-visible:outline-offset-2 
	bg-gradient-to-b from-red-500 to-red-600 
	active:bg-red-800
	text-white   active:text-red-100 
	focus-visible:outline-red-600 transition-colors text-shadow-lg
	relative "
                onClick={showForm}
            >
                <span>Access Beta</span>
            </button>
            <form className={isFormShowing ? "show" : ""} ref={r => form = r!}>
                <input
                    name="email"
                    placeholder="Enter your e-mail address"
                    className="h-12 ext-lg"
                    ref={r => inputEl = r!}
                />

                <button
                    className="group inline-flex items-center justify-center rounded-xl 
			py-3 px-8 text-lg font-semibold focus:outline-none 
			focus-visible:outline-2 focus-visible:outline-offset-2 
			bg-gradient-to-b from-red-500 to-red-600 
			active:bg-red-800
			text-white   active:text-red-100 
			focus-visible:outline-red-600 transition-colors text-shadow-lg
			relative shrink-0 "
                >
                    Access Beta
                </button>
            </form>
        </div>

    </>);


}

export default GetStartedFreeBig;