import * as React from "react";
import type { SVGProps } from "react";
const SvgRadioSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <rect
      width={14.5}
      height={14.5}
      x={0.75}
      y={1.25}
      stroke="#F55"
      strokeWidth={1.5}
      rx={7.25}
    />
    <rect width={8} height={8} x={4} y={4.5} fill="#F55" rx={4} />
  </svg>
);
export default SvgRadioSelected;
