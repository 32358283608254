import * as React from "react";
const MicrophoneOne1 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path
            d="M7.75 13.657C7.75 13.657 10.048 13.48 11.639 11.889C13.23 10.298 13.407 8 13.407 8L17.283 14.091C17.5878 14.5696 17.7203 15.1378 17.6585 15.7018C17.5967 16.2658 17.3443 16.7919 16.9431 17.1931C16.5419 17.5943 16.0158 17.8467 15.4518 17.9085C14.8878 17.9703 14.3196 17.8378 13.841 17.533L7.75 13.657Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M7.75 13.5C10.7876 13.5 13.25 11.0376 13.25 8C13.25 4.96243 10.7876 2.5 7.75 2.5C4.71243 2.5 2.25 4.96243 2.25 8C2.25 11.0376 4.71243 13.5 7.75 13.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
              d="M16.5429 16.2929C16.9334 15.9024 17.5666 15.9024 17.9571 16.2929L21.9571 20.2929C22.2431 20.5789 22.3287 21.009 22.1739 21.3827C22.0191 21.7564 21.6545 22 21.25 22H16.75C16.1977 22 15.75 21.5523 15.75 21C15.75 20.4477 16.1977 20 16.75 20H18.8358L16.5429 17.7071C16.1524 17.3166 16.1524 16.6834 16.5429 16.2929Z"
              fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default MicrophoneOne1;
