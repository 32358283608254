import { t } from "i18next";
import { Close, Picture } from "../../assets/IconComponents";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import SvgText from "../../assets/IconComponents/Text";
import ContentContainer from "../ContentContainer";
import IconButton from "../IconButton";
import ChannelContentSelectorItem from "./ChannelContentSelectorItem";
import { useEffect, useState } from "react";
import { IChannelContentData } from "./ChannelContentSelector";

export interface IChannelContentSelectorPopupProps {
    data: IChannelContentData[]
    closePopup: () => void
    selectedChannelContents: IChannelContentData[] | undefined
    selectedChannelContentsChanged: (data: IChannelContentData[] | undefined) => void
    deselectable?: boolean
}

function ChannelContentSelectorPopup(props: IChannelContentSelectorPopupProps) {
    const [selectedItems, setSelectedItems] = useState<IChannelContentData[]>()

    useEffect(() => {
        if (props.selectedChannelContents) {
            setSelectedItems(props.selectedChannelContents)
        }
    }, [props.selectedChannelContents])


    return (
        <ContentContainer
            hoverable={false}
            className="w-[250px] h-[120px]  overflow-hidden"
            children={
                <>
                    {/*Body*/}
                    <div className="p-[12px] h-full overflow-scroll scrollbar-hide">
                        {
                            props.data.map(k => (
                                <ChannelContentSelectorItem
                                    key={`channel_sellector_item_${k.id}`}
                                    data={k}
                                    selected={!!(selectedItems?.find((items) => items.id === k.id))}
                                    itemSelected={(e, isSelected) => {
                                        if (isSelected) {
                                            if (selectedItems) {
                                                const newSelectedItems = [...selectedItems, e]
                                                setSelectedItems(newSelectedItems)
                                                props.selectedChannelContentsChanged(newSelectedItems)
                                            } else {
                                                setSelectedItems([e])
                                                props.selectedChannelContentsChanged([e])
                                            }
                                        } else {
                                            if (selectedItems) {
                                                const newSelectedItems = selectedItems.filter((a) => a.id !== e.id)
                                                setSelectedItems([...newSelectedItems])
                                                props.selectedChannelContentsChanged([...newSelectedItems])
                                            } else {
                                                console.log(`can not remove from empty array`)
                                            }
                                        }

                                    }} />
                            ))
                        }
                    </div>
                </>
            } />
    );
}

export default ChannelContentSelectorPopup;
