import { t } from "i18next";
import ContentContainer from "../../../components/ContentContainer";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setEvent, setPlan } from "../../../store/plannerSlice";
import { Button, Dropdown, MenuProps, Space } from "antd";
import {
    AudienceResponse,
    useAddAudienceMutation,
    useLazyGetAudienceByIdQuery,
    useLazyGetAudienceQuery
} from "../../../services/AudiencetApi";
import { useLazyFindById4Query } from "../../../services/BrandApi";
import MarketingAudiences from "../../../components/MarketingPlanner/MarketingAudiences";
import RecommendationBar from "../../../components/MarketingPlanner/RecommendationBar";
import Icon from "../../../components/Icon";
import { DownOutlined } from "@ant-design/icons";
import { useGetReachEstimateMutation } from "../../../services/AdManagerApi";
import PrimaryButton from "../../../components/PrimaryButton";
import { setNewAudienceInfo } from "../../../store/brandSlice";
import { toggleLoading } from "../../../store/appSlice";
import ApproximateIcon from "../../../assets/IconComponents/ApproximateIcon";

const DescribeYourAudience = (props) => {
    const dispatch = useAppDispatch();
    const { plan, event } = useAppSelector((k) => k.planner);
    const selectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const audienceInfo = useAppSelector((k) => k.brand.newAudience);

    const [marker, setMarker] = useState<AudienceResponse | undefined>()
    const [selectedTab, setSelectedTab] = useState<string>('');
    const [isSavedAudienceEdited, setIsSavedAudienceEdited] = useState<boolean>(false);
    const [approximateData, setApproximateData] = useState<any>()
    const [visualApproximateData, setVisualApproximateData] = useState<any>()

    const [getBrand, { data: brand }] = useLazyFindById4Query();
    const [getBrandAudience, { data: brandAudience }] = useLazyGetAudienceQuery()
    const [getContentAudience] = useLazyGetAudienceByIdQuery();
    const [addAudience] = useAddAudienceMutation();
    const [getReachEstimate, { isSuccess: reachEstimateOk, data: reachEstimate, isLoading: isReaching }] = useGetReachEstimateMutation();

    useEffect(() => {
        if (reachEstimateOk) {
            console.log(JSON.stringify(reachEstimate));
            dispatch(toggleLoading(false));
            calculateApproximate(reachEstimate)
        }
    }, [reachEstimateOk])

    useEffect(() => {
        if (selectedBrandId) {
            getBrand({ id: selectedBrandId })
        }
    }, [selectedBrandId])

    useEffect(() => {
        if (brand?.id) {
            console.log(brand?.id)
            getBrandAudience({ brandId: brand?.id })
        }
    }, [brand])

    useEffect(() => {

        if (brandAudience?.[0]?.audience_id) {
            console.log(brand?.id)
            getContentAudience({ audienceId: brandAudience?.[0]?.audience_id })
        }
    }, [brandAudience])

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (Number(e.key) === 1) {
            addAudience({
                addAudienceRequest: {
                    ...audienceInfo,
                    brand_id: selectedBrandId,
                    name:
                        audienceInfo.name !== undefined &&
                            audienceInfo.name?.length > 0
                            ? audienceInfo.name
                            : "Audience",
                }
            })
            setTimeout(() => goToNext(), 10)
        } else {
            goToNext();
        }
    };

    const handleSavedMenuClick: MenuProps['onClick'] = (e) => {
        if (Number(e.key) === 1) {
            addAudience({
                addAudienceRequest: {
                    ...audienceInfo,
                    brand_id: selectedBrandId,
                    name:
                        audienceInfo.name !== undefined &&
                            audienceInfo.name?.length > 0
                            ? audienceInfo.name
                            : "Audience",
                }
            })
            setTimeout(() => goToNext(), 10)
        } else {
            dispatch(
                setNewAudienceInfo({
                    ...audienceInfo,
                    audience_id: ''
                })
            );
            addAudience({
                addAudienceRequest: {
                    ...audienceInfo,
                    brand_id: selectedBrandId,
                    audience_id: '',
                    name:
                        audienceInfo.name !== undefined &&
                            audienceInfo.name?.length > 0
                            ? audienceInfo.name
                            : "Audience",
                }
            })
            setTimeout(() => goToNext(), 10)
        }
    };

    const goToNext = () => {
        if (props.type === 'event') {
            dispatch(setEvent({
                ...event,
                audience: audienceInfo
            }))
        } else {
            dispatch(setPlan({
                ...plan,
                audience: audienceInfo
            }))
        }
        props.onComplate();
    }

    const items: MenuProps['items'] = [
        {
            label: 'Save & ' + t("continue"),
            key: 1,
        },
        {
            label: t("continue") + ' without saving',
            key: 2,
        },
    ];

    const savedItems: MenuProps['items'] = [
        {
            label: 'Save & ' + t("continue"),
            key: 1,
        },
        {
            label: 'Save as New & ' + t("continue"),
            key: 2,
        },
    ];

    useEffect(() => {
        if (marker?.audience_id) {
            setIsSavedAudienceEdited(JSON.stringify({ ...audienceInfo }) !== JSON.stringify({ ...marker }))
        }
    }, [audienceInfo]);

    const calculateApproximate = (param) => {
        const data: any = {
            userLowerBound: param.userLowerBound,
            userUpperBound: param.userUpperBound,
        }
        if (param.userUpperBound < 5000) {
            data.approximateLevel = 1
            data.approximateResult = 'Too narrow'
        } else if (param.userUpperBound > 5000 && param.userLowerBound < 100000000) {
            data.approximateLevel = 2
            data.approximateResult = 'Perfect'
        } else if (param.userLowerBound > 100000000) {
            data.approximateLevel = 3
            data.approximateResult = 'Too broad'
        }
        setApproximateData(data)

        const visualData = {
            userLowerBound: param.userLowerBound.toString().split("").reduce((acc, char) => char + acc, "").split(/([0-9][0-9][0-9])/).join(".").split("").reduce((acc, char) => char + acc, "").replace('..', '.'),
            userUpperBound: param.userUpperBound.toString().split("").reduce((acc, char) => char + acc, "").split(/([0-9][0-9][0-9])/).join(".").split("").reduce((acc, char) => char + acc, "").replace('..', '.')
        }
        if (visualData.userLowerBound[visualData.userLowerBound.length -1] === '.') {
            visualData.userLowerBound = visualData.userLowerBound.toString().slice(0, visualData.userLowerBound.length - 1).replace('..', '.')
        }
        if (visualData.userLowerBound[0] === '.') {
            visualData.userLowerBound = visualData.userLowerBound.toString().slice(1).replace('..', '.')
        }
        if (visualData.userUpperBound[visualData.userUpperBound.length -1] === '.') {
            visualData.userUpperBound = visualData.userUpperBound.toString().slice(0, visualData.userUpperBound.length - 1).replace('..', '.')
        }
        if (visualData.userUpperBound[0] === '.') {
            visualData.userUpperBound = visualData.userUpperBound.toString().slice(1).replace('..', '.')
        }
        setVisualApproximateData(visualData)
    }

    return (
        <div className={'flex'}>
            <div className={'w-7/12'}>
                <div className={'mb-7 ml-2'}>
                    <div className={'text-H4'}>
                        <h2>Describe your audience</h2>
                    </div>
                    <div className={'text-BodySmall mt-2'}>
                        Tailor your campaign by defining the key demographics of your target audience.
                    </div>
                </div>
                <ContentContainer
                    ref={props.ref}
                    className={`w-full `}
                    hoverable={false}
                    children={
                        <>
                            <div className={'text-black'}>
                                <MarketingAudiences
                                    selectedBrandId={props.selectedBrandId}
                                    setSelectedTab={setSelectedTab}
                                    selectedTab={selectedTab}
                                    setMarker={setMarker}
                                    savedaudience={props.type === 'event' ? event.audience : plan.audience}
                                    setAudience={(a) => {
                                        getReachEstimate({
                                            reachEstimateRequest: {
                                                ...a
                                            }
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }
                />
                <div className="flex justify-between mt-3">
                    <PrimaryButton
                        className="h-10 w-full lg:w-[140px]"
                        noFill
                        title={"Back"}
                        onClick={() => {
                            props.onCancel();
                        }}
                    />
                    <div>
                        {selectedTab === 'saved' ? (
                            <div className={'flex'}>
                                <PrimaryButton
                                    className="h-10 w-full lg:w-[120px] lg:ml-2 max-lg:mt-1"
                                    title={t("continue")}
                                    isDisabled={!audienceInfo}
                                    onClick={goToNext}
                                />
                                {isSavedAudienceEdited && <div>
                                    <Dropdown menu={{ items: savedItems, onClick: handleSavedMenuClick }} className={'ml-2'}>
                                        <Button type="primary" className="h-10 w-full lg:w-[180px] lg:ml-2 max-lg:mt-1">
                                            <Space>
                                                Save & {t("continue")}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </div>}
                            </div>

                        ) : (<>
                            <Dropdown menu={{ items, onClick: handleMenuClick }}>
                                <Button type="primary" className="h-10 w-full lg:w-[200px] lg:ml-2 max-lg:mt-1">
                                    <Space>
                                        Create New & {t("continue")}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </>)}
                    </div>
                </div>
            </div>
            <div className={'w-3/12 mt-12 ml-5'}>
                <ContentContainer
                    ref={props.ref}
                    className={`w-full mt-10`}
                    hoverable={false}
                    children={
                        <div className={'p-6'}>
                            <div className="flex items-center text-H8 text-black mb-3"
                                title={''}
                            >
                                <Icon
                                    width="28"
                                    height="28"
                                    component={ApproximateIcon}
                                    className="mr-1 inline-block"
                                    color={"#DD3344"}
                                />
                                Approximate Audience Size
                            </div>
                            <div className="text-H7 text-black">
                                {visualApproximateData?.userLowerBound || 0} - {visualApproximateData?.userUpperBound || 0}
                            </div>
                            <div className={'my-4'}>
                                <RecommendationBar level={approximateData?.approximateLevel || 0} result={approximateData?.approximateResult} />
                            </div>
                            <div className="text-gray mb-2 text-sm"
                                title={''}
                            >
                                <Icon
                                    width="15"
                                    height="15"
                                    component={SvgQuestionMark}
                                    viewBox="0 0 20 20"
                                    className="ml-1 inline-block"
                                />
                                Most ads perform best with an audience size of 2 to 10 million people.
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default DescribeYourAudience
