import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../components/PrimaryButton";
import { toggleLoading } from "../../store/appSlice";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import InputText from "../../components/InputText";
import SuccessMessage from "../../components/SuccessMessage";
import WarningMessage from "../../components/WarningMessage";
import { useForgotPasswordMutation } from "../../services/ContentApi.old/ContentApi";
import { Email, LogoDark } from "../../assets/IconComponents";
import { Alert, Input } from "antd";
function ForgotPassworPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [isSend, setSend] = useState(false)
    const [setMailSent, { isSuccess: isMailSent, isError: actionError, error }] = useForgotPasswordMutation()
    const [warningMessage, setWarningMessage] = useState("")
    const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    const [hasEmailError, setEmailError] = useState(false)

    const isValid = () => {
        if (!emailRegex.test(email ?? "")) {
            setEmailError(true);
            return false
        }
        setEmailError(false);
        return true
    }
    const forgotPassword = () => {
        if (!isValid()) { return }
        dispatch(toggleLoading(true))

        setMailSent({
            email: email.toLowerCase()
        })

    }
    useEffect(() => {
        if (isMailSent) {
            setSend(true)
        }
        dispatch(toggleLoading(false))

    }, [isMailSent])

    useEffect(() => {
        dispatch(toggleLoading(false))
        if (error)
            setWarningMessage((error as any)?.data?.message)
    }, [actionError])


    const redirectToLogin = () => {
        navigate("/login")
    }

    return (<>
        <div className="flex flex-col w-full items-center justify-center">
            <LogoDark width={200} height={32} preserveAspectRatio="xMinYMin meet" viewBox="0 0 150 75" className="mb-[40px] cursor-pointer"
                onClick={() => {
                    window.location.replace("https://www.evercopy.ai/")
                }} />
            <div
                className={`w-full sm:w-2/3 md:w-2/3 lg:w-2/5 rounded-xl bg-white p-6 flex items-center justify-center flex-col border`}>
                <div className="flex justify-between items-start w-full">
                    <div className="flex flex-col">
                        <span
                            className="text-H3 font-medium text-black"
                        >
                            {t('forgot-password')}
                        </span>
                        <span
                            className="text-BodyMedium text-gray"
                        >
                            {t('no-worries-well-send-you-reset-instructions')}
                        </span>
                    </div>
                    <button className="text-red text-BodyMedium underline"
                        onClick={() => { redirectToLogin() }}>{t('back-to-login')}</button>
                </div>

                {(warningMessage != "") && !isMailSent &&
                    <Alert message={warningMessage ? warningMessage : t("warning")} type="error" showIcon className="w-full mt-5" />
                }
                {isSend &&
                    <Alert message="Email sent! Please follow the reset instructions." type="success" showIcon className="w-full mt-5" />
                }

                {!isSend &&
                    <div className="flex flex-col items-stretch w-full mt-5">
                        <Input size="large" placeholder={t('email') ?? ""} prefix={<Email width={16} height={16} fill="" />}
                            className={`bg-inputBg ${hasEmailError ? "border-red" : "mb-2"}`}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }} />

                        {hasEmailError && <span className="text-BodyMedium text-red mt-1 mb-2">{t("please-check-email-address")}</span>}

                        <PrimaryButton
                            title={t('reset-password')}
                            onClick={() => { forgotPassword() }}
                            className="mt-5" />
                    </div>
                }
            </div>
        </div>
    </>);
}

export default ForgotPassworPage;
