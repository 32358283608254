import { FunctionComponent, useEffect, useState } from "react";
import SvgRadioSelected from "../../assets/IconComponents/RadioSelected";
import SvgRadioUnselected from "../../assets/IconComponents/RadioUnselected";
import { changeBrandPopupItemModel } from "./PopupRadio";
import { t } from "i18next";

interface PopupRadioItemProps {
    item: changeBrandPopupItemModel
    selected: (status: boolean) => void
    isSelected: boolean
}

const PopupRadioItem: FunctionComponent<PopupRadioItemProps> = (props: PopupRadioItemProps) => {
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        setSelected(props.isSelected)
    }, [props.isSelected])

    return (
        <div
            className="flex flex-row cursor-pointer items-center"
            onClick={() => {
                setSelected(true)
                props.selected(true)
            }} >
            {selected &&
                <SvgRadioSelected className="min-w-[30px]"/>
            }
            {!selected &&
                <SvgRadioUnselected className="min-w-[30px]" />
            }
            <p className="text-black text-BodyMedium ml-2">{t(props.item.title)}</p>
        </div>
    );
}

export default PopupRadioItem;