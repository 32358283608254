import { useEffect, useState } from "react";
import Review from "../../components/Review";
import Reviews from "./Reviews";

export interface IReviewColumnProps {
    reviewClassName?: (index: number) => string
    reviews: {}[]
    className?: string
    msPerPixel: number
}
function ReviewColumn(props: IReviewColumnProps) {
    const [duration, setDuration] = useState("")
    const {
        reviewClassName,
        className,
        reviews,
        msPerPixel
    } = props

    let columnHeight = 0,
        resizeObserver: ResizeObserver,
        columnEl: HTMLElement;

    const onMount = () => {
        resizeObserver = new window.ResizeObserver(() => {
            columnHeight = columnEl?.offsetHeight ?? 1400;
            setDuration(`${columnHeight * msPerPixel}ms`);
        });

        resizeObserver.observe(columnEl);
    }

    const onDestroy = () => {
        if (resizeObserver) {
            resizeObserver.disconnect();
        }
    };

    useEffect(() => {
        onMount()

        return () => {
            onDestroy()
        }
    }, [])


    return (<>
        <div
            ref={r => columnEl = r!}
            className={`review-column animate-marquee space-y-8 py-4 ${className} ${className}`}
            style={{ "--marquee-duration": duration } as React.CSSProperties}
        >
            {
                
                Reviews.map((review, i) => {
                    return <Review key={i} author={review.author}
                        body={review.body}
                        rating={review.rating}
                        title={review.title}
                        className={reviewClassName && reviewClassName(i % Reviews.length)} />
                })
            }

        </div>
    </>);
}

export default ReviewColumn;