
import { t } from "i18next";
import { FunctionComponent } from "react";
import { Add } from "../../assets/IconComponents";
import SvgBrandTritoneColor from "../../assets/IconComponents/BrandTritoneColor";
import ContentContainer from "../../components/ContentContainer";
import PrimaryButton from "../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import SvgBrandTritoneSmall from "../../assets/IconComponents/BrandTritoneSmall";

interface BrandHubNoBrandLineProps {}

const BrandHubNoBrandLineVertical: FunctionComponent<BrandHubNoBrandLineProps> = () => {
  const navigate = useNavigate();
  return (
    <div className=" h-[220px] w-[450px] bg-white flex flex-col justify-between items-center rounded-[10px] border-1 transform-gpu">
      <div className="flex flex-col items-center">
        <div className="mt-5">
          <SvgBrandTritoneSmall />
        </div>
        <div className="ml-[20px] mr-[20px] items-center justify-center text-center mt-5">
          <p className="text-H6 text-black">
            Get started with brand templates!
          </p>
          <p className="text-BodyMedium text-gray mt-1">
            Instantly create contents tailored to your brand's style and voice
            using templates.
          </p>
        </div>
      </div>
      <PrimaryButton
        onClick={() => {
          navigate("/app/brandhub/new");
        }}
        title={t("add-new-brand")}
        className="h-[36px] w-[calc(95%+0px)] mb-4 mt-5  md:mt-0"
      />
    </div>
  );
};

export default BrandHubNoBrandLineVertical;