import * as React from "react";
import type { SVGProps } from "react";
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M16.593 2.5a.904.904 0 0 0-.642.265L9.054 9.663l-.305 1.588 1.588-.305 6.897-6.897a.904.904 0 0 0-.641-1.548Zm-11.385.417a2.304 2.304 0 0 0-2.291 2.291v9.584a2.304 2.304 0 0 0 2.291 2.291h9.584a2.304 2.304 0 0 0 2.291-2.291V7.917a.832.832 0 1 0-1.666 0v6.875c0 .355-.27.625-.625.625H5.208a.613.613 0 0 1-.625-.625V5.208c0-.355.27-.625.625-.625h6.875a.833.833 0 1 0 0-1.666H5.208Z"
    />
  </svg>
);
export default SvgEdit;
