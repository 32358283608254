import { FunctionComponent, useEffect, useState } from "react";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FileProps, useLazyFindById4Query } from "../../../services/BrandApi";
import Header from "./Header";
import ControlButtons from "./ControlButtons";
import { setNewContent, setSelectedTechnicalDocument } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import UploadCompanyMaterialNew from "../../../components/UploadFile/UploadCompanyMaterialNew";

interface TechnicalDocumentsLibraryProps {
    selectionList: any[]
    onReferenceFilesUpdated: (referenceFiles: FileProps[]) => void
}

const TechnicalDocumentsLibrary: FunctionComponent<TechnicalDocumentsLibraryProps> = (props: TechnicalDocumentsLibraryProps) => {
    const newContent = useAppSelector((k) => k.content.newContent);
    const dispatch = useAppDispatch();
    const [finalPicks, setFinalPicks] = useState<FileProps[]>([])


    return (<div className="flex-col w-full h-full inline-flex">
        <Header />
        <div className="flex-col w-full h-full inline-flex border-b-1">
            <div className="self-stretch justify-start items-center m-[20px] flex-1">
                <UploadCompanyMaterialNew
                    size="library"
                    maxFileCount={24}
                    uploadedFiles={props.selectionList}
                    uploadInputCount={1}
                    description="Upload .pdf, .txt, .pptx or doc files."
                    filesChanged={(f) => {

                    }}
                    selectionChanged={(pickedFiles: FileProps[]) => {
                        if (pickedFiles && pickedFiles.length > 0) {
                            setFinalPicks(pickedFiles)
                        }
                    }}
                    selectionEnabled={true}
                    maxSelectionSize={2}
                    uploadEnabled={false}
                    deleteEnabled={false}
                />
            </div>
        </div>
        <ControlButtons
            onImageSave={() => {
                props.onReferenceFilesUpdated(finalPicks);
                dispatch(setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.contentDetails
                }));
            }}
        />
    </div>)
}

export default TechnicalDocumentsLibrary;