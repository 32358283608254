import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { AudienceResponse, useLazyGetAudienceQuery } from "../../services/AudiencetApi";
import { setNewBrandInfo } from "../../store/brandSlice";
import BrandHubCreateNewAudience from "../../pages/BrandHub/CreateNew/BrandHubCreateNewAudience";
import { Form, Select, Tabs, TabsProps } from "antd";
import SvgGenerateSimilarBrushIcon from "../../assets/IconComponents/GenerateSimilarBrushIcon";
import IconSelect from "../../assets/IconComponents/IconSelect";

interface IBrandHubAudiencesProps {
    className?: string;
    selectedBrandId?: string;
    selectedTab: string;
    isSaved?: boolean;
    setSelectedTab: (key: string) => void
    setAudience: (key: AudienceResponse) => void
    savedaudience?: AudienceResponse
    selectedAudience?: AudienceResponse
    setMarker?: (marker: AudienceResponse) => void
}

const MarketingAudiences: FunctionComponent<IBrandHubAudiencesProps> = (props: IBrandHubAudiencesProps) => {
    const dispatch = useAppDispatch();
    const brandInfo = useAppSelector((k) => k.brand.newBrand);
    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId);
    const [selectedAudience, setSelectedAudience] = useState<AudienceResponse | undefined>(undefined);
    const [getAudiencesList, { data: audiencesListApi }] = useLazyGetAudienceQuery();
    const [savedTabDisabled, setSavedTabDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (lastSelectedBrandId) {
            getAudiencesList({ brandId: lastSelectedBrandId ?? '' });
        }
    }, [lastSelectedBrandId]);

    const onChange = (key: string) => {
        props.setSelectedTab(key)
        setSelectedAudience({})
    };

    useEffect(() => {
        if (props.selectedAudience?.name) {
            setSelectedAudience(props.selectedAudience)
            props.setSelectedTab('saved')
        }
    }, []);

    const items: TabsProps['items'] = [
        {
            key: 'create',
            label: 'Create New Audience',
            children: <div className={''}>
                <BrandHubCreateNewAudience
                    hideButtons
                    hideBorder
                    hideHeader
                    saveToBrand
                    onAudienceSaved={(audience) => {
                        props.setAudience(audience)
                    }}
                    inCampaign
                    setAudience={props.setAudience}
                    onClose={() => {
                        props.setSelectedTab('saved')
                    }}
                    audience={props.selectedAudience}
                    onMetaSettingsUpdated={(adsCategorySelected, beneficiarySelected) => {
                        setSavedTabDisabled((adsCategorySelected ?? false) || (beneficiarySelected ?? false))
                    }}
                />
            </div>,
        },
        {
            key: 'saved',
            label: 'Saved Audiences',
            disabled: savedTabDisabled,
            children: <div className="w-full">
                <Form.Item className={'mt-5 px-5'} label={<p className={'text-H8 text-black'}>Select Audience</p>} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                    <Select
                        suffixIcon={<IconSelect />}
                        placeholder="Please select"
                        options={brandInfo?.associatedAudiences?.map(audience => ({
                            value: JSON.stringify(audience),
                            label: audience.name
                        }))}
                        onChange={(a) => {
                            if (props.setMarker) {
                                props.setMarker(JSON.parse(a))
                            }
                            setSelectedAudience(JSON.parse(a))
                        }}
                        size="large"
                        className={'w-full'}
                    />
                </Form.Item>
                {selectedAudience?.audience_id &&
                    <BrandHubCreateNewAudience
                        hideBorder
                        hideHeader
                        hideButtons
                        saveToBrand
                        onAudienceSaved={(audience) => {
                            console.log(audience)
                        }}
                        onClose={() => {
                            //setSelectedAudience(undefined);
                        }}
                        inCampaign
                        audience={selectedAudience}
                        setAudience={props.setAudience}
                    />}
            </div>
        }
    ];

    useEffect(() => {
        if (audiencesListApi) {
            dispatch(
                setNewBrandInfo({
                    ...brandInfo,
                    associatedAudiences: audiencesListApi,
                })
            );
        }
    }, [audiencesListApi]);

    return (
        <>
            <div className="flex justify-between w-full p-[20px] relative">
                <div className={'w-full'}>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                </div>
                {/*{props.selectedTab === 'create' && <div className="absolute rounded-[3px] top-8 right-5 bg-slightRed p-1">
                    <div className="flex flex-row cursor-pointer items-center justify-center hidden">
                        <SvgGenerateSimilarBrushIcon/>
                        <p className="ml-2 mr-2 text-H8 text-red">
                            Generate with AI
                        </p>
                    </div>
                </div>}*/}
            </div>
        </>
    );
};

export default MarketingAudiences;
