import * as React from "react";
const SvgYoutubeIcon = (props) => (
  

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.677 4H5.323C3.48775 4 2 5.57684 2 7.52199V12.478C2 14.4232 3.48775 16 5.323 16H14.677C16.5122 16 18 14.4232 18 12.478V7.52199C18 5.57684 16.5122 4 14.677 4ZM12.4297 10.2411L8.05451 12.4528C7.93793 12.5117 7.80327 12.4216 7.80327 12.2847V7.7232C7.80327 7.58437 7.94147 7.4944 8.05829 7.55715L12.4335 9.90704C12.5636 9.9769 12.5613 10.1746 12.4297 10.2411Z" fill="#F61C0D"/>
</svg>


);
export default SvgYoutubeIcon;
