import * as React from "react";
import type { SVGProps } from "react";
const SvgText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M18.542 2.917c-6.328 0-10.94 1.446-13.709 4.299-2.394 2.466-2.535 5.108-2.54 5.611v.01a10.244 10.244 0 0 0-1.749 3.526c-.035.12-.065.225-.095.314a.625.625 0 1 0 1.186.396c.034-.102.069-.222.109-.358.206-.702.542-1.839 1.569-3.187v-.001l-1.015-.697.11-.143c.13-.167.263-.335.413-.507 1-1.147 2.45-2.397 4.583-3.634l.11-.063c.083-.047.164-.094.248-.14.09-.051.18-.102.272-.152.13-.071.266-.142.402-.213.089-.047.175-.094.266-.14.155-.079.316-.158.476-.236.078-.039.153-.077.233-.115.243-.117.492-.233.75-.348a.624.624 0 1 1 .512 1.139c-.485.218-.94.437-1.371.656l-.014.007-.097.05c-1.289.662-2.348 1.326-3.219 1.975l-.061.046c-1.171.881-2.002 1.733-2.597 2.515 1.542-.08 7.308-.607 11.68-4.1.229-.182.3-.499.172-.762l-.402-.824 1.571.044a.617.617 0 0 0 .492-.217 12.778 12.778 0 0 0 2.3-3.91.623.623 0 0 0-.585-.841ZM9.967 8.606l-.218.107.218-.107Zm-4.365 2.643Z" />
  </svg>
);
export default SvgText;
