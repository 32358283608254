import { useDispatch } from "react-redux";
import ContentImage from "../../components/ContentImage";
import { useAppSelector } from "../../helpers/hooks";
import ContentContainer from "../../components/ContentContainer";
import SvgForYouLightBulb from "../../assets/IconComponents/ForYouLightBulb";
import { CreativeTheme } from "../../services/CreativeApi";
import { setGuidelinesState, setNewContent } from "../../store/contentSlice";
import { ContentWizardView } from "../../models/ContentWizardView";
import moment from "moment";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard } from "../../store/appSlice";
import { ContentType } from "../../constants/ContentType";
import { Mediums } from "../../constants/Mediums";
import SvgMagic from "../../assets/IconComponents/Magic";

interface ICreativePopularThemesGridProps {
  trendingThemes?: CreativeTheme[];
  onRefresh: () => void;
  onClick: (revisionId: string) => void;
  isCreative?: boolean;
}
function CreativePopularThemesGrid(
  props: ICreativePopularThemesGridProps
) {
  const dispatch = useDispatch();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);

  return (
    <div className="flex flex-col w-[1050px] items-center">
      <div className="flex flex-row w-full grid grid-cols-5 gap-2">
        {props.trendingThemes?.map((trendingTheme) => {
          return (
            <div key={trendingTheme.id} className="flex-col ">
              <ContentContainer
                hoverable
                overlayActiveOnHover={true}
                onHoverElement={
                  <button
                    onClick={(e) => {
                      dispatch(setGuidelinesState(1));
                      e.stopPropagation();
                      dispatch(
                        setNewContent({
                          ...newContent,
                          medium: Mediums.filter(
                            (m) =>
                              m.contentType === ContentType.image &&
                              m.isCustom === true
                          ).at(0),
                          contentType: ContentType.image,
                          wizardView: ContentWizardView.contentDetails,
                          loopName: `Loop_${moment().format(
                            "DD_MM_yyyy_hh_mm"
                          )}`,
                          brand: { id: lastSelectedBrandId },
                          isCreative: true,
                          creative_theme: trendingTheme.id,
                        } as INewContent)
                      );
                      dispatch(toggleContentWizard());
                    }}
                    style={{ left: "50%", top: "45%", transform: "translateX(-50%)" }} className="flex flex-row  p-1 py-1.5 items-center text-H5 font-normal text-black justify-center  absolute bottom-10 bg-white w-2/3 h-9 border rounded">
                    <SvgMagic width={14} height={14} className="mr-2" fill="currentColor" />
                    Create Similar
                  </button>
                }
                children={
                  <div className="flex-col">
                    <div
                      className={`w-full relative h-[210px] rounded-[8px] cursor-pointer`}
                    >
                      <ContentImage
                        id={trendingTheme?.imageId ?? ""}
                        className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain"
                      />
                    </div>
                  </div>
                }
              />
              <div
                className={`flex flex-col ml-1 justify-start w-full mt-2 mb-2`}
              >
                <span className="text-BodyMedium font-inter-tight font-medium text-black">
                  {trendingTheme.subChildConcept}
                </span>
              </div>
            </div>
          );
        })}
      </div >

    </div >
  );
}

export default CreativePopularThemesGrid;
