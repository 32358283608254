import * as React from "react";
import type { SVGProps } from "react";
const SvgLiked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M6.25 2.917A4.598 4.598 0 0 0 1.667 7.5c0 1.413.845 2.683 1.928 3.956 1.084 1.273 2.472 2.555 3.818 3.813.641.599 1.99 1.98 1.99 1.98a.833.833 0 0 0 1.194 0s1.349-1.381 1.99-1.98c1.346-1.258 2.734-2.54 3.818-3.813 1.083-1.273 1.928-2.543 1.928-3.956a4.598 4.598 0 0 0-4.583-4.583c-1.382 0-2.69.63-3.75 1.917-1.06-1.288-2.368-1.917-3.75-1.917Z" />
  </svg>
);
export default SvgLiked;
