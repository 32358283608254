import * as React from "react";
import type { SVGProps } from "react";
const SvgGeneralAnnouncementIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <path opacity="0.5" d="M20.0001 18C20.0001 18.1399 19.9561 18.2763 19.8742 18.3898C19.7924 18.5033 19.677 18.5882 19.5442 18.6325C19.4115 18.6767 19.2682 18.6781 19.1346 18.6363C19.0011 18.5946 18.884 18.5119 18.8001 18.4C18.2396 17.6565 17.5146 17.0529 16.6818 16.6365C15.849 16.2201 14.9312 16.0023 14.0001 16H11.3334C11.1567 16 10.9873 15.9299 10.8622 15.805C10.7372 15.6801 10.6669 15.5107 10.6667 15.334V11.3333C10.6667 11.1566 10.7369 10.9872 10.8618 10.8622C10.9867 10.7372 11.1561 10.6668 11.3327 10.6667H14.0001C14.9312 10.6644 15.849 10.4465 16.6818 10.0302C17.5146 9.61377 18.2396 9.01017 18.8001 8.26667C18.8839 8.15487 19.0008 8.07224 19.1342 8.03047C19.2675 7.9887 19.4106 7.9899 19.5433 8.0339C19.6759 8.07789 19.7914 8.16246 19.8734 8.27565C19.9553 8.38883 19.9997 8.52492 20.0001 8.66467V18Z" fill="#FF5555" />
    <path d="M12 16H10C9.46957 16 8.96086 15.7893 8.58579 15.4143C8.21071 15.0392 8 14.5305 8 14V12.6667C8 12.1363 8.21071 11.6276 8.58579 11.2525C8.96086 10.8774 9.46957 10.6667 10 10.6667H12V16ZM19.3333 19.3334C19.1566 19.3334 18.9872 19.2632 18.8622 19.1383C18.7372 19.0135 18.6668 18.8441 18.6667 18.6674V8.00004C18.6667 7.82323 18.7369 7.65366 18.8619 7.52864C18.987 7.40361 19.1565 7.33337 19.3333 7.33337C19.5101 7.33337 19.6797 7.40361 19.8047 7.52864C19.9298 7.65366 20 7.82323 20 8.00004V18.6667C20 18.8434 19.9299 19.0129 19.805 19.1379C19.6801 19.2629 19.51 19.3332 19.3333 19.3334Z" fill="#FF5555" />
    <path opacity="0.2" d="M11.9999 16H10.3226L8.71994 19.7373C8.67645 19.8388 8.65883 19.9494 8.66867 20.0594C8.6785 20.1693 8.71547 20.2751 8.77627 20.3672C8.83707 20.4593 8.91979 20.5349 9.01701 20.5872C9.11422 20.6395 9.2229 20.6668 9.33328 20.6667H11.9999C12.2666 20.6667 12.5079 20.508 12.6126 20.2627L14.4279 16.026C14.2859 16.0153 14.1439 16.0007 13.9999 16H11.9999Z" fill="#FF5555" />
  </svg>

);
export default SvgGeneralAnnouncementIcon;
