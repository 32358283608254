import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    save1: build.mutation<Save1ApiResponse, Save1ApiArg>({
      query: (queryArg) => ({
        url: `/model`,
        method: "POST",
        body: queryArg.aiModel,
      }),
    }),
    findById1: build.query<FindById1ApiResponse, FindById1ApiArg>({
      query: (queryArg) => ({ url: `/model/${queryArg.id}` }),
    }),
    deleteById1: build.mutation<DeleteById1ApiResponse, DeleteById1ApiArg>({
      query: (queryArg) => ({ url: `/model/${queryArg.id}`, method: "DELETE" }),
    }),
    list1: build.query<List1ApiResponse, List1ApiArg>({
      query: () => ({ url: `/model/list` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type Save1ApiResponse = /** status 200 OK */ AiModel;
export type Save1ApiArg = {
  aiModel: AiModel;
};
export type FindById1ApiResponse = /** status 200 OK */ AiModel;
export type FindById1ApiArg = {
  id: string;
};
export type DeleteById1ApiResponse = unknown;
export type DeleteById1ApiArg = {
  id: string;
};
export type List1ApiResponse = /** status 200 OK */ AiModel[];
export type List1ApiArg = void;
export type AiModel = {
  id: string;
  name: string;
  priority: number;
};
export const {
  useSave1Mutation,
  useFindById1Query,
  useLazyFindById1Query,
  useDeleteById1Mutation,
  useList1Query,
  useLazyList1Query,
} = injectedRtkApi;
