import * as React from "react";
import type { SVGProps } from "react";
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path

      d="M9.988 2.072a.833.833 0 0 0-.821.845v6.25h-6.25a.833.833 0 1 0 0 1.666h6.25v6.25a.834.834 0 1 0 1.666 0v-6.25h6.25a.832.832 0 1 0 0-1.666h-6.25v-6.25a.833.833 0 0 0-.845-.845Z"
    />
  </svg>
);
export default SvgPlus;
