import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../store/appSlice";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "antd";
import PrimaryButton from "../../components/PrimaryButton";
import { Email, FacebookColor, Google, LogoDark, Password, TwitterColor } from "../../assets/IconComponents";
import { getAuth, signInWithPopup, getRedirectResult, signInWithRedirect, TwitterAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { setAccessToken, setRefreshToken, toggleLoggedIn } from "../../store/persistedSlice";
import { useLazyList1Query } from "../../services/AIModelApi";
import { useRegisterMutation, useSocialLoginMutation } from "../../services/AuthApi";
import { Input } from "antd";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

enum SocialLoginType {
    google,
    twitter,
    facebook
}

export interface registerData {
    email?: string
    pasword?: string
}

function RegisterPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const fromSocialLogin = localStorage.getItem('fromSocialLogin');
    if (fromSocialLogin !== null) {
        console.log('Loading...')
        dispatch(toggleLoading(true));
    }

    const [registerData, setRegisterData] = useState<registerData>({})
    const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    const [warningMessage, setWarningMessage] = useState("")
    const [captchaToken, setCaptchaToken] = useState("");
    const [register, { isLoading: registering, isSuccess: registeredSuccess, isError: registerdError, data, error }] = useRegisterMutation();
    const [socialLogin, { isLoading: socialLoginLoading, data: socialLoginData, isError: socialLoginError }] = useSocialLoginMutation()
    const [fetchAiModels, { data: aiModelDatas }] = useLazyList1Query()
    const captchaRef = useRef<MutableRefObject<ReCAPTCHA>>(null)


    useEffect(() => {
        fetchAiModels()
    }, [])

    const isFormValid = () => {

        if (registerData.email?.trim() === "" || registerData.email === undefined ||
            registerData.pasword?.trim() === "" || registerData.pasword === undefined
        ) {
            console.log('fill out all fields')
            setWarningMessage(t('please-fill-out-all-fields') ?? "")
            return false
        }

        if (!emailRegex.test(registerData.email ?? "")) {
            console.log('check e-mail')
            setWarningMessage(t('please-check-email-address') ?? "")
            return false
        }

        return true
    }

    const signup = () => {
        if (!isFormValid()) { return }

        (captchaRef.current as ReCAPTCHA).reset();

        register({
            registerRequest: {
                email: (registerData.email ?? "").toLowerCase(),
                name_surname: "",
                password: registerData.pasword ?? "",
                captchaToken: captchaToken
            }

        })
    }

    useEffect(() => {
        if (registering) {
            dispatch(toggleLoading(registering))
        }
        else {
            const fromSocialLogin = localStorage.getItem('fromSocialLogin');
            if (fromSocialLogin) {
                localStorage.removeItem('fromSocialLogin');
            }
            else {
                dispatch(toggleLoading(false))
            }
        }
    }, [registering])

    useEffect(() => {
        if (registeredSuccess) {
            dispatch(toggleLoading(false))
            setTimeout(() => {
                navigate("/login")
            }, 2000);
        }
    }, [registeredSuccess])

    useEffect(() => {
        console.log(warningMessage)
        setWarningMessage((error as any)?.data?.message ?? "")
    }, [registerdError])

    const login = () => {
        navigate("/login")
    }


    const twitterLogin = () => {
        dispatch(toggleLoading(false))
        const provider = new TwitterAuthProvider();
        provider.addScope("email")
        const auth = getAuth();
        localStorage.setItem('fromSocialLogin', 'twitter');
        dispatch(toggleLoading(true));
        signInWithRedirect(auth, provider);
    }

    const facebookLogin = () => {
        dispatch(toggleLoading(false))

        const provider = new FacebookAuthProvider();
        provider.addScope("email")
        const auth = getAuth();
        localStorage.setItem('fromSocialLogin', 'facebook');
        dispatch(toggleLoading(true));
        signInWithRedirect(auth, provider);
    }

    const googleLogin = () => {
        dispatch(toggleLoading(false))
        const provider = new GoogleAuthProvider();
        provider.addScope("email")
        const auth = getAuth();
        localStorage.setItem('fromSocialLogin', 'google');
        dispatch(toggleLoading(true));
        signInWithRedirect(auth, provider);
    }

    useEffect(() => {
        if (socialLoginData) {
            dispatch(toggleLoading(false))
            dispatch(setAccessToken(socialLoginData.access_token ?? ""))
            dispatch(setRefreshToken(socialLoginData.refresh_token ?? ""))
            dispatch(toggleLoggedIn(true))
            navigate("/app/homepage")
        }
    }, [socialLoginData])

    useEffect(() => {
        if (socialLoginError) {
            dispatch(toggleLoading(false))
        }
    }, [socialLoginError])

    useEffect(() => {
        const auth = getAuth();
        getRedirectResult(auth)
            .then((result) => {
                console.log("Redirect result: ", result);
                console.log("Redirect called for :", result?.user);
                const user = result?.user;
                if (user === undefined) {
                    console.log('Stop loading')
                    dispatch(toggleLoading(false))
                }
                var email = ""
                if (user?.providerData != undefined && user.providerData.length > 0)
                    email = user.providerData[0].email ?? ""

                user?.getIdToken().then(k => {
                    socialLogin({
                        socialLoginRequest: {
                            email: email ?? "",
                            access_token: k ?? "",
                        }
                    })
                })

            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                console.log('Stop loading')
                dispatch(toggleLoading(false))
            });
    }, []);

    return (<>
        <div className="flex flex-col w-full items-center justify-center">
            <LogoDark width={200} height={32} preserveAspectRatio="xMinYMin meet" viewBox="0 0 150 75" className="mb-[40px] cursor-pointer"
                onClick={() => {
                    window.location.replace("https://www.evercopy.ai/")
                }} />

            <div
                className={`w-full sm:w-2/3 md:w-2/3 lg:w-2/5 rounded-xl bg-white p-6 flex items-center justify-center flex-col border`}>
                <div className="flex justify-between w-full">
                    <span
                        className="text-H3 font-medium text-black"
                    >
                        {t('sign-up')}
                    </span>
                    <button className="text-red text-BodyMedium underline"
                        onClick={() => { login() }}>{t('i-have-an-account')}</button>
                </div>

                {(warningMessage != "" && !registeredSuccess) &&
                    <Alert message={warningMessage ? warningMessage : t("warning")} type="error" showIcon className="w-full mt-5" />
                }
                {registeredSuccess &&
                    <Alert message={t('a-verification-email-sent-please-verify-your-email-address') ?? ""} type="success" showIcon className="w-full mt-5" />
                }
                {!registeredSuccess &&
                    <>
                        <div className="flex flex-col items-stretch w-full mt-5">
                            <Input size="large" placeholder={t('email') ?? ""} prefix={<Email width={16} height={16} fill="" />}
                                className="mb-2 bg-inputBg"
                                onChange={(e) => {
                                    setWarningMessage("")
                                    setRegisterData({
                                        ...registerData,
                                        email: e.target.value
                                    })
                                }} />

                            <Input.Password size="large" visibilityToggle={false} placeholder={t("password") ?? ""} prefix={<Password width={16} height={16} fill="" />}
                                className="bg-inputBg"
                                onChange={(e) => {
                                    setWarningMessage("")
                                    setRegisterData({
                                        ...registerData,
                                        pasword: e.target.value
                                    })
                                }} />

                            <PrimaryButton
                                isDisabled={!captchaToken || captchaToken.length === 0}
                                title={t('sign-up')}
                                onClick={() => {
                                    setWarningMessage("")
                                    signup()
                                }}
                                className="mt-5" />
                        </div>
                        <div className="mt-5 min-w-full flex flex-col justify-between items-stretch">
                            <Button className="text-H5 font-normal" icon={<Google width={16} height={16} />} iconPosition="start" onClick={() => googleLogin()}>
                                {t("login-with-google")}
                            </Button>

                            <Button className="text-H5 font-normal mt-2" icon={<TwitterColor width={16} height={16} />} iconPosition="start" onClick={() => twitterLogin()}>
                                {t("login-with-twitter")}
                            </Button>

                            <Button className="text-H5 font-normal mt-2" icon={<FacebookColor width={16} height={16} />} iconPosition="start" onClick={() => facebookLogin()}>
                                {t("login-with-facebook")}
                            </Button>

                        </div>
                    </>
                }
                <span className="text-BodySmall text-gray mt-5">{t('by-continuing-you-agree-to-our')}
                    <a
                        href="https://www.evercopy.ai/terms"
                        target="_blank"
                        className="underline mx-1"
                    >
                        {t("terms-of-use")}
                    </a>{" "}
                    {t("and")}
                    <a
                        href="https://www.evercopy.ai/privacy-policy"
                        target="_blank"
                        className="underline mx-1"
                    >
                        {t("privacy-policy")}
                    </a>
                    .</span>
                {!registeredSuccess &&
                    <div className="flex flex-row mt-[15px]">
                        <ReCAPTCHA sitekey="6LeDuiAqAAAAAJrlP411iXHcjX9yHHtevAadcSnL"
                            ref={captchaRef}
                            onChange={(value) => {
                                setCaptchaToken(value);
                            }}></ReCAPTCHA>
                    </div>
                }
            </div>

        </div>
    </>);
}

export default RegisterPage;
