import * as React from "react";
import type { SVGProps } from "react";
const SvgSelect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M14.045 8.136a.555.555 0 1 0 .785-.785L10.39 2.912a.555.555 0 0 0-.784 0l-4.44 4.44a.555.555 0 1 0 .785.784L10 4.089l4.046 4.047Zm0 3.662a.555.555 0 1 1 .785.784l-4.439 4.439a.555.555 0 0 1-.784 0l-4.44-4.439a.555.555 0 1 1 .785-.784L10 15.844l4.046-4.046Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSelect;
