import * as React from "react";
const CreativityIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
        <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
        <path transform="translate(6, 6)" opacity="0.5" d="M5.10492 4.54472L5.91332 4.13999C5.93928 4.12695 5.9611 4.10695 5.97635 4.08223C5.9916 4.05751 5.99967 4.02904 5.99967 3.99999C5.99967 3.97094 5.9916 3.94247 5.97635 3.91774C5.9611 3.89302 5.93928 3.87303 5.91332 3.85999L5.10492 3.45565C4.86232 3.33437 4.66561 3.13766 4.54432 2.89505L4.13999 2.08666C4.12695 2.0607 4.10695 2.03888 4.08223 2.02363C4.05751 2.00838 4.02904 2.00031 3.99999 2.00031C3.97094 2.00031 3.94247 2.00838 3.91774 2.02363C3.89302 2.03888 3.87303 2.0607 3.85999 2.08666L3.45565 2.89505C3.33437 3.13766 3.13766 3.33437 2.89505 3.45565L2.08666 3.85999C2.0607 3.87303 2.03888 3.89302 2.02363 3.91774C2.00838 3.94247 2.00031 3.97094 2.00031 3.99999C2.00031 4.02904 2.00838 4.05751 2.02363 4.08223C2.03888 4.10695 2.0607 4.12695 2.08666 4.13999L2.89512 4.54426C3.13771 4.66552 3.33443 4.86221 3.45572 5.10479L3.85999 5.91332C3.87303 5.93928 3.89302 5.9611 3.91774 5.97635C3.94247 5.9916 3.97094 5.99967 3.99999 5.99967C4.02904 5.99967 4.05751 5.9916 4.08223 5.97635C4.10695 5.9611 4.12695 5.93928 4.13999 5.91332L4.54419 5.10486C4.66553 4.86228 4.86229 4.66595 5.10492 4.54472Z" fill="#FF5555"/>
        <path transform="translate(6, 6)" d="M4.86896 9.00659L6.75529 8.06345C7.32131 7.78041 7.78027 7.32142 8.06329 6.75539L9.00682 4.86872C9.03719 4.80799 9.08386 4.75692 9.14162 4.72123C9.19937 4.68553 9.26593 4.66663 9.33382 4.66663C9.40172 4.66663 9.46827 4.68553 9.52603 4.72123C9.58378 4.75692 9.63046 4.80799 9.66082 4.86872L10.604 6.75505C10.887 7.32109 11.346 7.78007 11.912 8.06312L13.798 9.00659C13.8588 9.03693 13.9099 9.0836 13.9456 9.14136C13.9813 9.19912 14.0002 9.26568 14.0002 9.33359C14.0002 9.40149 13.9813 9.46806 13.9456 9.52581C13.9099 9.58357 13.8588 9.63024 13.798 9.66059L11.9117 10.6038C11.3457 10.8868 10.8867 11.3458 10.6037 11.9118L9.66049 13.7981C9.63013 13.8588 9.58345 13.9099 9.52569 13.9456C9.46794 13.9813 9.40139 14.0002 9.33349 14.0002C9.26559 14.0002 9.19904 13.9813 9.14129 13.9456C9.08353 13.9099 9.03685 13.8588 9.00649 13.7981L8.06329 11.9118C7.78029 11.3458 7.32132 10.8868 6.75529 10.6038L4.86896 9.66025C4.8083 9.62987 4.75729 9.58321 4.72164 9.52549C4.68599 9.46777 4.66711 9.40126 4.66711 9.33342C4.66711 9.26558 4.68599 9.19907 4.72164 9.14135C4.75729 9.08363 4.8083 9.03697 4.86896 9.00659Z" fill="#FF5555"/>
    </svg>

);
export default CreativityIcon;
