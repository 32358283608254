import {t} from "i18next";
import ContentContainer from "../../../components/ContentContainer";
import {useEffect, useState} from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import {
    BlogColor,
    Facebook,
    FacebookColor,
    Instagram,
    InstagramColor,
    Linkedin, LinkedinColor,
    TwitterColor
} from "../../../assets/IconComponents";
import {useAppDispatch} from "../../../helpers/hooks";
import {setEvent, setPlan, setSavedData} from "../../../store/plannerSlice";
import Icon from "../../../components/Icon";
import SvgCheckboxSelectedIcon from "../../../assets/IconComponents/CheckboxSelectedIcon";
import SvgCheckboxUnchecked from "../../../assets/IconComponents/CheckboxUnchecked";
import Twitter from "../../../assets/IconComponents/Twitter";
import ToggleButton from "../../../components/ToggleButton";
import {useSaveMarketingPlannerMutation, useSaveCampaignMutation} from "../../../services/MarketingPlanner";
import {setActivities} from "../../../store/activitySlice";
import NewsLetterColor from "../../../assets/IconComponents/NewsLetterColor";
import GoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";

import SvgMetaSocialIcon from "../../../assets/IconComponents/MetaSocialIcon";

const ChooseChannels = (props) => {
    const dispatch = useAppDispatch()
    const [channels, setChannels] = useState([
        {key: 'FACEBOOK', label: 'Facebook', isSelected: false, isPaid: false, icon: Facebook, colorIcon: FacebookColor},
        {key: 'X', label: 'X (Twitter)', isSelected: false, isPaid: false, icon: Twitter, colorIcon: TwitterColor},
        {key: 'INSTAGRAM', label: 'Instagram', isSelected: false, isPaid: false, icon: Instagram, colorIcon: InstagramColor},
        {key: 'LINKEDIN', label: 'Linkedin', isSelected: false, isPaid: false, icon: Linkedin, colorIcon: LinkedinColor},
        {key: 'BLOG', label: 'Blog', isSelected: false, isPaid: false, icon: Facebook, colorIcon: BlogColor},
        {key: 'NEWSLETTER', label: 'Newsletter', isSelected: false, isPaid: false, icon: Facebook, colorIcon: NewsLetterColor, disabled: true},
        {key: 'GOOGLE_ADS', label: 'Google Ads', isSelected: false, isPaid: true, icon: Facebook, colorIcon: GoogleAdsColor},
        {key: 'META_ADS', label: 'Meta Ads', isSelected: false, isPaid: true, icon: Facebook, colorIcon: SvgMetaSocialIcon},
    ])
    const [showPaidChannels, setShowPaidChannels] = useState<boolean>(true)
    const [selectedChannels, setSelectedChannels] = useState<any[]>([])
    const [save2, { data: createdData, isLoading:createIsLoading }] = useSaveMarketingPlannerMutation()
    const [saveCampaign] = useSaveCampaignMutation()


    useEffect(() => {

        if(props[props.type === "event" ? "event" : "plan"]?.channels?.length >0 ){

            props[props.type === "event" ? "event" : "plan"]?.channels?.map((channel, index) => (
                setChannels(prev => (prev.map(item => {
                    if (item.key === channel) {
                        return {...item, isSelected: true}
                    } else {
                        return item
                    }
                })))

            ))

        }
        else {
            setChannels(prev => (prev.map(item => {
                    return {...item, isSelected: item.key !== 'NEWSLETTER'}

            })))
        }
    }, []);

    useEffect(() => {
        setSelectedChannels([])
        channels.forEach(channel => {
            if (channel.isSelected) {
                setSelectedChannels(prevState => ([...prevState, channel]))
            }
        })
        const paidSelected = channels.filter(item => (item.key == 'GOOGLE_ADS' && item.isSelected) || (item.key == 'META_ADS' && item.isSelected) )
        if(paidSelected.length > 0 )
          setShowPaidChannels(true)
         else setShowPaidChannels(false)
    }, [channels]);

    const goToNext = ({hasPaid}) => {

        if (props.type === 'event') {
            dispatch(setEvent({
                ...props.event,
                channels: selectedChannels.map(channel => channel.key)
            }))
            if (!hasPaid) {
                props.slidePageProgressBar();
                save2({marketingPlannerRequest: {
                        ...props.event,
                        channels: selectedChannels.map(channel => channel.key)
                    }}).then(aa=> {
                    dispatch(setSavedData(aa))
                    console.log(aa)
                    props.onComplate({pageCount: 2});
                    props.stopPageProgressBar();
                })
            }
        } else {
            dispatch(setPlan({
                ...props.plan,
                channels: selectedChannels.map(channel => channel.key)
            }))
            if (!hasPaid) {
                props.slidePageProgressBar();
                saveCampaign({marketingPlannerRequest: {
                        ...props.plan,
                        channels: selectedChannels.map(channel => channel.key)
                    }}).then(activities => {
                    console.log(activities)
                    // @ts-ignore
                    dispatch(setActivities(activities.data))
                    props.onComplate({pageCount: 3 });
                    props.stopPageProgressBar();
                })
            }
        }
        if (hasPaid) {
            props.onComplate({pageCount: 1 });
        }
    }

    return (
        <div className={'w-7/12'}>
            <div className={'mb-7 ml-2'}>
                <div className={'text-H4'}>
                    <h2>Choose channels</h2>
                </div>
                <div className={'text-BodySmall mt-2'}>
                    Select to activate the AI-recommended channels for paid and organic reach.
                </div>
            </div>
            <ContentContainer
                ref={props.ref}
                className={`w-full`}
                hoverable={false}
                children={
                    <>
                    <div className="justify-start items-center mt-2  gap-2.5 flex ml-3">
                                        <span className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                                            Social Channels
                                        </span>
                                    </div>
                        <div className="flex flex-row flex-wrap w-full ">

                            {channels.map((channel, index) => (
                                !channel.isPaid && (
                                    <div className={`h-[42px] w-[48%] ml-3 mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center p-2 justify-between
                                            ${channel.isSelected ? "bg-lightBg border-1 border-border rounded-[5px]" : ""} ${channel.disabled ? 'opacity-40' : ''}`}
                                         onClick={() => {
                                             if(!channel.disabled) {
                                                 setChannels(prev => (prev.map(item => {
                                                     if (item.key === channel.key) {
                                                         return {...item, isSelected: !item.isSelected}
                                                     } else {
                                                         return item
                                                     }
                                                 })))
                                             }
                                         }}
                                    >
                                        <div className="flex flex-row items-center">
                                            <Icon width="20" height="20" viewBox="0 0 20 20"
                                                  component={channel.colorIcon}
                                                  color="#999999" className="mr-1 w-[20px] "/>
                                            <text className="text-BodyMedium text-black ml-2">{channel.label}</text>
                                        </div>
                                        {channel.isSelected &&
                                            <SvgCheckboxSelectedIcon/>
                                        }
                                        {!channel.isSelected &&
                                            <SvgCheckboxUnchecked/>
                                        }
                                    </div>
                                )
                            ))}

                            <div className="w-full justify-between items-center inline-flex mt-5 mb-3">
                                <div className={'flex ml-3'}>
                                    <ToggleButton isChecked={showPaidChannels}
                                                  handleCheckBoxChange={function (checked: boolean): void {
                                                      setShowPaidChannels(checked)
                                                      setChannels(prev => (prev.map(item => {
                                                          if (item.isPaid) {
                                                              return {...item, isSelected: checked}
                                                          } else {
                                                              return item
                                                          }
                                                      })))
                                                  }}
                                                  checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                                  checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                                                  uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                                                  uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
                                    />
                                    <div className="justify-start items-center gap-2.5 flex ml-2">
                                        <span className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                                            Paid Channels
                                        </span>
                                    </div>
                                </div>
                                <div >
                                    <span className={'text-sm mr-5 text-gray'}>We’ll ask for detailed configuration for paid channels in the next step.</span>
                                </div>
                            </div>

                            {showPaidChannels && channels.map((channel, index) => (
                                channel.isPaid && (
                                    <div className={`h-[42px] w-[48%] ml-3 mt-1 mb-3 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center p-2 justify-between
                                            ${channel.isSelected ? "bg-lightBg border-1 border-border rounded-[5px]" : ""}`}
                                         onClick={() => {
                                             setChannels(prev => (prev.map(item => {
                                                 if (item.key === channel.key) {
                                                     return {...item, isSelected: !item.isSelected}
                                                 } else {
                                                     return item
                                                 }
                                             })))
                                         }}
                                    >
                                        <div className="flex flex-row items-center">
                                            <Icon width="20" height="20" viewBox="0 0 20 20"
                                                  component={channel.colorIcon}
                                                  color="#999999" className="mr-1 w-[20px] "/>
                                            <text className="text-BodyMedium text-black ml-2">{channel.label}</text>
                                        </div>
                                        {channel.isSelected &&
                                            <SvgCheckboxSelectedIcon/>
                                        }
                                        {!channel.isSelected &&
                                            <SvgCheckboxUnchecked/>
                                        }
                                    </div>
                                )
                            ))}
                        </div>
                    </>
                }
            />
            <div className="flex justify-between mt-3">
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]"
                    noFill
                    title={"Back"}
                    onClick={() => {
                        props.onCancel();

                    }}
                />
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                    isDisabled={!selectedChannels.length}
                    title={t("continue")}
                    onClick={() => {
                        let hasPaid = false;
                        selectedChannels.forEach(channel => {
                            if (channel.isPaid) {
                                hasPaid = true;
                            }
                        })
                        goToNext({hasPaid})
                    }}
                />
            </div>
        </div>
    )
}

export default ChooseChannels
