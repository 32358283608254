import { t } from "i18next";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Add, Plus } from "../../assets/IconComponents";
import PrimaryButton from "../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { BrandRequest, useDeleteById4Mutation, useLazyList6Query } from "../../services/BrandApi";
import { setDialog, toggleDialog, toggleLoading } from "../../store/appSlice";
import { setNewBrandInfo } from "../../store/brandSlice";
import BrandHubBrandLine from "./BrandHubBrandLine";
import BrandHubNoBrandLine from "./BrandHubNoBrandLine";
import SvgQuestionMark from "../../assets/IconComponents/QuestionMark";
import { useLazyUserSettingsQuery } from "../../services/UsertApi";
import { setUserSettings } from "../../store/userSettingsSlice";

interface BrandHubPageProps {

}

interface BrandModel {
  name: string
}

const BrandHubPage: FunctionComponent<BrandHubPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const userSettings = useAppSelector((state) => state.userSettings);
  const [
    getUserSettings,
    {
      isLoading: userSettingsLoading,
      isSuccess: userSettingsSuccess,
      isFetching: userSettingsFetching,
      data: userSettingsResponse,
    },
  ] = useLazyUserSettingsQuery();

  const [getBrands, { data: brands, isLoading: brandsLoading }] = useLazyList6Query()
  const [deleteBrand, { isSuccess: brandDeleted, isLoading: isDeleting, error: brandDeletionError }] = useDeleteById4Mutation()

  useEffect(() => {
    dispatch(setNewBrandInfo({}))
    getBrands()
    getUserSettings()
  }, [])

  useEffect(() => {
    if (userSettingsResponse) {
      dispatch(setUserSettings(userSettingsResponse));
    }
  }, [userSettingsResponse]);

  useEffect(() => {
    dispatch(
      toggleLoading(
        brandsLoading || userSettingsFetching || userSettingsLoading
      )
    );
  }, [brandsLoading, userSettingsFetching, userSettingsLoading]);

  const showDeleteDialog = (brand: BrandRequest) => {
    dispatch(setDialog({
      actions: <>
        <PrimaryButton className="h-12 w-[100px]" noFill title={t('cancel')} onClick={() => {
          dispatch(toggleDialog(false))

        }} />
        <PrimaryButton className="h-12 w-[150px] ml-2" title={t('yes-delete')} onClick={() => {
          dispatch(toggleDialog(false));

          deleteBrand({
            id: brand.id ?? ""
          })
        }} />
      </>,
      title: t('delete-brand'),
      message: t('are-you-sure-that-you-would-like-to-delete-this-brand-this-cant-be-undone'),
      onClose: () => { dispatch(toggleDialog(false)) }
    }))
    dispatch(toggleDialog(true))
  }
  useEffect(() => {
    if (brandDeleted) {
      getBrands();
      getUserSettings();
    }
  }, [brandDeleted])

  useEffect(() => {
    dispatch(toggleLoading(isDeleting))
  }, [isDeleting])

  useEffect(() => {
    if (brandDeletionError) {
      showBrandErrors((brandDeletionError as any)?.data?.message)
    }
  }, [brandDeletionError])


  const showBrandErrors = (error: string) => {
    dispatch(setDialog({
      actions: <>
        <PrimaryButton className="h-12 w-20 ml-2" title={t('ok')} onClick={() => { dispatch(toggleDialog(false)); }} />
      </>,
      title: t('brand-hub-0'),
      message: error,
      onClose: () => { dispatch(toggleDialog(false)) }
    }))
    dispatch(toggleDialog(true))
  }


  return (
    <>
      <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border items-center justify-between">
        <div className="flex flex-row items-center justify-center">
          <span className="text-H5 font-medium text-black ml-[15px] ">
            {t("brand-hub")}
          </span>
          {userSettings.subscription?.brands_used && (
            <>
              <span className="text-BodyMedium text-gray ml-[15px] ">
                {userSettings.subscription?.brands_used}/
                {userSettings.subscription?.brands_available}
              </span>
              <SvgQuestionMark
                className="mt-1 ml-1"
                data-tooltip-id={"app-tooltip"}
                data-tooltip-html={t("plan-future-price-tooltip")}
              ></SvgQuestionMark>
            </>
          )}
        </div>

        {brands?.length !== 0 && (
          <div className="mr-5">
            <PrimaryButton
              onClick={() => {
                dispatch(setNewBrandInfo({}));
                navigate("/app/brandhub/new");
              }}
              icon={<Plus width={14} height={14} fill="#ffffff" />}
              title={t("add-new-brand")}
            />
          </div>
        )}
      </div>
      <div className="w-full h-[calc(100vh-80px)] pr-3 pl-3 overflow-scroll scrollbar-hide">
        <div className="grid mt-1 lg:grid-cols-3 gap-3">
          {brands?.length === 0 && <BrandHubNoBrandLine />}

          {brands?.map((k) => (
            <BrandHubBrandLine
              brand={k}
              deleteBrand={(k) => {
                showDeleteDialog(k);
              }}
              editBrand={(k) => {
                navigate("/app/brandhub/new", { state: { brand: k } });
              }}
            />
          ))}
        </div>
      </div>

    </>
  );
}

export default BrandHubPage;
