import * as React from "react";
const SvgTextToSpeechInbox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#F55"
      d="m41.494 20.911-.01-.037L36.225 7.51A1.81 1.81 0 0 0 34.5 6.235H13.181c-.796 0-1.504.53-1.73 1.293L6.536 20.757l-.015.032-.009.038c-.06.23-.08.464-.047.694-.004.075-.01.15-.01.225v17.151a2.855 2.855 0 0 0 2.85 2.85h29.4c1.571 0 2.85-1.28 2.855-2.85V21.746c0-.061 0-.122-.004-.174.019-.23 0-.45-.061-.66Zm-13.866-2.015-.014.735c-.037 2.105-1.49 3.52-3.614 3.52-1.036 0-1.926-.332-2.569-.965-.642-.633-.993-1.514-1.012-2.555l-.014-.735h-9.647l3.727-9.061h18.712l3.83 9.06h-9.399Zm-17.578 3.6h7.374c1.139 2.676 3.562 4.256 6.58 4.256 1.58 0 3.048-.44 4.234-1.275 1.04-.731 1.851-1.753 2.376-2.981h7.336v15.651h-27.9V22.496Z"
    />
  </svg>
);
export default SvgTextToSpeechInbox;
