import type { SVGProps } from "react";
const AISmartPlacementsBlueIcon = (props: SVGProps<SVGSVGElement>) => (

<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.1" width="28" height="28" rx="4" fill="#1A8CF5"/>
<path d="M14.0013 21.333L9.2013 17.733C9.03464 17.6108 8.90408 17.4552 8.80964 17.2663C8.71519 17.0775 8.66797 16.8775 8.66797 16.6663V8.66634C8.66797 8.29967 8.79852 7.98579 9.05964 7.72467C9.32075 7.46356 9.63464 7.33301 10.0013 7.33301H18.0013C18.368 7.33301 18.6819 7.46356 18.943 7.72467C19.2041 7.98579 19.3346 8.29967 19.3346 8.66634V16.6663C19.3346 16.8775 19.2874 17.0775 19.193 17.2663C19.0985 17.4552 18.968 17.6108 18.8013 17.733L14.0013 21.333ZM14.0013 19.6663L18.0013 16.6663V8.66634H10.0013V16.6663L14.0013 19.6663ZM14.0013 8.66634H10.0013H18.0013H14.0013Z" fill="#1A8CF5"/>
<path d="M12.7782 11.5681L13.5641 10.5506C13.6203 10.4757 13.6869 10.4207 13.7641 10.3856C13.8413 10.3506 13.922 10.333 14.0062 10.333C14.0904 10.333 14.1711 10.3506 14.2483 10.3856C14.3255 10.4207 14.3922 10.4757 14.4483 10.5506L15.2343 11.5681L16.4273 11.9681C16.5489 12.0055 16.6448 12.0745 16.715 12.1751C16.7852 12.2757 16.8203 12.3868 16.8203 12.5084C16.8203 12.5646 16.8121 12.6207 16.7957 12.6769C16.7793 12.733 16.7524 12.7868 16.715 12.8383L15.9431 13.933L15.9711 15.0839C15.9758 15.2476 15.922 15.3856 15.8097 15.4979C15.6975 15.6102 15.5665 15.6663 15.4168 15.6663C15.4074 15.6663 15.3559 15.6593 15.2624 15.6453L14.0062 15.2944L12.7501 15.6453C12.7267 15.6546 12.701 15.6605 12.6729 15.6628C12.6448 15.6652 12.6191 15.6663 12.5957 15.6663C12.446 15.6663 12.315 15.6102 12.2027 15.4979C12.0904 15.3856 12.0366 15.2476 12.0413 15.0839L12.0694 13.926L11.3045 12.8383C11.2671 12.7868 11.2402 12.733 11.2238 12.6769C11.2074 12.6207 11.1992 12.5646 11.1992 12.5084C11.1992 12.3915 11.2331 12.2827 11.301 12.1821C11.3688 12.0815 11.4635 12.0102 11.5852 11.9681L12.7782 11.5681Z" fill="#1A8CF5"/>
</svg>

);
export default AISmartPlacementsBlueIcon;
