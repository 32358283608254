import * as React from "react";
import type { SVGProps } from "react";
const SvgChannelSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M14 20.334A6.333 6.333 0 1 1 20.334 14 6.342 6.342 0 0 1 14 20.334ZM14 9.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Z"
    />
    <path
      fill="#F55"
      d="M14 16.375a2.375 2.375 0 1 1 0-4.75 2.375 2.375 0 0 1 0 4.75Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M14 21.916a.792.792 0 0 1-.792-.791V19.54a.792.792 0 0 1 1.583 0v1.584a.792.792 0 0 1-.791.791ZM14 9.25a.791.791 0 0 1-.792-.792V6.875a.792.792 0 1 1 1.583 0v1.583A.792.792 0 0 1 14 9.25ZM8.458 14.79H6.875a.792.792 0 0 1 0-1.583h1.583a.792.792 0 1 1 0 1.583Zm12.667 0H19.54a.792.792 0 0 1 0-1.583h1.584a.792.792 0 0 1 0 1.583Z"
    />
  </svg>
);
export default SvgChannelSelected;
