import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface NavigationItemProps {
    title: string;
    description: string;
    image: string;
    route: string;
    isHovered: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

const MainNavigationCard: FC<NavigationItemProps> = ({ title, description, image, route, isHovered, onMouseEnter, onMouseLeave }) => {
    const navigate = useNavigate();

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="flex flex-col md:w-1/3 sm:w-full cursor-pointer pr-4 select-none"
            onClick={() => navigate(route)}
        >
            <div className="relative pb-[58%] overflow-hidden">
                <img
                    className={`absolute inset-0 h-auto w-full overflow-hidden object-contain rounded-lg ${isHovered ? 'border-1 border-red box-border' : ''}`}
                    src={require(`../../../assets/images/${image}`)}
                    alt={title}
                />
            </div>
            <div className="mt-5">
                <div className="text-black text-H5 font-medium">
                    {title}
                </div>
                <span className="text-BodySmall text-footnote mt-2">
                    {description}
                </span>
            </div>
        </div>
    );
};

export default MainNavigationCard;
