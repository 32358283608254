import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowUpDownSlim = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.19243 5.38601L4.67181 6.92783C4.53531 7.06623 4.37497 7.13544 4.19078 7.13544C4.00661 7.13544 3.84626 7.06623 3.70975 6.92783C3.56672 6.7828 3.49684 6.61525 3.50011 6.4252C3.50337 6.23514 3.57324 6.07421 3.70975 5.94243L6.39718 3.21753C6.46795 3.14578 6.5436 3.09159 6.62415 3.05495C6.70469 3.01832 6.79264 3 6.88799 3C6.97683 3 7.06151 3.01832 7.14206 3.05495C7.22261 3.09158 7.29827 3.14577 7.36903 3.21751L10.0738 5.95998C10.2103 6.09839 10.2785 6.26097 10.2785 6.44771C10.2785 6.63446 10.2103 6.79704 10.0738 6.93545C9.93727 7.07386 9.77693 7.14472 9.59274 7.14802C9.40855 7.15134 9.24821 7.08379 9.11171 6.94538L7.5738 5.38601L7.5738 15.8726C7.5738 16.0711 7.50843 16.2383 7.37768 16.3741C7.24695 16.51 7.08372 16.5779 6.88799 16.5779C6.68574 16.5779 6.51925 16.51 6.38852 16.3741C6.25779 16.2383 6.19243 16.0711 6.19243 15.8726L6.19243 5.38601ZM12.4089 14.6239L12.4089 4.12735C12.4089 3.92889 12.4743 3.76339 12.605 3.63083C12.7357 3.49828 12.9022 3.432 13.1045 3.432C13.3002 3.432 13.4634 3.49828 13.5942 3.63083C13.7249 3.76339 13.7903 3.92889 13.7903 4.12735L13.7903 14.6239L15.3282 13.0645C15.4647 12.9195 15.625 12.847 15.8092 12.847C15.9934 12.847 16.157 12.9195 16.3 13.0645C16.4365 13.2029 16.5032 13.3672 16.4999 13.5572C16.4966 13.7473 16.43 13.9082 16.3 14.04L13.5855 16.7924C13.5147 16.8575 13.4391 16.9084 13.3585 16.945C13.278 16.9817 13.1933 17 13.1045 17C13.0091 17 12.9212 16.9817 12.8406 16.945C12.7601 16.9084 12.6877 16.8575 12.6234 16.7924L9.92622 14.0576C9.78972 13.9191 9.72147 13.7566 9.72147 13.5698C9.72147 13.3831 9.78972 13.2205 9.92622 13.0821C10.0627 12.9437 10.2231 12.8728 10.4073 12.8695C10.5914 12.8662 10.7518 12.9371 10.8883 13.0821L12.4089 14.6239Z"
            fill={props.fill ?? "#000"}
        />

    </svg>
);
export default SvgArrowUpDownSlim;
