const Reviews = [
	{
		title: 'Too good to be true',
		body: 'I was skeptical about using an AI-powered tool for content creation, but Evercopy exceeded my expectations. The quality of content it generates is exceptional. ',
		author: 'Mark, Startup Founder',
		rating: 5
	},
	{
		title: 'My go-to content tool',
		body: "Evercopy has become my go-to tool for content creation. It's helped me save time, improve my productivity, and ultimately produce higher quality content.",
		author: 'Ryan, Copywriter',
		rating: 5
	},
	{
		title: 'Increased my click-through rates',
		body: 'Evercopy has taken my email marketing to the next level. Its ability to generate personalized subject lines and email copy that resonates with my subscribers has helped me increase open and click-through rates. ',
		author: 'Tom, Email Marketer',
		rating: 5
	},
	{
		title: 'A must-have tool for marketing',
		body: 'Evercopy is a must-have tool for anyone who wants to create marketing content that drives results. Its ability to generate content that speaks directly to your target audience is simply unmatched. ',
		author: 'Lily, Marketing Consultant',
		rating: 5
	},
	{
		title: 'Perfect visuals for my product',
		body: "I've been able to create high-quality visuals for my products that convert like crazy thanks to Evercopy's AI-powered copy generation. It completely replaced my graphic design and photo shooting efforts.",
		author: 'Rachel, E-commerce Store Owner',
		rating: 5
	},
	{
		title: 'Highly recommend to startup owners',
		body: "Evercopy is an absolute must-have for any startup looking to scale quickly. The platform's ability to create custom content in seconds has allowed us to rapidly test and iterate our marketing strategies. ",
		author: 'Mark, CEO of a Digital Marketing Agency',
		rating: 5
	},
	{
		title: 'A team worth of job done by a tool',
		body: "As a solopreneur, I don't have the resources to hire a full-time copywriter. Evercopy has been a cost-effective solution for me to create high-quality content for my business. ",
		author: 'Sarah, Solopreneur',
		rating: 5
	},
	{
		title: 'Creative engine for our business',
		body: "The platform's content suggestions feature has allowed us to explore new ideas and experiment with different messaging. It's like having a team of marketing experts at our fingertips. ",
		author: 'Tom, Marketing Director at a FinTech Startup',
		rating: 5
	},
	{
		title: 'A secret weapon for startups',
		body: "Evercopy is like having a secret weapon in your marketing toolkit. It's so powerful that I almost don't want to share it with my competitors! ",
		author: 'Josh, Digital Marketer',
		rating: 5
	},
	{
		title: 'Puts you ahead of the competition',
		body: "If you're not using Evercopy, you're missing out on a major competitive advantage. It's that simple. ",
		author: 'Amanda, Online Business Coach',
		rating: 5
	},
	{
		title: 'Has great impact on engagement',
		body: "I've been using Evercopy to create social media content for my business, and the engagement rates have been through the roof. The AI-generated images are eye-catching and on-brand, and the text is always spot-on. ",
		author: 'Jason, Small Business Owner',
		rating: 5
	}
];

// const reviews = [
// 	{
// 		title: 'It really works.',
// 		body: 'I downloaded Pocket today and turned $5000 into $25,000 in half an hour.',
// 		author: 'CrazyInvestor',
// 		rating: 5
// 	},
// 	{
// 		title: 'You need this app.',
// 		body: 'I didn’t understand the stock market at all before Pocket. I still don’t, but at least I’m rich now.',
// 		author: 'CluelessButRich',
// 		rating: 5
// 	},
// 	{
// 		title: 'This shouldn’t be legal.',
// 		body: 'Pocket makes it so easy to win big in the stock market that I can’t believe it’s actually legal.',
// 		author: 'LivingDaDream',
// 		rating: 5
// 	},
// 	{
// 		title: 'Screw financial advisors.',
// 		body: 'I barely made any money investing in mutual funds. With Pocket, I’m doubling my net-worth every single month.',
// 		author: 'JordanBelfort1962',
// 		rating: 5
// 	},
// 	{
// 		title: 'I love it!',
// 		body: 'I started providing insider information myself and now I get new insider tips every 5 minutes. I don’t even have time to act on all of them. New Lamborghini is being delivered next week!',
// 		author: 'MrBurns',
// 		rating: 5
// 	},
// 	{
// 		title: 'Too good to be true.',
// 		body: 'I was making money so fast with Pocket that it felt like a scam. But I sold my shares and withdrew the money and it’s really there, right in my bank account. This app is crazy!',
// 		author: 'LazyRich99',
// 		rating: 5
// 	},
// 	{
// 		title: 'Wish I could give 6 stars',
// 		body: 'This is literally the most important app you will ever download in your life. Get on this before it’s so popular that everyone else is getting these tips too.',
// 		author: 'SarahLuvzCash',
// 		rating: 5
// 	},
// 	{
// 		title: 'Bought an island.',
// 		body: 'Yeah, you read that right. Want your own island too? Get Pocket.',
// 		author: 'ScroogeMcduck',
// 		rating: 5
// 	},
// 	{
// 		title: 'No more debt!',
// 		body: 'After 2 weeks of trading on Pocket I was debt-free. Why did I even go to school at all when Pocket exists?',
// 		author: 'BruceWayne',
// 		rating: 5
// 	},
// 	{
// 		title: 'I’m 13 and I’m rich.',
// 		body: 'I love that with Pocket’s transaction anonymization I could sign up and start trading when I was 12 years old. I had a million dollars before I had armpit hair!',
// 		author: 'RichieRich',
// 		rating: 5
// 	},
// 	{
// 		title: 'Started an investment firm.',
// 		body: 'I charge clients a 3% management fee and just throw all their investments into Pocket. Easy money!',
// 		author: 'TheCountOfMonteChristo',
// 		rating: 5
// 	},
// 	{
// 		title: 'It’s like a superpower.',
// 		body: 'Every tip Pocket has sent me has paid off. It’s like playing Blackjack but knowing exactly what card is coming next!',
// 		author: 'ClarkKent',
// 		rating: 5
// 	},
// 	{
// 		title: 'Quit my job.',
// 		body: 'I downloaded Pocket three days ago and quit my job today. I can’t believe no one else thought to build a stock trading app that works this way!',
// 		author: 'GeorgeCostanza',
// 		rating: 5
// 	},
// 	{
// 		title: 'Don’t download this app',
// 		body: 'Unless you want to have the best life ever! I am literally writing this from a yacht.',
// 		author: 'JeffBezos',
// 		rating: 5
// 	}
// ];

export default Reviews