import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigationCard from "./components/MainNavigationCard";

interface NavigationItem {
    title: string;
    description: string;
    image: string;
    route: string;
}

const mainNavigationLists: NavigationItem[] = [{
    title: "Generate A Multimedia Ad",
    description: "Create converting ad designs with AI",
    image: "generate_multimedia_ad.png",
    route: "/app/home",
},
{
    title: "Schedule An Activity",
    description: "Plan and schedule marketing content with AI",
    image: "schedule_activity.png",
    route: "/app/content-calendar",
},
{
    title: "Plan A Multi-Channel Campaign",
    description: "Create marketing campaigns with AI",
    image: "multi_channel_campaign.png",
    route: "/app/marketing-planner",
},]

const MainNavigations = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    return (
        <div className="w-full flex flex-row flex-wrap mt-14">
            {mainNavigationLists.length > 0 &&
                mainNavigationLists.map((item, index) => (
                    <MainNavigationCard
                        key={index}
                        title={item.title}
                        description={item.description}
                        image={item.image}
                        route={item.route}
                        isHovered={index === hoveredIndex}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    />
                ))}
        </div>
    );
};

export default MainNavigations;
