import {LeftOutlined} from "@ant-design/icons";
import { useState} from "react";
import SelectImageContent from "./SubStepComponents/SelectImageContent";
import SelectTextContentType from "./SubStepComponents/SelectTextContentType";

const GenerateImageWithAI = (props) => {
    const [selectedTextContentType, setSelectedTextContentType] = useState()
    const [step, setStep]=useState(0)
    const [isInspirationThemeSelectionOpen, setIsInspirationThemeSelectionOpen] = useState(false)
    const [isLogoSelectionOpen, setIsLogoSelectionOpen] = useState(false)
    const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false)
    const backToSelection = () => {
        setIsLogoSelectionOpen(false);
        setIsProductSelectorOpen(false);
        setIsInspirationThemeSelectionOpen(false);
    }

    return (
        <div className={`min-w-[350px] ${props.isInSide == true ? "w-full" : "max-w-[450px]"} h-full`}>
            {props.title && (
                <div>
                    <div className="p-3 flex justify-between items-center">
                        <div className={'flex items-center'}>
                            <span className="text-black mr-1 cursor-pointer" onClick={() => {
                                if (!step) {
                                    props.closeSideModal();
                                } else {
                                    if (isProductSelectorOpen || isLogoSelectionOpen || isProductSelectorOpen) {
                                        backToSelection();
                                    } else {
                                        setStep(prevState => prevState - 1)
                                    }
                                }
                            }}><LeftOutlined/></span>
                            <span className="text-H8"> {props.title}</span>
                        </div>
                        <div>
                           <span className="text-gray text-xs"> {props.description ?? ''}</span>
                        </div>
                    </div>
                    <hr className="bg-border mx-1"/>
                </div>
            )}
            <div className={'h-[calc(100%-55px)] pl-5 pr-3'}>
                <SelectImageContent
                    editCreativeStep={props.editCreativeStep}
                    isInspirationThemeSelectionOpen={isInspirationThemeSelectionOpen}
                    isLogoSelectionOpen={isLogoSelectionOpen}
                    isProductSelectorOpen={isProductSelectorOpen}
                    setIsProductSelectorOpen={setIsProductSelectorOpen}
                    setIsLogoSelectionOpen={setIsLogoSelectionOpen}
                    setIsInspirationThemeSelectionOpen={setIsInspirationThemeSelectionOpen}
                    backToSelection={backToSelection}
                    isInSide={props.isInSide}
                    selectedTextContentType={selectedTextContentType}
                    setSelectedTextContentType={setSelectedTextContentType}
                    setGeneratedAIImages={props.setGeneratedAIImages}
                    closeSideModal={props.closeSideModal}
                    setStep={setStep}
                    step={step}
                />
            </div>
        </div>
    )
}

export default GenerateImageWithAI;
