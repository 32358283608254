import * as React from "react";
const SvgAudienceArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#F55"
      d="M13.137 6.738a.664.664 0 0 0 0-.943.667.667 0 0 0-.942 0l-3.53 3.53L8 10l-.666-.676-3.53-3.529a.667.667 0 1 0-.942.943l4.667 4.667c.26.26.682.26.943 0l4.666-4.667Z"
    />
  </svg>
);
export default SvgAudienceArrowUp;
