import { Plan } from '../../../services/PlanApi'

interface IBenefitsTableHeadingProps {
    plans: Plan[]
}

function BenefitsTableHeading(props: IBenefitsTableHeadingProps) {
    return (
        <div className="w-full h-[70px] justify-start items-start inline-flex">
            <div className="w-[360px] h-[70px] px-2.5 py-[15px] justify-start items-center gap-2.5 flex ">
            </div>
            {
                props.plans.map(p => {
                    return (<div className={`grow shrink basis-0 h-[70px] px-2.5 py-[15px] justify-center items-center gap-2.5 rounded-tl-[10px] rounded-tr-[10px] flex ${p.mostPopular ? "border-l-1 border-r-1 border-t-1 border-rose-400 " : ""}`}>
                        <div id={`benefit_heading_${p.name}`} className={p.mostPopular ? `text-red text-lg font-semibold font-['Inter Tight'] leading-[25.20px]` : `text-neutral-900 text-lg font-semibold font-['Inter Tight'] leading-[25.20px]`}>{p.name}</div>
                    </div>)
                })
            }
        </div >
    )
}


export default BenefitsTableHeading
