import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close, Copy, Delete, Edit, Like, Liked, Refresh } from "../assets/IconComponents";
import SvgCloseLittle from "../assets/IconComponents/CloseLittle";
import { useAppDispatch, useAppSelector } from "../helpers/hooks";
import { ContentWizardStep } from "../models/ContentWizardStep";
import { LoopChannels, LoopDetailChannel } from "../pages/LoopDetails/LoopDetailsHeader/Channel/LoopDetailChannels";
import { Content } from "../services/ContentApi";
import { useEditNameMutation } from "../services/ContentApi.old/ContentApi";
import { useDeleteImageMutation, useFavoriteImageMutation } from "../services/ImageApi.old/ImageApi";
import { Revision, useEditTextMutation, useFavoriteMutation } from "../services/RevisionApi";
import { refreshPage, setDialog, toggleDialog, updateMenu } from "../store/appSlice";
import { setNewContent } from "../store/contentSlice";
import IconButton from "./IconButton";
import PrimaryButton from "./PrimaryButton";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import SvgGenerateSimilarIcon from "../assets/IconComponents/GenerateSimilarIcon";

export interface ITextContentDetailPopupProps {
  show: boolean;
  onClose: () => void;
  className?: string;
  closable?: boolean;
  fullScreen?: boolean;
  content?: Content;
  revision?: Revision;
  generateSimilar: () => void;
  updatePage: () => void;
  deleteRevisionItem: (revision: Revision) => void;
  editingEnabled?: boolean;
  loopChannels?: LoopDetailChannel[];
}

function TextContentDetailPopup(props: ITextContentDetailPopupProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const newContent = useAppSelector(k => k.content.newContent)

  const [updateText, { isSuccess: textUpdated }] = useEditTextMutation()
  const [editName, { isSuccess: nameUpdated }] = useEditNameMutation()

  const [setTextFavorite, { isSuccess: favoriteTextUpdated }] = useFavoriteMutation()

  const [setImageFavorite, { isSuccess: favoriteImageUpdated }] = useFavoriteImageMutation()
  const [setImageDelete, { isSuccess: isImageDeleted }] = useDeleteImageMutation()

  const [editing, setEditing] = useState(false)
  const [isFavori, setFavori] = useState(props.revision?.is_favorite ?? false)
  const [contentName, setContentName] = useState(props.content?.name ?? "")
  const [text, setText] = useState(props.revision?.generated_content)
  const [showUpdateBrief, setShowUpdateBrief] = useState(false)
  const [showCopyRevision, setShowCopyRevision] = useState(false)
  const [channel, setChannel] = useState<LoopDetailChannel>(LoopChannels[0])
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [copyButtonText, setCopyButtonText] = useState(t('copy'))
  const [aiModelsLocal, setAiModelsLocal] = useState<LoopDetailChannel[]>([]);

  useEffect(() => {
    setContentName(props.content?.name ?? "")
  }, [props.content?.name])


useEffect(() => {
  if (props.revision && aiModelsLocal.length !== 0) {
    setFavori(props.revision.is_favorite ?? false);

    const modelFromLocal = aiModelsLocal.find(
      (k) => k.name.toLowerCase() === props.revision?.ai_model?.toLowerCase()
    );

    const modelFromLoopChannels =
      modelFromLocal ??
      LoopChannels.find(
        (k) => k.name.toLowerCase() === props.revision?.ai_model?.toLowerCase()
      )!;

    setChannel(modelFromLoopChannels);
  }
}, [props.revision, aiModelsLocal, LoopChannels]);

   useEffect(() => {
     if (props.loopChannels) {
       setAiModelsLocal(props.loopChannels);
     }
   }, [props.loopChannels]);

  useEffect(() => {
    setText(props.revision?.generated_content ?? "")
    setFavori(props.revision?.is_favorite ?? false)
  }, [props.revision?.generated_content])

  useEffect(() => {
    if (props.editingEnabled)
      setEditing(true)

  }, [props.editingEnabled])

  useEffect(() => {
    dispatch(updateMenu())
    dispatch(refreshPage())
    props.updatePage()

    if (newContent?.wizardStep === ContentWizardStep.contentResult) {
      dispatch(setNewContent(
        {
          ...newContent,
          loopName: contentName
        }
      ))
    }

  }, [textUpdated, nameUpdated, favoriteTextUpdated, favoriteImageUpdated, isImageDeleted])

  useEffect(() => {
    dispatch(updateMenu())
    props.updatePage()
    props.onClose()
  }, [isImageDeleted])


  const updateFields = () => {
    setEditing(false)

    updateText({
      revisionUpdateRequest: {
        revision_id: props.revision?.id?.revision_id ?? "",
        updated_text_content: text ?? ""
      }
    })
  }

  const updateFavorite = () => {
    setFavori(!isFavori);
    setTextFavorite({
      isFavorite: !isFavori ?? false,
      revisionId: props.revision?.id?.revision_id ?? "",
    })
  }
  const copyContent = () => {
    if (text) {
      setCopyButtonText(t('copied-0'))
      copyToClipboard(text)
      setTimeout(() => {
        setCopyButtonText(t('copy'))
      }, 2000);
    }
  }


  return (
    <>
      {props.show && (
        <div
          className={`${props.fullScreen
              ? "fixed left-0 right-0 top-0 bottom-0"
              : "absolute  w-full h-[40px] lg:w-[calc(100%-220px)]"
            } backdrop-brightness-50 z-30 flex items-center justify-center ${props.className
            }`}
          onClick={() => {
            if (props.closable) {
              setEditing(false);
              props.onClose();
            }
          }}
        >
          <div
            className={`flex flex-col mt-5 mx-2 sm:mt-0 lg:mx-0 sm:justify-center items-center w-full lg:w-[calc(66%+0px)]`}
          >
            <div
              className="w-full flex flex-col"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex flex-col w-full bg-white border-border border-1 rounded-[12px] shadow-lg shadow-red-100">
                <div className="w-full flex justify-between items-center border-b-1 border-border">
                  <p className="text-black outline-none text-H6 p-[15px]">
                    Revision {props.revision?.revision_group} -{" "}
                    {channel.name.replace("-", " ")}
                  </p>
                </div>
                <div
                  className={`h-[200px] overflow-auto m-[15px] p-[15px] border-1 border-border flex rounded-[8px] flex-col`}
                >
                  <textarea
                    className="text-black bg-white h-full text-BodyMedium mt-2  overflow-scroll scrollbar-hide  outline-none"
                    defaultValue={text}
                    onChange={(e) => {
                      if (props.revision?.generated_content ?? "" !== text) {
                        setEditing(true);
                      }
                      else {
                        setEditing(false);
                      }
                      setText(e.target.value);
                    }}
                  />
                </div>

                {!props.content?.read_only && (
                  <div className="flex flex-row justify-between">
                    <div>
                      <button
                        className="ml-5 flex flex-row"
                        onClick={() => {
                          props.onClose();
                          props.generateSimilar();
                        }}
                      >
                        <SvgGenerateSimilarIcon />
                        <span className="ml-2 text-H8 text-red">
                          {t("generate-similiar")}
                        </span>
                      </button>
                    </div>

                    <div className="flex flex-row  mr-5">
                      <IconButton
                        color="#000000"
                        className="hover:w-[80px]"
                        title={copyButtonText ?? ""}
                        icon={Copy}
                        onClick={() => {
                          copyContent();
                        }}
                      />

                      <IconButton
                        color="#000000"
                        className="hover:w-20 ml-1"
                        title={t("delete")}
                        icon={Delete}
                        onClick={() => {
                          props.deleteRevisionItem(props.revision!);
                        }}
                      />
                    </div>
                  </div>
                )}
                <hr className="bg-border -l-10 -r-10  mt-3" />


                <div className="w-full flex flex-row p-[15px] pb-0 pt-0 justify-end items-end">
                  <div className="h-16 flex  mt-5">
                    <PrimaryButton
                      noFill
                      className="h-10 w-16"
                      title={t("cancel") ?? ""}
                      onClick={() => {
                        props.onClose();
                        setEditing(false);
                      }}
                    />
                    <PrimaryButton
                      className="h-10 w-28 ml-2"
                      title={t("save-and-close") ?? ""}
                      isDisabled={!editing}
                      onClick={() => {
                        updateFields();
                        props.onClose();
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TextContentDetailPopup;
