import { useEffect, useState } from "react";
import { t } from "i18next";

import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { setDialog, toggleDialog, toggleLoading } from "../../store/appSlice";
import { clickOutside } from "../../helpers/ClickOutside";
import PrimaryButton from "../../components/PrimaryButton";
import InputText, { InputTextState } from "../../components/InputText";
import { useAddApiKeysMutation } from "../../services/AdManagerApi";

export interface ISocialsInfoPopupProps {
  onClose: () => void;
}

function SocialsInfoPopupOpener(props: ISocialsInfoPopupProps) {
  const dispatch = useAppDispatch();


  useEffect(() => {
    showEditAddressPopup();
  }, [props]);

  const showEditAddressPopup = () => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: <SocialsInfoPopup {...props}></SocialsInfoPopup>,
      })
    );
    dispatch(toggleDialog(true));
  };

  return <></>;
}

function SocialsInfoPopup(props: ISocialsInfoPopupProps) {
    const dispatch = useAppDispatch();
    const brandInfo = useAppSelector(k => k.brand.newBrand)
    const [apiKeyName, setApiKeyName] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [apiAccessToken, setApiAccessToken] = useState("");
    const [apiTokenSecret, setApiTokenSecret] = useState("");
    const [addApiKeys, { isSuccess: apiKeysAdded, isLoading: apiKeysAdding }] =
      useAddApiKeysMutation();
  useEffect(() => {
    dispatch(toggleLoading(false));
  }, [apiKeysAdding]);

  useEffect(() => {
   if(apiKeysAdded)
   {
    dispatch(toggleDialog(false));
    props.onClose();
   }
  }, [apiKeysAdded]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      return clickOutside(ref.current, () => {
        dispatch(toggleDialog(false));
        props.onClose();
      });
    }
  }, [ref.current]);

  return (
    <>
      <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen">
        <div
          ref={ref}
          className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-5 rounded-[10px]"
        >
          <>
            <div className="h-10 items-center flex justify-between">
              <span className="text-black text-H6 mb-3">
                {t("enter-account-information")}
              </span>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />
            <div className="w-full mt-[20px] flex-col">
              <InputText
                title={t("api-key-name") ?? ""}
                className="mt-4"
                onChange={(e) => {
                    setApiKeyName(e);
                }}
                sideBySide={true}
                innerClassName="w-1/2 rounded-[4px]"
              />
              <InputText
                title={t("api-key") ?? ""}
                className="mt-4"
                onChange={(e) => {
                    setApiKey(e);
                }}
                sideBySide={true}
                innerClassName="w-1/2 rounded-[4px]"
              />
              <InputText
                title={t("api-secret") ?? ""}
                className="mt-4"
                onChange={(e) => {
                    setApiSecret(e);
                }}
                sideBySide={true}
                innerClassName="w-1/2 rounded-[4px]"
              />
              <InputText
                title={t("api-access-token") ?? ""}
                className="mt-4"
                onChange={(e) => {
                    setApiAccessToken(e);
                }}
                sideBySide={true}
                innerClassName="w-1/2 rounded-[4px]"
              />
              <InputText
                title={t("api-token-secret") ?? ""}
                className="mt-4"
                onChange={(e) => {
                    setApiTokenSecret(e);
                }}
                sideBySide={true}
                innerClassName="w-1/2 rounded-[4px]"
              />
            </div>
            <hr className="bg-border -ml-5 -mr-5 my-5" />
            <div className="flex-col lg:flex lg:flex-row lg:justify-end">
              <PrimaryButton
                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  props.onClose();
                }}
              />
              <PrimaryButton
                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                isDisabled={false}
                title={t("save")}
                onClick={() => {
                  addApiKeys({addApiKeysRequest:{brandId: (brandInfo.id ?? ""), apiKey:apiKey, apiAccessToken: apiAccessToken, apiTokenSecret: apiTokenSecret, apiSecret:apiSecret, apiType:"x", apiKeyName:apiKeyName}})
                }}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
}
export default SocialsInfoPopupOpener;
