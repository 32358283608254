import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

interface AppState {
    isLoggedIn: boolean
    accessToken: string
    refreshToken: string

    activeAIModels: string[]
    lastSelectedBrandId?: string
}

// Define the initial state using that type
const initialState: AppState = {
    isLoggedIn: false,
    accessToken: "",
    refreshToken: "",

    activeAIModels: []
}

export const appSlice = createSlice({
    name: 'persisted',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggleLoggedIn: (state, status: PayloadAction<boolean>) => {
            state.isLoggedIn = status.payload
            if (status.payload == false) {
                state.accessToken = ""
                state.refreshToken = ""
            }
        },
        setAccessToken: (state, status: PayloadAction<string>) => {
            state.accessToken = status.payload
        },
        setRefreshToken: (state, status: PayloadAction<string>) => {
            state.refreshToken = status.payload
        },
        setActiveAIModels: (state, brand: PayloadAction<string[]>) => {
            state.activeAIModels = brand.payload
        },
        setLastSelectedBrandId: (state, brand: PayloadAction<string | undefined>) => {
            console.log('', state.lastSelectedBrandId = brand.payload)
            state.lastSelectedBrandId = brand.payload
        },
    },
})

export const { toggleLoggedIn, setAccessToken, setRefreshToken, setActiveAIModels, setLastSelectedBrandId } = appSlice.actions
export const isLoggedIn = (state: RootState) => state.persisted.isLoggedIn
export const accessToken = (state: RootState) => state.persisted.accessToken
export const refreshToken = (state: RootState) => state.persisted.refreshToken

export default appSlice.reducer
