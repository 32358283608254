import * as React from "react";
const SvgArrowRightWithTip = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#F55"
      d="M11.25 14.521a.611.611 0 0 1-.187-.447c0-.174.055-.316.166-.428l3.063-3.062H3.958a.607.607 0 0 1-.625-.625.607.607 0 0 1 .625-.625h10.334l-3.083-3.083a.564.564 0 0 1-.178-.438.64.64 0 0 1 .198-.437.651.651 0 0 1 .448-.167c.174 0 .316.056.427.167L16.25 9.52c.07.07.118.14.146.209.028.07.042.146.042.229 0 .083-.014.16-.042.23a.637.637 0 0 1-.146.207l-4.125 4.125a.6.6 0 0 1-.437.188.6.6 0 0 1-.438-.188Z"
    />
  </svg>
);
export default SvgArrowRightWithTip;
