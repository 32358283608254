import * as React from "react";
import type { SVGProps } from "react";
const SvgLoops = (props: SVGProps<SVGSVGElement>) => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width={40}
  //   height={40}
  //   fill="none"
  //   {...props}
  // >
  //   <path d="M9.99 1.658a.625.625 0 0 0-.615.634v.625H6.458a1.877 1.877 0 0 0-1.875 1.875v3.75c0 1.033.842 1.875 1.875 1.875h7.084a1.877 1.877 0 0 0 1.875-1.875v-3.75a1.877 1.877 0 0 0-1.875-1.875h-2.917v-.625a.625.625 0 0 0-.635-.634ZM8.125 5.417a1.042 1.042 0 1 1 0 2.083 1.042 1.042 0 0 1 0-2.083Zm3.75 0a1.041 1.041 0 1 1 0 2.083 1.041 1.041 0 0 1 0-2.083Zm-6.667 6.25a1.877 1.877 0 0 0-1.875 1.875v.317c0 .981.408 1.924 1.12 2.586.925.862 2.637 1.888 5.547 1.888 2.91 0 4.623-1.026 5.547-1.888a3.54 3.54 0 0 0 1.12-2.586v-.317a1.877 1.877 0 0 0-1.875-1.875H5.208Z" />
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path fill={props.fill ?? "#FFFFFF"} d="M10.8333 6.66667V3.33333C10.8333 3.09722 10.9132 2.89931 11.0729 2.73958C11.2326 2.57986 11.4306 2.5 11.6667 2.5H16.6667C16.9028 2.5 17.1007 2.57986 17.2604 2.73958C17.4201 2.89931 17.5 3.09722 17.5 3.33333V6.66667C17.5 6.90278 17.4201 7.10069 17.2604 7.26042C17.1007 7.42014 16.9028 7.5 16.6667 7.5H11.6667C11.4306 7.5 11.2326 7.42014 11.0729 7.26042C10.9132 7.10069 10.8333 6.90278 10.8333 6.66667ZM2.5 10V3.33333C2.5 3.09722 2.57986 2.89931 2.73958 2.73958C2.89931 2.57986 3.09722 2.5 3.33333 2.5H8.33333C8.56944 2.5 8.76736 2.57986 8.92708 2.73958C9.08681 2.89931 9.16667 3.09722 9.16667 3.33333V10C9.16667 10.2361 9.08681 10.434 8.92708 10.5938C8.76736 10.7535 8.56944 10.8333 8.33333 10.8333H3.33333C3.09722 10.8333 2.89931 10.7535 2.73958 10.5938C2.57986 10.434 2.5 10.2361 2.5 10ZM10.8333 16.6667V10C10.8333 9.76389 10.9132 9.56597 11.0729 9.40625C11.2326 9.24653 11.4306 9.16667 11.6667 9.16667H16.6667C16.9028 9.16667 17.1007 9.24653 17.2604 9.40625C17.4201 9.56597 17.5 9.76389 17.5 10V16.6667C17.5 16.9028 17.4201 17.1007 17.2604 17.2604C17.1007 17.4201 16.9028 17.5 16.6667 17.5H11.6667C11.4306 17.5 11.2326 17.4201 11.0729 17.2604C10.9132 17.1007 10.8333 16.9028 10.8333 16.6667ZM2.5 16.6667V13.3333C2.5 13.0972 2.57986 12.8993 2.73958 12.7396C2.89931 12.5799 3.09722 12.5 3.33333 12.5H8.33333C8.56944 12.5 8.76736 12.5799 8.92708 12.7396C9.08681 12.8993 9.16667 13.0972 9.16667 13.3333V16.6667C9.16667 16.9028 9.08681 17.1007 8.92708 17.2604C8.76736 17.4201 8.56944 17.5 8.33333 17.5H3.33333C3.09722 17.5 2.89931 17.4201 2.73958 17.2604C2.57986 17.1007 2.5 16.9028 2.5 16.6667ZM4.16667 9.16667H7.5V4.16667H4.16667V9.16667ZM12.5 15.8333H15.8333V10.8333H12.5V15.8333ZM12.5 5.83333H15.8333V4.16667H12.5V5.83333ZM4.16667 15.8333H7.5V14.1667H4.16667V15.8333Z" />
  </svg>
);
export default SvgLoops;
