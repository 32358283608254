import * as React from "react";
import type { SVGProps } from "react";
const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M9.375 9.375V2.5H4.792A2.294 2.294 0 0 0 2.5 4.792v4.583h6.875Zm1.25 0H17.5V4.792A2.294 2.294 0 0 0 15.208 2.5h-4.583v6.875Zm-1.25 1.25H2.5v4.583A2.294 2.294 0 0 0 4.792 17.5h4.583v-6.875Zm1.25 0V17.5h4.583a2.294 2.294 0 0 0 2.292-2.292v-4.583h-6.875Z"
    />
  </svg>
);
export default SvgGrid;
