import * as React from "react";
import type { SVGProps } from "react";
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M10 1.667c-4.593 0-8.333 3.74-8.333 8.333 0 4.592 3.74 8.333 8.333 8.333 2.23 0 4.285-.88 5.784-2.315a.833.833 0 0 0-1.152-1.204A6.698 6.698 0 0 1 10 16.667 6.654 6.654 0 0 1 3.333 10 6.654 6.654 0 0 1 10 3.333c1.812 0 3.434.693 4.63 1.85a.834.834 0 1 0 1.159-1.199A8.296 8.296 0 0 0 10 1.667Zm4.991 4.992a.833.833 0 0 0-.58 1.43l1.077 1.078H7.917a.833.833 0 1 0 0 1.666h7.571l-1.077 1.078a.834.834 0 1 0 1.178 1.178l2.5-2.5a.833.833 0 0 0 0-1.178l-2.5-2.5a.834.834 0 0 0-.598-.252Z" />
  </svg>
);
export default SvgLogout;
