import * as React from "react";
import type { SVGProps } from "react";
const SvgMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M9.167 9.167h-6.25a.833.833 0 1 0 0 1.666h14.166a.832.832 0 1 0 0-1.666H9.167Z"
    />
  </svg>
);
export default SvgMinus;
