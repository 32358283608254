import { t } from "i18next";
import {
  FunctionComponent,
  useEffect,
  useState,
} from "react";

import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { toggleLoading } from "../../../store/appSlice";
import { LinkSocialAccounts } from "../../../assets/IconComponents";
import SocialsInfoPopupOpener from "../../Socials/SocialsInfoPopup";
import { BrandSocialsModel, SocialApiKeyModel, useLazyGetApiKeysByBrandIdQuery, useLazyGetApiKeysByOrganizationIdQuery } from "../../../services/AdManagerApi";
import { useSaveTokenInfoMutation } from "../../../services/SocialMediaApi";
import BrandHubSocialAccountSelectionCard from "./BrandHubSocialAccountSelectionCard";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";

interface IBrandHubSocialsProps {
  className?: string;
}

const BrandHubSocials: FunctionComponent<IBrandHubSocialsProps> = (
  props: IBrandHubSocialsProps
) => {
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const dispatch = useAppDispatch();
  const [showSocialsInfoPopup, setShowSocialsInfoPopup] = useState(false);
  const [getApiKeysSocials, { data: apiKeys, isLoading: apiKeysLoading }] = useLazyGetApiKeysByBrandIdQuery();
  const [brandHubSocials, setBrandHubSocials] = useState<BrandSocialsModel[]>([]);

  useEffect(() => {
    dispatch(toggleLoading(apiKeysLoading));
  }, [apiKeysLoading]);

  useEffect(() => {
    if (apiKeys) {
      console.log(`${apiKeys.length} api keys found for brand`)
      setBrandHubSocials(apiKeys);
    }
  }, [apiKeys]);

  useEffect(() => {
    getApiKeysSocials({ brandId: brandInfo.id ?? "" });
  }, []);

  const [connectionInfo, setConnectionInfo] = useState<SocialApiKeyModel[]>([])

  const [getOrganizationKeys, { data: organizationKeys, isLoading: organizationKeysLoading }] = useLazyGetApiKeysByOrganizationIdQuery()

  useEffect(() => {
    if (organizationKeys !== undefined) {
      setConnectionInfo(organizationKeys)
    }
  }, [apiKeys])

  useEffect(() => { getOrganizationKeys() }, [])

  return (
    <>
      <div className="flex flex-col w-full bg-white border-1 rounded-[10px]  mt-6">
        <div className="mt-5 ml-5 flex flex-row w-full items-center">
          <LinkSocialAccounts />
          <span className="text-H7 text-black ml-2  truncate text-ellipsis overflow-hidden">
            {t("link-social-accounts")}
          </span>
          <SvgQuestionMark className="ml-2" data-tooltip-id={"app-tooltip"}
            data-tooltip-html={t("link-social-accounts-tooltip")} />
        </div>
        <div
          className={`flex flex-col w-full gap-2 p-4`}
        >
          <BrandHubSocialAccountSelectionCard
            brandId={brandInfo.id ?? ""}
            apiType="FACEBOOK_PAGE"
            apiTypeSecond="FACEBOOK_AD_ACCOUNT"
            firstAccount={brandHubSocials.find(bh => bh.apiKeyModel?.apiType === "FACEBOOK_PAGE")}
            secondAccount={brandHubSocials.find(bh => bh.apiKeyModel?.apiType === "FACEBOOK_AD_ACCOUNT")}
            selecteblaApiKeys={connectionInfo.filter(ci => ci.apiType === "FACEBOOK_PAGE")}
            selecteblaApiKeysSecond={connectionInfo.filter(ci => ci.apiType === "FACEBOOK_AD_ACCOUNT")}
          ></BrandHubSocialAccountSelectionCard>
          <BrandHubSocialAccountSelectionCard brandId={brandInfo.id ?? ""} apiType="INSTAGRAM" firstAccount={brandHubSocials.find(bh => bh.apiKeyModel?.apiType === "INSTAGRAM")} selecteblaApiKeys={connectionInfo.filter(ci => ci.apiType === "INSTAGRAM")}></BrandHubSocialAccountSelectionCard>
          <BrandHubSocialAccountSelectionCard brandId={brandInfo.id ?? ""} apiType="GOOGLE_ADS" firstAccount={brandHubSocials.find(bh => bh.apiKeyModel?.apiType === "GOOGLE_ADS")} selecteblaApiKeys={connectionInfo.filter(ci => ci.apiType === "GOOGLE_ADS")}></BrandHubSocialAccountSelectionCard>
          <BrandHubSocialAccountSelectionCard brandId={brandInfo.id ?? ""} apiType="X" firstAccount={brandHubSocials.find(bh => bh.apiKeyModel?.apiType === "X")} selecteblaApiKeys={connectionInfo.filter(ci => ci.apiType === "X")}></BrandHubSocialAccountSelectionCard>
          <BrandHubSocialAccountSelectionCard brandId={brandInfo.id ?? ""} apiType="LINKEDIN" firstAccount={brandHubSocials.find(bh => bh.apiKeyModel?.apiType === "LINKEDIN")} selecteblaApiKeys={connectionInfo.filter(ci => ci.apiType === "LINKEDIN")}></BrandHubSocialAccountSelectionCard>

        </div>
      </div>
      {showSocialsInfoPopup && (
        <SocialsInfoPopupOpener
          onClose={function (): void {
            setShowSocialsInfoPopup(false);
          }}
        ></SocialsInfoPopupOpener>
      )}
    </>
  );
};

export default BrandHubSocials;
