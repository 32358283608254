import {t} from "i18next";
import ContentContainer from "../../../components/ContentContainer";
import {useEffect, useState} from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import {useAppDispatch, useAppSelector} from "../../../helpers/hooks";
import {setEvent, setPlan} from "../../../store/plannerSlice";
import {BrandRequest, FileProps, useLazyFindById4Query} from "../../../services/BrandApi";
import MarketingImagePicker from "../../../components/MarketingPlanner/MarketingImagePicker";
import {Button, Tabs, TabsProps} from "antd";
import {LoopsApiArg, useLazyLoopsQuery} from "../../../services/ContentApi";

const AddPhotos = (props) => {
    const dispatch = useAppDispatch()
    const [brandInfo, setBrandInfo] = useState<BrandRequest>()
    const [selectedImages, setSelectedImages] = useState<string[]>(props[props.type === 'event' ? 'event' : 'plan']?.visuals ?? []);
    const [images, setImages] = useState<FileProps[]>([]);
    const [favouriteImages, setFavouriteImages] = useState<FileProps[]>([]);
    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)
    const [getBrand, {data: brand, isLoading: brandLoading}] = useLazyFindById4Query();
    const [
        loops,
        {
            isLoading: favoritesContentListLoading,
            isFetching: favoritesContentsFetching,
            data: favoritesContentList,
        },
    ] = useLazyLoopsQuery();

    useEffect(() => {


    }, []);

    useEffect(() => {
        if (lastSelectedBrandId) {
            getBrand({id: lastSelectedBrandId}).then(brand => {
                setBrandInfo(brand?.data)
                const newList = brand?.data?.product_file_ids ? [...brand?.data?.product_file_ids] : [];
                console.log(newList)
                selectedImages?.forEach(img => {
                    if (!newList.filter(k => k.id === img)?.length) {
                        const lastFile: FileProps = {
                            id: img,
                            name: '',
                            uploaded: undefined,
                        };
                        newList.push(lastFile)
                    }
                })
                setImages(newList)
            })
            loops({
                page: 0,
                size: 50,
                favorite: true,
                brandId: lastSelectedBrandId,
                contentType: 'image'
            } as LoopsApiArg).then(a => {
                const newList: FileProps[] = [];
                console.log(a);
                a.data?.map(c => {
                    const lastFile: FileProps = {
                        id: c.revision?.generated_content,
                        name: '',
                        uploaded: undefined,
                    };
                    newList.push(lastFile)
                })
                setFavouriteImages(newList)
            })
        }
    }, [lastSelectedBrandId]);

    const onChange = (key: string) => {
        console.log(key);
    };


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Favorite Contents',
            children: <div>
                <MarketingImagePicker
                    uploadButtonEnabled={false}
                    uploadedFiles={favouriteImages}
                    isInside
                    activity={props.activity}
                    isGrid
                    type={'favouriteImages'}
                    title=""
                    description=""
                    hideFileName={true}
                    fileChanged={(f: any) => {}}
                    selectionChanged={(pickedFile) => {
                        if (pickedFile?.id) {
                            if (selectedImages.indexOf(pickedFile.id) === -1 && selectedImages.length < 10) {
                                setSelectedImages(prev => [String(pickedFile.id), ...prev])
                            } else {
                                setSelectedImages(prev => prev.filter(item => item !== String(pickedFile.id)))
                            }
                        }
                    }}
                    selectionEnabled={true}
                    selectedImages={selectedImages.filter(id => !!favouriteImages.filter(a => a.id === id).length)}
                />
            </div>,
        },
        {
            key: '2',
            label: 'Brand Library',
            children: <MarketingImagePicker
                uploadButtonEnabled={false}
                uploadedFiles={images}
                isInside
                activity={props.activity}
                isGrid
                type={'marketingplanner'}
                title=""
                description=""
                hideFileName={true}
                fileChanged={(f: any) => {}}
                selectionChanged={(pickedFile) => {
                    if (pickedFile?.id) {
                        if (selectedImages.indexOf(pickedFile.id) === -1 && selectedImages.length < 10) {
                            setSelectedImages(prev => [String(pickedFile.id), ...prev])
                        } else {
                            setSelectedImages(prev => prev.filter(item => item !== String(pickedFile.id)))
                        }
                    }
                }}
                selectionEnabled={true}
                selectedImages={images.filter(a => selectedImages.filter(id => a.id === id).length).map(a=> a.id ?? '')}
            />,
        },
        {
            key: '3',
            label: 'Upload Image',
            children: <MarketingImagePicker
                uploadButtonEnabled={true}
                uploadedFiles={images.filter(a => a.uploaded)}
                isInside
                activity={props.activity}
                isGrid
                type={'marketingplanner'}
                title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                description="Upload .jpg or .png files. Max 25mb."
                hideFileName={true}
                fileChanged={(f: any) => {
                    if (f) {
                        const lastFile: FileProps = {
                            id: f.uploadedId,
                            name: f.name,
                            uploaded: true,
                        };
                        setImages((prevItems) => [
                            lastFile,
                            ...prevItems,
                        ]);
                        if (selectedImages.length < 10) {
                            setSelectedImages(prev => {
                                const temp = [String(lastFile.id), ...prev]
                                /*if(props.setSelectedImages) {
                                    props.setSelectedImages(temp)
                                }*/
                                return temp
                            })
                        }
                    }
                }}
                selectionChanged={(pickedFile) => {
                    if (pickedFile?.id) {
                        if (selectedImages.indexOf(pickedFile.id) === -1 && selectedImages.length < 10) {
                            setSelectedImages(prev => [String(pickedFile.id), ...prev])
                        } else {
                            setSelectedImages(prev => prev.filter(item => item !== String(pickedFile.id)))
                        }
                    }
                }}
                selectionEnabled={true}
                selectedImages={selectedImages.filter(id => !!images.filter(a => a.uploaded).filter(a => a.id === id).length)}
            />,
        },
    ];

    return (
        <div
            className={`${props.setSelectedImages ? 'w-full min-h-[74vh] flex flex-col justify-between' : 'w-6/12'} relative`}>
            {!props.setSelectedImages && (
                <>
                    <div className={'mb-7 ml-2'}>
                        <div className={'text-H4'}>
                            <h2>Add Photos</h2>
                        </div>
                        <div className={'text-BodySmall mt-2'}>
                            Upload visuals to give Evercopy AI a visual foundation for crafting custom marketing
                            materials.
                        </div>
                    </div>
                    <span className={'text-gray text-sm pl-2'}>Select up to 10 photos</span>
                </>
            )}
            <ContentContainer
                ref={props.ref}
                className={`w-full overflow-auto border-none`}
                hoverable={false}
                children={
                    <>
                        <div className={`${props.setSelectedImages ? '' : 'flex flex-col w-full p-[20px]'}`}>
                            {props.isInside ?
                                <Tabs defaultActiveKey="1" items={items} onChange={onChange}/> :
                                <MarketingImagePicker
                                    uploadButtonEnabled={true}
                                    uploadedFiles={images}
                                    isInside
                                    activity={props.activity}
                                    isGrid
                                    type={'marketingplanner'}
                                    title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                                    description="Upload .jpg or .png files. Max 25mb."
                                    hideFileName={true}
                                    fileChanged={(f: any) => {
                                        if (f) {
                                            const lastFile: FileProps = {
                                                id: f.uploadedId,
                                                name: f.name,
                                                uploaded: true,
                                            };
                                            setImages((prevItems) => [
                                                lastFile,
                                                ...prevItems,
                                            ]);
                                            if (selectedImages.length < 10) {
                                                setSelectedImages(prev => {
                                                    const temp = [String(lastFile.id), ...prev]
                                                    /*if(props.setSelectedImages) {
                                                        props.setSelectedImages(temp)
                                                    }*/
                                                    return temp
                                                })
                                            }
                                        }
                                    }}
                                    selectionChanged={(pickedFile) => {
                                        if (pickedFile?.id) {
                                            if (selectedImages.indexOf(pickedFile.id) === -1 && selectedImages.length < 10) {
                                                setSelectedImages(prev => [String(pickedFile.id), ...prev])
                                            } else {
                                                setSelectedImages(prev => prev.filter(item => item !== String(pickedFile.id)))
                                            }
                                        }
                                    }}
                                    selectionEnabled={true}
                                    selectedImages={selectedImages}
                                    selectedFileId={undefined}
                                />
                            }
                        </div>
                    </>
                }
            />
            {!props.setSelectedImages ? (<>
                <div className="flex justify-between mt-3">
                    <PrimaryButton
                        className="h-10 w-full lg:w-[140px]"
                        noFill
                        title={"Back"}
                        onClick={() => {
                            props.onCancel();
                        }}
                    />
                    <div>
                        <PrimaryButton
                            className="h-10 w-full lg:w-[140px] text-red"
                            noFill
                            title={"Skip"}
                            onClick={() => {
                                props.onComplate();
                            }}
                        />
                        <PrimaryButton
                            className="h-10 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                            title={t("Add Photos")}
                            onClick={() => {
                                props.onComplate();
                                if (props.type === 'event') {
                                    dispatch(setEvent({
                                        ...props.event,
                                        visuals: selectedImages
                                    }))
                                } else {
                                    dispatch(setPlan({
                                        ...props.plan,
                                        visuals: selectedImages
                                    }))
                                }
                            }}
                            isDisabled={!selectedImages.length}
                        />
                    </div>
                </div>
            </>) : (
                <div className={'flex justify-end my-2 absolute bottom-1 right-1'}>
                    {selectedImages.length ? (
                        <Button
                            type="default"
                            onClick={() => {
                                setSelectedImages([]);
                                props.setSelectedImages([]);
                            }}
                        >
                            Clear Selection
                        </Button>
                    ) : ''}
                    <Button
                        className={'ml-1'}
                        disabled={!selectedImages.length}
                        type="primary"
                        onClick={() => {
                            console.log(selectedImages)
                            props.setSelectedImages(selectedImages);
                            props.closeSideModal();
                        }}
                    >
                        Add Selected {selectedImages.length ? (`(${selectedImages.length})`) : ''}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default AddPhotos
