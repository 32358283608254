import React, { FunctionComponent, useEffect } from 'react';
import { useLazyUserSettingsQuery } from '../../services/UsertApi';
import { Utils } from '../../helpers/Utils';
import { Progress } from 'antd';

interface CreditLeftCardProps {
    className?: string;
}
const CreditLeftCard: FunctionComponent<CreditLeftCardProps> = ({ className }) => {

    const [getUser, { isLoading: userSettingsLoading, isFetching: userSettingsFetching, data: userSettings }] = useLazyUserSettingsQuery();

    useEffect(() => {
        getUser();
    }, []);


    const creditsLeft =
        (userSettings?.subscription?.credits_available ?? 10000) -
        (userSettings?.subscription?.credits_used ?? 0);

    const percent = (creditsLeft / (userSettings?.subscription?.credits_available ?? 10000)) * 100;
    const renewDate = Utils.formatTimestampToDateString(userSettings?.subscription?.current_period_end_date ?? 0);


    return (
        <div className={`flex flex-col w-full h-[92px] mb-1 bg-[#F4F4F4] rounded-lg p-3 pb-0 ${className}`}>
            <div className="text-BodyMedium font-medium text-black">
                {`${creditsLeft} Credit${creditsLeft === 1 ? '' : 's'} Left`}
            </div>

            {!userSettings?.subscription?.cancels_at && (
                <div className="text-BodySmall text-footnote mt-1">
                    Renews at : {" " + renewDate}
                </div>
            )}

            <Progress percent={percent} size={[184, 7]} strokeColor={"#FF5555"} trailColor={"#ffffff"} showInfo={false} />



        </div>
    );
};

export default CreditLeftCard;
