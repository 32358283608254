import { AudienceResponse } from "../../services/AudiencetApi";
import { Cake, Gender, Location, Translate } from "../../assets/IconComponents"
import SvgCheckboxUnchecked from "../../assets/IconComponents/CheckboxUnchecked";
import SvgCheckboxSelectedIcon from "../../assets/IconComponents/CheckboxSelectedIcon";
import IconButton from "../IconButton";
import SvgEdit from "../../assets/IconComponents/Edit";

export interface IAudienceSelectorItemProps {
    data: AudienceResponse
    selected: boolean
    itemSelected: (data: AudienceResponse) => void
    onItemEdit: (data: AudienceResponse) => void
    editingDisabled?: boolean
}

function AudienceSelectorItem(props: IAudienceSelectorItemProps) {
    const { selected = false } = props

    return (
        <div key={`item_${props.data.audience_id}`} className={`w-full mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] p-2.5 rounded-[5px] border border-stone-200 flex-col justify-start items-start gap-2.5 inline-flex
        ${selected ? " bg-stone-50 border-stone-200 rounded-[5px]" : ""}
        `}
            onClick={(e) => {
                props.itemSelected(props.data)
                e.stopPropagation()
            }}
        >
            <div className="self-stretch justify-between items-center inline-flex">
                <div className="flex flex-row gap-2">
                    <div className="text-black leading-tight">{props.data.name}</div>
                    {!props.editingDisabled &&
                        <IconButton title={""} icon={SvgEdit} onClick={function (e: any): void {
                            props.onItemEdit(props.data)
                            e.stopPropagation()
                        }} />
                    }
                </div>


                {selected &&
                    <SvgCheckboxSelectedIcon />
                }
                {!selected &&
                    <SvgCheckboxUnchecked />
                }

            </div>
        </div >
    );
}

export default AudienceSelectorItem;
