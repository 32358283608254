import * as React from "react";
const SvgBrandHubBrandLogosIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M14.553 20.667a1.988 1.988 0 0 1-1.414-.586l-5.61-5.61A.667.667 0 0 1 7.334 14V8a.667.667 0 0 1 .667-.667h6c.176 0 .346.07.471.196l5.61 5.609a1.998 1.998 0 0 1 0 2.828l-4.115 4.115a1.987 1.987 0 0 1-1.414.586Z"
      opacity={0.5}
    />
    <path fill="#F55" d="M11 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
  </svg>
);
export default SvgBrandHubBrandLogosIcon;
