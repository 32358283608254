const antDTheme = {
  token: {
    colorPrimary: "#FF5555",
    borderRadius: 4,
    colorBgContainer: "#F3F4EF",
    colorBorder: "#EAEBE6",
    fontFamily: "Inter Tight",
    fontWeightStrong: 600,
    // fontSize: 14, // default is 14
    fontSizeIcon: 12,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
  },
  components: {
    Slider: {
      railSize: 8,
      handleColor: "#ff7d70",
      handleActiveColor: "#ff0d00",
      handleSize: 0,
      handleSizeHover: 0,
      handleLineWidth: 0,
      handleLineWidthHover: 0,
    },
    Input: {
      activeBg: "#ffffff",
      addonBg: "#ffffff",
      activeBorderColor: "#ccc",
      hoverBorderColor: "#ccc",
      activeShadow: "#aaa",
    },
    InputNumber: {
      activeBg: "#ffffff",
      addonBg: "#ffffff",
      handleHoverColor: "#ccc",
      activeBorderColor: "#ccc",
      hoverBorderColor: "#ccc",
      activeShadow: "#aaa",
    },
    Modal: {
      colorBgMask: "rgba(0, 0, 0, 0.6)",
    },
    Tabs: {
      titleFontSize: 14,
      fontWeight: 500,
    }
  },
};

export default antDTheme;
