import { useEffect, useState } from "react";
import { PickerModel } from "../models/PickerModel";
import Select, { CSSObjectWithLabel, MenuPlacement, StylesConfig } from 'react-select'
import Icon from "./Icon";
import { Help } from "../assets/IconComponents";
import Warning from "../assets/IconComponents/Warning";

export interface IPickerViewProps {
  title?: string;
  items: PickerModel[];
  onChange: (value: PickerModel[]) => void;
  className?: string;
  selectedValue?: string[];
  allowMultiSelect?: boolean;
  maxSelectionCount?: number;
  error?: string;
  info?: string;
  placeholder?: string;
  smallItems?: boolean;
  optionsOnSide?: boolean;
  getPickerStyles?: (
    smallItems?: boolean,
    optionsOnSide?: boolean,
    isFontPicker?: boolean
  ) => StylesConfig<PickerOptions>;
  hideTitle?: boolean;
  isDisabled?: boolean;
  isFontPicker?: boolean;
  selectContainerClassname?: string;
  icon?: any;
  overrideMenuPlacement?: MenuPlacement;
  useKey?: boolean;
  onSearch?: (val) => void;
}

export interface PickerOptions {
  title?: string;
  label: string;
  value: string;

}

function PickerView(props: IPickerViewProps) {
  const [items, setItems] = useState<PickerOptions[]>([])
  const [selectedLocalItems, setSelectedLocalItems] = useState<PickerModel[]>([])

  useEffect(() => {
    const useKey = props.useKey;
    const itemsToShow = props.items.map(k => { return { label: k.title, value: useKey ? (k.key ?? "") : k.value}; })
    setItems(itemsToShow)
  }, [props.items])


  const getPickerStyles = (optionsOnSide?: boolean, isFontPicker?: boolean): StylesConfig<PickerOptions> => {
    return {
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: "#FAFBF9",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        minHeight: 41,
        borderRadius: 7,
      }  as CSSObjectWithLabel),
      singleValue: (styles, { data }) => ({
        ...styles,
        fontFamily: isFontPicker ? data.label : ""
      } as CSSObjectWithLabel),
      multiValueLabel: (styles) => ({
        ...styles,
        fontSize: 15,
        paddingTop: 8,
        paddingLeft: 10,
      } as CSSObjectWithLabel),
      multiValueRemove: (styles) => ({
        ...styles,
        color: "black",
        padding: 10,
      } as CSSObjectWithLabel),
      indicatorSeparator: (styles) => ({ color: "white" }),
      control: (styles,) => ({
        ...styles,
        backgroundColor: "white",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        borderRadius: 4,
        minHeight: 40,
        fontSize: 15,
        border: "1px solid #EAEBE6",
        width: optionsOnSide ? "150px" : undefined,
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #EAEBE6",
          boxShadow: "none",
        },
      } as CSSObjectWithLabel),
      menu: (styles) => ({
        ...styles,
        borderRadius: 12,
        overflow: "hidden",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        padding: 5,
        paddingTop: 0,
        outline: "none",
        zIndex: 999,
      } as CSSObjectWithLabel),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          padding: 10,
          paddingLeft: props.smallItems ? 5 : 10,
          backgroundColor: isFocused
            ? "#FAFBF9"
            : isSelected
              ? "#FAFBF9"
              : "white",
          borderWidth: isFocused ? 1 : isSelected ? 1 : 0,
          borderColor: isSelected ? "#EAEBE6" : "",
          fontSize: props.smallItems ? 12 : 15,
          borderRadius: 7,
          height: 41,
          color: "#121315",
          cursor: isDisabled ? "not-allowed" : "default",
          fontFamily: isFontPicker ? data.label : "",
          ":active": {
            ...styles[":active"],
            backgroundColor: "#FAFBF9",
          } as CSSObjectWithLabel,
        } as CSSObjectWithLabel;
      },
    };
  };

  return (
    <div className={`flex flex-col ${props.className}`}>
      <div className="w-full">
        <div
          className={`flex ${
            props.optionsOnSide
              ? "flex-row justify-between items-center"
              : "flex-col"
          } w-full`}
        >
          {props.title && (
            <div className="flex flex-row items-center">
              <div className="text-H8 text-black">{props.title}</div>
              {props.icon && (
                <div>
                  <Icon
                    width="15"
                    height="15"
                    component={props.icon}
                    viewBox="0 0 20 20"
                    className="ml-1"
                  />
                </div>
              )}
            </div>
          )}

          <div
            className={`min-h-[40px] bg-white   ${props.title && `mt-2`} ${
              props.selectContainerClassname
            } `}
          >
            <Select
              isDisabled={props.isDisabled}
              placeholder={props.placeholder}
              menuPlacement={
                props.overrideMenuPlacement
                  ? props.overrideMenuPlacement
                  : props.optionsOnSide
                  ? "top"
                  : undefined
              }
              hideSelectedOptions={props.allowMultiSelect != false}
              isMulti={props.allowMultiSelect != false}
              closeMenuOnSelect={props.allowMultiSelect != true}
              value={props.selectedValue?.map(
                (k) => items.find((l) => l.value === k)!
              )}
              options={items}
              styles={
                props.getPickerStyles
                  ? props.getPickerStyles(
                      props.smallItems,
                      props.optionsOnSide,
                      props.isFontPicker
                    )
                  : getPickerStyles(props.optionsOnSide, props.isFontPicker)
              }
              isClearable={false}
              isOptionDisabled={() =>
                props.maxSelectionCount != undefined &&
                (selectedLocalItems ?? []).length >=
                  (props.maxSelectionCount ?? 0)
              }
              onInputChange={props.onSearch}
              //onKeyPr={(e) => console.log(e.target.value)}
              onChange={(e) => {
                if (props.allowMultiSelect != false) {
                  const selectedItems = (e as PickerOptions[]).map(
                    (k) =>
                      props.items.find(
                        (l) => (props.useKey ? (l.key ?? ""): l.value) === (k as PickerOptions).value
                      )!
                  );
                  props.onChange(selectedItems);
                  setSelectedLocalItems(selectedItems);
                } else {
                  props.onChange([e as PickerModel]);
                  setSelectedLocalItems([e as PickerModel]);
                }
              }}
            />
            {props.error && (
              <div className="flex flex-row mt-2 items-start">
                <Warning
                  width="20"
                  height="20"
                  fill="#FF5555"
                  className="mr-2"
                />
                <text className="text-red text-BodySmall">{props.error}</text>
              </div>
            )}
            {props.info && (
              <div className="flex flex-row mt-2 items-center">
                <Help width="20" height="20" fill="#999999" className="mr-2" />
                <text className="text-gray text-BodySmall">{props.info}</text>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PickerView;
