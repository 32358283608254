import * as React from "react";
const SvgBrandFontsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M12 13.333H8a.667.667 0 0 0 0 1.334h1.334V18a.667.667 0 1 0 1.333 0v-3.333h1.334a.667.667 0 0 0 0-1.334Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M20 9.333h-8a.667.667 0 1 0 0 1.334h3.334V18a.667.667 0 1 0 1.333 0v-7.333h3.334a.667.667 0 0 0 0-1.334Z"
    />
  </svg>
);
export default SvgBrandFontsIcon;
