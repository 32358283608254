import moment from "moment";
import CreatePresetContentCard from "./CreatePresetContentCard";
import { useGenerateContentFromRevisionMutation } from "../../services/ContentApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InspirationResponse,
  useLazyGetImageInspirationQuery,
} from "../../services/InspirationApi";
import InspirationsForQuickstartGrid from "./InspirationsForQuickstartGrid";
import { Mediums } from "../../constants/Mediums";
import { ContentType } from "../../constants/ContentType";
import { setNewContent } from "../../store/contentSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../helpers/hooks";
import { ContentWizardView } from "../../models/ContentWizardView";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard, toggleLoading } from "../../store/appSlice";
import { ContentSize, SizeConstants } from "../../constants/ContentSize";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import { Carousel } from "antd";
import { useWindowSize } from "@uidotdev/usehooks";

function ContentBanner() {
  const navigate = useNavigate();
  const [inspirations, setInspirations] = useState<InspirationResponse[]>([]);
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId);
  const newContent = useAppSelector((k) => k.content.newContent);
  const [imageInspirationPage, setImageInspirationPage] = useState<number>(1);
  const size = useWindowSize();

  const [
    generateContentFromRevision,
    {
      isLoading: generatingContentFromRevision,
      isSuccess: successGeneratingContentFromRevision,
    },
  ] = useGenerateContentFromRevisionMutation();

  const [
    getInspirations,
    {
      isLoading: inspirationsLoading,
      isFetching: inspirationsFetching,
      error: errorInspirationsLoading,
      data: inspirationsList,
    },
  ] = useLazyGetImageInspirationQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoading(generatingContentFromRevision));
  }, [generatingContentFromRevision]);

  useEffect(() => {
    if (inspirationsList) {
      if (inspirationsList && inspirationsList.length > 0) {
        setInspirations(inspirationsList);
      }
      if (inspirationsList.length === 0 || inspirationsList.length < PAGE_SIZE) {
        setImageInspirationPage(0);
      }
    }
  }, [inspirationsList]);

  useEffect(() => {
    getInspirations({
      page: 0,
      size: PAGE_SIZE,
    });
  }, []);

  const isMobile = () => {
    return size.width && size.width <= 500;
  };

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching || inspirationsLoading));
  }, [inspirationsLoading, inspirationsFetching])

  const PAGE_SIZE = 10;

  return (
    <div className="flex-col w-full h-full mt-2">
      <div className="flex-col w-[1050px]">
        <Carousel
          slidesToShow={isMobile() ? 1 : 5}
          nextArrow={<CustomRightArrow />}
          prevArrow={<CustomLeftArrow />}
          dots={false}
        >
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.post,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(8),
                  medium: Mediums.find(
                    (k) =>
                      k.isCustom === true && k.contentType === ContentType.image
                  ),
                  imageHeight: SizeConstants[ContentSize.post].height + "",
                  imageWidth: SizeConstants[ContentSize.post].width + "",
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Post"
            sampleImagePath="content-instagram-post-sample.png"
          />

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.landscape,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(9),
                  medium: Mediums.find(
                    (k) =>
                      k.isCustom === true && k.contentType === ContentType.image
                  ),
                  imageHeight: SizeConstants[ContentSize.landscape].height + "",
                  imageWidth: SizeConstants[ContentSize.landscape].width + "",
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Landscape"
            sampleImagePath="content-banner-landscape-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.story,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(10),
                  medium: Mediums.find(
                    (k) =>
                      k.isCustom === true && k.contentType === ContentType.image
                  ),
                  imageHeight: SizeConstants[ContentSize.story].height + "",
                  imageWidth: SizeConstants[ContentSize.story].width + "",
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Story"
            sampleImagePath="content-instagram-story-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  contentSize: ContentSize.mobilemedr,
                  wizardView: ContentWizardView.contentDetails,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(0),
                  medium: Mediums.find(
                    (k) =>
                      k.isCustom === true && k.contentType === ContentType.image
                  ),
                  imageHeight:
                    SizeConstants[ContentSize.mobilemedr].height + "",
                  imageWidth: SizeConstants[ContentSize.mobilemedr].width + "",
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Mobile Medium Rectangle"
            sampleImagePath="content-mobile-medium-rectangle-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  contentSize: ContentSize.mobileleaderb,
                  wizardView: ContentWizardView.contentDetails,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(6),
                  medium: Mediums.find(
                    (k) =>
                      k.isCustom === true && k.contentType === ContentType.image
                  ),
                  imageHeight:
                    SizeConstants[ContentSize.mobileleaderb].height + "",
                  imageWidth:
                    SizeConstants[ContentSize.mobileleaderb].width + "",
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Mobile Leaderboard"
            sampleImagePath="content-mobile-leaderboard-sample.png"
          />
        </Carousel>
      </div>


      {inspirations?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              Inspirations For Quick Start
            </span>
            <hr className="bg-border mt-1" />
          </div>
          <InspirationsForQuickstartGrid
            onClick={(revisionId) => {
              generateContentFromRevision({ revisionId: revisionId })
                .unwrap()
                .then((k) => {
                  const contentId = k.content_id ?? "";
                  navigate(`/app/loopdetails/` + contentId);
                });
            }}
            onRefresh={() => {
              console.log(`fetching ${imageInspirationPage}`)
              getInspirations({
                page: imageInspirationPage,
                size: PAGE_SIZE,
              })
              setImageInspirationPage(imageInspirationPage + 1)
            }}
            inspirations={inspirations}
          />
        </>
      )}
    </div>

  );
}

export default ContentBanner;
