import * as React from "react";
const SvgImageSliderCounterLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M9.533 20.567a2.028 2.028 0 0 1-1.491-.609 2.028 2.028 0 0 1-.609-1.491V9.533c0-.589.203-1.086.609-1.491a2.028 2.028 0 0 1 1.491-.609h8.934c.589 0 1.086.203 1.491.609.406.405.609.902.609 1.491v8.934c0 .589-.203 1.086-.609 1.491a2.029 2.029 0 0 1-1.491.609H9.533Zm1.2-3.034h6.534c.222 0 .378-.094.466-.283a.52.52 0 0 0-.05-.55l-1.766-2.45a.5.5 0 0 0-.417-.225.5.5 0 0 0-.433.208l-1.567 2.1-1.067-1.433a.5.5 0 0 0-.433-.208.5.5 0 0 0-.417.225L10.317 16.7a.52.52 0 0 0-.05.55c.089.189.244.283.466.283Z"
      opacity={0.23}
    />
    <path
      fill="#F55"
      d="M10.733 17.533h6.534c.222 0 .378-.094.466-.283a.52.52 0 0 0-.05-.55l-1.766-2.45a.5.5 0 0 0-.417-.225.5.5 0 0 0-.433.208l-1.567 2.1-1.067-1.433a.5.5 0 0 0-.433-.208.5.5 0 0 0-.417.225L10.317 16.7a.52.52 0 0 0-.05.55c.089.189.244.283.466.283Z"
    />
  </svg>
);
export default SvgImageSliderCounterLogo;
