import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    update: build.mutation<UpdateApiResponse, UpdateApiArg>({
      query: (queryArg) => ({
        url: `/auth/update-password`,
        method: "POST",
        body: queryArg.updatePasswordRequest,
      }),
    }),
    socialLogin: build.mutation<SocialLoginApiResponse, SocialLoginApiArg>({
      query: (queryArg) => ({
        url: `/auth/social-login`,
        method: "POST",
        body: queryArg.socialLoginRequest,
      }),
    }),
    reset: build.mutation<ResetApiResponse, ResetApiArg>({
      query: (queryArg) => ({
        url: `/auth/reset-password`,
        method: "POST",
        body: queryArg.passwordResetRequest,
      }),
    }),
    register: build.mutation<RegisterApiResponse, RegisterApiArg>({
      query: (queryArg) => ({
        url: `/auth/register`,
        method: "POST",
        body: queryArg.registerRequest,
      }),
    }),
    refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
      query: () => ({ url: `/auth/refresh-token`, method: "POST" }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: "POST",
        body: queryArg.authenticationRequest,
      }),
    }),
    forgot: build.mutation<ForgotApiResponse, ForgotApiArg>({
      query: (queryArg) => ({
        url: `/auth/forgot-password`,
        method: "POST",
        body: queryArg.passwordForgotRequest,
      }),
    }),
    verify: build.query<VerifyApiResponse, VerifyApiArg>({
      query: (queryArg) => ({
        url: `/auth/verify`,
        params: { email: queryArg.email },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateApiResponse = /** status 200 OK */ ApiResponse;
export type UpdateApiArg = {
  updatePasswordRequest: UpdatePasswordRequest;
};
export type SocialLoginApiResponse =
  /** status 200 OK */ AuthenticationResponse;
export type SocialLoginApiArg = {
  socialLoginRequest: SocialLoginRequest;
};
export type ResetApiResponse = /** status 200 OK */ ApiResponse;
export type ResetApiArg = {
  passwordResetRequest: PasswordResetRequest;
};
export type RegisterApiResponse = /** status 200 OK */ ApiResponse;
export type RegisterApiArg = {
  registerRequest: RegisterRequest;
};
export type RefreshTokenApiResponse =
  /** status 200 OK */ AuthenticationResponse;
export type RefreshTokenApiArg = void;
export type LoginApiResponse = /** status 200 OK */ AuthenticationResponse;
export type LoginApiArg = {
  authenticationRequest: AuthenticationRequest;
};
export type ForgotApiResponse = /** status 200 OK */ ApiResponse;
export type ForgotApiArg = {
  passwordForgotRequest: PasswordForgotRequest;
};
export type VerifyApiResponse = /** status 200 OK */ ApiResponse;
export type VerifyApiArg = {
  email: string;
};
export type ApiResponse = {
  message?: string;
};
export type UpdatePasswordRequest = {
  old_password: string;
  new_password: string;
  email: string;
};
export type AuthenticationResponse = {
  access_token?: string;
  refresh_token?: string;
  access_token_expire_at?: string;
  refresh_token_expire_at?: string;
  account_status?: string;
};
export type SocialLoginRequest = {
  access_token: string;
  email?: string;
};
export type PasswordResetRequest = {
  token: string;
  password: string;
};
export type RegisterRequest = {
  name_surname?: string;
  email: string;
  password: string;
  captchaToken: string;
};
export type AuthenticationRequest = {
  email: string;
  password: string;
};
export type PasswordForgotRequest = {
  email: string;
};
export const {
  useUpdateMutation,
  useSocialLoginMutation,
  useResetMutation,
  useRegisterMutation,
  useRefreshTokenMutation,
  useLoginMutation,
  useForgotMutation,
  useVerifyQuery,
  useLazyVerifyQuery,
} = injectedRtkApi;
