import * as React from "react";
import type { SVGProps } from "react";
const SvgUploadIconNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} viewBox="0 0 19 18" fill="none" {...props}>
    <path
      d="M5.9 16.2C5.645 16.2 5.43125 16.1137 5.25875 15.9412C5.08625 15.7687 5 15.555 5 15.3C5 15.045 5.08625 14.8312 5.25875 14.6587C5.43125 14.4862 5.645 14.4 5.9 14.4H13.1C13.355 14.4 13.5688 14.4862 13.7413 14.6587C13.9138 14.8312 14 15.045 14 15.3C14 15.555 13.9138 15.7687 13.7413 15.9412C13.5688 16.1137 13.355 16.2 13.1 16.2H5.9ZM9.5 12.6C9.245 12.6 9.03125 12.5137 8.85875 12.3412C8.68625 12.1687 8.6 11.955 8.6 11.7V5.2425L6.89 6.93C6.725 7.095 6.51875 7.18124 6.27125 7.18875C6.02375 7.19624 5.81 7.10999 5.63 6.93C5.465 6.765 5.3825 6.555 5.3825 6.29999C5.3825 6.045 5.465 5.835 5.63 5.67L8.87 2.43C8.96 2.34 9.0575 2.27625 9.1625 2.23875C9.2675 2.20125 9.38 2.1825 9.5 2.1825C9.62 2.1825 9.7325 2.20125 9.8375 2.23875C9.9425 2.27625 10.04 2.34 10.13 2.43L13.37 5.67C13.535 5.835 13.6213 6.04124 13.6288 6.28874C13.6363 6.53624 13.55 6.74999 13.37 6.93C13.205 7.095 12.995 7.1775 12.74 7.1775C12.485 7.1775 12.275 7.095 12.11 6.93L10.4 5.2425V11.7C10.4 11.955 10.3138 12.1687 10.1413 12.3412C9.96875 12.5137 9.755 12.6 9.5 12.6Z"
      fill={`${props.fill ? props.fill : "#FFF"}`}
    />
  </svg>
);
export default SvgUploadIconNew;
