import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    textToSpeech: build.mutation<TextToSpeechApiResponse, TextToSpeechApiArg>({
      query: (queryArg) => ({
        url: `/tools/tts`,
        method: "POST",
        body: queryArg.textToSpeechRequest,
      }),
    }),
    fileToSpeech: build.mutation<FileToSpeechApiResponse, FileToSpeechApiArg>({
      query: (queryArg) => ({
        url: `/tools/fts`,
        method: "POST",
        body: queryArg.fileToSpeechRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type TextToSpeechApiResponse = /** status 200 OK */ TextToSpeechResponse;
export type TextToSpeechApiArg = {
  textToSpeechRequest: TextToSpeechRequest;
};
export type FileToSpeechApiResponse = /** status 200 OK */ TextToSpeechResponse;
export type FileToSpeechApiArg = {
  fileToSpeechRequest: FileToSpeechRequest;
};
export type FileProps = {
  id?: string;
  name?: string;
  uploaded?: boolean;
  summary?: string;
  create_date?: number;
  file_name?: string;
  file_type?: string;
};
export type TextToSpeechResponse = {
  speechFile?: FileProps;
};
export type TextToSpeechRequest = {
  text: string;
  voice: string;
  response_format: string;
};
export type FileToSpeechRequest = {
  file_id: string;
  voice: string;
  response_format: string;
};
export const { useTextToSpeechMutation, useFileToSpeechMutation } =
  injectedRtkApi;
