import { FunctionComponent, useEffect, useState } from "react";
import SvgFacebookColor from "../../../assets/IconComponents/FacebookColor";
import { Select } from "antd";
import IconSelect from "../../../assets/IconComponents/IconSelect";
import { BrandSocialsModel, SocialApiKeyModel, useConnectApiKeyMutation, useDisconnectApiKeysByBrandIdMutation } from "../../../services/AdManagerApi";
import SvgGoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";
import SvgInstagramColor from "../../../assets/IconComponents/InstagramColor";
import SvgLinkedinColor from "../../../assets/IconComponents/LinkedinColor";
import SvgTwitterColor from "../../../assets/IconComponents/TwitterColor";
import PrimaryButton from "../../../components/PrimaryButton";
import BrandHubMetaAdsSettings from "./BrandHubMetaAdsSettings";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";

interface IBrandHubSocialAccountSelectionCardProps {
    brandId: string
    apiType: "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
    | "FACEBOOK_PAGE"
    | "FACEBOOK_AD_ACCOUNT"
    apiTypeSecond?: "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
    | "FACEBOOK_PAGE"
    | "FACEBOOK_AD_ACCOUNT"
    firstAccount?: BrandSocialsModel
    secondAccount?: BrandSocialsModel
    selecteblaApiKeys: SocialApiKeyModel[]
    selecteblaApiKeysSecond?: SocialApiKeyModel[]
}

const BrandHubSocialAccountSelectionCard: FunctionComponent<
    IBrandHubSocialAccountSelectionCardProps
> = (props) => {

    const [mainIcon, setMainIcon] = useState(<></>)
    const [title, setTitle] = useState("")
    const [firstPlaceHolder, setFirstPlaceHolder] = useState("")
    const [secondPlaecHolder, setSecondPlaceHolder] = useState("")
    const [connectApiKey, { }] = useConnectApiKeyMutation()
    // const [firstValueSelected, setFirstValueSelected] = useState(false)
    // const [secondValueSelected, setSecondValueSelected] = useState(false)
    const [noContentWarning, setNoContentWarning] = useState("")
    const [secondNoContentWarning, setSecondNoContentWarning] = useState("")
    const navigate = useNavigate();
    const [disconnectApiKey, { }] = useDisconnectApiKeysByBrandIdMutation();
    const [firstSelectedAccount, setFirstSelectedAccount] = useState<string>();
    const [secondSelectedAccount, setSecondSelectedAccount] = useState<string>();
    const brandInfo = useAppSelector(k => k.brand.newBrand)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (props.firstAccount) { setFirstSelectedAccount(props.firstAccount.apiKeyModel?.id) }
        if (props.secondAccount) { setSecondSelectedAccount(props.secondAccount.apiKeyModel?.id) }
    }, [props])


    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setMainIcon(<SvgFacebookColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.apiType === "GOOGLE_ADS") {
            setMainIcon(<SvgGoogleAdsColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.apiType === "INSTAGRAM") {
            setMainIcon(<SvgInstagramColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.apiType === "LINKEDIN") {
            setMainIcon(<SvgLinkedinColor className="w-[35px] h-[35px] rounded-full" />)
        } else if (props.apiType === "X") {
            setMainIcon(<SvgTwitterColor className="w-[35px] h-[35px] rounded-full" />)
        }
    }, [props])

    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setTitle("Facebook")
        } else if (props.apiType === "GOOGLE_ADS") {
            setTitle("Google Ads")
        } else if (props.apiType === "INSTAGRAM") {
            setTitle("Instagram")
        } else if (props.apiType === "LINKEDIN") {
            setTitle("Linkedin")
        } else if (props.apiType === "X") {
            setTitle("X (Twitter)")
        }
    }, [props])

    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setFirstPlaceHolder("Select a Facebook Page")
        } else if (props.apiType === "GOOGLE_ADS") {
            setFirstPlaceHolder("Select a Google Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setFirstPlaceHolder("Select an Instagram Page")
        } else if (props.apiType === "LINKEDIN") {
            setFirstPlaceHolder("Select a Linkedin Page")
        } else if (props.apiType === "X") {
            setFirstPlaceHolder("Select an X Page")
        }
    }, [props])

    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setNoContentWarning("Facebook Page")
        } else if (props.apiType === "GOOGLE_ADS") {
            setNoContentWarning("Google Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setNoContentWarning("Instagram Page")
        } else if (props.apiType === "LINKEDIN") {
            setNoContentWarning("Linkedin Page")
        } else if (props.apiType === "X") {
            setNoContentWarning("X Page")
        }
    }, [props])


    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setSecondPlaceHolder("Select a Facebook Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setSecondPlaceHolder("Select a Facebook Ad Account")
        }
    }, [props])


    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setSecondNoContentWarning("Facebook Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setSecondNoContentWarning("Facebook Ad Account")
        }
    }, [props])

    return (

        <div className="flex flex-row w-full items-center rounded-[4px] border-1">
            <div className="flex flex-col w-full p-[10px] gap-[12px]">
                <div className="flex flex-row w-full h-[60px] p-[10px] gap-[12px] justify-between items-center">
                    <div className="flex flex-row items-center gap-[10px]">
                        <div className="relative w-[45px]">
                            {mainIcon}
                        </div>
                        <div className="text-black text-H7">{title}</div>

                    </div>
                    <div className="flex flex-row gap-[12px]">
                        {<Select placeholder={firstPlaceHolder} style={{ width: "250px" }} suffixIcon={<IconSelect />}
                            key={props.firstAccount?.apiKeyModel?.id}
                            allowClear
                            onClear={() => {
                                setFirstSelectedAccount(undefined);
                                disconnectApiKey({ brandId: props.brandId, apiKey: secondSelectedAccount ?? "" });
                                if (props.apiType === "FACEBOOK_PAGE") {
                                    console.log(`delete emeta info`)
                                    dispatch(
                                        setNewBrandInfo({
                                            ...brandInfo,
                                            metaAdsSettings: undefined
                                        }))
                                }
                            }}
                            onChange={(a) => {
                                connectApiKey({ brandId: props.brandId, connectApiKeyRequest: { key_id: a, apiType: props.apiType } })
                                setFirstSelectedAccount(a);
                            }}
                            size="large"
                            options={props.selecteblaApiKeys.map(key => {
                                return {
                                    value: key.id,
                                    label: key.name
                                }
                            })}
                            defaultValue={props.firstAccount?.apiKeyModel?.id}
                            notFoundContent={(
                                <div className="flex flex-col items-center p-[20px] gap-[20px]">
                                    <div className="flex flex-col items-center gap-[10px]">
                                        <div className="text-black text-H8 text-[13px] text-center">{`No ${noContentWarning} found`}</div>
                                        <div className="text-black text-[13px] text-center">{`Add a ${noContentWarning} to your account and link with your brand.`}</div>
                                    </div>
                                    <PrimaryButton
                                        className="w-[110px] h-[36px]"
                                        onClick={function (): void {
                                            navigate('/app/settings?view=social_accounts')
                                        }}
                                        title={"Go To Settings"}
                                    ></PrimaryButton>
                                </div>
                            )}
                        />
                        }
                        {(props.apiTypeSecond === "FACEBOOK" || props.apiTypeSecond === "FACEBOOK_AD_ACCOUNT") &&
                            < Select placeholder={secondPlaecHolder} style={{ width: "250px" }} suffixIcon={<IconSelect />}
                                key={props.secondAccount?.apiKeyModel?.id}
                                allowClear
                                onClear={() => {
                                    setSecondSelectedAccount(undefined);
                                    disconnectApiKey({ brandId: props.brandId, apiKey: firstSelectedAccount ?? "" })
                                    if (props.apiTypeSecond === "FACEBOOK_AD_ACCOUNT") {
                                        dispatch(
                                            setNewBrandInfo({
                                                ...brandInfo,
                                                metaAdsSettings: undefined
                                            }))
                                    }
                                }}
                                onChange={(a) => {
                                    connectApiKey({ brandId: props.brandId, connectApiKeyRequest: { key_id: a, apiType: props.apiTypeSecond } })
                                    setSecondSelectedAccount(a)
                                }}
                                size="large"
                                options={props.selecteblaApiKeysSecond?.map(key => {
                                    return {
                                        value: key.id,
                                        label: key.name
                                    }
                                })}
                                defaultValue={props.secondAccount?.apiKeyModel?.id}
                                notFoundContent={(
                                    <div className="flex flex-col items-center p-[20px] gap-[20px]">
                                        <div className="flex flex-col items-center gap-[10px]">
                                            <div className="text-black text-H8 text-[13px] text-center">{`No ${secondNoContentWarning} found`}</div>
                                            <div className="text-black text-[13px] text-center">{`Add a ${secondNoContentWarning} to your account and link with your brand.`}</div>
                                        </div>
                                        <PrimaryButton
                                            className="w-[110px] h-[36px]"
                                            onClick={function (): void {
                                                navigate('/app/settings?view=social_accounts')
                                            }}
                                            title={"Go To Settings"}
                                        ></PrimaryButton>
                                    </div>
                                )}
                            />
                        }
                    </div>
                </div>
                {/* meta ads settings */}
                {
                    (firstSelectedAccount && secondSelectedAccount) &&
                    <BrandHubMetaAdsSettings></BrandHubMetaAdsSettings>
                }
            </div>
        </div>



    )
}

export default BrandHubSocialAccountSelectionCard;

function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
