import { PickerModel } from "../models/PickerModel";

export const TextToSpeechVoices: PickerModel[] = [
  {
    key: "female1",
    title: "Female 1",
    value: "female1",
  },
  {
    key: "female2",
    title: "Female 2",
    value: "female2",
  },
  {
    key: "male1",
    title: "Male 1",
    value: "male1",
  },
  {
    key: "male2",
    title: "Male 2",
    value: "male2",
  },
];
