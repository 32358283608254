import { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

export interface IPrimaryButtonProps {
  title?: string | null;
  onClick: () => void;
  className?: string;
  icon?: JSX.Element;
  iconComponent?: any;
  noFill?: boolean;
  isDisabled?: boolean;
  iconColor?: string;
  strokeColor?: string;
  tooltip?: string;
  size?: SizeType;
  innerClassName?: string;
}
function PrimaryButton(props: IPrimaryButtonProps) {
  const {
    title = undefined,
    icon,
    noFill,
    isDisabled = false,
    size = "large",
  } = props;
  const [iconComponentToShow, setIconComponentToShow] = useState<any>();

  useEffect(() => {
    setIconComponentToShow(props.iconComponent);
  }, [props.iconComponent]);

  return (
    <>
      <Tooltip title={props.tooltip}>
        <Button
          type={noFill ? "default" : "primary"}
          disabled={isDisabled}
          size={size}
          className={`${props.className}`}
          icon={icon}

          onClick={(e) => {
            e.stopPropagation();
            props.onClick();
          }}
        >
          {
            props.iconComponent &&
            <div className={`flex items-center ${props.innerClassName ?? ""}`}>
              {
                iconComponentToShow
              }
            </div>
          }
          {title}
        </Button>
      </Tooltip>
    </>
  );
}

export default PrimaryButton;
