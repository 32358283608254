import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import PageLoading from "../../components/PageLoading";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import "./AppLayoutStyles.scss"
import { useEffect } from "react";
import Dialog from "../../components/Dialog";
import TopMenu from "../../components/TopMenu/TopMenu";
import { Tooltip } from "react-tooltip";
import { setSharedContentId, toggleLoading } from "../../store/appSlice";
import { setUserSettings } from "../../store/userSettingsSlice";
import { useShareContentMutation } from "../../services/ContentApi";
import { useWindowSize } from "@uidotdev/usehooks";
import LeftMenuAlternative from "../../components/LeftMenu/LeftMenuAlternative";
import { useLazyUserSettingsQuery } from "../../services/UsertApi";
import ContentWizard from "../../pages/NewContentWizard/ContentWizard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NotificationsSidebar from "../../pages/Notifications/NotificationsSidebar";
import Sidebar from "../../components/LeftMenu/Sidebar";
import SidebarAlternative from "../../components/LeftMenu/SidebarAlternative";

const AppLayout = () => {
  const isLoading = useAppSelector(state => state.app.isLoading)
  const isLoggedIn = useAppSelector(state => state.persisted.isLoggedIn)
  const dialog = useAppSelector(state => state.app.dialog)
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  let params = useParams()
  const size = useWindowSize();
  const sharedContentId = useAppSelector(k => k.app.sharedContentId)
  const [shareContent, { isSuccess: contentShared, isError: contentShareError }] = useShareContentMutation()
  const [getUser, { isLoading: userSettingsFetching, data: userSettings }] =
    useLazyUserSettingsQuery();
  const contentWizardActive = useAppSelector(state => state.app.contentWizardActive)
  const notificationsSidebarActive = useAppSelector(
    (state) => state.app.notificationsSidebarActive
  );

  const isMobile = () => {
    return size.width && size.width <= 500;
  };
  useEffect(() => {
    if (params?.shared && params.shared == "shared") {
      console.log("1");
      navigate("/register")
    } else if (!isLoggedIn) {
      console.log("2");
      navigate("/login")
    }
  }, [isLoggedIn])

  useEffect(() => {
    dispatch(toggleLoading(userSettingsFetching));
  }, [userSettingsFetching]);


  useEffect(() => {
    if (isLoggedIn) {
      getUser().then((userResponse) => {
        if (userResponse.data) {
          dispatch(setUserSettings(userResponse.data));
        }
        if (userResponse.data?.subscription) {
        } else {
          // navigate("/app/onboarding");
        }
      });
    }

  }, [userSettings]);


  useEffect(() => {
    if (params?.shared && params.shared == "shared") {
      if (!isLoggedIn)
        dispatch(setSharedContentId(params.contentId))
      else {
        dispatch(setSharedContentId(params.contentId))
        shareContent({
          contentId: params?.contentId ?? ""
        })
      }
    }

  }, [params?.shared])

  useEffect(() => {
    if (sharedContentId !== undefined && sharedContentId !== "") {
      shareContent({
        contentId: sharedContentId
      })
    }
  }, [])

  useEffect(() => {
    if (contentShared || contentShareError) {
      dispatch(setSharedContentId(undefined))
      navigate("/app/loopdetails/" + sharedContentId)
    }
  }, [contentShared, contentShareError])

  return (
    <>
      <GoogleOAuthProvider clientId="926828575719-j5ni3lsnan4bihahsdvovpa9h400j6pp.apps.googleusercontent.com">

        <Dialog
          title={dialog?.title ?? ""}
          message={dialog?.message ?? ""}
          actionButtons={dialog?.actions}
          onClose={dialog?.onClose}
          customTemplate={dialog?.customTemplate}
          customZIndex={dialog?.customZIndex}
        />
        <PageLoading isShowing={isLoading} />
        <Tooltip
          className="!w-[250px] rounded-md z-[2001]"
          place="bottom"
          id="app-tooltip"
        />
        <div className="flex h-full w-full flex-col min-h-screen">
          <div className="flex flex-1 !p-0 bg-bg justify-center">
            <div className="flex flex-1">
              {isMobile() && <Sidebar />}
              {!isMobile() && <SidebarAlternative />}
              {contentWizardActive && <ContentWizard />}
              {notificationsSidebarActive && <NotificationsSidebar />}
              <div className="flex flex-1 flex-col">
                <TopMenu />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </GoogleOAuthProvider>
    </>
  );
}

export default AppLayout;
