import { FunctionComponent, useEffect, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { useRef } from "react";
import borderSvg from "../../../assets/icons/dashed-border.svg";
import borderSvgRed from "../../../assets/icons/dashed-border-red.svg";
import { FileProps } from "../../../services/BrandApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import {
  useDeleteFileDeleteByIdMutation,
  useLazyDownloadQuery,
  useUploadMutation,
} from "../../../services/FileApi";
import { toggleLoading } from "../../../store/appSlice";
import SvgUploadIcon from "../../../assets/IconComponents/UploadIcon";
import ContentImage from "../../../components/ContentImage";
import { newContent, setNewContent } from "../../../store/contentSlice";
import { ContentWizardStep } from "../../../models/ContentWizardStep";
import { ContentWizardView } from "../../../models/ContentWizardView";

interface IChooseAnAspirationOrThemeProps {
  title: string;
  description: string;
  className?: string;
  maxFileCount?: number;
  acceptedFileTypes?: Accept;
  maxFileSize?: number;
  disabled?: boolean;
  uploadButtonEnabled?: boolean;
  fileChanged: (filesIds: File) => void;
  selectionChanged?: (selectedFiles: FileProps) => void;
  uploadedFiles?: FileProps[];
  selectionEnabled: boolean;
  isGrid?: boolean;
  selectedFileId?: string;
  isAspiration?: boolean;
  type?: string;
  setGuidelinesState?: (state: number) => void;
  onRefresh?: (currentPage: number) => void;
  currentPage?: number;
  isInSide?: boolean;
}

const ChooseAnAspirationOrTheme: FunctionComponent<
  IChooseAnAspirationOrThemeProps
> = (props: IChooseAnAspirationOrThemeProps) => {
  const newContent = useAppSelector((k) => k.content.newContent);
  const refreshInputRef = useRef<HTMLInputElement | null>(null);
  const buttonClickRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const sidebarRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [sidebarHeight, setSidebarHeight] = useState(500);
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const [errorIndex, setErrorIndex] = useState(-1);

  useEffect(() => {
    console.log("New COntent: ", newContent?.wizardView);
    if((newContent?.wizardView ?? ContentWizardView.blogDetails) == ContentWizardView.inspoLibrary) {

    }
    const updateHeight = () => {
      console.log("Window Inner H is: ", window.innerHeight);
      // Subtract any known heights from other elements if necessary
      const calculatedHeight = window.innerHeight - 160; // Example: 50px for header/footer
       console.log("calculated height: ", calculatedHeight);
      setSidebarHeight(calculatedHeight);
    };


    // Calculate on mount
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Cleanup event listener
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    const sidebarElement = sidebarRef.current;
    if (!sidebarElement) return;

    const handleScroll = () => {
      // Your scroll handling logic here
    };

    sidebarElement.addEventListener("scroll", handleScroll);

    return () => sidebarElement.removeEventListener("scroll", handleScroll);
  }, [sidebarHeight]);


  useEffect(() => {
    if (props.uploadedFiles && props.uploadedFiles.length > 0) {
      Promise.all(
        props.uploadedFiles?.map((uploadedFile, index) =>
          downloadFileFrom(uploadedFile)
        )
      ).then((k) => {
        setFiles(k);
      });
    } else {
      setFiles(props.uploadedFiles || []);
    }
  }, [props.uploadedFiles]);

  const handleScroll = () => {
    const sidebar = sidebarRef.current;
    console.log("Inner width is: ", window.innerHeight);
    if (sidebar) {
      console.log(
        "Handle SCrol scrollHeight: ",
        sidebar.scrollHeight,
        "scrollTop: ",
        sidebar.scrollTop,
        "clientTop: ",
        sidebar.clientHeight
      );
      const bottom =
        sidebar.scrollHeight - sidebar.scrollTop <= sidebar.clientHeight ;
      if (bottom) {
        loadMoreRef.current?.click();
      }
    }
  };

  useEffect(() => {
    setSelectedImage(
      props.type === "theme"
        ? newContent?.creative_theme ?? ""
        : props.type === "inspiration" && newContent?.adId
        ? newContent?.adId
        : newContent?.creativeImageId ?? ""
    );
  }, [props.type, props.uploadedFiles]);

  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
        setErrorIndex(-1);
      }, 3000);
    }
  }, [error]);

  const downloadFileFrom = async (param: FileProps) => {
    const fileId = props.type === "theme" ? param.imageId : param.id;
    const name = props.type === "theme" ? param.subChildConcept : param.name;
    return new Promise<any>((resolve) => {
      if (fileId) {
        downloadFile({
          id: fileId,
        })
          .unwrap()
          .then(async (k) => {
            const item = {
              adId: param.adId,
              uploadedId: fileId,
              id: param.id,
              name: name,
              type: "other",
              preview: await blobToDataURL(k.file),
            };

            return resolve(item);
          })
          .catch((e) => {
            console.log("error", e);
          });
      }
    });
  };

  const handleRefreshButtonClick = (index: number) => {
    buttonClickRef.current?.click();
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: props.maxFileSize ?? 25000000,
    maxFiles: 1,
    accept: props.acceptedFileTypes ?? {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (rejectedFiles.length > 0) {
        let message = rejectedFiles[0].errors[0].message;
        setError(message);
        return;
      }

      selectFile(files);
    },
    onFileDialogOpen() {
      console.log("file dialog");
      setError("");
    },
  });

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const selectFile = async (files: any[]) => {
    if (selectedFiles.length < (props.maxFileCount ?? 1242151)) {
      dispatch(toggleLoading(true));
      let selectedFile = files[0];
      let a = (await fileToBlob(selectedFile)) as string;
      let blob = dataURLToBlob(a);
      const formData: FormData = new FormData();
      formData.append("files", blob, selectedFile.name);
      uploadFiles({
        body: formData,
      })
        .unwrap()
        .then((k) => {
          selectedFile["uploadedId"] = k.ids ?? "";
          selectedFile.isUploaded = true;
          props.fileChanged(selectedFile);
          dispatch(toggleLoading(false));
          if (props.type === "inspiration") {
            dispatch(
              setNewContent({
                ...newContent,
                adId: undefined,
                creative_theme: undefined,
                creativeImageId: selectedFile.uploadedId,
              })
            );
          }
        });
    } else {
      setError(
        `Selected files should be maximum ${props.maxFileCount ?? 2} items`
      );
    }
  };

  const thumbs = selectedFiles.map((file, index) => (
    <>
      {file ? (
        <div
          key={file.name}
          style={{
            backgroundSize: "cover", // Adjust the sizing as needed
            backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
            borderRadius: "6px",
          }}
          className={`${props.isGrid ? "flex  flex-col justify-center items-center gap-[10px] flex-1" : `relative flex mt-[8px] mr-[5px] cursor-pointer rounded-[8px] h-[180px] w-[121px] border-1 border-border justify-center`} ${
            selectedImage === file.adId || selectedImage === file.id
              ? "border-1 border-red"
              : ""
          }`}
          onClick={() => {
            if (props.type === "theme") {
              setSelectedImage(file.id);
            } else if (props.type === "inspiration" && !file.adId) {
              setSelectedImage(file.id);
            } else {
              setSelectedImage(file.adId);
            }
            if (props.selectionEnabled) {
              if (props.selectionChanged) {
                props.selectionChanged(file);
              }
            }
          }}
        >
          <div className="flex-col mt-2 items-center">
            {((props.type === "inspiration" && file.adId) ||
              props.type !== "inspiration") && (
              <>
                <ContentImage
                  title={file.name}
                  id={file.uploadedId ?? ""}
                  className={props.isGrid ? `flex h-[176px] flex-col items-start gap-[10px] self-stretch object-contain` : "py-1 h-[140px] w-[140px] bg-bg w-full rounded-[10px] object-contain"}
                />
              </>
            )}
            {props.type === "inspiration" && !file.adId && (
              <img
                title={file.name}
                src={file.preview}
                alt={file.name}
                style={{
                  marginTop: "",
                  width: `105px`,
                  height: `105px`,
                  borderRadius: 10,
                  backgroundColor: "rgb(234 235 230)",
                }}
                className="object-contain"
              />
            )}
              {(file.name != file.id) &&
                  <text className="text-BodySmall mt-1 pl-1 text-black w-[100px] inline-block truncate text-ellipsis overflow-hidden">
                      {file.name}
                  </text>
              }

          </div>
        </div>
      ) : <></>}
    </>
  ));

  return (
    <>
      <div
        className={`relative flex flex-col w-full  ${props.className}`}
        ref={sidebarRef}
        onScroll={handleScroll}
        style={{
          height: `${sidebarHeight}px`,
          display: "flex",
          overflow: "auto",
        }}
      >
        <div
          className={`${
            props.isGrid ?? false ? "grid grid-cols-4 gap-1" : "flex flex-row"
          } ${props.isInSide ? 'flex-wrap' : ''}`}
        >
          {props.uploadButtonEnabled && (
            <>
              {error && (
                <div
                  key={`upload_with_error`}
                  style={{
                    backgroundImage: `url(${borderSvgRed})`, // Set the SVG file as the background image
                    backgroundSize: "cover", // Adjust the sizing as needed
                    backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                  }}
                  className={`flex mr-2 cursor-pointer flex-col justify-center items-center ${props.isGrid ? "h-[220px]" : "h-[180px]"} w-[121px] rounded-[8px] mt-2 items`}
                  onClick={() => {}}
                >
                  <input {...getInputProps()} ref={buttonClickRef} />
                  <SvgUploadIcon fill="#FF5555" />
                  <p className="text-BodySmall text-red mt-2 text-center">
                    {props.description}
                  </p>
                </div>
              )}
              {!error && (
                <div
                  key={`upload_without_error`}
                  style={{
                    backgroundImage: `url(${borderSvg})`, // Set the SVG file as the background image
                    backgroundSize: "cover", // Adjust the sizing as needed
                    backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                  }}
                  className={`flex mr-2 cursor-pointer flex-col justify-center items-center ${props.isGrid ? "h-[220px]" : "h-[180px] w-1/4"} ${props.isInSide ? 'h-[149px] w-[121px] border-1 border-dashed mr-0 mt-0' : ''} rounded-[8px] mt-2 items`}
                  onClick={() => {
                    setErrorIndex(0);
                    handleRefreshButtonClick(-1);
                  }}
                >
                  <input {...getInputProps()} ref={buttonClickRef} />
                  <SvgUploadIcon />
                  <p className="text-BodySmall text-gray mt-2 text-center">
                    {props.description}
                  </p>
                </div>
              )}
            </>
          )}
          {thumbs}
        </div>
      </div>
      {props.onRefresh && selectedFiles?.length && (
        <div className={"flex justify-center mt-4"} style={{ display: "none" }}>
          <div
            ref={loadMoreRef}
            className={`flex flex-col items-center  mt-2 justify-center w-[88px] h-[33px] bg-slightRed rounded-[100px] cursor-pointer`}
            onClick={() =>
              props.currentPage !== undefined && props.onRefresh
                ? props.onRefresh(props.currentPage + 1)
                : null
            }
          >
            <text className="text-BodySmall text-red">Load More</text>
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseAnAspirationOrTheme;
