import Icon from "./Icon";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Close } from "../assets/IconComponents";
import Warning from "../assets/IconComponents/Warning";

export interface IWarningMessageProps {
    title: string
    className?: string
    show?: boolean
    onClose?: () => void
}

function WarningMessage(props: IWarningMessageProps) {
    const { title, className, show, onClose } = props

    return (
        <>
            {show &&
                <div 
                className={`flex flex-row p-3 h-12 border-red border-1 rounded-xl justify-between ${className}`}
                style={{backgroundColor: "#FF55550D"}}>
                    <div className="flex flex-row items-center">
                        <Warning width="20" height="20" fill="#FF5555" />
                        <text className="text-red text-H6 ml-3">{t('warning')}</text>
                        <text className="text-red text-BodyMedium ml-2">{title}</text>
                    </div>
                    <button onClick={() => { if(onClose) onClose() }} className="">
                        <Close width="20" height="20" color="#FF5555" />
                    </button>
                </div>
            }
        </>

    );
}

export default WarningMessage;