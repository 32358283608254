import React, { useEffect, useState } from 'react'

interface IToggleButtonProps {
    isChecked: boolean
    handleCheckBoxChange: (boolean) => void,
    checkedBoxClassName: string,
    checkedButtonClassName: string,
    uncheckedBoxClassName: string,
    uncheckedButtonClassName: string,
    tooltip?: string
}

const ToggleButton = (props: IToggleButtonProps) => {
    const [isChecked, setIsChecked] = useState(false)
    const [classBox, setClassbox] = useState<string>(`box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral`)
    const [classButton, setClassButton] = useState<string>(`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition`)

    const handleCheckboxChange = () => {
        const checkNew = !isChecked
        setIsChecked(checkNew)
        if (checkNew) {
            setClassbox(props.checkedBoxClassName)
            setClassButton(props.checkedButtonClassName)
        }
        else {
            setClassbox(props.uncheckedBoxClassName)
            setClassButton(props.uncheckedButtonClassName)
        }
        props.handleCheckBoxChange(checkNew)
    }

    useEffect(() => {
        setIsChecked(props.isChecked)
        if (props.isChecked) {
            setClassbox(props.checkedBoxClassName)
            setClassButton(props.checkedButtonClassName)
        }
        else {
            setClassbox(props.uncheckedBoxClassName)
            setClassButton(props.uncheckedButtonClassName)
        }
    }, [props.isChecked])

    return (
        <>
            <label className='flex cursor-pointer select-none items-center'>
                <div className='relative'
                    data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
                    data-tooltip-html={props.tooltip}>
                    <input
                        type='checkbox'
                        checked={props.isChecked}
                        onChange={handleCheckboxChange}
                        className='sr-only'
                    />
                    <div
                        className={classBox}
                    ></div>
                    <div
                        className={classButton}
                    ></div>
                </div>
            </label>
        </>
    )
}

export default ToggleButton
