import * as React from "react";
import type { SVGProps } from "react";
const SvgEyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#999"
      d="M10.002 13.125c.985 0 1.82-.345 2.508-1.034.688-.689 1.032-1.526 1.032-2.51 0-.985-.345-1.82-1.034-2.508-.69-.688-1.526-1.031-2.51-1.031-.985 0-1.82.344-2.508 1.033-.688.69-1.032 1.526-1.032 2.51 0 .985.345 1.821 1.034 2.509.69.687 1.526 1.031 2.51 1.031Zm-.004-1.397a2.063 2.063 0 0 1-1.519-.626 2.074 2.074 0 0 1-.624-1.52c0-.597.209-1.103.626-1.52a2.074 2.074 0 0 1 1.521-.624c.596 0 1.103.21 1.519.627.416.417.624.924.624 1.52 0 .597-.209 1.103-.626 1.52a2.074 2.074 0 0 1-1.521.623ZM10 16.104c-2.09 0-3.979-.604-5.665-1.811-1.686-1.208-2.943-2.778-3.772-4.71.829-1.931 2.086-3.501 3.772-4.709C6.021 3.666 7.91 3.062 10 3.062c2.09 0 3.979.604 5.665 1.812 1.686 1.208 2.943 2.778 3.773 4.71-.83 1.931-2.087 3.5-3.773 4.709C13.979 15.5 12.09 16.104 10 16.104Zm-.002-1.52a8.33 8.33 0 0 0 4.622-1.365 8.874 8.874 0 0 0 3.21-3.636 8.88 8.88 0 0 0-3.208-3.635 8.318 8.318 0 0 0-4.62-1.365A8.33 8.33 0 0 0 5.38 5.948 9.023 9.023 0 0 0 2.15 9.583a9.03 9.03 0 0 0 3.229 3.636 8.318 8.318 0 0 0 4.62 1.364Z"
    />
  </svg>
);
export default SvgEyeIcon;
