import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRevisions: build.query<GetRevisionsApiResponse, GetRevisionsApiArg>({
      query: (queryArg) => ({
        url: `/community_content/revision`,
        params: {
          platform: queryArg.platform,
          imageWidth: queryArg.imageWidth,
          imageHeight: queryArg.imageHeight,
        },
      }),
    }),
    saveRevision: build.mutation<SaveRevisionApiResponse, SaveRevisionApiArg>({
      query: (queryArg) => ({
        url: `/community_content/revision`,
        method: "POST",
        body: queryArg.saveCommunityContentRequest,
      }),
    }),
    deleteRevision: build.mutation<
      DeleteRevisionApiResponse,
      DeleteRevisionApiArg
    >({
      query: (queryArg) => ({
        url: `/community_content/revision/${queryArg.revisionId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetRevisionsApiResponse =
  /** status 200 OK */ SaveCommunityContentResponse[];
export type GetRevisionsApiArg = {
  platform?: string;
  imageWidth?: number;
  imageHeight?: number;
};
export type SaveRevisionApiResponse =
  /** status 200 OK */ SaveCommunityContentResponse;
export type SaveRevisionApiArg = {
  saveCommunityContentRequest: SaveCommunityContentRequest;
};
export type DeleteRevisionApiResponse = unknown;
export type DeleteRevisionApiArg = {
  revisionId: string;
};
export type SaveCommunityContentResponse = {
  type?: "image" | "text";
  platform?: string;
  size?: string;
  content_id?: string;
  revision_id?: string;
  generated_content?: string;
  filters?: string;
};
export type SaveCommunityContentRequest = {
  revisionId?: string;
};
export const {
  useGetRevisionsQuery,
  useLazyGetRevisionsQuery,
  useSaveRevisionMutation,
  useDeleteRevisionMutation,
} = injectedRtkApi;
