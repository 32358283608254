import { FunctionComponent, useEffect, useState } from "react";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import { Accept, useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading } from "../../store/appSlice";
import { useDeleteFileDeleteByIdMutation, useLazyDownloadQuery, useUploadMutation } from "../../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { FileProps } from "../../services/BrandApi";
import { useRemoveBackgroundMutation } from "../../services/ImageopsApi";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";
import { Refresh } from "../../assets/IconComponents";
import { useRef } from "react";
import borderSvg from "../../assets/icons/dashed-border.svg";
import borderSvgRed from "../../assets/icons/dashed-border-red.svg";
import { useAppSelector } from "../../helpers/hooks";
import SvgCheckers from "../../assets/IconComponents/Checkers";

interface UploadFileProps {
  title: string
  description: string
  className?: string
  maxFileCount?: number
  acceptedFileTypes?: Accept
  maxFileSize?: number
  disabled?: boolean
  filesChanged: (filesIds: File[]) => void
  uploadedFiles?: FileProps[]
}

const UploadFile: FunctionComponent<UploadFileProps> = (props: UploadFileProps) => {
  const refreshInputRef = useRef<HTMLInputElement | null>(null);
  const buttonClickRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("")
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const [fileToRefresh, setFileToRefresh] = useState<number>(-1);
  const [fileIndexToRemoveBackground, setFileIndexToRemoveBackground] = useState<number>(-1)
  const dispatch = useAppDispatch()
  const [uploadFiles, { data: uploadedFileData, isSuccess: filesUploaded, isLoading: filesUploading }] = useUploadMutation()
  const [deleteFile, { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting }] = useDeleteFileDeleteByIdMutation()
  const [downloadFile, { data: fileDownloaddData, isSuccess: fileDownloaded, isLoading: fileDownloading, currentData: dt }] = useLazyDownloadQuery()
  const [removeBackground, { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress }] = useRemoveBackgroundMutation()

  const [errorIndex, setErrorIndex] = useState(-1);

  useEffect(() => {
    if (props.uploadedFiles && props.uploadedFiles.length > 0) {

      Promise.all(props.uploadedFiles?.map(k => downloadFileFrom(k.id ?? "")))
        .then(k => {
          setFiles(k)
        })
    }
  }, [props.uploadedFiles])

  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
        setErrorIndex(-1);
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    console.log(`background removed ${backgroundRemovedImage?.backgroundRemovedFile?.id}`)
    if (backgroundRemovedImage?.backgroundRemovedFile?.id) {
      if (fileIndexToRemoveBackground > -1) {
        const backgroundRemovedFile = backgroundRemovedImage?.backgroundRemovedFile
        Promise.all([downloadFileFrom(backgroundRemovedFile.id ?? "")]).then(fileProps => {
          console.log(`background removed downloaded ${fileProps[0].uploadedId}`)
          let selectedFilesCopy = [...selectedFiles];
          selectedFilesCopy[fileIndexToRemoveBackground] = fileProps[0];
          setFiles(selectedFilesCopy);
          setFileIndexToRemoveBackground(-1)
          props.filesChanged(selectedFilesCopy)
        })
      }
    }
  }, [backgroundRemovedImage]);

  useEffect(() => {
    dispatch(toggleLoading(backgroundRemovalInProgress));
  }, [backgroundRemovalInProgress])

  const downloadFileFrom = async (fileId: string) => {
    return new Promise<any>((resolve) => {
      downloadFile({
        id: fileId
      })
        .unwrap()
        .then(async k => {

          const item = {
            uploadedId: fileId,
            name: k.name,
            type: k.file.type.includes("ima") ? "image" : "other",
            preview: await blobToDataURL(k.file)
          }

          return resolve(item)
        })
        .catch(e => {

          console.log('error', e)
        })
    })
  }

  const handleRefreshButtonClick = (index: number) => {
    if (refreshInputRef.current) {
      console.log("first");
      if (index !== -1) {
        setFileToRefresh(index);
      }
      refreshInputRef.current.click();
    }
    else {
      if (buttonClickRef.current) {
        buttonClickRef.current?.click();
      }
    }
  };



  const downloadFileToDesktop = (uploadId: string) => {
    downloadFile({
      id: uploadId
    })
      .unwrap()
      .then(async k => {
        const dataUrl = await blobToDataURL(k.file)

        var link = document.createElement("a");
        link.download = k.name;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  }

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: props.maxFileSize ?? 25000000,
    maxFiles: 1,
    accept: props.acceptedFileTypes ?? {
      'image/png': ['.png', '.jpg', '.jpeg'],
      "image/svg+xml": ['.svg']
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const files = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))

      if (rejectedFiles.length > 0) {
        let message = rejectedFiles[0].errors[0].message
        setError(message)
        return
      }

      selectFile(files, fileToRefresh);
    },
    onFileDialogOpen() {
      console.log("file dialog");
      setError("")
    },
  });

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result)
        }
        else {
          reject(Error("Failed converting to base64"))
        }
      }
    })
  }

  const selectFile = async (files: any[], index: number) => {
    if (selectedFiles.length < 2 || index !== -1) {

      dispatch(toggleLoading(true))

      let selectedFile = files[0]
      let a = await fileToBlob(selectedFile) as string
      let blob = dataURLToBlob(a)
      const formData: FormData = new FormData();
      formData.append('files', blob, selectedFile.name);
      uploadFiles({
        body: formData
      })
        .unwrap()
        .then(k => {
          selectedFile["uploadedId"] = k.ids ?? ""
          console.log("success", k);
          if (index !== -1) {
            const toBeDeleted = selectedFiles[index];

            setFiles([
              ...selectedFiles.filter(
                (k) => k.uploadedId !== toBeDeleted.uploadedId
              ),
              selectedFile
            ]);
            props.filesChanged([
              ...selectedFiles.filter(
                (k) => k.uploadedId !== toBeDeleted.uploadedId
              ),
              selectedFile,
            ]);
          }
          else {

            setFiles((selectedFiles) => [
              ...selectedFiles,
              selectedFile,
            ]);
            props.filesChanged([...selectedFiles, selectedFile]);

          }

          dispatch(toggleLoading(false))
          setFileToRefresh(-1);
        })
    } else {
      setError(`Selected files should be maximum ${props.maxFileCount ?? 2} items`)
    }
  }

  const thumbs = selectedFiles.map((file, index) => (
    <>
      {file && (
        <div
          key={file.name}
          style={{
            backgroundImage: `url(${borderSvg})`, // Set the SVG file as the background image
            backgroundSize: "cover", // Adjust the sizing as needed
            backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
          }}
          className="relative flex mr-2 mt-2 cursor-pointerrounded-[8px] h-[180px] min-w-[180px] justify-center"
          onClick={() => { }}
        >
          <div className="flex flex-row h-10 absolute -bottom-3 m-3">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} ref={refreshInputRef} />
              <PrimaryButton
                icon={<Refresh fill={"#121315"} />}
                noFill
                className=" h-[30px] w-[30px]  bg-bg border-1 border-border "
                isDisabled={false}
                tooltip="Change Visual"
                onClick={() => {
                  handleRefreshButtonClick(index);
                }}
              />
            </div>

            <PrimaryButton
              icon={<Delete fill={"#121315"} />}
              noFill
              className=" ml-[3px] h-[30px] w-[30px]  bg-bg border-1 border-border "
              isDisabled={false}
              tooltip="Delete Visual"
              onClick={() => {
                //e.stopPropagation();
                const id = file.uploadedId;

                setFiles(
                  selectedFiles.filter(
                    (k) => k.uploadedId !== file.uploadedId
                  )
                );
                props.filesChanged(
                  selectedFiles.filter(
                    (k) => k.uploadedId !== file.uploadedId
                  )
                );
                deleteFile({
                  id: id,
                });
              }}
            />

            <PrimaryButton
              icon={<SvgCheckers fill={"#121315"} />}
              noFill
              className=" ml-[3px] h-[30px] w-[30px]  bg-bg border-1 border-border "
              isDisabled={false}
              tooltip="Remove Background"
              onClick={() => {
                //e.stopPropagation();
                const id = file.uploadedId;
                setFileIndexToRemoveBackground(index)
                removeBackground({ removeBackgroundRequest: { imageFileId: id } });
              }}
            />
          </div>

          <img
            src={file.preview}
            alt={file.name}
            style={{
              marginTop: "10px",
              width: "120px",
              height: "120px",
              borderRadius: 10,
              backgroundColor: "rgb(234 235 230)",
            }}
            className="object-contain"
          />
        </div>
      )}
    </>
  ));

  return (
    <>
      <div
        className={`relative flex flex-col mt-[20px] md:w-1/2 ${props.disabled ? "p-3" : ""
          } ${props.className}`}
        style={{ pointerEvents: props.disabled ? "none" : "all" }}
      >
        {props.disabled && (
          <div
            className="absolute left-0 top-0 right-0 bottom-0 bg-black/5 z-50 rounded-[8px]"
            style={{ pointerEvents: "none" }}
          ></div>
        )}
        <p className="text-H8 text-black">{props.title}</p>
        <div className="flex flex-row">
          <aside className="flex flex-row ">{thumbs}</aside>
          {selectedFiles.length < 2 && (
            <>
              {[...Array(2 - selectedFiles.length)].map((_, index) => (
                <>
                  {error && index == errorIndex && (
                    <div
                      style={{
                        backgroundImage: `url(${borderSvgRed})`, // Set the SVG file as the background image
                        backgroundSize: "cover", // Adjust the sizing as needed
                        backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                      }}
                      className={`flex ${index != 0 ? "max-md:ml-2" : ""
                        } mr-2 cursor-pointer flex-col justify-center items-center h-[180px] md:w-[180px] rounded-[8px] mt-2 items`}
                      onClick={() => { }}
                    >
                      <input {...getInputProps()} ref={buttonClickRef} />
                      <SvgUploadIcon fill="#FF5555" />
                      <p className="text-BodySmall text-red mt-2 text-center">
                        {props.description}
                      </p>
                    </div>
                  )}
                  {(!error || index != errorIndex) && (
                    <div
                      style={{
                        backgroundImage: `url(${borderSvg})`, // Set the SVG file as the background image
                        backgroundSize: "cover", // Adjust the sizing as needed
                        backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                      }}
                      className={`flex ${index != 0 ? "max-md:ml-2" : ""
                        } mr-2 cursor-pointer flex-col justify-center items-center h-[180px] md:w-[180px] rounded-[8px] mt-2 items`}
                      onClick={() => {
                        setErrorIndex(index);
                        handleRefreshButtonClick(-1);
                      }}
                    >
                      <input {...getInputProps()} ref={buttonClickRef} />
                      <SvgUploadIcon />
                      <p className="text-BodySmall text-gray mt-2 text-center">
                        {props.description}
                      </p>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default UploadFile;