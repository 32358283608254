import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandHubProductPhotosIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <rect
      x="7"
      y="7"
      width="14"
      height="14"
      fill="url(#pattern_YAAADimHc4AAAACXBIWXMA)"
    />
    <defs>
      <pattern
        id="pattern_YAAADimHc4AAAACXBIWXMA"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_6505_28668" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0_6505_28668"
        width="96"
        height="96"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD4ElEQVR4nO1cy04UQRRlZ8AvMLgFP8CAMS5wScJsBupUU4AJG3SBLmSr8A2G+A3qEtwp4BowkYVfoLhTFjI+khmTa7qnBiPOOEO/qrr7nOQkJDOTps65desxd+7QEEEQBEEQBEEQBEEQBEEQBEEQBEEQBEF0gdRqIxIE4zI3d1uAGVEKAphSU0VjnInGHI69VhvJPTgEGBatJ0SpeeeCwLkh86L1LVlaupyP+PX61UpEOi5MJcBotuID1xj1+P9sCILxLCOfKQcDpKS0Z0K02DLtmAumo+H0DJibu+FBjjUF42Sa0c/UgwsaEGqWxhY12uv2e1gQ3BfgQICfAkgfhu85FGDNgyjNlsaMJTegfcjqJ35jAOHPsyHAqnORsuVUGgbU+jzkIIb4He57IFKWnEluQP/dzyBppxd/eCBSdlQKyQ3o/6AkBnxPZaBaLwqwIVq/sOvLJ1HqmwC/Irb/Po5ea79nXYJgIQ8T8jDgMIEBB4kGGK4/Sr0U4CTGs8PPbGe9DuVhwFqCRfhhzIi/J8CeAK0E5nfYEqV2JAjuFtUAY6No3+b0QfL+QWzxlXoa0/BBAmKzqAbkwSUB3mQg/Hnu2mfRAPkjwrJofZSD+B2Gz1rmDICN/HzFb1Op9wLcYQpCLmmnlwk71TZARQuuOOZmVQ1YEaVOPTCgkWSLWmQD9jwQv8PXVTNgNaVDVloMD2sPqmOAiq4XxDNuV8OAILoki3O3kzVP7KVfyQ0ANjwQuzu1flx+A3R0XSye8nn5DUCi6+2suV8FA449ELo7lfpYBQMaHhtwWgUDWs6F7s0mDQANYApCuVPQsQepptKL8KFzoSu9DdUeH8S0flZ+A4B150L3YhA8qsZlnFJfnIv9Lz/HqaYrngGIuO2B4Oe5FWcsRTVg1bMDWTNuCWNRDTC2IkE84au44yiuAeCX8q4NMLYkxHX0P0kyhiLPAGO5W8TUUx4DtF4Upd46EP9dGkW6xTcAEcNC2aOcxWdxrvxtwlJOO6Mw7bA8XXrPhs1MShaV+pp0wS1zCjLnuGLLBZspiN+0Ub+Sxf9aVgOMZXg63Y51d9T+zFYZfqTnnkGwYIumwrqdffvFScNeZ7Ts3x+i18Ir5fBWszA/U2WbGpPAAJVHqwISWbYq6NesgzTZNusYpF0NabpqMDubQrsaNmwyThs2RSYAk4xyXMwArSdSEd8aMGwb0THdwEHTvsiEev0Ke8dhsNSTVQPXaEFmAz/jpHHrmQnAKNMR3LQuPjNhevqSANc5G9BJOTdTz/kDb1GNGatk+3pgKtznO2lfTxAEQRAEQRAEQRAEQQwVEb8BcZHch54JL1AAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);
export default SvgBrandHubProductPhotosIcon;
