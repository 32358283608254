import * as React from "react";
import type { SVGProps } from "react";
const SvgBookThumbnailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.1875 1.875C2.877 1.875 2.625 2.12663 2.625 2.4375V3H2.0625C1.752 3 1.5 3.252 1.5 3.5625V15.1875C1.5 15.498 1.752 15.75 2.0625 15.75H15.9375C16.248 15.75 16.5 15.498 16.5 15.1875V3.5625C16.5 3.252 16.248 3 15.9375 3H15.375V2.4375C15.375 2.12663 15.123 1.875 14.8125 1.875H11.25C10.3294 1.875 9.51338 2.32182 9 3.00732C8.48663 2.32182 7.67062 1.875 6.75 1.875H3.1875ZM3.75 3H6.75C7.68038 3 8.4375 3.75713 8.4375 4.6875V12.9375C8.4375 13.2484 8.6895 13.5 9 13.5C9.3105 13.5 9.5625 13.2484 9.5625 12.9375V4.6875C9.5625 3.75713 10.3196 3 11.25 3H14.25V13.5H11.25C10.3403 13.5 9.519 13.9407 9 14.6367C8.481 13.9407 7.65975 13.5 6.75 13.5H3.75V3ZM11.8125 5.25C11.6136 5.25 11.4228 5.32902 11.2822 5.46967C11.1415 5.61032 11.0625 5.80109 11.0625 6C11.0625 6.19891 11.1415 6.38968 11.2822 6.53033C11.4228 6.67098 11.6136 6.75 11.8125 6.75C12.0114 6.75 12.2022 6.67098 12.3428 6.53033C12.4835 6.38968 12.5625 6.19891 12.5625 6C12.5625 5.80109 12.4835 5.61032 12.3428 5.46967C12.2022 5.32902 12.0114 5.25 11.8125 5.25ZM11.8037 7.49194C11.6547 7.49427 11.5126 7.55567 11.4088 7.66265C11.305 7.76963 11.2479 7.91344 11.25 8.0625V11.0625C11.2489 11.137 11.2627 11.211 11.2905 11.2802C11.3183 11.3494 11.3596 11.4123 11.4119 11.4654C11.4642 11.5185 11.5266 11.5607 11.5954 11.5894C11.6642 11.6182 11.738 11.633 11.8125 11.633C11.887 11.633 11.9608 11.6182 12.0296 11.5894C12.0984 11.5607 12.1608 11.5185 12.2131 11.4654C12.2654 11.4123 12.3067 11.3494 12.3345 11.2802C12.3623 11.211 12.3761 11.137 12.375 11.0625V8.0625C12.3761 7.98721 12.362 7.91247 12.3337 7.84272C12.3054 7.77296 12.2633 7.7096 12.21 7.65639C12.1568 7.60318 12.0933 7.56121 12.0235 7.53296C11.9538 7.50472 11.879 7.49077 11.8037 7.49194Z"
      fill="#8E8E8E"
    />
  </svg>
);
export default SvgBookThumbnailIcon;
