import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    save: build.mutation<SaveApiResponse, SaveApiArg>({
      query: (queryArg) => ({
        url: `/subject`,
        method: "POST",
        body: queryArg.subject,
      }),
    }),
    findById: build.query<FindByIdApiResponse, FindByIdApiArg>({
      query: (queryArg) => ({ url: `/subject/${queryArg.id}` }),
    }),
    deleteById: build.mutation<DeleteByIdApiResponse, DeleteByIdApiArg>({
      query: (queryArg) => ({
        url: `/subject/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    surpriseMe: build.query<SurpriseMeApiResponse, SurpriseMeApiArg>({
      query: (queryArg) => ({
        url: `/subject/surprise-me`,
        params: { keyword: queryArg.keyword, brand_id: queryArg.brandId },
      }),
    }),
    list: build.query<ListApiResponse, ListApiArg>({
      query: () => ({ url: `/subject/list` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type SaveApiResponse = /** status 200 OK */ Subject;
export type SaveApiArg = {
  subject: Subject;
};
export type FindByIdApiResponse = /** status 200 OK */ Subject;
export type FindByIdApiArg = {
  id: string;
};
export type DeleteByIdApiResponse = unknown;
export type DeleteByIdApiArg = {
  id: string;
};
export type SurpriseMeApiResponse = /** status 200 OK */ Subject;
export type SurpriseMeApiArg = {
  keyword?: string;
  brandId?: string;
  _noCache: number;
};
export type ListApiResponse = /** status 200 OK */ Subject[];
export type ListApiArg = void;
export type Subject = {
  id?: string;
  keyword: string;
  value: string;
};
export const {
  useSaveMutation,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useDeleteByIdMutation,
  useSurpriseMeQuery,
  useLazySurpriseMeQuery,
  useListQuery,
  useLazyListQuery,
} = injectedRtkApi;
