import { FunctionComponent, useEffect, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { useRef } from "react";
import borderSvg from "../../assets/icons/dashed-border.svg";
import borderSvgRed from "../../assets/icons/dashed-border-red.svg";
import { FileProps } from "../../services/BrandApi";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import ContentImage from "../ContentImage";
import {toggleLoading} from "../../store/appSlice";
import {useDeleteFileDeleteByIdMutation, useLazyDownloadQuery, useUploadMutation} from "../../services/FileApi";
import {useAppDispatch, useAppSelector} from "../../helpers/hooks";

interface IMarketingImagePicker {
  title: string;
  description: string;
  className?: string;
  maxFileCount?: number;
  acceptedFileTypes?: Accept;
  maxFileSize?: number;
  disabled?: boolean;
  uploadButtonEnabled?: boolean;
  fileChanged: (filesIds: File) => void;
  selectionChanged?: (selectedFiles: FileProps) => void;
  uploadedFiles?: FileProps[];
  selectionEnabled: boolean;
  isGrid?: boolean
  selectedFileId?: string;
  isAspiration?: boolean;
  type?: string;
  setGuidelinesState?: (state: number) => void;
  onRefresh?: (currentPage: number) => void;
  currentPage?: number;
  selectedImages?: string[];
  hideFileName?: boolean;
  isInside?: boolean;
  activity?: boolean;
}

const MarketingImagePicker: FunctionComponent<
  IMarketingImagePicker
> = (props: IMarketingImagePicker) => {
  const newContent = useAppSelector((k) => k.content.newContent);
  const buttonClickRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const [errorIndex, setErrorIndex] = useState(-1);

  useEffect(() => {
    if (props.uploadedFiles && props.uploadedFiles.length > 0) {
      Promise.all(
        props.uploadedFiles?.map((uploadedFile, index) => downloadFileFrom(uploadedFile))
      ).then((k) => {
        setFiles(k);
      });
    } else {
      setFiles(props.uploadedFiles || []);
    }

  }, [props.uploadedFiles]);


  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
        setErrorIndex(-1);
      }, 3000);
    }
  }, [error]);

  const downloadFileFrom = async (param: FileProps) => {
    console.log(param)
    const fileId = props.type === 'theme' ? param.imageId : param.id;
    const name = props.type === 'theme' ? param.subChildConcept : param.name;
    return new Promise<any>((resolve) => {
      if (fileId) {
        downloadFile({
          id: fileId,
        })
          .unwrap()
          .then(async (k) => {
            const item = {
              adId: param.adId,
              uploadedId: fileId,
              id: param.id,
              name: name,
              type: "other",
              preview: await blobToDataURL(k.file),
            };

            return resolve(item);
          })
          .catch((e) => {
            console.log("error", e);
          });

      }
    });
  };

  const handleRefreshButtonClick = (index: number) => {
    buttonClickRef.current?.click();
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: props.maxFileSize ?? 25000000,
    maxFiles: 1,
    accept: props.acceptedFileTypes ?? {
      "image/png": [".png", ".jpg", ".jpeg"]
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (rejectedFiles.length > 0) {
        let message = rejectedFiles[0].errors[0].message;
        setError(message);
        return;
      }

      selectFile(files);
    },
    onFileDialogOpen() {
      console.log("file dialog");
      setError("");
    },
  });

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const selectFile = async (files: any[]) => {
    if (
      selectedFiles.length < (props.maxFileCount ?? 1242151)
    ) {
      dispatch(toggleLoading(true));
      let selectedFile = files[0];
      let a = (await fileToBlob(selectedFile)) as string;
      let blob = dataURLToBlob(a);
      const formData: FormData = new FormData();
      formData.append("files", blob, selectedFile.name);
      uploadFiles({
        body: formData,
      })
        .unwrap()
        .then((k) => {
          selectedFile["uploadedId"] = k.ids ?? "";
          selectedFile.isUploaded = true;
          props.fileChanged(selectedFile)
          dispatch(toggleLoading(false));
        });
    } else {
      setError(
        `Selected files should be maximum ${props.maxFileCount ?? 2} items`
      );
    }
  };

  const thumbs = selectedFiles.map((file, index) => (
    <>
      {file && (
        <div
          key={file.name}
          style={{
            backgroundSize: "cover", // Adjust the sizing as needed
            backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
          }}
          className={`relative flex mt-[8px] mr-[5px] cursor-pointer rounded-[8px] min-h-[125px] max-h-[143px] min-w-[110px] max-w-[135px] border-1 border-border justify-center ${props.selectedImages?.indexOf(file.id) !== -1 ? 'border-1 border-red' : ''
            }`}
          onClick={() => {
            if (props.selectionEnabled) {
              if (props.selectionChanged) {
                props.selectionChanged(file)
              }
            }
          }}
        >
          <div className={`${props.type === 'favouriteImages' ? 'flex-col items-center ' : 'flex-col mt-2 items-center'}  rounded-[10px]`}>
            {((props.type === "inspiration" && file.adId) || (props.type !== "inspiration" && props.type !== "marketingplanner")) && (
                <>
                  <ContentImage
                      title={file.name}
                      id={file.uploadedId ?? ""}
                      className={`${props.type === 'favouriteImages' ? 'object-contain w-full p-2' : 'py-1 h-[105px] w-[105px] bg-bg w-full rounded-[10px] object-contain'}`}
                  />
                </>
            )}
            {props.type === "inspiration" && !file.adId && (
                <img
                    title={file.name}
                    src={file.preview}
                    alt={file.name}
                    style={{
                      marginTop: "",
                      width: `105px`,
                      height: `105px`,
                      borderRadius: 10,
                      backgroundColor: "rgb(234 235 230)",
                    }}
                    className="object-contain"
                />
            )}
            {props.type === "marketingplanner" && !file.adId && (
                <img
                    title={file.name}
                    src={file.preview}
                    alt={file.name}
                    style={{
                      marginTop: "",
                      minWidth: `105px`,
                      minHeight: `105px`,
                      maxWidth: `110px`,
                      maxHeight: `110px`,
                      borderRadius: 10,
                      backgroundColor: "rgb(234 235 230)",
                    }}
                    className="object-contain"
                />
            )}

            {!props.hideFileName &&
                <text
                    className="text-BodySmall text-black w-[100px] inline-block truncate text-ellipsis overflow-hidden">
                {file.name}
            </text>
            }
          </div>
        </div>
      )}
    </>
  ));


  return (
      <>
        <div
            className={`relative flex flex-col w-full overflow-auto ${props.disabled ? "p-3" : ""
            } ${props.className}`}
            style={{pointerEvents: "all"}}
        >
          <div
              className={`${props.isGrid ?? false ? `${props.isInside && !props.activity ? 'grid grid-cols-4 w-full max-h-[50vh]': 'grid grid-cols-3 w-full max-h-[50vh]'}` : "flex flex-row overflow-auto"
              }`}
          >
            {props.uploadButtonEnabled && (
                <>
                  {error && (
                      <div
                          key={`upload_with_error`}
                          style={{
                            backgroundImage: `url(${borderSvgRed})`, // Set the SVG file as the background image
                            backgroundSize: "cover", // Adjust the sizing as needed
                            backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                          }}
                          className={`flex mr-2 cursor-pointer flex-col justify-center items-center min-h-[125px] max-h-[143px] min-w-[110px] max-w-[135px] rounded-[8px] mt-2 items`}
                          onClick={() => {
                          }}
                      >
                        <input {...getInputProps()} ref={buttonClickRef}/>
                        <SvgUploadIcon fill="#FF5555"/>
                        <p className="text-BodySmall text-red mt-2 text-center">
                          {props.description}
                        </p>
                      </div>
                  )}
                  {!error && (
                      <div
                          key={`upload_without_error`}
                          className={`flex mr-2 cursor-pointer flex-col justify-center items-center min-h-[125px] max-h-[143px] min-w-[110px] max-w-[135px] rounded-[8px] mt-2 items border-dashed border-1`}
                          onClick={() => {
                            setErrorIndex(0);
                            handleRefreshButtonClick(-1);
                          }}
                      >
                        <input {...getInputProps()} ref={buttonClickRef}/>
                        <SvgUploadIcon/>
                        <p className="text-BodySmall text-gray mt-2 text-center">
                          {props.description}
                        </p>
                      </div>
                  )}
                </>
            )}
            {thumbs}
          </div>
        </div>
        {props.onRefresh && selectedFiles?.length && (
            <div className={'flex justify-center mt-4'}>
              <div
                  className={`flex flex-col items-center  mt-2 justify-center w-[88px] h-[33px] bg-slightRed rounded-[100px] cursor-pointer`}
                  onClick={() => props.currentPage !== undefined && props.onRefresh ? props.onRefresh(props.currentPage + 1) : null}
              >
                <text className="text-BodySmall text-red">Load More</text>
              </div>
            </div>)}
      </>
  );
};

export default MarketingImagePicker;
