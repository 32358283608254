import * as React from "react";
import type { SVGProps } from "react";
const SvgFork = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M9.988 2.083a.833.833 0 0 0-.577.244L7.327 4.411a.833.833 0 1 0 1.179 1.178l.66-.66v12.154a.834.834 0 1 0 1.667 0V4.928l.661.661a.834.834 0 1 0 1.178-1.178L10.59 2.327a.833.833 0 0 0-.601-.244ZM4.154 4.167a.833.833 0 0 0-.577.244L1.494 6.494a.833.833 0 1 0 1.179 1.179l.66-.661V8.75A4.18 4.18 0 0 0 7.5 12.917a.836.836 0 0 0 .845-.834.833.833 0 0 0-.845-.833c-1.39 0-2.5-1.11-2.5-2.5V7.012l.66.66a.833.833 0 1 0 1.18-1.178L4.755 4.411a.834.834 0 0 0-.602-.244Zm11.667 0a.834.834 0 0 0-.577.244l-2.083 2.083a.833.833 0 1 0 1.178 1.179L15 7.012V8.75c0 1.39-1.11 2.5-2.5 2.5a.832.832 0 0 0-.597 1.427.834.834 0 0 0 .597.24 4.18 4.18 0 0 0 4.167-4.167V7.012l.66.66a.832.832 0 0 0 1.367-.907.833.833 0 0 0-.188-.27L16.422 4.41a.833.833 0 0 0-.6-.244Z"
    />
  </svg>
);
export default SvgFork;
