import * as React from "react";
import type { SVGProps } from "react";
const SvgGenerateSimilarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#FFB9B9"
      d="M15.083 17.708a.37.37 0 0 1-.166-.041.263.263 0 0 1-.125-.146l-.604-1.354-1.5-.646a.278.278 0 0 1-.146-.115.351.351 0 0 1-.042-.177c0-.07.014-.128.042-.177a.278.278 0 0 1 .146-.114l1.5-.646.604-1.438a.278.278 0 0 1 .114-.146.351.351 0 0 1 .177-.041c.07 0 .129.014.177.041a.279.279 0 0 1 .115.146l.604 1.438 1.5.646c.07.027.118.066.146.114a.351.351 0 0 1 .042.177c0 .07-.014.129-.042.177a.278.278 0 0 1-.146.115l-1.5.646-.604 1.354a.263.263 0 0 1-.125.146.37.37 0 0 1-.167.041ZM15.083 7.292a.352.352 0 0 1-.177-.042.279.279 0 0 1-.114-.146l-.604-1.417-1.521-.666a.279.279 0 0 1-.146-.115.351.351 0 0 1-.042-.177c0-.07.014-.128.042-.177a.279.279 0 0 1 .146-.114l1.52-.646.605-1.354a.279.279 0 0 1 .114-.146.352.352 0 0 1 .177-.042c.07 0 .129.014.177.042a.279.279 0 0 1 .115.146l.604 1.354 1.521.646c.07.027.118.065.146.114a.351.351 0 0 1 .041.177c0 .07-.013.129-.041.177a.279.279 0 0 1-.146.115l-1.52.667-.605 1.416a.279.279 0 0 1-.115.146.352.352 0 0 1-.177.042Z"
    />
    <path
      fill="#F55"
      d="M6.937 15.104a.62.62 0 0 1-.322-.094.62.62 0 0 1-.24-.26l-1.354-2.896-2.917-1.312a.62.62 0 0 1-.26-.24.62.62 0 0 1-.094-.323.62.62 0 0 1 .094-.323.62.62 0 0 1 .26-.24l2.917-1.312L6.375 5.23a.62.62 0 0 1 .563-.375.62.62 0 0 1 .322.094.62.62 0 0 1 .24.26l1.375 2.896 2.896 1.313a.62.62 0 0 1 .375.563.62.62 0 0 1-.375.562l-2.896 1.312L7.5 14.75a.543.543 0 0 1-.24.27.677.677 0 0 1-.323.084Z"
    />
  </svg>
);
export default SvgGenerateSimilarIcon;
