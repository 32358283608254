import * as React from "react";
const AppPromotionIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.2"
              d="M18.6666 25.6666H9.33325C8.40528 25.6657 7.51558 25.2966 6.8594 24.6404C6.20322 23.9843 5.83418 23.0946 5.83325 22.1666V5.83325C5.83418 4.90528 6.20322 4.01558 6.8594 3.3594C7.51558 2.70323 8.40528 2.33418 9.33325 2.33325H18.6666C19.5946 2.33418 20.4843 2.70323 21.1404 3.3594C21.7966 4.01558 22.1657 4.90528 22.1666 5.83325V22.1666C22.1657 23.0946 21.7966 23.9843 21.1404 24.6404C20.4843 25.2966 19.5946 25.6657 18.6666 25.6666Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M13.9998 23.3324C13.6156 23.3364 13.2366 23.2444 12.8969 23.0648C12.5572 22.8852 12.2678 22.6238 12.0546 22.3041C11.8415 21.9845 11.7115 21.6167 11.6764 21.2341C11.6413 20.8515 11.7022 20.4662 11.8536 20.1131C11.9717 19.8303 12.1374 19.5699 12.3435 19.3432C12.6186 19.0723 12.9561 18.8732 13.3263 18.7633C13.6965 18.6535 14.088 18.6363 14.4664 18.7133C14.6124 18.7393 14.7539 18.7865 14.8863 18.8533C15.0352 18.9038 15.1765 18.9745 15.3062 19.0633L15.6562 19.3432C15.8668 19.5664 16.0331 19.8277 16.1461 20.1131C16.3279 20.5373 16.3784 21.0064 16.291 21.4596C16.2037 21.9129 15.9826 22.3295 15.6562 22.6559C15.4242 22.8614 15.1652 23.0341 14.8863 23.1691C14.6044 23.2813 14.3032 23.3368 13.9998 23.3324Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.25"
              d="M13 9.33675H9.5C9.43433 9.33676 9.36931 9.3267 9.30864 9.30715C9.24797 9.2876 9.19284 9.25893 9.14641 9.22279C9.09998 9.18665 9.06315 9.14375 9.03803 9.09652C9.0129 9.0493 8.99998 8.99869 9 8.94758V5.05591C8.99998 5.0048 9.0129 4.95419 9.03803 4.90697C9.06315 4.85975 9.09998 4.81684 9.14641 4.7807C9.19284 4.74456 9.24797 4.7159 9.30864 4.69634C9.36931 4.67679 9.43433 4.66673 9.5 4.66675H13C13.0657 4.66673 13.1307 4.67679 13.1914 4.69634C13.252 4.7159 13.3072 4.74456 13.3536 4.7807C13.4 4.81684 13.4369 4.85975 13.462 4.90697C13.4871 4.95419 13.5 5.0048 13.5 5.05591V8.94758C13.5 8.99869 13.4871 9.0493 13.462 9.09652C13.4369 9.14375 13.4 9.18665 13.3536 9.22279C13.3072 9.25893 13.252 9.2876 13.1914 9.30715C13.1307 9.3267 13.0657 9.33676 13 9.33675Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.25"
              d="M13 15.3367H9.5C9.43433 15.3368 9.36931 15.3267 9.30864 15.3072C9.24797 15.2876 9.19284 15.2589 9.14641 15.2228C9.09998 15.1867 9.06315 15.1437 9.03803 15.0965C9.0129 15.0493 8.99998 14.9987 9 14.9476V11.0559C8.99998 11.0048 9.0129 10.9542 9.03803 10.907C9.06315 10.8597 9.09998 10.8168 9.14641 10.7807C9.19284 10.7446 9.24797 10.7159 9.30864 10.6963C9.36931 10.6768 9.43433 10.6667 9.5 10.6667H13C13.0657 10.6667 13.1307 10.6768 13.1914 10.6963C13.252 10.7159 13.3072 10.7446 13.3536 10.7807C13.4 10.8168 13.4369 10.8597 13.462 10.907C13.4871 10.9542 13.5 11.0048 13.5 11.0559V14.9476C13.5 14.9987 13.4871 15.0493 13.462 15.0965C13.4369 15.1437 13.4 15.1867 13.3536 15.2228C13.3072 15.2589 13.252 15.2876 13.1914 15.3072C13.1307 15.3267 13.0657 15.3368 13 15.3367Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M18.5 9.33341H15C14.9343 9.33343 14.8693 9.31836 14.8086 9.28905C14.748 9.25974 14.6928 9.21678 14.6464 9.1626C14.6 9.10843 14.5631 9.04412 14.538 8.97334C14.5129 8.90255 14.5 8.82669 14.5 8.75008V5.25008C14.5 5.17347 14.5129 5.09761 14.538 5.02683C14.5631 4.95604 14.6 4.89173 14.6464 4.83756C14.6928 4.78339 14.748 4.74042 14.8086 4.71111C14.8693 4.6818 14.9343 4.66673 15 4.66675H18.5C18.5657 4.66673 18.6307 4.6818 18.6914 4.71111C18.752 4.74042 18.8072 4.78339 18.8536 4.83756C18.9 4.89173 18.9369 4.95604 18.962 5.02683C18.9871 5.09761 19 5.17347 19 5.25008V8.75008C19 8.82669 18.9871 8.90255 18.962 8.97334C18.9369 9.04412 18.9 9.10843 18.8536 9.1626C18.8072 9.21678 18.752 9.25974 18.6914 9.28905C18.6307 9.31836 18.5657 9.33343 18.5 9.33341Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.25"
              d="M18.5 15.3334H15C14.9343 15.3334 14.8693 15.3184 14.8086 15.2891C14.748 15.2597 14.6928 15.2168 14.6464 15.1626C14.6 15.1084 14.5631 15.0441 14.538 14.9733C14.5129 14.9026 14.5 14.8267 14.5 14.7501V11.2501C14.5 11.1735 14.5129 11.0976 14.538 11.0268C14.5631 10.956 14.6 10.8917 14.6464 10.8376C14.6928 10.7834 14.748 10.7404 14.8086 10.7111C14.8693 10.6818 14.9343 10.6667 15 10.6667H18.5C18.5657 10.6667 18.6307 10.6818 18.6914 10.7111C18.752 10.7404 18.8072 10.7834 18.8536 10.8376C18.9 10.8917 18.9369 10.956 18.962 11.0268C18.9871 11.0976 19 11.1735 19 11.2501V14.7501C19 14.8267 18.9871 14.9026 18.962 14.9733C18.9369 15.0441 18.9 15.1084 18.8536 15.1626C18.8072 15.2168 18.752 15.2597 18.6914 15.2891C18.6307 15.3184 18.5657 15.3334 18.5 15.3334Z"
              fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default AppPromotionIcon;
