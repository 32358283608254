import {BookFilled, FacebookFilled, InstagramFilled, LinkedinFilled,
    MailFilled, TwitterSquareFilled} from "@ant-design/icons";
import {Button} from "antd";
import { BlogColor, FacebookColor, InstagramColor, LinkedinColor, TwitterColor } from "../../../../assets/IconComponents";
import NewsLetterColor from "../../../../assets/IconComponents/NewsLetterColor";
import {useEffect, useState} from "react";
import {ActivityType} from "../../AIActivityModal";

const channelList = [
    {name: 'Facebook',   label: 'Facebook',   colorIcon: <FacebookColor />, key: "FACEBOOK"},
    {name: 'X (Twitter)',label: 'X (Twitter)',colorIcon: <TwitterColor />, key: "X"},
    {name: 'Instagram',  label: 'Instagram',  colorIcon: <InstagramColor />, key: "INSTAGRAM"},
    {name: 'Linkedin',   label: 'Linkedin',   colorIcon: <LinkedinColor />, key: "LINKEDIN"},
    {name: 'Blog',       label: 'Blog',       colorIcon: <BlogColor />, key: "BLOG"},
    {name: 'NewsLetter', label: 'NewsLetter', colorIcon: <NewsLetterColor />, key: "NEWSLETTER", disabled: true},

]

const ChannelList = (props) => {
    const [channels, setChannels] = useState(channelList);
    useEffect(() => {
        if (props.activity.postCategory == ActivityType.PaidMeta) {
            setChannels([
                {name: 'Facebook',   label: 'Facebook',   colorIcon: <FacebookColor />, key: "FACEBOOK"},
                {name: 'Instagram',  label: 'Instagram',  colorIcon: <InstagramColor />, key: "INSTAGRAM"}
                ]);
        }
    }, []);
    return (
        <div className={'w-full h-full mt-3'}>
            {channels.map(channel => (
                <Button disabled={channel.disabled} className={`w-full text-start mt-1 h-[40px] items-center flex flex-row ${props.selectedChannels.filter(ch => ch.key === channel.key).length ? 'bg-red text-white':''}`} icon={channel.colorIcon} onClick={() => {
                    if (!channel.disabled) {
                        props.setSelectedChannel(channel)
                    }
                }}>
                    {channel.name}
                </Button>
            ))}
        </div>
    )
}

export default ChannelList;
