import * as React from "react";
import type { SVGProps } from "react";
const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path

      d="M10 2.083c-3.212 0-5.833 2.622-5.833 5.834v3.821l-.661-.66a.833.833 0 1 0-1.179 1.178l2.01 2.01a.832.832 0 0 0 1.33-.004l2.006-2.006a.834.834 0 1 0-1.179-1.178l-.66.66V7.917A4.154 4.154 0 0 1 10 3.75c.91 0 1.742.292 2.43.787a.833.833 0 1 0 .974-1.352A5.814 5.814 0 0 0 10 2.083Zm4.988 3.322a.834.834 0 0 0-.652.33l-2.008 2.01a.833.833 0 1 0 1.178 1.178l.66-.661v3.821A4.154 4.154 0 0 1 10 16.25c-.91 0-1.742-.292-2.43-.787a.833.833 0 1 0-.974 1.352A5.814 5.814 0 0 0 10 17.917c3.212 0 5.833-2.622 5.833-5.834V8.262l.661.66a.834.834 0 1 0 1.178-1.178l-2.011-2.012a.834.834 0 0 0-.673-.327Z"
    />
  </svg>
);
export default SvgRefresh;
