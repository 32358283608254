import * as React from "react";
import type { SVGProps } from "react";
const SvgMediaRoundBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32} height={32} rx={16} fill = {props.fill}/>
    <path
      d="M22.3079 16.9507C22.0108 16.7328 21.8333 16.3828 21.8333 16.014C21.8333 15.6453 22.0108 15.2953 22.3075 15.0778L23.68 14.0715C23.8988 13.9107 23.9892 13.6278 23.9038 13.3699C23.5504 12.3082 22.9917 11.3399 22.2425 10.4911C22.0621 10.2878 21.7713 10.2236 21.5225 10.3328L19.9771 11.0124C19.6404 11.1607 19.2483 11.1395 18.9288 10.9549C18.6096 10.7707 18.3954 10.4424 18.355 10.0761L18.1692 8.37945C18.1396 8.10945 17.9388 7.88945 17.6729 7.83487C16.5908 7.61362 15.4538 7.60987 14.3525 7.8307C14.085 7.88445 13.8838 8.10445 13.8542 8.3757L13.67 10.0615C13.6296 10.4282 13.4154 10.7565 13.0954 10.9407C12.7763 11.1249 12.3854 11.147 12.0475 10.9982L10.4938 10.3149C10.2471 10.2057 9.95543 10.269 9.77501 10.472C9.02334 11.3174 8.46209 12.2845 8.10501 13.3453C8.01834 13.6028 8.10834 13.8874 8.32793 14.0486L9.69168 15.0486C9.98918 15.267 10.1667 15.617 10.1667 15.9857C10.1667 16.3545 9.98918 16.7045 9.69251 16.922L8.32001 17.9282C8.10126 18.089 8.01084 18.372 8.09626 18.6299C8.44959 19.6915 9.00834 20.6599 9.75751 21.5086C9.93793 21.7124 10.2296 21.777 10.4775 21.667L12.0229 20.9874C12.3596 20.839 12.7513 20.8603 13.0713 21.0449C13.3904 21.229 13.6046 21.5574 13.645 21.9236L13.8308 23.6203C13.8604 23.8903 14.0613 24.1103 14.3271 24.1649C14.8746 24.2765 15.4371 24.3332 16 24.3332C16.5492 24.3332 17.1033 24.2778 17.6471 24.1686C17.9146 24.1149 18.1158 23.8949 18.1454 23.6236L18.33 21.9378C18.3704 21.5711 18.5846 21.2428 18.9046 21.0586C19.2238 20.8749 19.615 20.8532 19.9525 21.0011L21.5063 21.6845C21.7538 21.794 22.0446 21.7307 22.225 21.5274C22.9767 20.682 23.5379 19.7149 23.895 18.654C23.9817 18.3965 23.8917 18.112 23.6721 17.9507L22.3079 16.9507ZM16 18.9165C14.3892 18.9165 13.0833 17.6107 13.0833 15.9999C13.0833 14.389 14.3892 13.0832 16 13.0832C17.6108 13.0832 18.9167 14.389 18.9167 15.9999C18.9167 17.6107 17.6108 18.9165 16 18.9165Z"
      fill={props.color}
    />
  </svg>
);
export default SvgMediaRoundBg;
