import { FunctionComponent, SVGProps, useEffect, useState } from "react";
import LoopDetailChannelButton from "./LoopDetailChannelButton";
import SvgGpt35Icon from "../../../../assets/IconComponents/Gpt35Icon";
import SvgGpt4Icon from "../../../../assets/IconComponents/Gpt4Icon";
import SvgDavinciIcon from "../../../../assets/IconComponents/DavinciIcon";
import SvgBardIcon from "../../../../assets/IconComponents/BardIcon";
import SvgDisabledGpt35Icon from "../../../../assets/IconComponents/DisabledGpt35Icon";
import SvgDisabledGpt4Icon from "../../../../assets/IconComponents/DisabledGpt4Icon";
import SvgDisabledDavinciIcon from "../../../../assets/IconComponents/DisabledDavinciIcon";
import SvgDisabledBardIcon from "../../../../components/DisabledBardIcon";
import { useAppDispatch, useAppSelector } from "../../../../helpers/hooks";
import { setActiveAIModels } from "../../../../store/persistedSlice";
import SvgSettingsIcon from "../../../../assets/IconComponents/SettingsIcon";
import Icon from "../../../../components/Icon";
import ActionSheet from "../../../../components/ActionSheet/ActionSheet";
import PrimaryButton from "../../../../components/PrimaryButton";
import SvgCheckboxSelectedIcon from "../../../../assets/IconComponents/CheckboxSelectedIcon";
import SvgCheckIcon from "../../../../assets/IconComponents/CheckIcon";
import { useFindByContentId2Query, useLazyFindByContentId2Query } from "../../../../services/ConfigurationApi";
import { toggleLoading } from "../../../../store/appSlice";

export interface LoopDetailChannel {
    name: string
    bgColor: string
    textColor: string
    tooltip: string
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    disabledIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

export const LoopChannels: LoopDetailChannel[] = [
  {
    name: "GPT-3.5",
    tooltip: "Chat GPT 3.5",
    bgColor: "rgba(255, 255, 255, 1)",
    textColor: "#10A37F",
    icon: SvgGpt4Icon,
    disabledIcon: SvgDisabledGpt4Icon,
  },
  {
    name: "GPT-4",
    tooltip: "Chat GPT 4",
    bgColor: "rgba(255, 255, 255, 1)",
    textColor: "#000",
    icon: SvgGpt35Icon,
    disabledIcon: SvgDisabledGpt35Icon,
  },
  // {
  //     name: "BARD",
  //     tooltip: "Bard",
  //     bgColor: "rgba(176, 109, 173, 0.10)",
  //     textColor: "#B06DAD",
  //     icon: SvgBardIcon,
  //     disabledIcon: SvgDisabledBardIcon
  // },
  //{
  //  name: "DAVINCI",
  //  tooltip: "Davinci",
  //  bgColor: "rgba(255, 255, 255, 1)",
  //  textColor: "#FF8855",
  //  icon: SvgDavinciIcon,
  //  disabledIcon: SvgDisabledDavinciIcon,
  //},
];


interface LoopDetailChannelsProps {
    isDisabled: boolean
    loopChannels?: LoopDetailChannel[]
}

interface AiModelData {
  name: string;
  tooltip: string;
  bg_color: string;
  text_color: string;
  icon: string;
  disabled_icon: string;
}

const LoopDetailChannels: FunctionComponent<LoopDetailChannelsProps> = (props: LoopDetailChannelsProps) => {
    const organizationAIModel = useAppSelector(k => k.brand.organizationAIModels)
    const activeAIModels = useAppSelector(k => k.persisted.activeAIModels)
    const dispatch = useAppDispatch()
    const [actionSheetOpened, setActionSheetOpened] = useState(false)
    const [aiModelsLocal, setAiModelsLocal] = useState<LoopDetailChannel[]>([]);
    useEffect(() => {
        if (activeAIModels === undefined || activeAIModels?.length == 0) {
            dispatch(setActiveAIModels(organizationAIModel))
        }
    }, [activeAIModels, organizationAIModel])
 

useEffect(() => {
  if (props.loopChannels) {
    setAiModelsLocal(props.loopChannels);
  }
}, [props.loopChannels]);  


    return (
      <>
        <div className="max-lg:hidden lg:flex items-center">
          {aiModelsLocal.map((k, index) => (
            <>
              <LoopDetailChannelButton
                key={k.name}
                isDisabled={
                  organizationAIModel.find(
                    (l) => l.toLowerCase() === k.name.toLowerCase()
                  ) === undefined
                }
                channel={k}
                onClick={(c) => {}}
                toggled={(status) => {
                  if (status) {
                    const ch = [...activeAIModels, k];
                    dispatch(setActiveAIModels([...activeAIModels, k.name]));
                  } else {
                    const ch = activeAIModels.filter((m) => m !== k.name);
                    dispatch(setActiveAIModels(ch.map((k) => k)));
                  }
                }}
                isTurnedOn={
                  activeAIModels?.find((l) => l === k.name) !== undefined
                }
              />
              {index !== aiModelsLocal.length - 1 && (
                <div
                  className=" mr-1"
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "#EAEBE6",
                  }}
                ></div>
              )}
            </>
          ))}
        </div>
        <div className="lg:hidden max-lg:flex">
          <ActionSheet
            isExpanded={actionSheetOpened}
            setIsExpanded={(status) => {
              setActionSheetOpened(status);
            }}
            title="Model Settings"
            triggerView={
              <button
                className={`lg:h:hidden                                  m-1
                                    mx-2
                                    bg-white p-[10px]
                                    rounded-lg flex flex-row items-center
                                    border-1 border-border
                                `}
                onClick={() => {}}
              >
                <div className="rounded-sm p-[2px]">
                  <Icon
                    component={SvgSettingsIcon}
                    viewBox="0 0 20 20"
                    className="w-[30px]"
                  />
                </div>
              </button>
            }
            body={
              <div className="w-full p-[15px] pt-[5px] flex flex-col">
                {LoopChannels.map((k) => (
                  <button
                    className={`h-[52px] mt-2 border-border border-1 rounded-xl flex ${
                      activeAIModels.includes(k.name) ? "bg-lightBg" : ""
                    }`}
                    onClick={() => {
                      if (!activeAIModels.includes(k.name)) {
                        const ch = [...activeAIModels, k];
                        dispatch(
                          setActiveAIModels([...activeAIModels, k.name])
                        );
                      } else {
                        const ch = activeAIModels.filter((m) => m !== k.name);
                        dispatch(setActiveAIModels(ch.map((k) => k)));
                      }
                    }}
                  >
                    <div className="w-full h-full flex flex-row items-center justify-between p-[10px]">
                      <div className="flex flex-row items-center">
                        <div
                          className="h-[32px] w-[32px] rounded-md flex items-center justify-center"
                          style={{ backgroundColor: k.bgColor }}
                        >
                          <Icon component={k.icon} viewBox="0 0 23 23" />
                        </div>
                        <p className="ml-2 text-BodyMedium text-black">
                          {k.tooltip}
                        </p>
                      </div>
                      {activeAIModels.includes(k.name) && (
                        <SvgCheckboxSelectedIcon />
                      )}
                    </div>
                  </button>
                  // <LoopDetailChannelButton
                  //     key={k.name}
                  //     isDisabled={organizationAIModel.find(l => l.toLowerCase() === k.name.toLowerCase()) === undefined}
                  //     channel={k}
                  //     onClick={(c) => { }}
                  //     toggled={(status) => {
                  //         if (status) {
                  //             const ch = [...activeAIModels, k]
                  //             dispatch(setActiveAIModels([...activeAIModels, k.name]))
                  //         } else {
                  //             const ch = activeAIModels.filter(m => m !== k.name)
                  //             dispatch(setActiveAIModels(ch.map(k => k)))
                  //         }
                  //     }}
                  //     isTurnedOn={activeAIModels?.find(l => l === k.name) !== undefined}
                  // />
                ))}
                <PrimaryButton
                  isDisabled={activeAIModels.length == 0}
                  className="h-[41px] mt-2"
                  onClick={() => {
                    setActionSheetOpened(false);
                  }}
                  title="Apply Settings"
                />
              </div>
            }
          />
        </div>
      </>
    );
}

export default LoopDetailChannels;