import { t } from "i18next";
import LeftMenuAlternative from "../../components/LeftMenu/LeftMenuAlternative";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDialog, toggleDialog, toggleLoading, toggleMenu } from "../../store/appSlice";
import PrimaryButton from "../../components/PrimaryButton";
import SvgThumbsUpIcon from "../../assets/IconComponents/ThumbsUp";
import { useAppSelector } from "../../helpers/hooks";
import Dialog from "../../components/Dialog";
import { useRevokeUserMutation } from "../../services/UsertApi";
import SvgCelebrationIcon from "../../assets/IconComponents/CelebrationIcon";
import { toggleLoggedIn } from "../../store/persistedSlice";
import { useNavigate } from "react-router-dom";

const AccountFrozenPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const dialog = useAppSelector((state) => state.app.dialog);
    const [revokeUser, { isLoading: userRevoking }] = useRevokeUserMutation();

  useEffect(() => {
    dispatch(toggleMenu(false));
    showAccountFrozenDialog();
  }, []);

  useEffect(() => {
    dispatch(toggleLoading(userRevoking));
  }, [userRevoking]);

  const showAccountReactivationConfirmedDialog = () => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgCelebrationIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("your-account-is-reactivated")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("your-account-is-back-in-action")}
                &nbsp;
                {t("all-features-and-data-are-now-accessible")}
                &nbsp;
                {t("continue-where-you-left-off")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    dispatch(toggleLoggedIn(true));
                    dispatch(toggleLoading(false));
                    navigate("/app/homepage");
                  }}
                  title={t("great")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const showAccountFrozenDialog = () => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center py-[40px]">
              <SvgThumbsUpIcon viewBox="0 0 72 72" />
              <p className="mt-[10px] text-H6 text-black">
                {t("your-account-is-frozen")}
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                {t("unfreeze-any-moment-to-continue-your-journey-with-us")}
              </p>
              <div className="mt-[20px] h-[40px] w-full flex flex-col md:flex-row items-center justify-center">
                <PrimaryButton
                  className="h-full w-full md:w-[80px]"
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    revokeUser().then(() => {})
                    showAccountReactivationConfirmedDialog();
                  }}
                  title={t("reactivate")}
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };
  return (
    <>
      <Dialog
        title={dialog?.title ?? ""}
        message={dialog?.message ?? ""}
        actionButtons={dialog?.actions}
        onClose={dialog?.onClose}
        customTemplate={dialog?.customTemplate}
        customZIndex={dialog?.customZIndex}
      />
      <div className="flex h-full w-full flex-col min-h-screen">
        <div className="flex flex-1 !p-0 bg-bg justify-center">
          <div className="flex flex-1">
            <LeftMenuAlternative />
            <div className="flex flex-1 lg:ml-2 flex-col"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountFrozenPage;
