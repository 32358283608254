import { t } from "i18next";
import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import SvgDefaultBrandLogo from "../../assets/IconComponents/DefaultBrandLogo";
import { useLazyList6Query, BrandRequest } from "../../services/BrandApi";
import BrandSelectorPopup from "./BrandSelectorPopup";
import BrandLogo from "../BrandLogo";
import ActionSheet from "../ActionSheet/ActionSheet";
import BrandSelectorItem from "./BrandSelectorItem";
import PrimaryButton from "../PrimaryButton";
import { useAppSelector } from "../../helpers/hooks";

export interface IBrandData {
  id?: string,
  title: string,
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

export interface IBrandSelectorProps {
  BrandSelected: (data: BrandRequest | undefined) => void
  tooltip?: string
  selectDefaultFirst?: boolean
  selectedBrandId?: string
  className?: string
}

function BrandSelector(props: IBrandSelectorProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<BrandRequest | undefined>()
  const [getBrands, { data: brands }] = useLazyList6Query()
  const [actionSheetOpened, setActionSheetOpened] = useState(false);
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );

  useEffect(() => {
    getBrands()
  }, [])

  useEffect(() => {
    props.BrandSelected(selectedBrand)
    setIsPopoverOpen(false)
  }, [selectedBrand])

  useEffect(() => {
    if (brands && props.selectDefaultFirst && brands.length > 0) {
      let firstBrand = brands![0]
      setSelectedBrand(firstBrand)
    } else if (brands && props.selectedBrandId && brands.length > 0) {
      let selectedBrand = brands!.find(k => k.id === props.selectedBrandId)!
      setSelectedBrand(selectedBrand)
    }
    else if (brands && brands.length > 0) {
      let selectedBrand = brands!.find(
        (k) => k.id === lastSelectedBrandId
      )!;
      setSelectedBrand(selectedBrand);
    }

  }, [brands])

  useEffect(() => {
    if (brands && props.selectedBrandId && brands.length > 0) {
      let selectedBrand = brands!.find(k => k.id === props.selectedBrandId)!
      setSelectedBrand(selectedBrand)
    }

  }, [props.selectedBrandId])

  return (
    <>
      <div className="max-lg:hidden lg:flex">
        <Popover
          isOpen={isPopoverOpen}
          positions={["bottom"]}
          align="start"
          padding={10}
          onClickOutside={() => {
            setIsPopoverOpen(false);
          }}
          content={
            <BrandSelectorPopup
              data={brands ?? []}
              closePopup={() => setIsPopoverOpen(false)}
              selectedBrand={selectedBrand}
              selectedBrandChanged={(e) => {
                setSelectedBrand(e);
              }}
            />
          }
        >
          <div
            className={props.className ?? "h-[41px]"}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
            data-tooltip-html={props.tooltip}
          >
            <button
              className={`
                                    h-full                                    
                                    bg-white p-[10px] pl-[6px]
                                    rounded-[7px] flex flex-row items-center
                                    border-1 border-border
                                `}
              onClick={() => { }}
            >
              <BrandLogo
                brand={brands?.find((k) => k.id === selectedBrand?.id)}
              />

              <div className="flex flex-row">
                <text className={`text-H8 text-gray ml-2`}>{t("brand")}</text>
                {selectedBrand !== undefined && (
                  <>
                    <text className={`text-H8 text-gray ml-0`}>:</text>
                    <text className={`text-H8 text-blue ml-1`}>
                      {selectedBrand.brand_name}
                    </text>
                  </>
                )}
              </div>
            </button>
          </div>
        </Popover>
      </div>
      <div className="lg:hidden max-lg:flex">
        <ActionSheet
          title="Select Brand"
          isExpanded={actionSheetOpened}
          setIsExpanded={setActionSheetOpened}
          triggerView={
            <button
              className={`
                                    h-full                                    
                                    bg-white p-[10px] pl-[6px]
                                    rounded-[7px] flex flex-row items-center
                                    border-1 border-border
                                `}
              onClick={() => { }}
            >
              <BrandLogo
                brand={brands?.find((k) => k.id === selectedBrand?.id)}
              />

              <div className="flex flex-row">
                <text className={`text-H8 text-gray ml-2`}>{t("brand")}</text>
                {selectedBrand !== undefined && (
                  <>
                    <text className={`text-H8 text-gray ml-0`}>:</text>
                    <text className={`text-H8 text-blue ml-1`}>
                      {selectedBrand.brand_name}
                    </text>
                  </>
                )}
              </div>
            </button>
          }
          body={
            <div className="w-full p-[15px] pt-[5px] flex flex-col">
              <div className="h-full">
                <div className="max-h-[170px] overflow-scroll scrollbar-hide">
                  {(brands ?? []).map((k) => (
                    <BrandSelectorItem
                      data={k}
                      selected={selectedBrand?.id === k.id}
                      itemSelected={(e) => {
                        var selectBrand: BrandRequest | undefined = undefined;

                        if (e.id === selectedBrand?.id) {
                          selectBrand = undefined;
                        } else {
                          selectBrand = e;
                        }

                        setSelectedBrand(selectBrand);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

export default BrandSelector;
