import { MutableRefObject, RefObject, useEffect, useState } from "react";

export interface IContentContainerProps {
  className?: String
  children: JSX.Element
  onHover?: () => void
  onBlur?: () => void
  onClick?: () => void
  hoverable?: boolean
  isSelected?: boolean
  ref?: RefObject<HTMLDivElement>
  width?: number
  onHoverElement?: JSX.Element
  overlayActiveOnHover?: boolean
}

function ContentContainer(props: IContentContainerProps) {
  const { hoverable = true, isSelected = false } = props

  const [itemIsActive, setActive] = useState(isSelected)


  useEffect(() => {
    setActive(isSelected)
  }, [isSelected])



  return (
    <div
      ref={props.ref}
      style={{ width: props.width }}
      className={`bg-white rounded-[10px] border-1 transform-gpu
            ${itemIsActive && hoverable
          ? " border-red"
          : " border-border"
        }
              ${props.className}`}
      onMouseEnter={() => {
        setActive(true);
        if (props.onHover) props.onHover();
      }}
      onMouseLeave={() => {
        if (!props.isSelected) setActive(false);
        if (props.onBlur) props.onBlur();
      }}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.children}
      {(props.overlayActiveOnHover ?? false) && (
        <div
          className="rounded-[10px]"
          style={{
            position: "absolute",
            padding: "1px",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            opacity: itemIsActive ? 1 : 0,
            pointerEvents: "none", // To allow clicks to go through the overlay
          }}
        />
      )}
      {itemIsActive && props.onHoverElement}
    </div>
  );
}

export default ContentContainer;
