import * as React from "react";
import type { SVGProps } from "react";
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      d="M9 .667C4.405.667.667 4.405.667 9c0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333C17.333 4.405 13.595.667 9 .667Zm3.542 8.958H9.625v2.917a.625.625 0 1 1-1.25 0V9.625H5.458a.625.625 0 1 1 0-1.25h2.917V5.458a.625.625 0 1 1 1.25 0v2.917h2.917a.625.625 0 1 1 0 1.25Z"
    />
  </svg>
);
export default SvgAdd;
