import { useEffect, useState } from "react";
import OnboardingChooseStyleCard from "./OnboardingChooseStyleCard";
import OnboardingChooseStylePage from "./OnboardingChooseStyle";
import OnboardingWebsite from "./OnboardingWebsite";
import { useUpdateOrganisationDetailsMutation } from "../../services/OrganizationApi";
import { useDispatch } from "react-redux";
import { setCollapsedModeEnabled, setMenuCollapsed, toggleLoading, toggleMenu, updateMenu } from "../../store/appSlice";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../helpers/hooks";

function OnboardingContent() {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [websiteURL, setWebsiteURL] = useState<string>("");
  const showMenu = useAppSelector((state) => state.app.showMenu);
  const [
    updateOrganisationDetails,
    { isLoading: updatingOrganisation },
  ] = useUpdateOrganisationDetailsMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleLoading(updatingOrganisation));
  }, [updatingOrganisation]);


  useEffect(() => {
    dispatch(toggleMenu(false));
  }, []);

  useEffect(() => {
    // Check if gtag function is available
    if (window.gtag) {
      // Trigger the conversion event
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11309297361/xLELCIuSvIUZENHd2JAq' // Replace with your conversion ID
      });
    }
  }, []);

  return (
    <>
      {onboardingStep === 0 && <OnboardingChooseStylePage key={0} onContinue={(style) => {
        setSelectedItem(style);
        setOnboardingStep(1);
      }} />}
      {onboardingStep === 1 && <OnboardingWebsite key={1} onContinue={(websiteURL) => {
        setWebsiteURL(websiteURL);
        updateOrganisationDetails({ organisationDetailUpdateRequest: { website: websiteURL, description_tags: [selectedItem] } }).then(() => {
          navigate(`/app/plan`);
        });
      }} />}
    </>
  );
}

export default OnboardingContent;
