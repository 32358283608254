

import * as React from "react";
import type { SVGProps } from "react";
const SvgOpenLockIcon = (props: SVGProps<SVGSVGElement>) => (
<svg
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.50002 14.6667C4.13335 14.6667 3.81946 14.5361 3.55835 14.275C3.29724 14.0139 3.16669 13.7 3.16669 13.3333V6.66667C3.16669 6.30001 3.29724 5.98612 3.55835 5.72501C3.81946 5.46389 4.13335 5.33334 4.50002 5.33334H10.5V4.00001C10.5 3.44445 10.3056 2.97223 9.91669 2.58334C9.5278 2.19445 9.05558 2.00001 8.50002 2.00001C8.03335 2.00001 7.62502 2.14167 7.27502 2.42501C6.92502 2.70834 6.68891 3.06112 6.56669 3.48334C6.52224 3.63889 6.43058 3.76389 6.29169 3.85834C6.1528 3.95278 6.00002 4.00001 5.83335 4.00001C5.64446 4.00001 5.48613 3.93889 5.35835 3.81667C5.23058 3.69445 5.18335 3.55001 5.21669 3.38334C5.37224 2.61667 5.75558 1.97223 6.36669 1.45001C6.9778 0.927783 7.68891 0.666672 8.50002 0.666672C9.42224 0.666672 10.2084 0.991672 10.8584 1.64167C11.5084 2.29167 11.8334 3.07778 11.8334 4.00001V5.33334H12.5C12.8667 5.33334 13.1806 5.46389 13.4417 5.72501C13.7028 5.98612 13.8334 6.30001 13.8334 6.66667V13.3333C13.8334 13.7 13.7028 14.0139 13.4417 14.275C13.1806 14.5361 12.8667 14.6667 12.5 14.6667H4.50002ZM4.50002 13.3333H12.5V6.66667H4.50002V13.3333ZM8.50002 11.3333C8.86669 11.3333 9.18058 11.2028 9.44169 10.9417C9.7028 10.6806 9.83335 10.3667 9.83335 10C9.83335 9.63334 9.7028 9.31945 9.44169 9.05834C9.18058 8.79723 8.86669 8.66667 8.50002 8.66667C8.13335 8.66667 7.81946 8.79723 7.55835 9.05834C7.29724 9.31945 7.16669 9.63334 7.16669 10C7.16669 10.3667 7.29724 10.6806 7.55835 10.9417C7.81946 11.2028 8.13335 11.3333 8.50002 11.3333Z"
    fill="#FF5555"
  />
</svg>
);
export default SvgOpenLockIcon;
