import {
  FunctionComponent,
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Add } from "../../assets/IconComponents";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { clickOutside } from "../../helpers/ClickOutside";
import { useClickAway } from "@uidotdev/usehooks";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";

interface ColorPickerProps {
  className?: string;
  colorSelected: (color: string | undefined) => void;
  selectedColor?: string | undefined;
  isDisabled?: boolean;
  popoverClassName?: string;
  hideDeleteButton?: boolean;
  title?: string;
}

const BrandColorPicker: FunctionComponent<ColorPickerProps> = (
  props: ColorPickerProps
) => {
  let popover: HTMLDivElement;
  const [isOpen, toggle] = useState(false);
  const [color, setColor] = useState<string | undefined>();
  const [colorSelected, setColorSelected] = useState(false);
  const close = useCallback(() => toggle(false), []);

  useEffect(() => {
    if (props.selectedColor && props.selectedColor !== "") {
      setColor(props.selectedColor!);
      setColorSelected(true);
    }
  }, [props.selectedColor]);

  useEffect(() => {
    props.colorSelected(color);
  }, [colorSelected]);

  useEffect(() => {
    if (props.isDisabled) {
      close();
    }
  }, [props.isDisabled]);

  const ref = useClickAway(() => {
    close();
  });

  useEffect(() => {
    clickOutside(popover, () => {
      close();
    });
  }, []);

  return (
    <div className="flex flex-row w-full  justify-between pl-5  mt-1 h-24">
      <div className="flex w-[200px]">
        <text className="text-H8 text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
          {props.title ?? ""}
        </text>
      </div>
      <div className="flex flex-row w-full items-center">
        <div
          style={{ backgroundColor: color }}
          className={` flex border-1 border-border rounded-[30px] ml-5 w-[40px] h-[40px] justify-center items-center ${props.className}`}
          onClick={() => {
            !props.isDisabled && toggle(true);
          }}
        >
          {isOpen && (
            <div
              className={`popover border-1 border-border p-[15px] rounded-[10px] bg-white z-50 ${props.popoverClassName}`}
              ref={ref as LegacyRef<HTMLDivElement>}
            >
              <HexColorPicker
                color={color}
                onChange={(e) => {
                  setColor(e);
                  setColorSelected(true);
                  props.colorSelected(e);
                }}
              />
              <HexColorInput
                className="w-full mt-[15px] text-black text-BodyMedium p-2 text-center rounded-md border-1 border-border"
                color={color}
                onChange={(e) => {
                  setColor(e);
                  setColorSelected(true);
                  props.colorSelected(e);
                }}
                prefixed
              />
            </div>
          )}
        </div>

        <div className="flex  bg-white items-center ml-2 w-3/4 h-[40px] border-1 rounded-[5px]">
          <text
            className="text-BodyMedium text-gray pl-2"
            style={{ textTransform: "uppercase" }}
          >
            {color ?? "#FFFFFF"}
          </text>
        </div>
      </div>
    </div>
  );
};

export default BrandColorPicker;
