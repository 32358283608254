import { Outlet, useNavigate } from "react-router-dom";
import PageLoading from "../../components/PageLoading";
import { useAppSelector } from "../../helpers/hooks";
import { Email, InstagramColor, LinkedinColor, TwitterColor } from "../../assets/IconComponents";
import { useDispatch } from "react-redux";

function UnonymLayout() {
    const isLoading = useAppSelector(state => state.app.isLoading)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    return (<>
        <PageLoading isShowing={isLoading} />
        <div className="w-screen h-screen bg-bg">
            {/* <AnonymHeader /> */}
            <div className="flex w-full h-full">
                <Outlet />
                <div className="absolute bg-white h-[50px] w-full bottom-0 left-0 right-0 justify-center">
                    <div className="flex flex-row justify-center items-center h-full">
                        <button onClick={() => { window.open("https://twitter.com/evercopyai", '_blank') }}>
                            <TwitterColor width={20} height={20} />
                        </button>

                        <button className="ml-2" onClick={() => { window.open("https://www.instagram.com/evercopyai/", '_blank') }}>
                            <InstagramColor width={20} height={20} />
                        </button>

                        <button className="ml-2" onClick={() => { window.open("https://www.linkedin.com/company/evercopy", '_blank') }}>
                            <LinkedinColor width={20} height={20} />
                        </button>

                        <button className="ml-2" onClick={() => { window.location.replace("mailto:info@evercopy.ai") }}>
                            <Email fill="#FF5555" width={20} height={20} />
                        </button>
                    </div>
                </div>
            </div>
            {/* <AnonymFooter /> */}
        </div>

    </>);
}

export default UnonymLayout;