import * as React from "react";
import type { SVGProps } from "react";
const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M10 1.667a8.31 8.31 0 0 0-5.833 2.387v-.72a.833.833 0 1 0-1.667 0V6.25a.833.833 0 0 0 .8.832c.124.029.252.03.376.001H6.25a.834.834 0 1 0 0-1.666H5.162A6.638 6.638 0 0 1 10 3.333 6.654 6.654 0 0 1 16.667 10 6.654 6.654 0 0 1 10 16.667 6.654 6.654 0 0 1 3.333 10a.833.833 0 1 0-1.666 0c0 4.592 3.74 8.333 8.333 8.333 4.592 0 8.333-3.74 8.333-8.333 0-4.592-3.74-8.333-8.333-8.333Zm-.012 3.738a.833.833 0 0 0-.821.845v4.583a.834.834 0 0 0 .833.834h2.917a.834.834 0 1 0 0-1.667h-2.084V6.25a.833.833 0 0 0-.845-.845Z"
    />
  </svg>
);
export default SvgHistory;
