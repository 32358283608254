import * as React from "react";
import type { SVGProps } from "react";
const SvgTritoneColorImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <rect width={50} height={50} fill="#F55" opacity={0.1} rx={8} />
    <path
      fill="#F55"
      d="M32.583 15.833H17.417a3.25 3.25 0 0 0-3.25 3.25v10.834a3.25 3.25 0 0 0 3.25 3.25h15.166a3.25 3.25 0 0 0 3.25-3.25V19.083a3.25 3.25 0 0 0-3.25-3.25Z"
      opacity={0.35}
    />
    <path
      fill="#F55"
      d="M30.417 22.333a2.167 2.167 0 1 0 0-4.333 2.167 2.167 0 0 0 0 4.333ZM16.333 28.833a1.083 1.083 0 0 0 1.084 1.084h15.166a1.084 1.084 0 0 0 1.084-1.084c0-1.477-1.829-3.25-2.709-3.25-.88 0-1.677 1.084-3.791 1.084-2.646 0-4.334-5.417-6.5-5.417-2.167 0-4.334 4.726-4.334 7.583Z"
    />
  </svg>
);
export default SvgTritoneColorImage;
