import { FunctionComponent, useEffect, useState } from "react";
import { useLazyDownloadQuery } from "../services/FileApi";
import { blobToDataURL } from "blob-util";
import { BrandRequest } from "../services/BrandApi";
import SvgDefaultBrandLogo from "../assets/IconComponents/DefaultBrandLogo";
import { t } from "i18next";
import { Popover } from "antd";
import SvgDefaultBrandLogoNew from "../assets/IconComponents/DefaultBrandLogoNew";

interface BrandLogoProps {
    brand?: BrandRequest
    width?: number
    height?: number
    tooltip?: string
}

const BrandLogo: FunctionComponent<BrandLogoProps> = (props: BrandLogoProps) => {
    const [downloadFile, { data: fileDownloaddData, isSuccess: fileDownloaded, isLoading: fileDownloading, currentData: dt }] = useLazyDownloadQuery()

    const [useIcon, setUseIcon] = useState(false)
    const [logoImage, setLogoImage] = useState<string>("")

    useEffect(() => {
        var logo = props.brand?.logo_file_ids
        if (logo && (logo?.length ?? 0) - 1 > -1) {
            downloadFile({
                id: logo[(logo?.length ?? 0) - 1].id ?? ""
            })
                .unwrap()
                .then(async k => {
                    let image = await blobToDataURL(k.file)
                    setLogoImage(image)
                    setUseIcon(false)
                })
        } else {
            setUseIcon(true)
        }

    }, [props.brand])

    return (
        <>
            {props.tooltip?.length ? <Popover content={props.tooltip} placement="right">
                <div
                    className="border-border border-1 rounded-[3px] overflow-hidden"
                    style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                >
                    {(useIcon || props.brand === undefined) && (
                        <div
                            className="w-full h-full flex items-center justify-center "
                            style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                        >
                            <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                        </div>
                    )}
                    {!useIcon && !logoImage && (
                        <div
                            className="w-full h-full flex items-center justify-center "
                            style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                        >
                            <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                        </div>
                    )}
                    {!useIcon && logoImage && (
                        <img
                            src={logoImage}
                            style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                            className="object-scale-down"
                        />
                    )}
                </div>
            </Popover> :
                <div
                    className="border-border border-1 rounded-[3px] overflow-hidden"
                    style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                >
                    {(useIcon || props.brand === undefined) && (
                        <div
                            className="w-full h-full flex items-center justify-center "
                            style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                        >
                            <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                        </div>
                    )}
                    {!useIcon && !logoImage && (
                        <div
                            className="w-full h-full flex items-center justify-center "
                            style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                        >
                            <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                        </div>
                    )}
                    {!useIcon && logoImage && (
                        <img
                            src={logoImage}
                            style={{ width: props.width ?? 22, height: props.height ?? 22 }}
                            className="object-scale-down"
                        />
                    )}
                </div>}
        </>
    );
}

export default BrandLogo;
