import * as React from "react";
const Snowman1 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.74998 8.5C5.30226 8.5 5.74997 8.94772 5.74997 9.5V10.882L7.19719 11.6056C7.69117 11.8526 7.89139 12.4532 7.6444 12.9472C7.39741 13.4412 6.79674 13.6414 6.30276 13.3944L4.32214 12.4041C4.30953 12.3981 4.29708 12.3919 4.28477 12.3854L2.30276 11.3944C1.80878 11.1474 1.60856 10.5468 1.85555 10.0528C2.10254 9.55881 2.70321 9.35858 3.19719 9.60557L3.74997 9.88197V9.5C3.74997 8.94772 4.19769 8.5 4.74998 8.5ZM20.75 8.5C21.3023 8.5 21.75 8.94772 21.75 9.5V11.5C21.75 12.0523 21.3023 12.5 20.75 12.5C20.1977 12.5 19.75 12.0523 19.75 11.5V9.5C19.75 8.94772 20.1977 8.5 20.75 8.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M23.6444 10.0528C23.8914 10.5467 23.6912 11.1474 23.1972 11.3944L19.1972 13.3944C18.7032 13.6414 18.1025 13.4412 17.8555 12.9472C17.6086 12.4532 17.8088 11.8525 18.3028 11.6055L22.3028 9.60555C22.7967 9.35856 23.3974 9.55878 23.6444 10.0528Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M12.75 8.5C14.4069 8.5 15.75 7.15685 15.75 5.5C15.75 3.84315 14.4069 2.5 12.75 2.5C11.0931 2.5 9.75 3.84315 9.75 5.5C9.75 7.15685 11.0931 8.5 12.75 8.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M12.75 22.5C16.3399 22.5 19.25 19.366 19.25 15.5C19.25 11.634 16.3399 8.5 12.75 8.5C9.16015 8.5 6.25 11.634 6.25 15.5C6.25 19.366 9.16015 22.5 12.75 22.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12.7498 14.5C13.302 14.5 13.7498 14.0523 13.7498 13.5C13.7498 12.9477 13.302 12.5 12.7498 12.5C12.1975 12.5 11.7498 12.9477 11.7498 13.5C11.7498 14.0523 12.1975 14.5 12.7498 14.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12.7498 17C13.302 17 13.7498 16.5523 13.7498 16C13.7498 15.4477 13.302 15 12.7498 15C12.1975 15 11.7498 15.4477 11.7498 16C11.7498 16.5523 12.1975 17 12.7498 17Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12.7498 19.5C13.302 19.5 13.7498 19.0523 13.7498 18.5C13.7498 17.9477 13.302 17.5 12.7498 17.5C12.1975 17.5 11.7498 17.9477 11.7498 18.5C11.7498 19.0523 12.1975 19.5 12.7498 19.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default Snowman1;
