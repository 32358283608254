import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAccessToken, setRefreshToken, toggleLoggedIn } from '../../store/persistedSlice'
import type { RootState } from '../../store/store'
import { LoginResponse } from '../ContentApi.old/Model/LoginRequestModel'

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    timeout: 300000,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const accessToken = (getState() as RootState).persisted.accessToken
        const refreshToken = (getState() as RootState).persisted.refreshToken

        if (accessToken && accessToken != "") {
            headers.set('authorization', `Bearer ${accessToken}`)
        }

        if (refreshToken && refreshToken != "" && endpoint.includes('refresh-token'))
            headers.set('authorization', `Bearer ${refreshToken}`)
        return headers
    },
})

export type ApiErrorWithCodeData = {
    message: string
    errorCode: number
    errorGroup: string
};
export type ApiErrorWithCode = {
    status: string
    data: ApiErrorWithCodeData
};


export function instanceOfApiErrorWithCode(test: any): test is ApiErrorWithCode {
    return 'data' in test && 'errorCode' in test.data;
}

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError | ApiErrorWithCode
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // try to get a new token
        const refreshResult = await baseQuery({
            url: 'auth/refresh-token',
            method: 'POST',
            headers: { "Authorization": `Bearer ${(api.getState() as RootState).persisted.refreshToken}` },
        }, api, extraOptions)

        if (refreshResult.data) {
            // store the new token
            api.dispatch(setAccessToken((refreshResult.data as LoginResponse).access_token))
            api.dispatch(setRefreshToken((refreshResult.data as LoginResponse).refresh_token))
            // retry the initial query
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(toggleLoggedIn(false))
        }
    }
    return result
}

export const coreApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),

})