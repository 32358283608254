import { t } from "i18next";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Facebook, FacebookColor, Instagram, InstagramColor, Linkedin, LinkedinColor, TwitterColor } from "../../assets/IconComponents";
import Twitter from "../../assets/IconComponents/Twitter";
import BrandSelector from "../../components/BrandSelector/BrandSelector";
import ChannelSelector, { IChannelTypeData } from "../../components/ChannelSelector/ChannelSelector";
import TrendingKeywordSelector from "../../components/TrendingKeywordSelector/TrendingKeywordSelector";
import { ContentType } from "../../constants/ContentType";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { Content, ContentInspirationRequest, useContentInspirationMutation } from "../../services/BrandApi";
import { ContentRequest, useSave4Mutation } from "../../services/ContentApi";
import { useLazyRefreshQuery } from "../../services/KeywordApi";
import { refreshPage, setActivePage, updateMenu } from "../../store/appSlice";
import { setNewContent } from "../../store/contentSlice";
import ContentInspirationCard from "./ContentInspirationCard";
import SvgArrowUpDownSlim from "../../assets/IconComponents/ArrowUpDownSlim";
import Icon from "../../components/Icon";
import SvgFilters from "../../assets/IconComponents/Filters";
import ContentTypeSelector, { IContentTypeData } from "../../components/ContentTypeSelector/ContentTypeSelector";
import Tag from "../../components/Tag";
import ChannelContentSelector, { IChannelContentData } from "../../components/ChannelContentSelector/ChannelContentSelector";
import GenericSelector, { IGenericSelectorData } from "../../components/GenericSelector/GenericSelector";

interface ContentInspirationsPageProps {

}

const inspirationPlatform = [
    { id: "1", title: "Facebook", icon: Facebook, colorIcon: FacebookColor },
    { id: "2", title: "Twitter", icon: Twitter, colorIcon: TwitterColor },
    { id: "3", title: "Instagram", icon: Instagram, colorIcon: InstagramColor },
    { id: "4", title: "LinkedIn", icon: Linkedin, colorIcon: LinkedinColor },
]

const ContentInspirationsPage: FunctionComponent<ContentInspirationsPageProps> = () => {
    const dispatch = useAppDispatch();
    const [filterAreaCollapsed, setFilterAreaCollapsed] = useState(false)
    const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>(["twitter"])
    const [selectedContentTypes, setSelectedContenTypes] = useState<string[]>([])
    const [selectedChannelContentIds, setSelectedChannelContentIds] = useState<string[]>([])
    const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>()
    const [searchTexts, setSearchTexts] = useState<string[]>([])
    const [getContentList, { isLoading: contenListLoading, data: contentList }] = useContentInspirationMutation();
    const [favoriteSearch, setFavoriteSearch] = useState(false)
    const [sortBy, setSortBy] = useState<"Engagement" | "Recent" | string>("Engagement")
    const navigate = useNavigate()
    const { state } = useLocation()
    const [enteredKeyword, setEnteredKeyword] = useState("")
    const [items, setItems] = useState<Content[] | undefined>([])
    const [getKeywords, { data: keywords, isLoading: keywordsLoading }] = useLazyRefreshQuery()
    const [createContent, { isLoading: isContentCreationLoading,
        isSuccess: contentCreationSuccess,
        data: contentCreationData }] = useSave4Mutation()
    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)

    useEffect(() => {
        if (state?.selectedTag) {
            setEnteredKeyword(state.selectedTag)
        }
    }, [state?.selectedTag])

    useEffect(() => {
        dispatch(setActivePage("inspirations"))

        if (state?.selectedTag !== undefined) {
            setSearchTexts([state.selectedTag])
        }

        getInspirationKeywords()
    }, [])


    useEffect(() => {
        if (keywords && state?.selectedTag === undefined) {
            setSearchTexts([keywords[0].name])
        }
    }, [keywords])


    const getInspirationKeywords = () => {
        getKeywords({
            brandId: selectedBrandId
        })
    }

    useEffect(() => {
        if (selectedBrandId)
            getInspirationKeywords()
    }, [selectedBrandId])

    const getLoopList = () => {
        if (!selectedFilterIds || selectedFilterIds.length === 0) {
            return
        }

        var payload = {
            brand_id: selectedBrandId ?? "",
            sm_type: selectedFilterIds[0]?.toLowerCase(),
            keywords: searchTexts?.at(0)?.trim() ?? "",
            sort_order: sortBy === "Engagement" ? "relevancy" : "recency"
        } as ContentInspirationRequest

        getContentList({
            contentInspirationRequest: payload
        })
    }

    useEffect(() => {
        dispatch(setNewContent(undefined))
        setItems([])
        getLoopList()

        return () => {
            setItems([])
        }
    }, [])

    useEffect(() => {
        setItems([])
        getLoopList()
    }, [selectedFilterIds, searchTexts, favoriteSearch, selectedBrandId, sortBy])

    useEffect(() => {

        if (contentList && (contentList?.content?.length ?? 0) > 0) {
            // console.log(contentList.content)
            setItems(contentList.content)
        } else {
            setItems([])
        }

    }, [contentList])

    function capitalizeFirstLetter(str: string): string {
        return str.replace(/^\w/, (c) => c.toUpperCase());
    }

    //Create new Loop
    const createLoop = (body: string) => {

        var payload = {
            name: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
            type: ContentType[ContentType.text],
            platform: `${capitalizeFirstLetter(selectedFilterIds?.at(0) ?? "")}`,
            filters: getFilters(body),
            brand_id: selectedBrandId,
        } as ContentRequest

        createContent({
            contentRequest: payload
        })
    }

    const getFilters = (body: string) => {

        var payload = {
            age_group: "All",
            bot_type: "marketing",
            gender_group: "All",
            education_level: "All",
            message: body,
            objective: "",
            tone_of_voice: ""
        }

        return payload
    }

    useEffect(() => {
        if (contentCreationSuccess)
            navigate("/app/loopdetails/" + contentCreationData?.id?.content_id ?? "", { state: { newLoopCreated: true } })
    }, [contentCreationSuccess])



    const MAXIMUM_SEARCH_TEXT = 3;
    return (
      <>
        <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border items-center justify-between">
          <text className="text-H6 text-black px-5 ">
            {t("content-inspirations-0")}
          </text>
        </div>
        <div className="w-full h-[calc(100vh-56px)] p-2 overflow-hidden">
          <div className="w-full gap-[20px] px-0 md:px-[30px] mt-2 md:mt-5 flex flex-col">
            <div className="flex flex-col gap-[10px]">
              <div className="w-full flex flex-row justify-start items-start gap-2.5">
                <TrendingKeywordSelector
                  keywords={keywords ?? []}
                  tagSelected={(e) => {
                    if (searchTexts.findIndex((b) => b === e.name) === -1) {
                      if (searchTexts.length < 3) {
                        setSearchTexts([...searchTexts, e.name]);
                      }
                    }
                  }}
                  placeholder={
                    searchTexts.length === MAXIMUM_SEARCH_TEXT
                      ? t("max-3-keywords-selected") ?? ""
                      : t("search-content-inspirations") ?? ""
                  }
                />

                {/* SORTING DIABLED TEMPORARILY*/}
                <GenericSelector
                  icon={SvgArrowUpDownSlim}
                  hideActionButton={true}
                  subTitle={""}
                  popupClassName="!w-[250px]"
                  buttonClassName={`border-border border-1 !w-[162px] !rounded-[7px] !h-12 pr-2 pl-3 py-2.5 bg-white border-stone-200 !justify-start  inline-flex gap-[5px]`}
                  buttonText={() => {
                    return (
                      <>
                        <span className="text-[13px] text-gray">
                          {"Sort:"}{" "}
                        </span>
                        <span className="text-[13px] text-black">{sortBy}</span>
                      </>
                    );
                  }}
                  ItemeSelected={(data: IGenericSelectorData | undefined) => {
                    setSortBy(data?.title ?? "Engagement");
                  }}
                  Items={[
                    { id: "0", title: "Engagement" },
                    { id: "1", title: "Recent" },
                  ]}
                />

                <button
                  onClick={() => {
                    setFilterAreaCollapsed(!filterAreaCollapsed);
                  }}
                  className={`border-border border-1 md:w-[84px] rounded-[7px] h-12 pr-2 pl-3 max-md:pr-3 py-2.5 bg-white border-stone-200 justify-start items-center inline-flex gap-[5px]`}
                >
                  <Icon width="20" height="20" component={SvgFilters} />
                  <span className="text-[13px] text-black max-md:hidden">
                    {"Filters"}
                  </span>
                </button>
              </div>

              {!filterAreaCollapsed && (
                <div className="w-full flex flex-row justify-start items-center gap-[6px]">
                  <BrandSelector
                    className="h-11"
                    selectedBrandId={selectedBrandId ?? lastSelectedBrandId}
                    BrandSelected={(e) => {
                      if (e?.id && e?.id !== selectedBrandId) {
                        setSelectedBrandId(e?.id);
                      }
                    }}
                  />
                  {/* THESE FILTERS ARE CLOSED TEMPORARILY*/}
                  {/* <ContentTypeSelector
                                    className="h-11"
                                    selectedContentTypes={selectedContentTypes ?? []}
                                    contentTypesSelected={(data: IContentTypeData[]) => {
                                        if (data) {
                                            setSelectedContenTypes(data.map(contentType => contentType.title.toLowerCase()))
                                        }
                                    }}></ContentTypeSelector>

                                <ChannelSelector
                                    className="h-11"
                                    deselectable={false}
                                    closeOnSelect={true}
                                    defaultValues={selectedFilterIds ?? ["twitter"]}
                                    customChannels={inspirationPlatform}
                                    channelsSelected={(e: IChannelTypeData[] | undefined) => {
                                        if (e) {
                                            setSelectedFilterIds(e.map(channel => channel.title))
                                        }
                                    }} />

                                {(selectedFilterIds && selectedFilterIds.length > 0) &&
                                    < ChannelContentSelector
                                        className="h-11"
                                        deselectable={false}
                                        closeOnSelect={true}
                                        defaultValues={selectedChannelContentIds ?? ["1"]}
                                        filterChannels={selectedFilterIds ?? ["twitter"]}
                                        channelContentsSelected={(e: IChannelContentData[] | undefined) => {
                                            e && setSelectedChannelContentIds(e.map(content => content.id))
                                        }} />
                                } */}
                  <div className="w-px h-6 border" />

                  {searchTexts &&
                    searchTexts.map((st) => {
                      return (
                        <Tag
                          className="h-11 p-2.5 bg-white rounded-[7px] border border-stone-200 justify-start items-center gap-[5px]"
                          tagText={st}
                          onClose={() => {
                            setSearchTexts([
                              ...searchTexts.filter((a) => a !== st),
                            ]);
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </div>

            <hr />

            <div className="w-full  flex h-[calc(100vh-250px)] md:h-[calc(100vh-250px)] overflow-scroll scrollbar-hide ">
              <div
                className={`mt-2 w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[12px] gap-y-[10px]`}
              >
                {items?.map((k, index) => {
                  return (
                    <ContentInspirationCard
                      key={index}
                      loop={k}
                      updateLoops={() => {
                        setItems([]);
                        getLoopList();
                        dispatch(updateMenu());
                        dispatch(refreshPage());
                      }}
                      onSelect={(contentId) => {
                        // navigate(`/app/loopdetails/` + contentId)
                      }}
                      generateSimilar={() => {
                        createLoop((k as any).data);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ContentInspirationsPage;
