
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FunctionComponent } from "react";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";

interface ControlButtonsProps {
    onImageSave: () => void
}

const ControlButtons: FunctionComponent<ControlButtonsProps> = (props: ControlButtonsProps) => {
    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);


    return <>
        <div className="w-full h-[85px] py-[15px] bg-white shadow justify-between items-center flex shrink-0">
            <div className="w-[52px] self-stretch px-2.5 py-2 opacity-0 bg-white rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex">
                <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">Back</div>
            </div>
            <div className="w-[260px] self-stretch justify-start items-center gap-2.5 flex">
                <PrimaryButton
                    title={"Cancel"}
                    noFill
                    className="w-[72px] h-11 mr-2"
                    onClick={function (): void {
                        dispatch(setNewContent({ ...newContent, wizardView: ContentWizardView.contentDetails }));
                    }}
                />
                <PrimaryButton
                    title={"Choose Training"}
                    className="w-[151px] h-11 mr-2"
                    onClick={props.onImageSave}
                />
            </div>
        </div>
    </>


}

export default ControlButtons;