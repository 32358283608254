import { SVGProps } from "react";
import Icon from "../Icon";
import SvgRadioSelected from "../../assets/IconComponents/RadioSelected";
import SvgRadioUnselected from "../../assets/IconComponents/RadioUnselected";
import { IBrandData } from "./BrandSelector";
import BrandLogo from "../BrandLogo";
import { BrandRequest } from "../../services/BrandApi";

export interface IBrandSelectorItemProps {
    data: BrandRequest
    selected: boolean
    itemSelected: (data: BrandRequest) => void
}

function BrandSelectorItem(props: IBrandSelectorItemProps) {
    const { selected = false } = props

    return (
        <div className={`h-[42px] mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center p-2 justify-between
        ${selected ? "bg-lightBg border-1 border-border rounded-[5px]" : ""}
        `}
            onClick={() => {
                props.itemSelected(props.data)
            }}
        >
            <div className="flex flex-row items-center">
                <div>
                    <BrandLogo brand={props.data} />
                </div>
                <text className="text-BodyMedium text-black ml-2">{props.data.brand_name}</text>
            </div>
            {selected &&
                <SvgRadioSelected />
            }
            {!selected &&
                <SvgRadioUnselected />
            }
        </div>
    );
}

export default BrandSelectorItem;
