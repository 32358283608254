export class Utils {
  static formatTimestampToDateString(timestamp) {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are 0-based, so add 1
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  static formatDaysUntilRenewal(timestamp) {
    const today = new Date();
    const targetDate = new Date(timestamp);
    const timeDiff = targetDate.getTime() - today.getTime();
    const daysUntilRenewal = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return daysUntilRenewal;
  }

  static formatPlatformName = (platformName: string): string => {
    const platformMappings: { [key: string]: string } = {
      "Twitter | Thread": "X (Twitter) Thread",
      "Instagram | Caption": "Instagram Post",
      "Twitter | Single post": "X (Twitter) Post",
      "Blog | Blog": "Blog",
      "": "Creative",
      "undefined":"Creative"
    };

    return platformMappings[platformName] || platformName;
  };
}

export const reformatText = (text) => {
  if (!text) return "";
  let l = '';
  text.toLowerCase().split('_').forEach(k => {
    l += `${k.charAt(0).toUpperCase()}${k.substring(1, k.length)} `
  })
  return l.trim()
}

export const testReg = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i

