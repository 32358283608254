import { Help } from "../assets/IconComponents";
import Warning from "../assets/IconComponents/Warning";
import Icon from "./Icon"
import { HTMLInputTypeAttribute, SVGProps, useEffect, useState } from "react";

export enum InputTextState {
    normal,
    disabled,
    error,
    active
}

export interface IInputTextProps {
    title?: string
    prefix?: string
    placeholder?: string
    state?: InputTextState
    onChange?: (e: string) => void
    onBlur?: (e: string) => void
    onClickSideText?: () => void
    icon?: any
    alternateIcon?: any
    error?: string
    info?: string
    isSecure?: boolean
    className?: string
    type?: HTMLInputTypeAttribute
    defaultValue?: string
    value?: string
    innerClassName? : string
    forcedBg? : string
    color? : string
    sideBySide?: boolean
    sideBySideText? : string
    iconPosition? : string ;
    maxLengthAllowed?: number;
    labelIcon?: any
    iconTitle?: string
}

function InputText(props: IInputTextProps) {
    const {
      title,
      placeholder,
      iconPosition,
      alternateIcon,
      onChange,
      state = InputTextState.normal,
      icon,
      error,
      info,
      isSecure,
      labelIcon,
      iconTitle
    } = props;

    const [localState, setLocalState] = useState<InputTextState>(InputTextState.normal)
    const [isAlternateIconOn, setIsAlternateIconOn] = useState(false);

    return (
      <div
        className={`flex  ${props.className} ${
          props.sideBySide ?? false
            ? "flex-row justify-between "
            : "flex-col w-full"
        }`}
      >
          {title && (
              <div className="flex flex-row">
                  <div className="text-H8 text-black mb-2 flex items-center" title={iconTitle}>{title}
                      {labelIcon && (
                          <Icon
                              width="20"
                              height="20"
                              component={labelIcon}
                              viewBox="0 0 20 20"
                              className="ml-1 inline-block"
                          />
                      )}
                  </div>
              </div>
          )}
        <div
          className={`flex flex-row w-1/2 h-12 border-border border-1 ${
            props.innerClassName ? props.innerClassName : "rounded-xl"
          } ${
            props.sideBySide ?? false ? "w-1/2" : "w-full"
          } px-3 items-center ${
            props.forcedBg
              ? props.forcedBg
              : state === InputTextState.disabled ||
                localState === InputTextState.normal
              ? "bg-inputBg"
              : ""
          }
            `}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {(iconPosition ?? "start") === "start" && (
            <>
              {icon && (
                <div
                  onClick={() => {
                    if (alternateIcon) {
                      setIsAlternateIconOn(!isAlternateIconOn);
                    }
                  }}
                >
                  <Icon
                    width="15"
                    height="15"
                    component={isAlternateIconOn ? alternateIcon : icon}
                    viewBox="0 0 20 20"
                    color={
                      props.color
                        ? props.color
                        : state === InputTextState.disabled ||
                          localState === InputTextState.normal
                        ? "#999999"
                        : "#00000"
                    }
                    className="mr-3"
                  />
                </div>
              )}
            </>
          )}
          {props.prefix && (
            <text
              className={`flex text-BodyMedium text-black h-full items-center ${
                props.forcedBg
                  ? props.forcedBg
                  : state === InputTextState.disabled ||
                    localState === InputTextState.normal
                  ? "bg-inputBg"
                  : ""
              }
            `}
            >
              {props.prefix}
            </text>
          )}
          <input
            className={`text-BodyMedium text-black flex flex-1 h-full outline-none
            ${
              props.forcedBg
                ? props.forcedBg
                : state === InputTextState.disabled ||
                  localState === InputTextState.normal
                ? "bg-inputBg"
                : ""
            }
                `}
            style={{ color: props.color ? props.color : undefined }}
            maxLength={props.maxLengthAllowed}
            value={props.value}
            defaultValue={props.defaultValue}
            disabled={state === InputTextState.disabled}
            placeholder={placeholder}
            onFocus={() => {
              setLocalState(InputTextState.active);
            }}
            onBlur={(e) => {
              setLocalState(state);
              if (props.onBlur) {
                props.onBlur((props.prefix ?? "") +(e.target.value ?? ""));
              }
            }}
            onChange={(e) => {
              if (onChange) onChange((props.prefix ?? "") + (e.target.value ?? ""));
            }}
            type={
              props.type
                ? props.type
                : (!isAlternateIconOn && alternateIcon) || isSecure
                ? "password"
                : "text"
            }
            onKeyPress={(event) => {
              if (props.type === "number") {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }
            }}
          />
          {(iconPosition ?? "start") === "end" && (
            <>
              {icon && (
                <div
                  onClick={() => {
                    if (alternateIcon) {
                      setIsAlternateIconOn(!isAlternateIconOn);
                    }
                  }}
                >
                  <Icon
                    width="15"
                    height="15"
                    component={isAlternateIconOn ? alternateIcon : icon}
                    viewBox="0 0 20 20"
                    color={
                      props.color
                        ? props.color
                        : state === InputTextState.disabled ||
                          localState === InputTextState.normal
                        ? "#999999"
                        : "#00000"
                    }
                    className="mr-3"
                  />
                </div>
              )}
            </>
          )}

          {props.sideBySideText && (
            <text
              className="text-H8 text-red text-end cursor-pointer"
              style={{ userSelect: "none" }}
              onClick={() => {
                if (props.onClickSideText) {
                  props.onClickSideText();
                }
              }}
            >
              {props.sideBySideText}
            </text>
          )}
        </div>
        {error && (
          <div className="flex flex-row mt-2 items-start">
            <Warning width="20" height="20" color="#FF5555" className="mr-2" />
            <text className="text-red text-BodySmall">{error}</text>
          </div>
        )}
        {info && (
          <div className="flex flex-row mt-2 items-center">
            <Help width="20" height="20" color="#999999" className="mr-2" />
            <text className="text-gray text-BodySmall">{info}</text>
          </div>
        )}
      </div>
    );
}

export default InputText;
