
import type { SVGProps } from "react";
const SvgGender = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        {...props}
    >
        <path id="Vector" d="M12.8117 0.5H10.4489C10.1549 0.5 9.91686 0.73803 9.91686 1.03207C9.91686 1.3261 10.1549 1.56413 10.4489 1.56413H11.5271L10.2232 2.86805C9.46356 2.26772 8.50619 1.90718 7.46481 1.90718C5.00225 1.90718 3 3.90943 3 6.37198C3 8.65252 4.72046 10.5375 6.93274 10.8018V11.8292H5.85811C5.56407 11.8292 5.32604 12.0672 5.32604 12.3612C5.32604 12.6553 5.56407 12.8933 5.85811 12.8933H6.93274V13.9679C6.93274 14.262 7.17077 14.5 7.46481 14.5C7.75884 14.5 7.99687 14.2637 7.99687 13.9697V12.8951H9.07151C9.36555 12.8951 9.60358 12.657 9.60358 12.363C9.60358 12.0689 9.36555 11.8309 9.07151 11.8309H7.99687V10.8035C10.2074 10.5393 11.9296 8.65427 11.9296 6.37373C11.9296 5.3376 11.5708 4.38374 10.9757 3.62589L12.2797 2.32198V3.39836C12.2797 3.6924 12.5177 3.93043 12.8117 3.93043C13.1058 3.93043 13.3438 3.6924 13.3438 3.39836V1.03557C13.3438 0.73803 13.1058 0.5 12.8117 0.5ZM7.46481 9.77091C5.59032 9.77091 4.06588 8.24647 4.06588 6.37198C4.06588 4.4975 5.59032 2.97306 7.46481 2.97306C9.33929 2.97306 10.8637 4.4975 10.8637 6.37198C10.8637 8.24647 9.33929 9.77091 7.46481 9.77091Z" fill="#8E8E8E" stroke="#8E8E8E" strokeWidth="0.25" />
    </svg>
);
export default SvgGender;
