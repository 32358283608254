import * as React from "react";
import type { SVGProps } from "react";
const SvgSwipeUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#fff"}
      d="M5.005 17.765a.791.791 0 0 0 .578.247c.22 0 .412-.082.577-.247l4.867-4.867 4.868 4.895a.729.729 0 0 0 .564.233.83.83 0 0 0 .564-.261.791.791 0 0 0 .247-.578.791.791 0 0 0-.247-.577l-5.418-5.417A.837.837 0 0 0 11.33 11a.917.917 0 0 0-.619 0c-.1.037-.197.1-.288.193l-5.445 5.444a.729.729 0 0 0-.234.564.83.83 0 0 0 .261.564Zm0-6.958a.791.791 0 0 0 .578.248c.22 0 .412-.082.577-.248l4.867-4.867 4.868 4.895a.729.729 0 0 0 .564.234.83.83 0 0 0 .564-.262.791.791 0 0 0 .247-.577.791.791 0 0 0-.247-.578l-5.418-5.417a.837.837 0 0 0-.275-.192.918.918 0 0 0-.619 0c-.1.036-.197.1-.288.192L4.977 9.68a.729.729 0 0 0-.234.564.83.83 0 0 0 .261.563Z"
    />
  </svg>
);
export default SvgSwipeUpIcon;
