import { t } from "i18next";
import { FunctionComponent, MutableRefObject, RefObject, useEffect, useState } from "react";
import SvgCloseLittle from "../../../assets/IconComponents/CloseLittle";
import SvgImportIcon from "../../../assets/IconComponents/ImportIcon";
import SvgUploadIcon from "../../../assets/IconComponents/UploadIcon";
import ContentContainer from "../../../components/ContentContainer";
import InputText from "../../../components/InputText";
import PrimaryButton from "../../../components/PrimaryButton";
import TextView from "../../../components/TextView";
import SvgArrowUp from "../../../assets/IconComponents/ArrowUp";
import PickerView, { PickerOptions } from "../../../components/PickerView";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import TagSelector from "../../../components/TagSelector/TagSelector";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";
import { Keyword, useLazyRefreshQuery } from "../../../services/KeywordApi";
import { AiRefreshKeywordResponse, BrandKeywords } from "../../../services/BrandApi";
import { ContentLanguages } from "../../../constants/ContentLanguages";

interface BrandHubBrandToneProps {
  ref: RefObject<HTMLDivElement>
  expanded: () => void
  keywords: BrandKeywords[]
  refreshKeywords: () => void
  className?: string
}

const BrandHubBrandTone: FunctionComponent<BrandHubBrandToneProps> = (props: BrandHubBrandToneProps) => {
  const [collapsed, setCollapsed] = useState(false)
  const brandInfo = useAppSelector(k => k.brand.newBrand)
  const dispatch = useAppDispatch()

  // useEffect(() => {
  // dispatch(setNewBrandInfo({
  //     ...brandInfo,
  //     selectedKeywords: props.keywords
  // }))
  // }, [props.keywords])

  return (
    <ContentContainer
      ref={props.ref}
      className={`mt-[20px] w-full z-30 ${props.className}`}
      hoverable={false}
      children={
        <>
          <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
            <span className="text-H6 text-black ml-[15px] ">
              {t("brand-tone")}
            </span>
            <button
              onClick={(e) => {
                setCollapsed(!collapsed);
                props.expanded();
              }}
            >
              <SvgArrowUp
                width={22}
                height={22}
                className={`mr-3 transition-all transform-gpu ${collapsed ? "rotate-180" : "rotate-0"
                  }`}
              />
            </button>
          </div>

          {!collapsed && (
            <div className="flex flex-col w-full p-[20px]">
              <PickerView
                className="mt-2 flex-1"
                title={t("brand-tone-0") ?? ""}
                items={TonOfVoices}
                selectedValue={brandInfo.brandTones}
                // maxSelectionCount={1}
                allowMultiSelect={false}
                onChange={(e) => {
                  dispatch(
                    setNewBrandInfo({
                      ...brandInfo,
                      brandTones: e.map((k) => k.value ?? ""),
                    })
                  );
                }}
              />

              <div className="mt-2">
                <TagSelector
                  selectedTags={brandInfo.selectedKeywords}
                  refreshKeywords={() => {
                    props.refreshKeywords();
                  }}
                  tagsSelected={(e) => {
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedKeywords: e,
                      })
                    );
                  }}
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
}

export default BrandHubBrandTone;
