import {LeftOutlined} from "@ant-design/icons";
import ChannelList from "./SubStepComponents/ChannelList";

const AddChannelStep = (props) => {

    return (
        <div className={'w-[350px] h-full'}>
            {props.title && (
                <div>
                    <div className="p-3 flex justify-between items-center">
                        <div className={'flex items-center'}>
                            <span className="text-black mr-1 cursor-pointer" onClick={props.closeSideModal}><LeftOutlined/></span>
                            <span className="text-H8"> {props.title}</span>
                        </div>
                        <div>
                           <span className="text-gray text-xs"> {props.description ?? ''}</span>
                        </div>
                    </div>
                    <hr className="bg-border mx-1"/>
                </div>
            )}
            <div className={'w-full h-[calc(100%-55px)] px-5'}>
                <ChannelList selectedChannels={props.selectedChannels} setSelectedChannel={props.setSelectedChannel} activity={props.activity} />
            </div>
        </div>
    )
}

export default AddChannelStep;
