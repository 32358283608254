import * as React from "react";
import type { SVGProps } from "react";
const SvgHideLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      d="M17.765 16.995C17.93 16.83 18.0125 16.6375 18.0125 16.4175C18.0125 16.1975 17.93 16.005 17.765 15.84L12.8975 10.9725L17.7925 6.10501C17.9575 5.94001 18.0354 5.75209 18.0263 5.54126C18.0171 5.33043 17.93 5.14251 17.765 4.97751C17.6 4.81251 17.4075 4.73001 17.1875 4.73001C16.9675 4.73001 16.775 4.81251 16.61 4.97751L11.1925 10.395C11.1008 10.4867 11.0367 10.5783 11 10.67C10.9633 10.7617 10.945 10.8625 10.945 10.9725C10.945 11.0825 10.9633 11.1879 11 11.2888C11.0367 11.3896 11.1008 11.4858 11.1925 11.5775L16.6375 17.0225C16.8025 17.1875 16.9904 17.2654 17.2013 17.2563C17.4121 17.2471 17.6 17.16 17.765 16.995ZM10.8075 16.995C10.9725 16.83 11.055 16.6375 11.055 16.4175C11.055 16.1975 10.9725 16.005 10.8075 15.84L5.94001 10.9725L10.835 6.10501C11 5.94001 11.0779 5.75209 11.0688 5.54126C11.0596 5.33043 10.9725 5.14251 10.8075 4.97751C10.6425 4.81251 10.45 4.73001 10.23 4.73001C10.01 4.73001 9.8175 4.81251 9.6525 4.97751L4.23501 10.395C4.14334 10.4867 4.07917 10.5783 4.04251 10.67C4.00584 10.7617 3.9875 10.8625 3.9875 10.9725C3.9875 11.0825 4.00584 11.1879 4.04251 11.2888C4.07917 11.3896 4.14334 11.4858 4.23501 11.5775L9.68001 17.0225C9.84501 17.1875 10.0329 17.2654 10.2438 17.2563C10.4546 17.2471 10.6425 17.16 10.8075 16.995Z"
      fill="black"
    />
  </svg>
);
export default SvgHideLeft;
